"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getQueryParams = exports.getPaymentOptions = void 0;
var _deskingCommons = require("@sm360/desking-commons");
var _selectors = require("../selectors");
var _paymentOptions = require("./paymentOptions");
/**
 * @param {Object} state
 * @returns {Object}
 */
const getQueryParams = state => {
  const {
    location,
    organizationId,
    organizationUnitId
  } = (0, _selectors.getConfig)(state);
  return {
    location,
    organizationId,
    organizationUnitId
  };
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getQueryParams = getQueryParams;
const getPaymentOptions = state => {
  const {
    saleType,
    taxPlan
  } = (0, _selectors.getPlan)(state);
  const purchaseMethod = (0, _selectors.getPurchaseMethod)(state);
  const tradeIn = (0, _selectors.getTradeIn)(state);
  const selectedFinancePlan = (0, _paymentOptions.getSelectedFinancePlan)(state);
  return {
    ...purchaseMethod,
    ...tradeIn,
    saleType,
    taxPlan,
    financePan: selectedFinancePlan,
    priceIncreaseRollCount: _deskingCommons.priceIncreaseSelectors.getPriceIncreaseRollCount(state)
  };
};
exports.getPaymentOptions = getPaymentOptions;