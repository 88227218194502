"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _requestPaymentOptions = require("../../selectors/requestPaymentOptions");
function* _default() {
  const pathParams = yield (0, _effects.select)(_requestPaymentOptions.getPathParams);
  const queryParams = yield (0, _effects.select)(_requestPaymentOptions.getQueryParams);
  const body = yield (0, _effects.select)(_requestPaymentOptions.getBody);
  if (!body.configurationId) return;
  try {
    return yield (0, _effects.call)(_commonsApi.ShowroomService.builder.updatePaymentOptions, pathParams, queryParams, body);
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'getModel'));
    return null;
  }
}