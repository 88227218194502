"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.DECISION_STATUS_COLOR = exports.DECISION_STATUS = void 0;
const DECISION_STATUS = exports.DECISION_STATUS = {
  PENDING: 'Pending',
  APPROVED: 'Approved',
  DECLINED: 'Declined',
  CONDITIONAL: 'Conditional',
  CANCELLED: 'Cancelled',
  WITHDRAWN: 'Withdrawn',
  ERROR: 'Error',
  PASSED: 'Passed',
  FAILED: 'Failed'
};
const DECISION_STATUS_COLOR = exports.DECISION_STATUS_COLOR = {
  PENDING: 'warning',
  APPROVED: 'success',
  DECLINED: 'alert',
  CONDITIONAL: 'warning',
  CANCELLED: 'warning',
  WITHDRAWN: 'warning',
  ERROR: 'alert',
  PASSED: 'success',
  FAILED: 'alert'
};
var _default = exports.default = {
  DECISION_STATUS,
  DECISION_STATUS_COLOR
};