"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetSelected = exports.default = void 0;
var _filters = require("../../constants/filters");
var _filters2 = require("../selectors/filters");
/**
 * @returns {Object}
 */
const resetSelected = () => Object.keys(_filters.KEYS).reduce((result, key) => {
  result[_filters.KEYS[key]] = [];
  return result;
}, {});

/**
 * @param {Object} state - Global store
 * @return {Object} new composed global store
 */
exports.resetSelected = resetSelected;
var _default = state => {
  const filters = (0, _filters2.getFilters)(state);
  return {
    ...state,
    filters: {
      ...filters,
      searchText: '',
      priceRange: {
        maxPrice: _filters.MAX_PRICE,
        selectedMaxPrice: _filters.MAX_PRICE
      },
      odometerRange: {
        maxOdometer: _filters.MAX_ODOMETER,
        selectedMaxOdometer: _filters.MAX_ODOMETER
      },
      selected: resetSelected()
    }
  };
};
exports.default = _default;