"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _commonsUtils = require("@sm360/commons-utils");
var _effects = require("redux-saga/effects");
var _deskingError = require("@sm360/desking-error");
var _uiLibrary = require("@sm360/ui-library");
var _actions = require("../../actions");
var _tabProtection = require("../../selectors/tabProtection");
var _reloadVehicle = _interopRequireDefault(require("../reloadVehicle"));
var _selectors = require("../../selectors");
var _updatePayOptAndTransDetails = _interopRequireDefault(require("../api/updatePayOptAndTransDetails"));
var _refreshQuote = _interopRequireDefault(require("../refreshQuote"));
var _bookFNIItems = _interopRequireDefault(require("./bookFNIItems"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const {
  toast
} = _uiLibrary.Toast;

/**
 * @returns {Object}
 * @param {String} fniType
 */
function _default(_ref) {
  let {
    fniType
  } = _ref;
  return function* () {
    const t = yield (0, _effects.getContext)('t');
    yield (0, _effects.put)(_actions.Creators.setIsFetchingFNIItems());
    const items = yield (0, _effects.select)(_tabProtection.getFNIItemsToBook, fniType);
    try {
      const response = yield (0, _effects.call)(_bookFNIItems.default, items);
      if (response) {
        const bookedItems = [];
        const unBookedItemsMessages = [];
        response.items.forEach(item => {
          if (!item.isBooked && !(0, _commonsUtils.isEmpty)(item.bookError)) {
            unBookedItemsMessages.push(item.bookError);
          } else if (item.isBooked) {
            bookedItems.push(item);
          }
        });
        if (!(0, _commonsUtils.isEmpty)(bookedItems)) {
          yield (0, _effects.put)(_actions.Creators.onBookFNIItemsClickSuccess(fniType, bookedItems));
        }
        if (!(0, _commonsUtils.isEmpty)(unBookedItemsMessages) || !response.isValid) {
          yield (0, _effects.put)(_actions.Creators.openInvalidResponseModal(response.validationMessage, unBookedItemsMessages));
        }
        if (response.isValid && !response.quoteSaved) {
          const paymentOptions = yield (0, _effects.select)(_selectors.getPaymentOptions);
          yield (0, _effects.call)(_updatePayOptAndTransDetails.default, {
            ...paymentOptions,
            transactionToken: response.transactionToken,
            callCommission: true
          });
          yield (0, _effects.call)(_reloadVehicle.default);
        } else if (response.quoteSaved) {
          yield (0, _effects.call)(_refreshQuote.default);
          toast.success(t('confirmModifications.changesSaved'));
        }
      }
      yield (0, _effects.put)(_actions.Creators.setIsFetchingFNIItemsDone());
    } catch (error) {
      yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'bookFNIItems'));
      yield (0, _effects.put)(_actions.Creators.setIsFetchingFNIItemsDone());
    }
  }();
}