"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getStatusDetails = exports.getShowConflict = exports.getQuoteStatus = exports.getIsQuoteUpdating = exports.getIsQuoteDelivered = void 0;
var _commonsApi = require("@sm360/commons-api");
var _selectors = require("../selectors");
var _vehicle = require("./vehicle");
/* eslint-disable import/no-cycle */

/**
 * @param {Object} state
 * @returns {Boolean}
 */
const getIsQuoteUpdating = state => state.isQuoteUpdating === true;

/**
 * @param {Object} state
 * @returns {String|null}
 */
exports.getIsQuoteUpdating = getIsQuoteUpdating;
const getQuoteStatus = state => {
  const {
    status
  } = (0, _selectors.getQuote)(state);
  return typeof status === 'string' && status.length > 0 ? status : null;
};

/**
 * @param {Object} state
 * @returns {String|null}
 */
exports.getQuoteStatus = getQuoteStatus;
const getVehicleStatus = state => {
  const {
    status
  } = (0, _vehicle.getVehicle)(state);
  return typeof status === 'string' && status.length > 0 ? status : null;
};

/**
 * @param {Object} state
 * @returns {Boolean}
 */
const getShowConflict = state => {
  const {
    showConflict
  } = state;
  return showConflict === true;
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getShowConflict = getShowConflict;
const getStatusDetails = state => {
  const isCatalog = (0, _vehicle.getIsCatalog)(state);
  const isUnlistedVehicle = (0, _selectors.getIsUnlistedVehicle)(state);
  const quoteStatus = getQuoteStatus(state);
  const vehicleStatus = getVehicleStatus(state);
  const found = _commonsApi.StatusService.STATUS_MATCHING_LIST.find(match => match.quoteStatus === quoteStatus && match.vehicleStatus === vehicleStatus);
  return {
    quoteStatus,
    vehicleStatus,
    showWarning: !isCatalog && !isUnlistedVehicle && found === undefined,
    showConflict: getShowConflict(state)
  };
};

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getStatusDetails = getStatusDetails;
const getIsQuoteDelivered = state => {
  const status = getQuoteStatus(state);
  return status === _commonsApi.StatusService.QUOTE_STATUS.DELIVERED;
};
exports.getIsQuoteDelivered = getIsQuoteDelivered;