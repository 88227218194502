"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _selectors = require("../selectors");
/**
 * @param {Object} state
 * @param {Object} payload - { organizationUnitId }
 * @returns {Object}
 */
const setOrganizationUnitId = (state, _ref) => {
  let {
    organizationUnitId
  } = _ref;
  return {
    ...state,
    config: {
      ...(0, _selectors.getConfig)(state),
      organizationUnitId
    }
  };
};
var _default = exports.default = setOrganizationUnitId;