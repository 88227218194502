"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _actions = require("../../redux/actions");
var _selectors = require("../../redux/selectors");
var _offerSheet = require("../../redux/selectors/offerSheet");
var _StatusBar = _interopRequireDefault(require("./StatusBar"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const mapStateToProps = (state, t) => ({
  contact: (0, _offerSheet.getContact)(state),
  newVehicle: (0, _offerSheet.getNewVehicle)(state),
  tradeIn: (0, _offerSheet.getTradeIn)(state),
  purchaseMethod: (0, _offerSheet.getPurchaseMethod)(state, t),
  dealDetails: (0, _offerSheet.getDealDetails)(state),
  leaseMethodOptions: (0, _offerSheet.getLeaseMethodOptions)(state, t),
  financeMethodOptions: (0, _offerSheet.getFinanceMethodOptions)(state, t),
  isReadOnly: (0, _selectors.getIsReadOnly)(state),
  leadId: (0, _selectors.getLeadId)(state),
  transactionPrintList: (0, _selectors.getTransactionPrintList)(state),
  transactionPrintCustomFields: (0, _selectors.getTransactionPrintCustomFields)(state),
  hasDeskingAccess: (0, _selectors.getHasDeskingAccess)(state),
  state
});
const mapDispatchToProps = dispatch => ({
  setReadOnly: isReadOnly => dispatch(_actions.Creators.setReadOnly(isReadOnly)),
  transactionPrint: (formInstanceId, customFields, checkedLang) => dispatch(_actions.Creators.transactionPrint(formInstanceId, customFields, checkedLang)),
  getTransactionPrintCustomFields: (formInstanceId, checkedLang) => dispatch(_actions.Creators.getTransactionPrintCustomFields(formInstanceId, checkedLang)),
  getTransactionPrintList: () => dispatch(_actions.Creators.getTransactionPrintList()),
  setEmptyTransactionPrintList: () => dispatch(_actions.Creators.setTransactionPrintList([])),
  setCustomerVisibility: isVisible => dispatch(_actions.Creators.setCustomerVisibility(isVisible))
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps))(_StatusBar.default);