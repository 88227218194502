"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UiSchema = exports.Schema = void 0;
const customMessage = {
  required: 'commons.validation.required',
  email: 'commons.validation.email'
};
const Schema = exports.Schema = {
  type: 'object',
  properties: {
    mailServerId: {
      title: 'profile-imap-config.manualForm.mailServerId',
      type: 'number',
      enum: [],
      customMessage
    },
    mailUsername: {
      title: 'profile-imap-config.manualForm.mailUsername',
      type: 'string',
      description: 'profile-imap-config.manualForm.mailUsernameHelper',
      customMessage
    },
    mailPassword: {
      title: 'profile-imap-config.manualForm.mailPassword',
      type: 'string',
      customMessage
    },
    mailPasswordConfirmation: {
      title: 'profile-imap-config.manualForm.mailPasswordConfirmation',
      type: 'string',
      customMessage
    }
  },
  required: ['mailServerId', 'mailUsername', 'mailPassword', 'mailPasswordConfirmation']
};
const UiSchema = exports.UiSchema = {
  mailPassword: {
    'ui:type': 'password'
  },
  mailPasswordConfirmation: {
    'ui:type': 'password'
  }
};