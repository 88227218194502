"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MIN_DELAY = exports.MAX_DELAY = exports.FAKE_DATA = void 0;
const MIN_DELAY = exports.MIN_DELAY = 60; // 1 minute in seconds
const MAX_DELAY = exports.MAX_DELAY = 2678400; // 31 days in seconds

const FAKE_DATA = exports.FAKE_DATA = [{
  id: 3132,
  userId: 23688,
  organizationId: 11,
  delay: 120,
  leadMediaIds: [71],
  leadTypeIds: [2]
}, {
  id: 3134,
  userId: 23688,
  organizationId: 11,
  delay: 3720,
  leadMediaIds: [71, 7972],
  leadTypeIds: [2, 3]
}, {
  id: 3134,
  userId: 23688,
  organizationId: 11,
  delay: 3600,
  leadMediaIds: [],
  leadTypeIds: []
}, {
  id: 3134,
  userId: 23688,
  organizationId: 11,
  delay: 3600,
  leadMediaIds: [],
  leadTypeIds: []
}, {
  id: 3134,
  userId: 23688,
  organizationId: 11,
  delay: 3600,
  leadMediaIds: [],
  leadTypeIds: []
}];