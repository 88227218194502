"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _commonsUtils = require("@sm360/commons-utils");
var _paymentOptions = require("../../../../redux/selectors/paymentOptions");
var _PaymentBarExpander = _interopRequireDefault(require("./PaymentBarExpander"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} state
 * @returns {Object}
 */
const mapStateToProps = state => (0, _commonsUtils.pick)((0, _paymentOptions.getPaymentBarData)(state), ['isCashSelected', 'paymentFrequency', 'purchaseMethod', 'term']);
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps))(_PaymentBarExpander.default);