"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _deskingError = require("@sm360/desking-error");
var _selectors = require("../../../redux/selectors");
var _actions = require("../../../redux/actions");
var _matches = require("../../../redux/selectors/matches");
var _MatchesVehicles = _interopRequireDefault(require("./MatchesVehicles"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const mapStateToProps = state => {
  return {
    isReadOnly: (0, _selectors.getIsReadOnly)(state),
    hasMatches: (0, _matches.getHasMatches)(state),
    perfectMatches: (0, _matches.getPerfectMatches)(state),
    perfectMatchesCount: (0, _matches.getPerfectMatchesCount)(state),
    similarMatches: (0, _matches.getSimilarMatches)(state),
    similarMatchesCount: (0, _matches.getSimilarMatchesCount)(state),
    vehiclesWidgetParams: (0, _selectors.getVehiclesWidgetParams)(state)
  };
};
const mapDispatchToProps = dispatch => ({
  loadMatches: transactionToken => dispatch(_actions.Creators.loadMatches(transactionToken))
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps), _deskingError.withErrorWrapper)(_MatchesVehicles.default);