"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validVin = exports.validPostalCode = exports.validPhoneNumber = exports.validJson = exports.validIpAdress = exports.validHostname = exports.validEmailOrDomain = exports.validEmail = exports.validDate = exports.optional = void 0;
var _constants = require("./constants");
const validPhoneNumber = phoneValue => {
  if (phoneValue) {
    let phoneString = phoneValue;
    if (typeof phoneValue !== 'string') phoneString = String(phoneValue);
    return _constants.PHONE_REGEX.test(phoneString);
  }
  return false;
};
exports.validPhoneNumber = validPhoneNumber;
const validPostalCode = postalCode => {
  if (postalCode) {
    return _constants.CA_US_POSTAL_CODE_REGEX.test(postalCode);
  }
  return false;
};
exports.validPostalCode = validPostalCode;
const validEmail = email => {
  if (email) {
    return _constants.EMAIL_REGEX.test(email.toLowerCase());
  }
  return false;
};
exports.validEmail = validEmail;
const validEmailOrDomain = email => {
  if (email) {
    return _constants.EMAIL_OR_DOMAIN_REGEX.test(email);
  }
  return false;
};
exports.validEmailOrDomain = validEmailOrDomain;
const validDate = date => {
  if (date) {
    const dateObj = new Date(date);
    return dateObj instanceof Date && !isNaN(dateObj);
  }
  return false;
};
exports.validDate = validDate;
const validVin = vin => {
  if (vin) {
    return _constants.VIN_REGEX.test(vin);
  }
  return false;
};
exports.validVin = validVin;
const validJson = str => {
  if (typeof str !== 'string') return false;
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
exports.validJson = validJson;
const validIpAdress = ip => {
  if (ip) {
    return _constants.IP_REGEX.test(ip);
  }
  return false;
};
exports.validIpAdress = validIpAdress;
const validHostname = hostname => {
  if (hostname) {
    return _constants.HOSTNAME_REGEX.test(hostname);
  }
  return false;
};

// Allow a validation function to be optional (accept falsy values)
exports.validHostname = validHostname;
const optional = validator => {
  return function (value) {
    for (var _len = arguments.length, rest = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      rest[_key - 1] = arguments[_key];
    }
    return !value || validator(value, ...rest);
  };
};
exports.optional = optional;