"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _actions = require("../actions");
var _getGarageAndQuote = _interopRequireDefault(require("./getGarageAndQuote"));
var _hasDeskingAccess = _interopRequireDefault(require("./api/hasDeskingAccess"));
var _getDealerInfo = _interopRequireDefault(require("./api/getDealerInfo"));
var _getLeadInfo = _interopRequireDefault(require("./api/getLeadInfo"));
var _canCreateUnlistedVehicle = _interopRequireDefault(require("./api/canCreateUnlistedVehicle"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function* _default() {
  yield (0, _effects.call)(_getLeadInfo.default);
  yield (0, _effects.call)(_getDealerInfo.default);
  yield (0, _effects.call)(_getGarageAndQuote.default);
  yield (0, _effects.call)(_hasDeskingAccess.default);
  yield (0, _effects.call)(_canCreateUnlistedVehicle.default);
  yield (0, _effects.put)(_actions.Creators.setLoadingSuccess());
}