"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _actions = require("../actions");
var _selectors = require("../selectors");
var _loadModel = _interopRequireDefault(require("./loadModel"));
var _loadPaymentOptions = _interopRequireDefault(require("./loadPaymentOptions"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * When a year has been selected, go to next step.
 */
function* _default() {
  const {
    currentStep
  } = yield (0, _effects.select)(_selectors.getNav);
  yield (0, _effects.call)(_loadModel.default);
  yield (0, _effects.call)(_loadPaymentOptions.default);
  yield (0, _effects.put)(_actions.Creators.setCurrentStep(currentStep + 1, currentStep));
}