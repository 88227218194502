"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useTransmissionTypes = void 0;
var _appsCore = require("@sm360/apps-core");
var _VehicleStaticDataService = require("../../api/VehicleStaticDataService");
/**
 * @returns {Object} a Query object
 */

const useTransmissionTypes = params => {
  return (0, _appsCore.useQuery)({
    queryKey: ['transmissionTypes'],
    queryFn: _VehicleStaticDataService.getVehicleTransmissionTypes,
    ...params
  });
};
exports.useTransmissionTypes = useTransmissionTypes;