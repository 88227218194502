"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _content = require("../selectors/content");
/**
 * @param {Object} state
 * @param {Object} payload - { similarMatches, similarMatches }
 */
var _default = (state, _ref) => {
  let {
    perfectMatches,
    similarMatches
  } = _ref;
  return {
    ...state,
    content: {
      ...(0, _content.getContent)(state),
      perfectMatches,
      similarMatches
    }
  };
};
exports.default = _default;