"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isPricingItemValid = void 0;
/**
 * @param {Object} item - { value, label }
 * @returns {Boolean}
 */
const isPricingItemValid = _ref => {
  let {
    value
  } = _ref;
  return typeof value === 'number' && value !== 0;
};
exports.isPricingItemValid = isPricingItemValid;