"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTradeIn = exports.getIsTradeInOutsideRegistry = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _commonsApi = require("@sm360/commons-api");
/**
 * For now, only 0 or 1 vehicle.
 * The MS can return an empty object, so we only test on 'make' param.
 *
 * @param {Object} state
 * @returns {Object|null}
 */
const getTradeIn = state => {
  const {
    tradeIn
  } = state;
  if (!(0, _commonsUtils.isPlainObject)(tradeIn) || (0, _commonsUtils.isEmpty)(tradeIn.make)) {
    return null;
  }
  const {
    bodyStyle,
    evaluatorValues,
    conditionForm,
    kilometers,
    make,
    model,
    paymentType: paymentTypeEnum,
    trim,
    vin,
    year
  } = tradeIn;
  let actualCashValue;
  let estimatedValue;
  let outsideRegistry;
  let reserve;
  if ((0, _commonsUtils.isPlainObject)(evaluatorValues)) {
    ({
      actualCashValue,
      estimatedValue,
      reserve
    } = evaluatorValues);
  }
  if ((0, _commonsUtils.isPlainObject)(conditionForm)) {
    ({
      outsideRegistry
    } = conditionForm);
  }
  const parsedOdometer = parseInt(kilometers, 10);
  const odometer = Number.isNaN(parsedOdometer) ? 0 : parsedOdometer;
  const paymentType = Object.values(_commonsApi.TradeInService.PAYMENT_TYPE).indexOf(paymentTypeEnum) !== -1 ? paymentTypeEnum : null;
  return {
    actualCashValue,
    bodyStyle,
    estimatedValue,
    evaluatorValues,
    outsideRegistry,
    isMissingVin: (0, _commonsUtils.isEmpty)(vin),
    make,
    model,
    odometer,
    paymentType,
    reserve,
    trim,
    year
  };
};

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getTradeIn = getTradeIn;
const getIsTradeInOutsideRegistry = state => {
  const {
    outsideRegistry
  } = getTradeIn(state);
  return outsideRegistry === 'true';
};
exports.getIsTradeInOutsideRegistry = getIsTradeInOutsideRegistry;