"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
function* _default() {
  try {
    const hasAccess = yield (0, _effects.call)(_commonsApi.AuthorizationService.hasDeskingAccess);
    if (!hasAccess) {
      yield (0, _effects.put)(_deskingError.errorActions.handleError(new Error(), 'forbidden'));
    }
    return hasAccess;
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'getRights'));
    return false;
  }
}