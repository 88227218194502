"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.associateCall = void 0;
var _appsCore = require("@sm360/apps-core");
const NEW_CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;

// Associate a call to a lead and always resolve the leadId
const associateCall = async _ref => {
  let {
    activityId,
    leadId,
    contactId,
    userId,
    organizationId,
    organizationUnitId
  } = _ref;
  return await _appsCore.axios.put(`${NEW_CRM_ENDPOINT}/activities/${activityId}/call/associateLeadOrContact?userId=${userId}&organizationId=${organizationId}&type=Call&contactId=${contactId}${leadId ? `&leadId=${leadId}` : ''}${organizationUnitId ? `&organizationUnitId=${organizationUnitId}` : ''}`).then(() => Promise.resolve(leadId)).catch(() => Promise.resolve(leadId));
};
exports.associateCall = associateCall;