"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _uiLibrary = require("@sm360/ui-library");
var _actions = require("../../actions");
var _selectors = require("../../selectors");
/**
 */
function _default(_ref) {
  let {
    formInstanceId,
    form
  } = _ref;
  return function* () {
    yield (0, _effects.put)(_actions.Creators.setIsQuoteUpdating());
    const quoteId = yield (0, _effects.select)(_selectors.getQuoteId);
    const queryParams = yield (0, _effects.select)(_selectors.getShowroomQueryParams);
    try {
      const updatedDealJacket = yield (0, _effects.call)(_commonsApi.ShowroomService.desking.uploadDealJacketForm, quoteId, formInstanceId, queryParams, form);
      if (updatedDealJacket) {
        yield (0, _effects.put)(_actions.Creators.updateTabFormsSuccess(updatedDealJacket));
      }
      yield (0, _effects.delay)(1500);
      yield (0, _effects.put)(_actions.Creators.setIsQuoteUpdatingDone());
    } catch (error) {
      _uiLibrary.Toast.toast.error(`${error}`);
      yield (0, _effects.put)(_actions.Creators.setIsQuoteUpdatingDone());
    }
  }();
}