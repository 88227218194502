"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _loadFilters = _interopRequireDefault(require("./loadFilters"));
var _loadVehicles = _interopRequireDefault(require("./loadVehicles"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function* _default() {
  yield (0, _effects.all)([(0, _effects.call)(_loadFilters.default), (0, _effects.call)(_loadVehicles.default)]);
}