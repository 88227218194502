"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _effects = require("redux-saga/effects");
var _selectors = require("../selectors");
var _actions = require("../actions");
var _api = require("./api");
var _loadCommission = _interopRequireDefault(require("./loadCommission"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Trigger the loadQuote saga to refresh the quote and hide the confirmation message
 */
function* refreshQuote() {
  const quoteId = yield (0, _effects.select)(_selectors.getQuoteId);
  const isFinancingDatesChanged = yield (0, _effects.select)(_selectors.getIsFinancingDatesChanged);
  if (isFinancingDatesChanged) {
    yield (0, _effects.call)(_api.loadQuoteWithDates, quoteId);
  }
  yield (0, _effects.call)(_api.loadQuote, quoteId);
  yield (0, _effects.call)(_loadCommission.default);
  yield (0, _effects.put)(_actions.Creators.setQuoteChanged(false, null));
}
var _default = exports.default = refreshQuote;