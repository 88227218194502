"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _propTypes = _interopRequireDefault(require("prop-types"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const financePlanShape = _propTypes.default.shape({
  cashDown: _propTypes.default.number.isRequired,
  paymentFrequency: _propTypes.default.number.isRequired,
  sellingPrice: _propTypes.default.number.isRequired,
  term: _propTypes.default.shape({
    apr: _propTypes.default.number.isRequired,
    payment: _propTypes.default.number.isRequired,
    term: _propTypes.default.number.isRequired
  })
});
var _default = exports.default = _propTypes.default.shape({
  bestIncentives: _propTypes.default.shape({
    total: _propTypes.default.number
  }),
  bestIncentivesDisclaimer: _propTypes.default.string,
  cashPurchase: _propTypes.default.shape({
    sellingPrice: _propTypes.default.number.isRequired
  }),
  exteriorColourPhoto: _propTypes.default.shape({
    side: _propTypes.default.string
  }),
  finance: financePlanShape,
  lease: financePlanShape,
  model: _propTypes.default.shape({
    name: _propTypes.default.string
  }),
  year: _propTypes.default.number
});