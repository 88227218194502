"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/**
 * @param {Object} state
 * @param {Object} payload
 * @returns {Object}
 */
var _default = (state, _ref) => {
  let {
    transactionPrintCustomFields
  } = _ref;
  return {
    ...state,
    transactionPrintCustomFields
  };
};
exports.default = _default;