"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.userHasAccessTo = void 0;
const userHasAccessTo = function () {
  let userRights = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  let rights = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return rights?.every(right => userRights?.includes(right));
};
exports.userHasAccessTo = userHasAccessTo;