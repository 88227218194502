"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _tabItems = require("../../../constants/tabItems");
var _actions = require("../../actions");
var _tabItems2 = require("../../selectors/tabItems");
var _getItems = _interopRequireDefault(require("./getItems"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function* _default() {
  const {
    categories,
    name
  } = yield (0, _effects.select)(_tabItems2.getSelectedTab);
  // Safety check
  if (Object.values(_tabItems.TAB_ITEMS).indexOf(name) === -1) {
    return;
  }
  const items = yield (0, _effects.call)(_getItems.default, categories);
  if (items) {
    yield (0, _effects.put)(_actions.Creators.reloadTabItemsSuccess(name, items));
  }
}