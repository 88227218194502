"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _tabProtection = require("../selectors/tabProtection");
/**
 * @param {Object} state
 * @param {String} fniType
 * @param {Object[]} bookedItems
 * @returns {Object}
 */
var _default = (state, _ref) => {
  let {
    fniType,
    bookedItems
  } = _ref;
  const fniTab = (0, _tabProtection.getFNITab)(state, fniType);
  const clonedBookedItems = structuredClone(bookedItems);
  if ((0, _commonsUtils.isEmpty)(fniTab) || (0, _commonsUtils.isEmpty)(clonedBookedItems)) {
    return state;
  }
  const updateIsBooked = items => {
    items.every((item, itemsIndex) => {
      return item.plans.every((plan, planIndex) => {
        const matchingBookedItemIndex = clonedBookedItems.findIndex(bookedItem => bookedItem.persistentId === plan.persistentId);
        if (matchingBookedItemIndex !== -1) {
          items[itemsIndex].plans[planIndex].isBooked = true;
          clonedBookedItems.splice(matchingBookedItemIndex, 1);
        }
        return !(0, _commonsUtils.isEmpty)(clonedBookedItems);
      });
    });
  };
  updateIsBooked(fniTab.content.warranties);
  if (!(0, _commonsUtils.isEmpty)(clonedBookedItems)) {
    updateIsBooked(fniTab.content.protections);
  }
  if (!(0, _commonsUtils.isEmpty)(clonedBookedItems)) {
    updateIsBooked(fniTab.content.insurances);
  }
  return {
    ...state,
    ...fniTab
  };
};
exports.default = _default;