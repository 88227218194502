"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _actions = require("../../actions");
var _selectors = require("../../selectors");
var _vehicle = require("../../selectors/vehicle");
function _default(_ref) {
  let {
    incentive,
    quoteFlagsParams
  } = _ref;
  return function* () {
    const {
      organizationId,
      organizationUnitId,
      location,
      lang
    } = yield (0, _effects.select)(_selectors.getConfig);
    const queryParams = {
      orgId: organizationId,
      orgUnitId: organizationUnitId,
      location,
      lang
    };
    const quoteId = yield (0, _effects.select)(_selectors.getQuoteId);
    const vehicle = yield (0, _effects.select)(_vehicle.getVehicle);
    const paymentOptions = yield (0, _effects.select)(_selectors.getPaymentOptions);
    const body = {
      incentive: {
        ...incentive
      },
      transactionToken: yield (0, _effects.select)(_selectors.getTransactionToken),
      saleType: paymentOptions.saleType,
      taxPlan: paymentOptions.taxPlan,
      vehicleCondition: paymentOptions.vehicleCondition,
      odometer: vehicle.odometer,
      purchaseMethod: paymentOptions.purchaseMethod,
      financePlan: paymentOptions.financePlan,
      kmPerYearPlan: paymentOptions.kmPerYearPlan,
      paymentFrequency: paymentOptions.paymentFrequency,
      term: paymentOptions.term,
      cashDown: paymentOptions.cashDown,
      tradeIn: paymentOptions.tradeIn,
      lien: paymentOptions.lien
    };
    try {
      const response = yield (0, _effects.call)(_commonsApi.QuoteService.setIncentive, quoteId, queryParams, body);
      yield (0, _effects.put)(_actions.Creators.updateTransactionToken({
        transactionToken: response.negociationTransaction.transactionToken
      }));
      yield (0, _effects.put)(_actions.Creators.reloadIncentives());
      yield (0, _effects.put)(_actions.Creators.setQuoteFlags({
        ...quoteFlagsParams,
        cashDown: response.negociationTransaction.cashDown
      }));
      return response;
    } catch (error) {
      yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'setIncentive'));
      return null;
    }
  }();
}