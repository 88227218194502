"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FINANCE_FORM = void 0;
const sourceString = 'contact.contactFinancing';
const translationPrefix = 'leads.updateView.financeForm';
const FINANCE_FORM = exports.FINANCE_FORM = [{
  legend: `${translationPrefix}.JOB`,
  fieldset: '',
  fields: [{
    input: 'input',
    className: 'small-12 medium-5',
    name: `${sourceString}.job`,
    label: `${translationPrefix}.TITLE`
  }, {
    input: 'select',
    className: 'small-12 medium-3',
    name: `${sourceString}.jobType`,
    label: `${translationPrefix}.JOB_TYPE`,
    optionLabelValue: 'label'
  }, {}, {
    input: 'input',
    className: 'small-12 medium-5',
    name: `${sourceString}.employer`,
    label: `${translationPrefix}.EMPLOYER`
  }, {
    input: 'select',
    className: 'small-6 medium-2',
    name: `${sourceString}.jobStartMonth`,
    label: `${translationPrefix}.SINCE`,
    optionLabelValue: 'label'
  }, {
    input: 'select',
    className: 'small-6 medium-2',
    name: `${sourceString}.jobStartYear`,
    label: '',
    optionLabelValue: 'label'
  }, {
    input: 'inputCurrency',
    className: 'small-12 medium-3',
    name: `${sourceString}.monthlyIncome`,
    label: `${translationPrefix}.MONTHLY_INCOME`
  }, {}, {
    input: 'inputNumber',
    className: 'small-12 medium-5',
    name: `${sourceString}.socialNumber`,
    label: `${translationPrefix}.SOCIAL_NUMBER`,
    customValidation: /^[0-9]{3} ?[0-9]{3} ?[0-9]{3}$/,
    format: '### ### ###'
  }]
}, {
  legend: `${translationPrefix}.RESIDENCE`,
  fieldset: '',
  fields: [{
    input: 'input',
    className: 'small-12 medium-6',
    name: `${sourceString}.oldAddress`,
    label: `${translationPrefix}.OLD_ADDRESS`
  }, {}, {
    input: 'input',
    className: 'small-12 medium-6',
    name: `${sourceString}.currentAddress`,
    label: `${translationPrefix}.CURRENT_ADDRESS`
  }, {
    input: 'select',
    className: 'small-6 medium-2',
    name: `${sourceString}.homeStartMonth`,
    label: `${translationPrefix}.SINCE`,
    optionLabelValue: 'label'
  }, {
    input: 'select',
    className: 'small-6 medium-2',
    name: `${sourceString}.homeStartYear`,
    label: '',
    optionLabelValue: 'label'
  }, {}, {
    input: 'select',
    className: 'small-12 medium-3',
    name: `${sourceString}.rentType`,
    label: `${translationPrefix}.RENT_TYPE`,
    optionLabelValue: 'label'
  }, {
    input: 'inputCurrency',
    className: 'small-12 medium-3',
    name: `${sourceString}.rent`,
    label: `${translationPrefix}.RENT`
  }]
}, {
  legend: `${translationPrefix}.APPROVAL`,
  fieldset: '',
  fields: [{
    input: 'select',
    className: 'small-12 medium-3',
    name: `${sourceString}.approvalStatus`,
    label: `${translationPrefix}.APPROVAL_STATUS`,
    optionLabelValue: 'label'
  }, {
    input: 'inputCurrency',
    className: 'small-12 medium-3',
    name: `${sourceString}.approvalAmount`,
    label: `${translationPrefix}.APPROVAL_AMOUNT`
  }, {}, {
    input: 'textarea',
    className: 'small-12 medium-6',
    name: `${sourceString}.info`,
    label: `${translationPrefix}.INFO`
  }]
}];
// Approval
// Approval Status approvalStatus (select)

// Approval Amount approvalAmount (number)
// Extra Information info (textarea)

// Job
// Title job
// Job Type jobType

// Employer employer
// Since jobStartMonth (select) jobStartYear (select)

// Monthly Income monthlyIncome
// Social Number socialNumber

// Residence
// Old Address oldAddress
// Current Address currentAddress
// Since homeStartMonth (select) homeStartYear (select)
// Landlord / Tenant rentType
// Rent rent

// Last Modification Date: Jun 13, 2022 3:21:54 PM

// approvalAmount: null
// approvalStatus: {id: 1, statusFr: "En traitement", statusEn: "In treatment", status: "In treatment"}
// currentAddress: null
// dateLastModified: 1655746166000
// employer: null
// financingType: {id: 3, typeFr: "Inconnu", typeEn: "Unknown", type: "Unknown"}
// homeStartMonth: null
// homeStartYear: null
// id: 10982865
// info: null
// job: null
// jobStartMonth: null
// jobStartYear: null
// jobType: {id: 3, typeFr: "Domicile", typeEn: "Home", type: "Home"}
// monthlyIncome: null
// oldAddress: null
// rent: null
// rentType: {id: 3, rentTypeFr: "Inconnu", rentTypeEn: "Unknown", rentType: "Unknown"}
// socialNumber: null

// .constant('JOB_TYPES', [
//     { id: 1, value: 'JOB_INSURANCE' },
//     { id: 2, value: 'SOCIAL_INSURANCE' },
//     { id: 3, value: 'HOME' },
//     { id: 4, value: 'STUDENT' },
//     { id: 5, value: 'RETIRED' },
//     { id: 6, value: 'FULL_TIME' },
//     { id: 7, value: 'PART_TIME' },
//     { id: 8, value: 'CONTRACT' },
//     { id: 9, value: 'TEMPORARY_INVALID' },
//     { id: 10, value: 'PERMANENTLY_INVALID' },
//     { id: 11, value: 'PARENTAL_BREAK' },
//     { id: 12, value: 'SELF_EMPLOYED' },
//     { id: 13, value: 'OTHER' }
// ])
// .constant('RENT_TYPES', [
//     { id: 1, value: 'TENANT' },
//     { id: 2, value: 'LANDLORD' },
//     { id: 3, value: 'UNKNOWN' }
// ])
// .constant('APPROVAL_STATUSES', [
//     { id: 1, value: 'IN_TREATMENT' },
//     { id: 2, value: 'PRE_APPROBATION' },
//     { id: 3, value: 'CONDITIONAL_APPROBATION' },
//     { id: 4, value: 'REFUSED' }
// ])