"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _actions = require("../actions");
var _addVehicleToGarage = _interopRequireDefault(require("./addVehicleToGarage"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function* _default(payload) {
  const {
    vehicle
  } = payload;
  const quote = yield (0, _addVehicleToGarage.default)(payload);
  if (quote) {
    yield (0, _effects.put)(_actions.Creators.selectVehicleSuccess(vehicle.vehicleId, quote.id));
  }
}