"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _deskingError = require("@sm360/desking-error");
var _selectors = require("../../redux/selectors");
var _finance = require("../../redux/selectors/finance");
var _actions = require("../../redux/actions");
var _PaymentBar = _interopRequireDefault(require("./PaymentBar"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const mapStateToProps = state => {
  const finance = (0, _finance.getQuoteFinance)(state);
  const isCashDownIncludesTaxes = (0, _finance.getIsCashDownIncludesTaxes)(state);
  const isRebatesIncludesTaxes = (0, _finance.getIsRebatesIncludesTaxes)(state);
  const securityDepositIsWaived = (0, _finance.getSecurityDepositIsWaived)(state);
  const salesDeposit = (0, _finance.getSalesDeposit)(state);
  const aprDetails = (0, _finance.getAprDetails)(state);
  const {
    cashDownTaxesDetails
  } = finance;
  return {
    isSinglePaymentLeaseSelected: (0, _selectors.getIsSinglePaymentLeaseSelected)(state),
    isCashSelected: (0, _selectors.getIsCashSelected)(state),
    isLeaseSelected: (0, _selectors.getIsLeaseSelected)(state),
    canEditInterestRates: (0, _selectors.getCanEditInterestRates)(state),
    securityDepositDetails: (0, _finance.getSecurityDepositDetails)(state),
    cashDownTaxesDetails,
    isCashDownIncludesTaxes,
    isRebatesIncludesTaxes,
    securityDepositIsWaived,
    salesDeposit,
    isMsdAllowedForSelectedPlan: (0, _selectors.getIsMsdAllowedForSelectedPlan)(state),
    cashOnlyRebatesAdjustment: aprDetails.cashOnlyRebatesAdjustment,
    adminFeesNotChargedToCashAdjustment: aprDetails.adminFeesNotChargedToCashAdjustment
  };
};

/**
 * @param {Function} dispatch
 * @returns {Object} injected props
 */
const mapDispatchToProps = dispatch => ({
  setQuoteFlags: params => dispatch(_actions.Creators.setQuoteFlags(params))
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps), _deskingError.withErrorWrapper)(_PaymentBar.default);