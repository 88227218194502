//import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ApplicationProvider } from '@sm360/apps-core';
import packageJson from '../package.json';
import App from './App';
import '@sm360/ui-crm/dist/css/crm.css';

const container = document.getElementById('rap-root');
const root = ReactDOM.createRoot(container);

root.render(<ApplicationProvider app={<App />} packageJson={packageJson} />);
