"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getQueryParams = exports.getPaymentOptions = void 0;
var _deskingCommons = require("@sm360/desking-commons");
var _selectors = require("../selectors");
/**
 * @param {Object} state
 * @returns {Object}
 */
const getQueryParams = state => {
  const {
    location,
    organizationId,
    organizationUnitId
  } = (0, _selectors.getConfig)(state);
  return {
    location,
    organizationId,
    organizationUnitId
  };
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getQueryParams = getQueryParams;
const getPaymentOptionsPayload = state => {
  const {
    cashDown,
    kmPerYearPlan,
    lien,
    paymentFrequency,
    purchaseMethod,
    saleType,
    taxPlan,
    term,
    tradeIn
  } = (0, _selectors.getPaymentOptions)(state);
  return {
    cashDown,
    kmPerYearPlan,
    lien,
    paymentFrequency,
    purchaseMethod,
    saleType,
    taxPlan,
    term,
    tradeIn,
    priceIncreaseRollCount: _deskingCommons.priceIncreaseSelectors.getPriceIncreaseRollCount(state)
  };
};
exports.getPaymentOptions = getPaymentOptionsPayload;