"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useVehicleMakes = void 0;
var _appsCore = require("@sm360/apps-core");
var _VehicleStaticDataService = require("../../api/VehicleStaticDataService");
/**
 * @returns {Object} a Query object
 */

const useVehicleMakes = params => {
  const {
    organizationId,
    ...rest
  } = params || {};
  return (0, _appsCore.useQuery)({
    queryKey: ['makes', {
      organizationId
    }],
    queryFn: () => (0, _VehicleStaticDataService.getVehicleMakes)(organizationId),
    ...rest
  });
};
exports.useVehicleMakes = useVehicleMakes;