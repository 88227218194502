"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QUICK_TO_PAY_ON_DELIVERY_EDIT = exports.QUICK_TO_PAY_EDIT = exports.QUICK_SELLING_PRICE_EDIT = exports.QUICK_PAYMENT_EDIT = exports.QUICK_ACCESSORY_EDIT = void 0;
const QUICK_SELLING_PRICE_EDIT = exports.QUICK_SELLING_PRICE_EDIT = 'sellingPrice';
const QUICK_TO_PAY_EDIT = exports.QUICK_TO_PAY_EDIT = 'toPay';
const QUICK_TO_PAY_ON_DELIVERY_EDIT = exports.QUICK_TO_PAY_ON_DELIVERY_EDIT = 'toPayOnDelivery';
const QUICK_ACCESSORY_EDIT = exports.QUICK_ACCESSORY_EDIT = 'accessory';
const QUICK_PAYMENT_EDIT = exports.QUICK_PAYMENT_EDIT = 'payment';