"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _actions = require("../../actions");
var _selectors = require("../../selectors");
/**
 * @param {Object} id
 */
function selectQuote(_ref) {
  let {
    id
  } = _ref;
  return function* () {
    const queryParams = yield (0, _effects.select)(_selectors.getQuoteServiceQueryParams);
    try {
      const selectedQuote = yield (0, _effects.call)(_commonsApi.QuoteService.getQuote, id, queryParams);
      yield (0, _effects.put)(_actions.Creators.loadQuoteSuccess(selectedQuote));
    } catch (error) {
      yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'getQuote'));
    }
  }();
}
var _default = exports.default = selectQuote;