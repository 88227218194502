"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _selectors = require("../selectors");
/**
 * No need to use composeNewBaseProps selector, always same behavior
 *  - Reset "previous" params
 *  - Keep prev token
 *
 * @param {Object} state
 * @param {Object} payload - { configurationId, modelTrimCode }
 */
var _default = (state, _ref) => {
  let {
    configurationId,
    modelTrimCode
  } = _ref;
  return {
    ...state,
    baseProps: {
      ...(0, _selectors.getBaseProps)(state),
      configurationId,
      modelTrimCode,
      previousConfigurationId: 0,
      previousModelTrimCode: ''
    }
  };
};
exports.default = _default;