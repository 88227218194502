"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getConfiguredProviders = void 0;
var _appsCore = require("@sm360/apps-core");
const CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;
const getConfiguredProviders = async (organization, userId, redirectUrl) => {
  const dealersQueryString = organization?.organizationUnits?.map(u => `&carDealerIds=${u.key}`)?.join('') || '';
  const userIdQueryString = userId ? `&userId=${userId}` : '';
  return await _appsCore.axios.get(`${CRM_ENDPOINT}/dealer-configurations/mailServer/providers?redirectUrl=${redirectUrl}${dealersQueryString}${userIdQueryString}`);
};
exports.getConfiguredProviders = getConfiguredProviders;