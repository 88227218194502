"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _actions = require("../../../../redux/actions");
var _selectors = require("../../../../redux/selectors");
var _InputPayment = _interopRequireDefault(require("./InputPayment"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} state - Global state
 * @returns {Object} injected props
 */
const mapStateToProps = state => {
  const financeOptions = (0, _selectors.getSelectedFinanceOptions)(state);
  const {
    selectedPayment
  } = financeOptions;
  const hasTaxesIncluded = (0, _selectors.getHasTaxesIncluded)(state);
  let value = 0;
  let min = 0;
  let max = 0;
  if (selectedPayment) {
    value = hasTaxesIncluded ? selectedPayment.totalPayment : selectedPayment.payment;
    [min, max] = hasTaxesIncluded ? selectedPayment.validTotalPaymentRange : selectedPayment.validPaymentRange;
  }
  const isReadOnly = (0, _selectors.getIsReadOnly)(state);
  return {
    item: selectedPayment,
    min,
    max,
    value,
    isReadOnly,
    hasTaxesIncluded
  };
};

/**
 * @param {Function} dispatch
 * @returns {Object} injected props
 */
const mapDispatchToProps = dispatch => ({
  changePayment: (targetType, value) => dispatch(_actions.Creators.changeDesiredPayment(targetType, value)),
  changeTaxes: value => dispatch(_actions.Creators.updateTaxesIncluded(value))
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps))(_InputPayment.default);