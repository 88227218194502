"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _selectors = require("../../redux/selectors");
var _StatusBar = _interopRequireDefault(require("./StatusBar"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const mapStateToProps = state => {
  return {
    leadId: (0, _selectors.getLeadId)(state)
  };
};
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps))(_StatusBar.default);