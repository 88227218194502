"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.vehicleHasVin = exports.getVehicleId = exports.getVehicleDetailDates = exports.getVehicle = exports.getPaymentDetails = exports.getNewIncentive = exports.getIsUsedCertified = exports.getIsUsed = exports.getIsQuoteInProgress = exports.getIsInventory = exports.getIsDemo = exports.getIsCatalog = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _commonsApi = require("@sm360/commons-api");
var _selectors = require("../selectors");
// eslint-disable-next-line import/no-cycle

/**
 * @param {Object} state
 * @returns {Object}
 */
const getVehicle = state => {
  const {
    vehicle
  } = (0, _selectors.getQuote)(state);
  return (0, _commonsUtils.isPlainObject)(vehicle) ? vehicle : {};
};

/**
 * @param {Object} state
 * @returns {Number}
 */
exports.getVehicle = getVehicle;
const getVehicleId = state => {
  const {
    id
  } = getVehicle(state);
  return id;
};

/**
 * @param {Object} state
 * @returns {String}
 */
exports.getVehicleId = getVehicleId;
const getVehicleDetailDates = state => {
  const {
    dateService,
    dateRetailDeliveryReports
  } = getVehicle(state);
  return {
    dateService,
    dateRetailDeliveryReports
  };
};

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getVehicleDetailDates = getVehicleDetailDates;
const getIsCatalog = state => {
  const {
    condition,
    stockNumber
  } = getVehicle(state);
  return condition === 'new' && (0, _commonsUtils.isEmpty)(stockNumber);
};

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getIsCatalog = getIsCatalog;
const getIsDemo = state => {
  const {
    condition,
    isDemo
  } = getVehicle(state);
  return isDemo === true || condition === 'demonstrator';
};

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getIsDemo = getIsDemo;
const getIsInventory = state => {
  const {
    vin,
    stockNumber
  } = getVehicle(state);
  return !(0, _commonsUtils.isEmpty)(vin) || !(0, _commonsUtils.isEmpty)(stockNumber);
};

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getIsInventory = getIsInventory;
const getIsQuoteInProgress = state => {
  const {
    status
  } = (0, _selectors.getQuote)(state);
  return status === _commonsApi.StatusService.QUOTE_STATUS.QUOTE;
};

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getIsQuoteInProgress = getIsQuoteInProgress;
const getIsUsed = state => {
  const {
    condition
  } = getVehicle(state);
  return condition === 'used';
};

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getIsUsed = getIsUsed;
const getIsUsedCertified = state => {
  const {
    condition
  } = getVehicle(state);
  return condition === 'usedCertified';
};

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getIsUsedCertified = getIsUsedCertified;
const vehicleHasVin = state => {
  const {
    vin
  } = getVehicle(state);
  return vin !== '';
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.vehicleHasVin = vehicleHasVin;
const getNewIncentive = state => {
  const {
    newIncentiveDisclaimer,
    newIncentiveIsMoreFavorable
  } = (0, _selectors.getQuote)(state);
  return {
    disclaimer: newIncentiveDisclaimer,
    isMoreFavorable: newIncentiveIsMoreFavorable
  };
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getNewIncentive = getNewIncentive;
const getPaymentDetails = state => {
  const isCashSelected = (0, _selectors.getIsCashSelected)(state);
  const {
    cashPurchase,
    finance
  } = (0, _selectors.getQuote)(state);
  const {
    cashDown,
    kmPerYearPlan,
    paymentFrequency,
    purchaseMethod,
    tradeIn
  } = (0, _selectors.getPaymentOptions)(state);
  let exclusionsDisclaimer;
  let incentives;
  let inclusionsDisclaimer;
  let rebate;
  let sellingPrice;
  let term = {};
  if (isCashSelected && (0, _commonsUtils.isPlainObject)(cashPurchase)) {
    let retailDetails;
    ({
      incentives,
      retailDetails,
      sellingPrice
    } = cashPurchase);
    if ((0, _commonsUtils.isPlainObject)(retailDetails)) {
      ({
        exclusionsDisclaimer,
        inclusionsDisclaimer
      } = retailDetails);
    }
  } else if ((0, _commonsUtils.isPlainObject)(finance)) {
    ({
      exclusionsDisclaimer,
      incentives,
      inclusionsDisclaimer,
      term
    } = finance);
  }
  if ((0, _commonsUtils.isPlainObject)(incentives)) {
    const {
      dealerRebates,
      total
    } = incentives;
    rebate = total - dealerRebates;
  }
  return {
    cashDown,
    exclusionsDisclaimer,
    inclusionsDisclaimer,
    kmPerYearPlan,
    paymentFrequency,
    purchaseMethod,
    rebate,
    sellingPrice,
    term,
    tradeIn
  };
};
exports.getPaymentDetails = getPaymentDetails;