"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _selectors = require("../../selectors");
var _default = (state, _ref) => {
  let {
    tradeInWidget
  } = _ref;
  return {
    ...state,
    tradeIn: {
      ...(0, _selectors.getTradeIn)(state),
      tradeInWidget
    }
  };
};
exports.default = _default;