"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _deskingError = require("@sm360/desking-error");
var _commonsApi = require("@sm360/commons-api");
var _deskingCommons = require("@sm360/desking-commons");
var _selectors = require("../../selectors");
var _config = require("../../../constants/config");
function* _default() {
  const {
    leadId
  } = yield (0, _effects.select)(_selectors.getConfig);
  const navigate = yield (0, _effects.getContext)('navigate');
  const deskingWidgetParams = yield (0, _effects.select)(_selectors.getDeskingWidgetParams);
  const {
    make,
    model,
    year,
    sellingPrice,
    odometer
  } = yield (0, _effects.select)(_selectors.getUnlistedVehicleFormData);
  const quoteServiceQueryParams = yield (0, _effects.select)(_selectors.getQuoteServiceQueryParams);
  const isUnlistedNew = yield (0, _effects.select)(_selectors.getIsUnlistedNew);
  const vehicleCondition = isUnlistedNew ? _config.VEHICLE_CATEGORY_NEW : _config.VEHICLE_CATEGORY_USED;
  const body = {
    leadId,
    accountId: 0,
    year,
    make,
    model,
    vehicleCondition,
    retail: 0,
    sellingPrice,
    odometer,
    // Backend team asked us to hardcode default values
    saleType: 'retail',
    taxPlan: 'standard',
    purchaseMethod: 'cash',
    financePlan: '',
    kmPerYearPlan: 0,
    paymentFrequency: 12,
    term: 0,
    cashDown: 0,
    tradeIn: 0,
    lien: 0,
    transactionTokenCopy: ''
  };
  try {
    const putUnlistedVehicleResponse = yield (0, _effects.call)(_commonsApi.QuoteService.putUnlistedVehicle, quoteServiceQueryParams, body);
    navigate((0, _deskingCommons.getDeskingPath)({
      quoteId: putUnlistedVehicleResponse.id,
      ...deskingWidgetParams,
      upg: 0
    }));
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'openUnlistedInDesking'));
  }
}