"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPaymentOptions = exports.getPaymentBarData = exports.getMetadata = exports.getIsLeaseSelected = exports.getIsCashSelected = void 0;
Object.defineProperty(exports, "getPurchaseMethod", {
  enumerable: true,
  get: function () {
    return _selectors.getPurchaseMethod;
  }
});
exports.getSelectedPurchaseMethodIndex = exports.getPurchaseMethodList = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _selectors = require("../selectors");
var _payment = require("../../constants/payment");
/**
 * @param {Object} state
 * @returns {Object}
 */
const getPaymentOptions = state => {
  const {
    paymentOptions
  } = state;
  return (0, _commonsUtils.isPlainObject)(paymentOptions) ? paymentOptions : {};
};

/**
 * @param {Object} state
 * @returns {Object[]}
 */
exports.getPaymentOptions = getPaymentOptions;
const getMetadata = state => {
  const {
    metadata
  } = getPaymentOptions(state);
  return Array.isArray(metadata) ? metadata : [];
};

/**
 * @param {Object} state
 * @param {String} method
 * @returns {Object}
 */
exports.getMetadata = getMetadata;
const getFinanceMetaData = (state, method) => {
  const metaData = getMetadata(state);
  const found = metaData.find(_ref => {
    let {
      purchaseMethod
    } = _ref;
    return purchaseMethod === method;
  });
  return (0, _commonsUtils.isPlainObject)(found) ? found : {};
};

/**
 * @param {Object} state
 * @returns {Boolean}
 */
const getIsCashSelected = state => {
  const paymentOptions = getPaymentOptions(state);
  const {
    purchaseMethod
  } = paymentOptions;
  return purchaseMethod === _payment.PURCHASE_METHOD_CASH;
};

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getIsCashSelected = getIsCashSelected;
const getIsLeaseSelected = state => {
  const paymentOptions = getPaymentOptions(state);
  const {
    purchaseMethod
  } = paymentOptions;
  return purchaseMethod === _payment.PURCHASE_METHOD_LEASE;
};

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getIsLeaseSelected = getIsLeaseSelected;
const getSelectedPurchaseMethodIndex = state => {
  const paymentOptions = getPaymentOptions(state);
  const {
    purchaseMethod
  } = paymentOptions;
  switch (purchaseMethod) {
    case _payment.PURCHASE_METHOD_CASH:
      return 0;
    case _payment.PURCHASE_METHOD_FINANCE:
      return 1;
    case _payment.PURCHASE_METHOD_LEASE:
      return 2;
    default:
      throw new Error('Purchase method is not valid');
  }
};

/**
 * @param {Object} state
 * @returns {Object[]}
 */
exports.getSelectedPurchaseMethodIndex = getSelectedPurchaseMethodIndex;
const getPurchaseMethodList = state => {
  const {
    purchaseMethod: selectedPurchaseMethod
  } = getPaymentOptions(state);
  const metadata = getMetadata(state);

  // Cash always available
  const list = [{
    name: _payment.PURCHASE_METHOD_CASH,
    isAvailable: true,
    isSelected: selectedPurchaseMethod === _payment.PURCHASE_METHOD_CASH
  }];
  let found;
  let item;
  [_payment.PURCHASE_METHOD_FINANCE, _payment.PURCHASE_METHOD_LEASE].forEach(name => {
    item = {
      name,
      isAvailable: false,
      isSelected: false
    };
    found = metadata.find(_ref2 => {
      let {
        purchaseMethod
      } = _ref2;
      return purchaseMethod === name;
    });
    if (!(0, _commonsUtils.isPlainObject)(found)) {
      list.push(item);
      return;
    }
    const {
      isAvailable
    } = found;
    item.isAvailable = isAvailable;
    item.isSelected = selectedPurchaseMethod === name;
    list.push(item);
  });
  return list;
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getPurchaseMethodList = getPurchaseMethodList;
const getCashPurchase = state => {
  const {
    cashPurchase
  } = getPaymentOptions(state);
  return (0, _commonsUtils.isPlainObject)(cashPurchase) ? cashPurchase : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
const getCashPurchaseData = state => {
  const {
    cashDown
  } = getPaymentOptions(state);
  const {
    lien,
    tradeIn,
    sellingPrice: payment
  } = getCashPurchase(state);
  return {
    cashDown,
    lien,
    payment,
    tradeIn
  };
};

/**
 * For finance and lease
 *
 * @param {Object} state
 * @returns {Object}
 */
const getFinanceData = state => {
  const {
    cashDown,
    kmPerYearPlan,
    lien,
    paymentFrequency,
    term,
    tradeIn
  } = getPaymentOptions(state);
  return {
    cashDown,
    kmPerYearPlan,
    lien,
    paymentFrequency,
    term,
    tradeIn
  };
};

/**
 * @param {Object} state
 * @returns {Object}
 */
const getPaymentBarData = state => {
  const purchaseMethod = (0, _selectors.getPurchaseMethod)(state);
  let selector;
  let kmPerYearPlans = [];
  let paymentFrequencies = [];
  let terms = [];
  switch (purchaseMethod) {
    case _payment.PURCHASE_METHOD_CASH:
      selector = getCashPurchaseData;
      break;
    case _payment.PURCHASE_METHOD_FINANCE:
    case _payment.PURCHASE_METHOD_LEASE:
      selector = getFinanceData;
      ({
        kmPerYearPlans,
        paymentFrequencies,
        terms
      } = getFinanceMetaData(state, purchaseMethod));
      break;
    default:
      throw new TypeError('The stored purchase method is incorrect.');
  }
  return {
    isCashSelected: getIsCashSelected(state),
    kmPerYearPlans,
    paymentFrequencies,
    purchaseMethod,
    terms,
    ...selector.call(null, state)
  };
};
exports.getPaymentBarData = getPaymentBarData;