"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _actions = require("../../actions");
var _selectors = require("../../selectors");
function* _default() {
  const leadId = yield (0, _effects.select)(_selectors.getLeadId);
  try {
    const leadSalesPerson = yield (0, _effects.call)(_commonsApi.LeadInfoService.getSalesPerson, leadId);
    const leadSalesManager = yield (0, _effects.call)(_commonsApi.LeadInfoService.getSalesManager, leadId);
    const leadBusinessManager = yield (0, _effects.call)(_commonsApi.LeadInfoService.getBusinessManager, leadId);
    const validateResult = (obj, key) => {
      return Object.prototype.hasOwnProperty.call(obj, key);
    };
    const salesperson = validateResult(leadSalesPerson, 'salespersonId') ? {
      ...leadSalesPerson
    } : {};
    const salesManager = validateResult(leadSalesManager, 'salesManagerId') ? {
      ...leadSalesManager
    } : {};
    const businessManager = validateResult(leadBusinessManager, 'businessManagerId') ? {
      ...leadBusinessManager
    } : {};
    const leadInfo = {
      salesperson: {
        ...salesperson
      },
      salesManager: {
        ...salesManager
      },
      businessManager: {
        ...businessManager
      }
    };
    yield (0, _effects.put)(_actions.Creators.setLeadInfo(leadInfo));
    return null;
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'getDealerInfo'));
    return false;
  }
}