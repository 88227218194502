"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useVehicleBodyStyle = void 0;
var _appsCore = require("@sm360/apps-core");
var _VehicleStaticDataService = require("../../api/VehicleStaticDataService");
var _utils = require("../../tools/utils");
const transformBodyStyleToOptions = data => (0, _utils.transformDataToOptions)(data, 'style');

/**
 * @returns {Object} a Query object
 */

const useVehicleBodyStyle = params => {
  const {
    category,
    select,
    ...rest
  } = params || {};
  return (0, _appsCore.useQuery)({
    queryKey: ['vehicleBodyStyle', {
      category
    }],
    queryFn: () => (0, _VehicleStaticDataService.getVehicleBodyStyle)(category),
    select: select || transformBodyStyleToOptions,
    ...rest
  });
};
exports.useVehicleBodyStyle = useVehicleBodyStyle;