"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getStaticData = exports.getEmployees = void 0;
var _appsCore = require("@sm360/apps-core");
var _commonsUtils = require("@sm360/commons-utils");
const CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;
const getData = query => query?.data || [];

// Get employees
const getEmployees = async _ref => {
  let {
    key,
    dispatch,
    right,
    body,
    translate
  } = _ref;
  const results = await _appsCore.axios.post(`${CRM_ENDPOINT}/users?activeOnly=true&withOrganizationUnitRight=${right}`, body);
  dispatch({
    type: 'setStaticData',
    staticData: [key, results.data.map(t => ({
      id: t.id,
      label: (0, _commonsUtils.getUserLabel)(t, translate),
      value: t.id,
      email: t.email
    })).sort((a, b) => (0, _commonsUtils.compareUserLabel)(a, b, translate))]
  });
};
exports.getEmployees = getEmployees;
const getStaticData = async _ref2 => {
  let {
    dispatch,
    organization,
    t,
    queries
  } = _ref2;
  const orgUnits = getData(queries.dealers);
  const orgUnitsIds = orgUnits.map(orgUnit => orgUnit.id);
  const territories = getData(queries.provinces);
  const provinces = territories.filter(t => t.countryCode === 'CA');
  const states = territories.filter(t => t.countryCode === 'US');

  // optional call
  let contactContactTags = {
    data: []
  };
  try {
    contactContactTags = await _appsCore.axios.get(`${CRM_ENDPOINT}/contacts/getContactTags/byOrganizationId?organizationId=${organization.id}`);
  } catch {}
  dispatch({
    type: 'initStaticData',
    staticData: {
      contactContactTags: contactContactTags.data.map(unitToMap => ({
        ...unitToMap,
        key: unitToMap.id,
        value: unitToMap.id,
        id: unitToMap.id,
        label: unitToMap.name || t('contacts.forms.profile.contactContactTags.NO_NAME')
      })).sort((a, b) => a.label.localeCompare(b.label)),
      organizationUnitIds: orgUnits,
      country: [{
        label: t(`contacts.forms.profile.localization.country.CANADA`),
        value: 'CA',
        id: 'CA'
      }, {
        label: t(`contacts.forms.profile.localization.country.USA`),
        value: 'US',
        id: 'US'
      }],
      territories: {
        CA: provinces,
        US: states
      },
      preferredContactMoment: [{
        label: t('contacts.forms.profile.contactInformation.preferredContactMoment.WEEKDAY'),
        value: 'WEEKDAY',
        id: 'WEEKDAY'
      }, {
        label: t('contacts.forms.profile.contactInformation.preferredContactMoment.WEEKEND'),
        value: 'WEEKEND',
        id: 'WEEKEND'
      }, {
        label: t('contacts.forms.profile.contactInformation.preferredContactMoment.ANY'),
        value: 'ANY',
        id: 'ANY'
      }],
      preferredContactTime: [{
        label: t(`contacts.forms.profile.contactInformation.preferredContactTime.MORNING`),
        value: 'MORNING',
        id: 'MORNING'
      }, {
        label: t(`contacts.forms.profile.contactInformation.preferredContactTime.AFTERNOON`),
        value: 'AFTERNOON',
        id: 'AFTERNOON'
      }, {
        label: t(`contacts.forms.profile.contactInformation.preferredContactTime.EVENING`),
        value: 'EVENING',
        id: 'EVENING'
      }, {
        label: t(`contacts.forms.profile.contactInformation.preferredContactTime.ANY`),
        value: 'ANY',
        id: 'ANY'
      }]
    },
    query: {
      filters: {
        organizationId: organization.id,
        organizationUnitIds: orgUnitsIds
      }
    }
  });
};
exports.getStaticData = getStaticData;