"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isLinkToParentSatisfied = exports.isAnswered = exports.countTotalQuestions = exports.countAnsweredQuestions = void 0;
const isEmpty = v => {
  if (Array.isArray(v)) {
    return v.length === 0;
  } else if (typeof v === 'object') {
    return Object.keys(v).length === 0;
  } else if (typeof v === 'boolean') {
    return false;
  } else {
    return !v;
  }
};
const isAnswered = answer => !isEmpty(answer) || isFinite(answer) || typeof answer === 'boolean';
exports.isAnswered = isAnswered;
const isLinkToParentSatisfied = (question, parentAnswer) => {
  if (!question || !question.linkToParent || !isAnswered(parentAnswer)) {
    return false;
  }
  const {
    linkToParent: {
      onlyFor,
      anyExcept
    }
  } = question;
  const equalsParentValue = value => value === parentAnswer || Array.isArray(parentAnswer) && parentAnswer.length === 1 && parentAnswer.includes(value);
  return !!onlyFor && equalsParentValue(onlyFor) || !!anyExcept && !equalsParentValue(anyExcept);
};
exports.isLinkToParentSatisfied = isLinkToParentSatisfied;
const countQuestions = (getBaseValue, shouldCountSubQuestion, model) => {
  const incrementForQuestion = (question, questionKey) => {
    if (!question) {
      return 0;
    }
    let increment = getBaseValue(questionKey);
    if (question.questions) {
      increment += Object.keys(question.questions).reduce((subQuestionsCount, subQuestionKey) => {
        const subQuestion = question.questions[subQuestionKey];
        return shouldCountSubQuestion(subQuestion, questionKey, subQuestionKey) ? subQuestionsCount + 1 : subQuestionsCount;
      }, 0);
    }
    return increment;
  };
  return typeof model.sections === 'object' && Object.keys(model.sections).length > 0 ? Object.values(model.sections).reduce((totalCount, section) => totalCount + Object.keys(section.questions).reduce((questionsCount, questionKey) => questionsCount + incrementForQuestion(section.questions[questionKey], questionKey), 0), 0) : 0;
};
const countTotalQuestions = (dataSource, model) => {
  return countQuestions(() => 1, (question, parentQuestionKey) => dataSource && isLinkToParentSatisfied(question, dataSource[parentQuestionKey]), model);
};
exports.countTotalQuestions = countTotalQuestions;
const countAnsweredQuestions = function () {
  let dataSource = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let model = arguments.length > 1 ? arguments[1] : undefined;
  return countQuestions(questionKey => dataSource && isAnswered(dataSource[questionKey]) ? 1 : 0, (question, parentQuestionKey, questionKey) => dataSource && isLinkToParentSatisfied(question, dataSource[parentQuestionKey]) && isAnswered(dataSource[questionKey]), model);
};
exports.countAnsweredQuestions = countAnsweredQuestions;