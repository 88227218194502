"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _effects = require("redux-saga/effects");
var _actions = require("../../actions");
var _selectors = require("../../selectors");
var _quoteChanges = require("../../../constants/quoteChanges");
var _updateCommissionDetails = _interopRequireDefault(require("../commission/updateCommissionDetails"));
var _updatePaymentOptions = _interopRequireDefault(require("./updatePaymentOptions"));
var _updateTransactionDetails = _interopRequireDefault(require("./updateTransactionDetails"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Update payment options and transaction details, call the 2 APIs
 * @param {Object} options parameters to send to the APIs
 */
function updatePayOptAndTransDetails(options) {
  let updateStore = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  let callConfirmAlert = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  return function* () {
    const quoteId = yield (0, _effects.select)(_selectors.getQuoteId);
    const queryParams = yield (0, _effects.select)(_selectors.getShowroomQueryParams);

    // Remove some data to not send
    const {
      cashPurchaseOption,
      ...paymentOptions
    } = options;
    const transactionOptions = {
      ...paymentOptions
    };
    delete transactionOptions.financeOptions;
    yield (0, _effects.all)([callConfirmAlert && (0, _effects.put)(_actions.Creators.setQuoteChanged(true, _quoteChanges.QUOTE_CHANGES_TYPE.MODIFY_QUOTE)), (0, _effects.call)(_updatePaymentOptions.default, quoteId, queryParams, paymentOptions, updateStore), (0, _effects.call)(_updateTransactionDetails.default, quoteId, queryParams, transactionOptions, updateStore), options.callCommission && (0, _effects.call)(_updateCommissionDetails.default, quoteId, queryParams, transactionOptions, updateStore)]);
  }();
}
var _default = exports.default = updatePayOptAndTransDetails;