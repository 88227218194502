"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  bodyStyle: 'car',
  kilometers: 'km',
  interiorColor: 'interior',
  exteriorColor: 'color'
};