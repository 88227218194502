"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _selectors = require("../selectors");
var _resetValidation = _interopRequireDefault(require("./common/resetValidation"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * We receive the new negociationTransaction params from the user choice, and put it in the store
 *
 * @param {Object} state
 * @param {Object} payload - { negociationTransaction }
 * @returns {Object}
 */
var _default = (state, _ref) => {
  let {
    negociationTransaction
  } = _ref;
  return {
    ...state,
    ...(0, _resetValidation.default)(),
    paymentOptions: {
      ...(0, _selectors.getPaymentOptions)(state),
      ...negociationTransaction
    }
  };
};
exports.default = _default;