"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _deskingCommons = require("@sm360/desking-commons");
var _commonsApi = require("@sm360/commons-api");
var _actions = require("../../actions");
var _selectors = require("../../selectors");
var _requestStatus = require("../../selectors/requestStatus");
var _api = require("../api");
var _quoteChanges = require("../../../constants/quoteChanges");
var _vehicle = require("../../selectors/vehicle");
var _status = require("../../selectors/status");
/**
 * A quote can change its status even if an error is triggered (error on vehicle or lead)
 *
 * @param {Object} payload - { status }
 */
function _default(_ref) {
  let {
    status
  } = _ref;
  return function* () {
    const body = yield (0, _effects.select)(_requestStatus.getBody);
    const isPreferred = yield (0, _effects.select)(_selectors.getIsPreferred);
    const quoteId = yield (0, _effects.select)(_selectors.getQuoteId);
    const accountId = yield (0, _effects.select)(_selectors.getAccountId);
    const isVehicleInInventory = yield (0, _effects.select)(_vehicle.getIsInventory);
    const showConflict = yield (0, _effects.select)(_status.getShowConflict);
    yield (0, _effects.put)(_actions.Creators.setIsQuoteUpdating());
    const preferredBody = {
      quoteId,
      accountId
    };
    const actionTriggersSetAsPreferred = () => {
      const {
        CANCEL_DELIVERY,
        CANCEL_SALE,
        REJECT_OFFER,
        RETURN_TO_SALES_MANAGER
      } = _commonsApi.StatusService.API_STATUS;
      switch (status) {
        case REJECT_OFFER:
        case CANCEL_DELIVERY:
        case CANCEL_SALE:
        case RETURN_TO_SALES_MANAGER:
          return false;
        default:
          return true;
      }
    };
    if (isVehicleInInventory && !showConflict) {
      // Reload the quote data to get the vehicle status updated.
      const quote = yield (0, _effects.call)(_api.loadQuote, quoteId);
      const {
        status: quoteStatus,
        vehicle
      } = quote || {};
      const {
        status: vehicleStatus
      } = vehicle || {};
      const unmatch = _commonsApi.StatusService.STATUS_UNMATCHING_LIST.find(match => match.quoteStatus === quoteStatus && match.vehicleStatus === vehicleStatus);
      yield (0, _effects.put)(_actions.Creators.reloadVehicleSuccess(vehicle));
      if (unmatch) {
        yield (0, _effects.put)(_actions.Creators.setShowConflict(true));
        // Do not update the quote status, because of conflict between both statuses
        // showConflict will open the conflict Modal and inform the user
        return;
      }
    }
    try {
      yield (0, _effects.call)(_commonsApi.StatusService.setStatus, status, body);
      if (!isPreferred && actionTriggersSetAsPreferred()) {
        yield (0, _effects.call)(_api.setPreferredQuote, preferredBody);
      }
    } catch (error) {
      yield (0, _effects.put)(_actions.Creators.setIsQuoteUpdatingDone());
      // TODO STDESK-44 commented because the problem is coming from Showroom
      // yield call(handleStatusError, error);
    }

    // Reload the quote data
    const quote = yield (0, _effects.call)(_api.loadQuote, quoteId);
    const {
      saleDate,
      deliveryDate,
      firstPaymentDate,
      lastPaymentDate,
      buyBackDate,
      status: quoteStatus
    } = quote || {};

    // If sale date isn't set in the quote when status is sold, set it
    if (!saleDate && status === _commonsApi.StatusService.API_STATUS.ACCEPT_OFFER && quoteStatus === _commonsApi.StatusService.QUOTE_STATUS.SOLD) {
      yield (0, _effects.put)(_actions.Creators.setFinancingDates({
        deliveryDate,
        firstPaymentDate,
        lastPaymentDate,
        buyBackDate,
        saleDate: (0, _deskingCommons.getTodayStartTimeStamp)()
      }));
      yield (0, _effects.put)(_actions.Creators.setFinancingDatesChanged(true));
      yield (0, _effects.put)(_actions.Creators.setQuoteChanged(false, _quoteChanges.QUOTE_CHANGES_TYPE.MODIFY_QUOTE));
      yield (0, _effects.put)(_actions.Creators.updateQuoteInformation());
    }

    // if sale date is set but the quote status went back to in approval, unset sale date
    if (saleDate && status === _commonsApi.StatusService.API_STATUS.RETURN_TO_SALES_MANAGER && quoteStatus === _commonsApi.StatusService.QUOTE_STATUS.APPROVAL) {
      yield (0, _effects.put)(_actions.Creators.setFinancingDates({
        deliveryDate,
        firstPaymentDate,
        lastPaymentDate,
        buyBackDate,
        saleDate: null
      }));
      yield (0, _effects.put)(_actions.Creators.setFinancingDatesChanged(true));
      yield (0, _effects.put)(_actions.Creators.setQuoteChanged(false, _quoteChanges.QUOTE_CHANGES_TYPE.MODIFY_QUOTE));
      yield (0, _effects.put)(_actions.Creators.updateQuoteInformation());
    }
    yield (0, _effects.put)(_actions.Creators.setIsQuoteUpdatingDone());
  }();
}