"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setPreferredQuote = exports.setIncentive = exports.setDates = exports.setCustomerVisibility = exports.putUnlistedVehicle = exports.loadIncentives = exports.getUnlistedVehicleTrims = exports.getUnlistedVehicleModels = exports.getUnlistedVehicleMakes = exports.getQuoteDetails = exports.getQuote = exports.getGarageData = exports.addUsedVehicle = exports.addNewVehicle = exports.addCatalogVehicle = void 0;
var _request = require("../../tools/request");
const QUOTE_API_URL = process.env.REACT_APP_QUOTE_API_URL;
const SHOWROOM_API_URL = process.env.REACT_APP_SHOWROOM_API_URL;

/**
 * @param {Object} leadId
 * @param {String[]} pathParams
 * @returns {Promise}
 */
const getGarageData = (leadId, queryParams) => {
  const url = `${QUOTE_API_URL}/leads/${leadId}/summaries`;
  return (0, _request.get)(url, {
    params: queryParams
  });
};

/**
 * @param {Object} queryParams
 * @param {Object} body
 * @param {Number} showroomVehicleId
 * @returns {Promise}
 */
exports.getGarageData = getGarageData;
const addNewVehicle = (queryParams, body, showroomVehicleId) => {
  const url = `${QUOTE_API_URL}/quote/vehicle/listingNew/${showroomVehicleId}`;
  return (0, _request.put)(url, body, {
    params: {
      ...queryParams
    }
  });
};

/**
 * @param {Object} queryParams
 * @param {Object} body
 * @returns {Promise}
 */
exports.addNewVehicle = addNewVehicle;
const addUsedVehicle = (queryParams, body) => {
  const url = `${QUOTE_API_URL}/quote/vehicle/listingUsed`;
  return (0, _request.put)(url, body, {
    params: {
      ...queryParams
    }
  });
};

/**
 * @param {Number} leadId
 * @param {Number} newCarTrimId
 * @param {Object} queryParams
 * @param {Object} body
 * @returns {Promise}
 */
exports.addUsedVehicle = addUsedVehicle;
const addCatalogVehicle = (leadId, newCarTrimId, queryParams, body) => {
  const url = `${QUOTE_API_URL}/leads/${leadId}/vehicleCatalog/${newCarTrimId}`;
  return (0, _request.put)(url, body, {
    params: {
      ...queryParams
    }
  });
};

/**
 * @param {Number} quoteId
 * @param {Object} queryParams
 * @returns {Promise}
 */
exports.addCatalogVehicle = addCatalogVehicle;
const getQuoteDetails = (quoteId, queryParams) => {
  const url = `${QUOTE_API_URL}/${quoteId}/details`;
  return (0, _request.get)(url, {
    params: queryParams
  });
};

/**
 * @param {Number} quoteId
 * @param {Object} queryParams
 * @returns {Promise}
 */
exports.getQuoteDetails = getQuoteDetails;
const getQuote = (quoteId, queryParams) => {
  const url = `${QUOTE_API_URL}/${quoteId}`;
  return (0, _request.get)(url, {
    params: queryParams
  });
};

/**
 * @param {Object} queryParams
 * @returns {Promise}
 */
exports.getQuote = getQuote;
const setPreferredQuote = (queryParams, body) => {
  const url = `${QUOTE_API_URL}/quote/setAsPreferred`;
  return (0, _request.post)(url, body, {
    params: queryParams
  });
};

/**
 * @param {Object} queryParams
 * @returns {Promise}
 */
exports.setPreferredQuote = setPreferredQuote;
const setCustomerVisibility = (queryParams, body) => {
  const url = `${SHOWROOM_API_URL}/quote/setCustomerVisibility`;
  return (0, _request.post)(url, body, {
    params: queryParams
  }, true);
};

/**
 * @param {String} quoteId
 * @param {Object} queryParams
 * @returns {Promise}
 */
exports.setCustomerVisibility = setCustomerVisibility;
const setDates = (quoteId, queryParams, body) => {
  const url = `${QUOTE_API_URL}/quote/desking/setDates/${quoteId}`;
  return (0, _request.post)(url, body, {
    params: queryParams
  });
};

/**
 * @param {String} quoteId
 * @param {Object} queryParams
 * @returns {Promise}
 */
exports.setDates = setDates;
const loadIncentives = (quoteId, queryParams, body) => {
  const url = `${SHOWROOM_API_URL}/quote/desking/incentives/${quoteId}`;
  return (0, _request.post)(url, body, {
    params: queryParams
  }, true);
};

/**
 * @param {String} quoteId
 * @param {Object} queryParams
 * @returns {Promise}
 */
exports.loadIncentives = loadIncentives;
const setIncentive = (quoteId, queryParams, body) => {
  const url = `${SHOWROOM_API_URL}/quote/desking/setIncentive/${quoteId}`;
  return (0, _request.post)(url, body, {
    params: queryParams
  }, true);
};

/**
 * @param {String} vehicleCondition
 * @param {Object} queryParams
 * @returns {Promise}
 */
exports.setIncentive = setIncentive;
const getUnlistedVehicleMakes = (vehicleCondition, queryParams) => {
  const url = `${SHOWROOM_API_URL}/quote/vehicle/makes`;
  return (0, _request.get)(url, {
    params: {
      ...queryParams,
      vehicleCondition
    }
  }, true);
};

/**
 * @param {String} vehicleCondition
 * @param {String} makeSlug
 * @param {Object} queryParams
 * @returns {Promise}
 */
exports.getUnlistedVehicleMakes = getUnlistedVehicleMakes;
const getUnlistedVehicleModels = (vehicleCondition, makeSlug, queryParams) => {
  const url = `${SHOWROOM_API_URL}/quote/vehicle/models/${makeSlug}`;
  return (0, _request.get)(url, {
    params: {
      ...queryParams,
      vehicleCondition
    }
  }, true);
};

/**
 * @param {String} vehicleCondition
 * @param {String} makeSlug
 * @param {String} modelSlug
 * @param {Object} queryParams
 * @returns {Promise}
 */
exports.getUnlistedVehicleModels = getUnlistedVehicleModels;
const getUnlistedVehicleTrims = (vehicleCondition, makeSlug, modelSlug, queryParams) => {
  const url = `${SHOWROOM_API_URL}/quote/vehicle/modelTrims/${makeSlug}/${modelSlug}`;
  return (0, _request.get)(url, {
    params: {
      ...queryParams,
      vehicleCondition
    }
  }, true);
};

/**
 * @param {Object} queryParams
 * @param {Object} body
 * @returns {Promise}
 */
exports.getUnlistedVehicleTrims = getUnlistedVehicleTrims;
const putUnlistedVehicle = (queryParams, body) => {
  const url = `${QUOTE_API_URL}/quote/vehicle/unlisted`;
  return (0, _request.put)(url, body, {
    params: {
      ...queryParams
    }
  });
};
exports.putUnlistedVehicle = putUnlistedVehicle;