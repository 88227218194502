"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _actions = require("../../actions");
var _selectors = require("../../selectors");
var _getAccTemplateData = _interopRequireDefault(require("./getAccTemplateData"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function* _default() {
  const {
    lang,
    location,
    organizationId,
    organizationUnitId
  } = yield (0, _effects.select)(_selectors.getConfig);
  const quoteId = yield (0, _effects.select)(_selectors.getQuoteId);
  const selectedTemplate = yield (0, _effects.select)(_selectors.getAccSelectedTemplate);
  const queryParams = {
    orgId: organizationId,
    orgUnitId: organizationUnitId,
    location,
    lang
  };
  try {
    const accountingTemplates = yield (0, _effects.call)(_commonsApi.ShowroomService.desking.getAccTemplates, quoteId, queryParams);
    const {
      templates
    } = accountingTemplates;
    if (templates.length > 0) {
      const selectedTemplateParam = selectedTemplate || templates[0].id;
      yield (0, _effects.put)(_actions.Creators.setAccTemplates(templates));
      yield (0, _effects.put)(_actions.Creators.setAccSelectedTemplate(selectedTemplateParam));
      yield (0, _effects.call)(_getAccTemplateData.default, selectedTemplateParam);
    }
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'getAccounting'));
  }
}