"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useLeadTypes = void 0;
var _react = require("react");
var _appsCore = require("@sm360/apps-core");
var _LeadService = require("../../api/LeadService");
var _utils = require("../../tools/utils");
var _utils2 = require("./utils");
/**
 * Returns a list of lead types (those configured for a dealer if specified)
 * @param {number} [organizationUnitId]
 * @param {boolean} [groupedByCategory]
 * @returns {Object} a Query object
 */
const useLeadTypes = params => {
  const {
    organizationUnitId,
    groupedByCategory,
    select,
    ...rest
  } = params || {};
  const queryKey = ['leads', 'types', {
    organizationUnitId
  }];
  const queryFn = () => (0, _LeadService.getLeadTypes)(organizationUnitId);
  const defaultSelect = (0, _react.useCallback)(data => groupedByCategory ? (0, _utils2.groupTypesByCategory)(data) : (0, _utils.transformDataToOptions)(data, 'type'), [groupedByCategory]);
  return (0, _appsCore.useQuery)({
    queryKey,
    queryFn,
    select: select || defaultSelect,
    ...rest
  });
};
exports.useLeadTypes = useLeadTypes;