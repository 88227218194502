"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _deskingError = require("@sm360/desking-error");
var _commonsApi = require("@sm360/commons-api");
var _actions = require("../../actions");
var _selectors = require("../../selectors");
function* _default(payload) {
  const showroomQueryParams = yield (0, _effects.select)(_selectors.getShowroomQueryParams);
  try {
    const unlistedVehicleMakes = yield (0, _effects.call)(_commonsApi.QuoteService.getUnlistedVehicleMakes, payload.vehicleCondition, showroomQueryParams);
    yield (0, _effects.put)(_actions.Creators.setUnlistedVehicleMakes(unlistedVehicleMakes));
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'getUnlistedVehicleMakes'));
  }
}