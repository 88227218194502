"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useAppointmentTypes = void 0;
var _appsCore = require("@sm360/apps-core");
var _ActivityService = require("../../api/ActivityService");
var _utils = require("../../tools/utils");
const transformTypesToOptions = data => (0, _utils.transformDataToOptions)(data, 'type');

/**
 * Returns a list of appointment types
 * @returns {Object} a Query object
 */
const useAppointmentTypes = params => {
  const {
    select,
    ...rest
  } = params || {};
  return (0, _appsCore.useQuery)({
    queryKey: ['appointments', 'types'],
    queryFn: _ActivityService.getAppointmentTypes,
    select: select || transformTypesToOptions,
    ...rest
  });
};
exports.useAppointmentTypes = useAppointmentTypes;