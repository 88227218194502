"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _actions = require("../actions");
var _getCommission = _interopRequireDefault(require("./commission/getCommission"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {String} transactionToken
 */
function* _default() {
  const response = yield (0, _effects.call)(_getCommission.default);
  if (response) {
    yield (0, _effects.put)(_actions.Creators.loadCommissionSuccess(response));
  }
}