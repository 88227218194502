"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _selectors = require("../../selectors");
function _default(_ref) {
  let {
    uploadedFormId
  } = _ref;
  return function* () {
    const queryParams = yield (0, _effects.select)(_selectors.getShowroomQueryParams);
    try {
      const form = yield (0, _effects.call)(_commonsApi.ShowroomService.desking.loadDealJacketForm, uploadedFormId, queryParams);
      if (form) {
        const contentType = form.headers['content-type'];
        let blob;
        let fileURL;
        if (contentType === 'application/pdf') {
          blob = new Blob([form.data], {
            type: 'application/pdf'
          });
          fileURL = URL.createObjectURL(blob);
          window.open(fileURL);
        } else if (contentType.startsWith('image/')) {
          blob = new Blob([form.data], {
            type: contentType
          });
          fileURL = URL.createObjectURL(blob);
          const imgWindow = window.open();
          imgWindow.document.write(`<img src="${fileURL}" alt="Uploaded Image" />`);
        }
      }
    } catch (error) {
      yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'loadDealJacketForm'));
      return null;
    }
  }();
}