"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _deskingCommons = require("@sm360/desking-commons");
function* _default() {
  // yield call(triggerEnableExitConfirmation, i18n.t('deskingBuilder.messages.onExit'));
  yield (0, _effects.call)(_deskingCommons.triggerEnableExitConfirmation, '');
}