"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _uiLibrary = require("@sm360/ui-library");
var _selectors = require("../selectors");
var _loadQuote = _interopRequireDefault(require("./api/loadQuote"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const {
  toast
} = _uiLibrary.Toast;
function* _default() {
  const {
    organizationUnitId,
    organizationId,
    location,
    lang
  } = yield (0, _effects.select)(_selectors.getConfig);
  const quoteId = yield (0, _effects.select)(_selectors.getQuoteId);
  const queryParams = {
    orgId: organizationId,
    orgUnitId: organizationUnitId,
    location,
    lang
  };
  try {
    const response = yield (0, _effects.call)(_commonsApi.ShowroomService.desking.submitRDR, quoteId, queryParams);
    if (response) {
      toast.success('Success');
      yield (0, _effects.call)(_loadQuote.default, quoteId);
    }
    return response;
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'submitRDR'));
    return null;
  }
}