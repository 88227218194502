"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transformConfiguredStatusesToOptions = exports.groupTypesByCategory = exports.groupSourcesBySuggestions = void 0;
var _commonsUtils = require("@sm360/commons-utils");
// Convert a flat list of leadTypes into a list of leadCategories which contain leadTypes
const groupTypesByCategory = types => {
  return types.reduce((acc, type) => {
    const groupIndex = acc.findIndex(group => group.groupId === String(type.leadCategory.id));
    if (groupIndex >= 0) {
      // group already exists, push new lead type in it
      const group = acc[groupIndex];
      const updatedGroup = {
        ...group,
        options: [...group.options, {
          id: String(type.id),
          label: type.type
        }]
      };
      const updatedAcc = [...acc];
      updatedAcc.splice(groupIndex, 1, updatedGroup);
      return updatedAcc;
    } else {
      // group doesn't exist yet, create it then push new lead type in it
      return [...acc, {
        groupId: String(type.leadCategory.id),
        groupLabel: type.leadCategory.category,
        options: [{
          id: String(type.id),
          label: type.type
        }]
      }];
    }
  }, []).sort((a, b) => a.groupId.localeCompare(b.groupId));
};
exports.groupTypesByCategory = groupTypesByCategory;
const groupSourcesBySuggestions = (sources, t) => {
  const suggestedSourceTypes = sources.filter(s => s.suggestion).map(s => ({
    id: s.id,
    label: s.sourceType,
    value: String(s.id)
  }));
  const otherSourceTypes = sources.filter(s => !s.suggestion).map(s => ({
    id: s.id,
    label: s.sourceType,
    value: String(s.id)
  }));
  return [{
    id: 'sourcesSuggestions',
    label: t('crm.SUGGESTIONS'),
    options: suggestedSourceTypes,
    isSuggested: true
  }, {
    id: 'sourcesOthers',
    label: t('crm.OTHERS'),
    options: otherSourceTypes
  }];
};
exports.groupSourcesBySuggestions = groupSourcesBySuggestions;
const transformConfiguredStatusesToOptions = (data, locale) => data.map(d => ({
  id: d.leadStatusId,
  value: (0, _commonsUtils.nullOrString)(d.leadStatusId),
  label: d.localizedLeadStatuses?.[locale === 'fr' ? 'fr' : 'en']?.status,
  note: d.noteRequired,
  restrictedAccess: d.restrictedAccess
}));
exports.transformConfiguredStatusesToOptions = transformConfiguredStatusesToOptions;