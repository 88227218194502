"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _deskingCommons = require("@sm360/desking-commons");
var _selectors = require("../selectors");
var _reloadCurrentStep = _interopRequireDefault(require("./reloadCurrentStep"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function* _default() {
  const {
    saleType,
    taxPlan
  } = yield (0, _effects.select)(_selectors.getPlan);
  const {
    cashDown,
    kmPerYearPlan,
    paymentFrequency,
    purchaseMethod,
    term
  } = yield (0, _effects.select)(_selectors.getPurchaseMethod);
  const {
    tradeIn,
    lien
  } = yield (0, _effects.select)(_selectors.getTradeIn);
  const includeTaxes = yield (0, _effects.select)(_selectors.getHasTaxesIncluded);
  const paymentBarOptions = {
    cashDown,
    includeTaxes,
    kmPerYearPlan,
    lien,
    paymentFrequency,
    purchaseMethod,
    saleType,
    taxPlan,
    term,
    tradeIn
  };
  yield (0, _deskingCommons.triggerSavePaymentBarOptions)(paymentBarOptions);
  yield (0, _effects.call)(_reloadCurrentStep.default);
}