"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getVehicleNotFoundInfo = exports.getErrorDetails = exports.default = void 0;
var _commonsUtils = require("@sm360/commons-utils");
/**
 * @param {Object} state
 * @returns {Object}
 */
const getErrorDetails = state => {
  const {
    error
  } = state;
  return (0, _commonsUtils.isPlainObject)(error) ? error : {};
};
exports.getErrorDetails = getErrorDetails;
const getVehicleNotFoundInfo = state => {
  const {
    selectedVehicleInfo
  } = state;
  return selectedVehicleInfo;
};
exports.getVehicleNotFoundInfo = getVehicleNotFoundInfo;
var _default = exports.default = {
  getErrorDetails,
  getVehicleNotFoundInfo
};