"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("react");
const deletePropertyPath = (obj, path) => {
  if (!obj || !path) return;
  if (typeof path === 'string') path = path.split('.');
  for (let i = 0; i < path.length - 1; i++) {
    obj = obj[path[i]];
    if (typeof obj === 'undefined') return;
  }
  delete obj[path.pop()];
};

// Delete a list of specific 'paths' for a given 'key' from Local Storage when the caller component unmounts or when closing tab
const useDeleteLsOnUnmount = (key, paths) => {
  const deleteLs = (0, _react.useCallback)(() => {
    const LS = localStorage?.getItem(key);
    if (LS && paths?.length > 0) {
      const parsedLS = JSON.parse(LS);
      paths.forEach(p => {
        if (typeof p === 'string') deletePropertyPath(parsedLS, p);
      });
      localStorage.setItem(key, JSON.stringify(parsedLS));
    }
  }, [key, paths]);
  (0, _react.useEffect)(() => {
    window.addEventListener('beforeunload', deleteLs);
    return () => {
      deleteLs();
      window.removeEventListener('beforeunload', deleteLs);
    };
  }, [deleteLs]);
};
var _default = exports.default = useDeleteLsOnUnmount;