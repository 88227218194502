"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updatePriceRollInURL = void 0;
const updatePriceRollInURL = (num, setSearchParams) => {
  // Update URL with 'new' query info
  if (typeof setSearchParams === 'function') {
    setSearchParams(params => {
      // Convert upg (if available) value to upg from new quote
      params.set('upg', num);
      return params;
    });
  }
};
exports.updatePriceRollInURL = updatePriceRollInURL;