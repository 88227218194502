"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _actions = require("../actions");
var _selectors = require("../selectors");
const {
  getDealersList
} = _commonsApi.DealerInfoService;
function* _default() {
  const config = yield (0, _effects.select)(_selectors.getConfig);
  const user = yield (0, _effects.getContext)('user');
  const {
    organizationId
  } = config;
  const userId = Number(user.profile.sub);
  try {
    const dealersList = yield (0, _effects.call)(getDealersList, organizationId, userId);
    yield (0, _effects.put)(_actions.Creators.setDealersList(dealersList));
    return dealersList;
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'getDealersList'));
    return false;
  }
}