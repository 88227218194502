"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UiSchema = exports.Schema = void 0;
var _constants = require("../../tools/constants");
const phoneCallPrefix = 'actions.phoneCall.form';
const sharedPrefix = 'actions.shared';
const placeholderPrefix = 'actions.placeholders';
const customMessage = {
  required: 'components.uikit.validation.required',
  maxChar: ['components.uikit.validation.maxChar', {
    count: _constants.PHONE_INTERLOCUTOR_CHAR_LIMIT
  }]
};
const Schema = exports.Schema = {
  type: 'object',
  properties: {
    direction: {
      title: `${phoneCallPrefix}.DIRECTION.TITLE`,
      type: 'string',
      default: 1,
      enum: [1, 2],
      enumNames: [`${phoneCallPrefix}.DIRECTION.OPTIONS.FROM_DEALER_TO_CLIENT`, `${phoneCallPrefix}.DIRECTION.OPTIONS.FROM_CLIENT_TO_DEALER`],
      customMessage
    },
    phone: {
      title: `${phoneCallPrefix}.PHONE`,
      type: 'string',
      enum: [],
      enumNames: [],
      placeholder: `${placeholderPrefix}.SELECT_PHONE`,
      otherLabel: `${phoneCallPrefix}.OTHER`,
      customMessage
    },
    interlocutor: {
      title: `${phoneCallPrefix}.INTERLOCUTOR`,
      type: 'string',
      maxChar: _constants.PHONE_INTERLOCUTOR_CHAR_LIMIT,
      customMessage
    },
    subject: {
      title: `${sharedPrefix}.SUBJECT`,
      type: 'string',
      name: 'phone-subject',
      autocomplete: true,
      customMessage
    },
    details: {
      title: `${phoneCallPrefix}.MESSAGE`,
      type: 'string',
      customMessage
    },
    duration: {
      title: `${phoneCallPrefix}.DURATION`,
      type: 'integer',
      customMessage
    }
  },
  required: ['subject', 'phone']
};
const UiSchema = exports.UiSchema = {
  direction: {},
  phone: {
    'ui:type': 'tel',
    'ui:widget': 'selectOrPhoneWidget'
  },
  duration: {
    'ui:type': 'number'
  },
  details: {
    'ui:widget': 'textareaWidget'
  }
};