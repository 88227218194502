"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _selectors = require("../../selectors");
/**
 * @param {Array} garage
 */
function* getDefaultQuote(garage) {
  const queryParams = yield (0, _effects.select)(_selectors.getQuoteServiceQueryParams);
  if (!Array.isArray(garage) || garage.length === 0) {
    return {};
  }
  const preferredQuote = garage.find(quote => quote.isPreferred);
  const selectedGarage = preferredQuote || garage[0];
  try {
    return yield (0, _effects.call)(_commonsApi.QuoteService.getQuote, selectedGarage.id, queryParams);
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'getQuote'));
    return false;
  }
}
var _default = exports.default = getDefaultQuote;