"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getYear = exports.getVehicleImage = exports.getSelectedModelYear = exports.getSelectedExteriorColour = exports.getNewCarTrimId = exports.getModelYears = exports.getModelName = exports.getModel = exports.getMakeName = exports.getExteriorColourPhotos = exports.getDrivePowerTrains = exports.getContent = exports.getConfigurations = void 0;
var _commonsUtils = require("@sm360/commons-utils");
/**
 * @param {Object} state
 * @returns {Object}
 */
const getContent = state => {
  const {
    content
  } = state;
  return (0, _commonsUtils.isPlainObject)(content) ? content : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getContent = getContent;
const getModel = state => {
  const {
    model
  } = getContent(state);
  return (0, _commonsUtils.isPlainObject)(model) ? model : {};
};

/**
 * @param {Object} state
 * @returns {Object[]}
 */
exports.getModel = getModel;
const getDrivePowerTrains = state => {
  const {
    drivePowerTrains
  } = getModel(state);
  return Array.isArray(drivePowerTrains) ? drivePowerTrains : [];
};

/**
 * @param {Object} state
 * @returns {Object[]}
 */
exports.getDrivePowerTrains = getDrivePowerTrains;
const getConfigurations = state => {
  const {
    configurations
  } = getModel(state);
  return Array.isArray(configurations) ? configurations : [];
};

/**
 * @param {Object} state
 * @returns {Object[]}
 */
exports.getConfigurations = getConfigurations;
const getModelYears = state => {
  const {
    modelYears
  } = getContent(state);
  return Array.isArray(modelYears) ? modelYears : [];
};

/**
 * @param {Object} state
 * @returns {String}
 */
exports.getModelYears = getModelYears;
const getMakeName = state => {
  const {
    make
  } = getModel(state);
  if ((0, _commonsUtils.isPlainObject)(make)) {
    return make.name;
  }
  const modelYears = getModelYears(state);
  const name = modelYears?.[0]?.make?.name;
  return name || '';
};

/**
 * @param {Object} state
 * @returns {String}
 */
exports.getMakeName = getMakeName;
const getModelName = state => {
  const {
    model
  } = getModel(state);
  if ((0, _commonsUtils.isPlainObject)(model)) {
    return model.name;
  }
  const modelYears = getModelYears(state);
  const name = modelYears?.[0]?.model?.name;
  return name || '';
};

/**
 * @param {Object} state
 * @returns {Number|null}
 */
exports.getModelName = getModelName;
const getNewCarTrimId = state => {
  const {
    newCarTrimId
  } = getContent(state);
  return Number.isInteger(newCarTrimId) && newCarTrimId > 0 ? newCarTrimId : null;
};

/**
 * @param {Object} state
 * @returns {Object[]}
 */
exports.getNewCarTrimId = getNewCarTrimId;
const getYear = state => {
  const {
    year
  } = getContent(state);
  return year;
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getYear = getYear;
const getSelectedModelYear = state => {
  const modelYears = getModelYears(state);
  const selectedYear = getYear(state);
  const selectedModelYear = modelYears.find(item => item.year === selectedYear);
  return selectedModelYear || {};
};

/**
 * @param {Object} state
 * @returns {Object[]}
 */
exports.getSelectedModelYear = getSelectedModelYear;
const getExteriorColourPhotos = state => {
  const {
    exteriorColourPhotos
  } = getModel(state);
  if (!Array.isArray(exteriorColourPhotos)) {
    return [];
  }
  return exteriorColourPhotos;
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getExteriorColourPhotos = getExteriorColourPhotos;
const getSelectedExteriorColour = state => {
  const exteriorColourPhotos = getExteriorColourPhotos(state);
  const exteriorColour = exteriorColourPhotos.find(_ref => {
    let {
      isSelected
    } = _ref;
    return isSelected === true;
  });
  return (0, _commonsUtils.isPlainObject)(exteriorColour) ? exteriorColour : {};
};

/**
 * @param {Object} state
 * @returns {Any}
 */
exports.getSelectedExteriorColour = getSelectedExteriorColour;
const getVehicleImage = state => {
  const {
    side
  } = getSelectedExteriorColour(state);
  return side;
};
exports.getVehicleImage = getVehicleImage;