"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.put = exports.post = exports.get = exports.deleteHttp = void 0;
var _appsCore = require("@sm360/apps-core");
const HTTP_NOCONTENT = 204;

/**
 * Returns an empty object when "no content".
 *
 * @param {Object} response
 * @param {Boolean} fullResponse - if true return full response
 * @returns {Object}
 */
const handleResponseSuccess = function (response) {
  let fullResponse = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  // Safety check, sometimes response is undefined
  if (!response) {
    return {};
  }
  const {
    status,
    data
  } = response;
  switch (status) {
    case HTTP_NOCONTENT:
      return {};
    default:
      return fullResponse || !data ? response : data;
  }
};

/**
 * Add logging when error with Axios, will log on server or browser
 * https://github.com/axios/axios#handling-errors
 *
 * @param {Error} error
 * @param {String} url
 * @param {Object} options
 * @param {Object} data
 * @param {String} errorKey
 * @returns {Promise}
 */
const handleResponseError = (error, url, options, data, errorKey) => {
  let message;
  let responseData;
  if (error.response) {
    message = 'The request was made and the server responded with a status code that falls out of the range of 2xx';
    responseData = error.response.data;
  } else if (error.request) {
    message = 'The request was made but no response was received';
  } else {
    message = 'Something happened in setting up the request that triggered an Error';
  }
  const logData = {
    customMessage: message
  };
  if (errorKey) {
    logData.errorKey = errorKey;
  }
  if (error.message) {
    logData.errorMessage = error.message;
  }
  if (responseData) {
    logData.responseData = responseData;
  }
  let completeURL = url;

  // Concat queryParams to have the complete URL
  if (options && options.params) {
    completeURL += `?${Object.keys(options.params).map(key => `${key}=${options.params[key]}`).join('&')}`;
  }
  logData.url = completeURL;
  if (data) {
    logData.payload = data;
  }
  return Promise.reject(new Error(JSON.stringify(logData)));
};

/**
 * @param {String} url
 * @param {Object} options - Additional config (headers, queryParams...)
 * @param {Boolean} useJwt - Specify if we want to authenticate using a x-jwt header
 * @param {String} errorKey - Error identifier to have error description
 * @returns {Promise}
 */
const get = async function (url) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  let useJwt = arguments.length > 2 ? arguments[2] : undefined;
  let errorKey = arguments.length > 3 ? arguments[3] : undefined;
  try {
    const response = await _appsCore.axios.get(url, {
      ...options,
      useJwt
    });
    return handleResponseSuccess(response, options?.fullResponse);
  } catch (error) {
    return handleResponseError(error, url, options, null, errorKey);
  }
};

/**
 * @param {String} url
 * @param {Object} data - Body of the request
 * @param {Object} options - Additional config (headers, queryParams...)
 * @param {Boolean} useJwt - Specify if we want to authenticate using a x-jwt header
 * @param {String} errorKey - Error identifier to have error description
 * @returns {Promise}
 */
exports.get = get;
const post = async function (url) {
  let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  let useJwt = arguments.length > 3 ? arguments[3] : undefined;
  let errorKey = arguments.length > 4 ? arguments[4] : undefined;
  try {
    const response = await _appsCore.axios.post(url, data, {
      ...options,
      useJwt
    });
    return handleResponseSuccess(response, options?.fullResponse);
  } catch (error) {
    return handleResponseError(error, url, options, data, errorKey);
  }
};

/**
 * @param {String} url
 * @param {Object} data - Body of the request
 * @param {Object} options - Additional config (headers, queryParams...)
 * @param {Boolean} useJwt - Specify if we want to authenticate using a x-jwt header
 * @param {String} errorKey - Error identifier to have error description
 * @returns {Promise}
 */
exports.post = post;
const put = async function (url) {
  let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  let useJwt = arguments.length > 3 ? arguments[3] : undefined;
  let errorKey = arguments.length > 4 ? arguments[4] : undefined;
  try {
    const response = await _appsCore.axios.put(url, data, {
      ...options,
      useJwt
    });
    return handleResponseSuccess(response, options?.fullResponse);
  } catch (error) {
    return handleResponseError(error, url, options, data, errorKey);
  }
};

/**
 * @param {String} url
 * @param {Object} data - Body of the request
 * @param {Object} options - Additional config (headers, queryParams...)
 * @param {Boolean} useJwt - Specify if we want to authenticate using a x-jwt header
 * @param {String} errorKey - Error identifier to have error description
 * @returns {Promise}
 */
exports.put = put;
const deleteHttp = async function (url) {
  let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  let useJwt = arguments.length > 3 ? arguments[3] : undefined;
  let errorKey = arguments.length > 4 ? arguments[4] : undefined;
  try {
    const response = await _appsCore.axios.delete(url, {
      data,
      ...options,
      useJwt
    });
    return handleResponseSuccess(response, options?.fullResponse);
  } catch (error) {
    return handleResponseError(error, url, options, data, errorKey);
  }
};
exports.deleteHttp = deleteHttp;