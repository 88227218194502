"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ContactMergeBar", {
  enumerable: true,
  get: function () {
    return _ContactMergeBar.default;
  }
});
Object.defineProperty(exports, "ContactMergeButton", {
  enumerable: true,
  get: function () {
    return _ContactMergeButton.default;
  }
});
Object.defineProperty(exports, "ContactMergeDrawer", {
  enumerable: true,
  get: function () {
    return _ContactMergeDrawer.default;
  }
});
Object.defineProperty(exports, "ContactMergeProvider", {
  enumerable: true,
  get: function () {
    return _ContactMergeProvider.default;
  }
});
Object.defineProperty(exports, "ContactMerger", {
  enumerable: true,
  get: function () {
    return _ContactMerger.default;
  }
});
Object.defineProperty(exports, "MergeResolver", {
  enumerable: true,
  get: function () {
    return _MergeResolver.default;
  }
});
var _ContactMergeProvider = _interopRequireDefault(require("./ContactMergeProvider"));
var _ContactMergeBar = _interopRequireDefault(require("./views/ContactMergeBar"));
var _ContactMergeButton = _interopRequireDefault(require("./views/ContactMergeButton"));
var _ContactMergeDrawer = _interopRequireDefault(require("./views/ContactMergeDrawer"));
var _ContactMerger = _interopRequireDefault(require("./components/ContactMerger"));
var _MergeResolver = _interopRequireDefault(require("./components/MergeResolver"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }