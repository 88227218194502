"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PURCHASE_METHOD_LEASE = exports.PURCHASE_METHOD_FINANCE = exports.PURCHASE_METHOD_DEFAULT = exports.PURCHASE_METHOD_CASH = exports.PURCHASE_METHODS = void 0;
const PURCHASE_METHOD_CASH = exports.PURCHASE_METHOD_CASH = 'cash';
const PURCHASE_METHOD_FINANCE = exports.PURCHASE_METHOD_FINANCE = 'finance';
const PURCHASE_METHOD_LEASE = exports.PURCHASE_METHOD_LEASE = 'lease';
const PURCHASE_METHOD_DEFAULT = exports.PURCHASE_METHOD_DEFAULT = PURCHASE_METHOD_CASH;
const PURCHASE_METHODS = exports.PURCHASE_METHODS = [PURCHASE_METHOD_CASH, PURCHASE_METHOD_FINANCE, PURCHASE_METHOD_LEASE];