"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useEmailTemplates = void 0;
var _appsCore = require("@sm360/apps-core");
var _EmailTemplateService = require("../../api/EmailTemplateService");
var _utils = require("../../tools/utils");
const transformTemplatesToOptions = data => (0, _utils.transformDataToOptions)(data, 'title');

/**
 * Returns a list of email templates with id / title
 * @param {Array.<number>} organizationUnitIds
 * @returns {Object} a Query object
 */
const useEmailTemplates = params => {
  const {
    organizationUnitIds,
    select,
    ...rest
  } = params || {};
  const sanitizedOrganizationUnitIds = (0, _utils.sanitizeOrganizationUnitIds)(organizationUnitIds);
  const queryKey = ['emailTemplates', {
    organizationUnitIds: sanitizedOrganizationUnitIds
  }];
  const queryFn = () => (0, _EmailTemplateService.getEmailTemplates)(sanitizedOrganizationUnitIds);
  const enabled = sanitizedOrganizationUnitIds.length > 0;
  return (0, _appsCore.useQuery)({
    queryKey,
    queryFn,
    enabled,
    select: select || transformTemplatesToOptions,
    ...rest
  });
};
exports.useEmailTemplates = useEmailTemplates;