"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingCommons = require("@sm360/desking-commons");
var _deskingError = require("@sm360/desking-error");
var _selectors = require("../../selectors");
/**
 * @param {Object} params
 */
function* assignLead(type) {
  const {
    leadId
  } = yield (0, _effects.select)(_selectors.getConfig);
  const user = yield (0, _effects.getContext)('user');
  const {
    SALES_PERSON,
    SALES_MANAGER,
    BUSINESS_MANAGER
  } = _deskingCommons.SALES_PERSONNEL;
  const queryParamData = Number(user.profile.sub);
  try {
    switch (type) {
      case SALES_PERSON:
        yield (0, _effects.call)(_commonsApi.LeadInfoService.assignLeadToSalesPerson, leadId, queryParamData);
        break;
      case SALES_MANAGER:
        yield (0, _effects.call)(_commonsApi.LeadInfoService.assignLeadToSalesManager, leadId, queryParamData);
        break;
      case BUSINESS_MANAGER:
        yield (0, _effects.call)(_commonsApi.LeadInfoService.assignLeadToBusinessManager, leadId, queryParamData);
        break;
      default:
    }
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'assignLead'));
  }
}
var _default = exports.default = assignLead;