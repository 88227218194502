"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _deskingError = require("@sm360/desking-error");
var _LandingCard = _interopRequireDefault(require("./LandingCard"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var _default = exports.default = (0, _redux.compose)(_deskingError.withErrorWrapper)(_LandingCard.default);