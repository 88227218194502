"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Types = exports.Creators = void 0;
var _reduxsauce = require("reduxsauce");
const {
  Types,
  Creators
} = (0, _reduxsauce.createActions)({
  initializeStore: ['queryParams'],
  openInDesking: ['vehicle'],
  setLoadingSuccess: [],
  setCurrentStep: ['step', 'lastVisitedStep'],
  setPermission: ['permission'],
  sendExitConfirmation: [],
  loadMatches: [],
  setLocation: ['location'],
  setLeadInfo: ['leadInfo'],
  getLeadInfo: [],
  // Steps loading
  loadModelSuccess: ['response'],
  loadStepOptionItemsSuccess: ['response'],
  loadStepYearSuccess: ['response'],
  loadMatchesSuccess: ['perfectMatches', 'similarMatches'],
  // Step1
  selectModelYear: ['year'],
  // Step2
  selectExteriorColour: ['exteriorColour'],
  selectInteriorTrim: ['interiorTrim'],
  selectTrim: ['configurationId', 'modelTrimCode'],
  // Step 3, 4
  updateModelTrim: ['configurationId', 'modelTrimCode'],
  // Step5
  addDeleteStepOptionItems: ['itemData'],
  selectOptionItem: ['selected'],
  // Validation step2, step3, step4, step5
  applyConflictResolution: ['negociationTransaction'],
  cancelConflict: [],
  validateFailure: ['response'],
  validateSuccess: ['negociationTransaction'],
  // PaymentOptions
  loadPaymentOptionsSuccess: ['response'],
  updatePaymentOptionsSuccess: ['response'],
  selectPurchaseMethod: ['purchaseMethod'],
  updateCashDown: ['cashDown'],
  updateKmPlan: ['kmPerYearPlan'],
  updateLien: ['lien'],
  updatePaymentFrequency: ['paymentFrequency'],
  updateTaxesIncluded: ['hasTaxesIncluded'],
  updateTerm: ['term'],
  updateTradeIn: ['tradeIn']
});
exports.Creators = Creators;
exports.Types = Types;