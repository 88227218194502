"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateLeadNotification = exports.getLeadNotifications = exports.getLeadNotification = exports.deleteLeadNotification = exports.createLeadNotification = void 0;
var _appsCore = require("@sm360/apps-core");
const NEW_CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;
const getLeadNotifications = async (userId, organizationId) => {
  return await _appsCore.axios.get(`${NEW_CRM_ENDPOINT}/leadNotificationConfigs?organizationId=${organizationId}&userId=${userId}`);
};
exports.getLeadNotifications = getLeadNotifications;
const getLeadNotification = async id => {
  return await _appsCore.axios.get(`${NEW_CRM_ENDPOINT}/leadNotificationConfigs/${id}`);
};
exports.getLeadNotification = getLeadNotification;
const createLeadNotification = async body => {
  return await _appsCore.axios.post(`${NEW_CRM_ENDPOINT}/leadNotificationConfigs`, body);
};
exports.createLeadNotification = createLeadNotification;
const updateLeadNotification = async body => {
  const id = body?.id;
  return await _appsCore.axios.put(`${NEW_CRM_ENDPOINT}/leadNotificationConfigs/${id}`, body);
};
exports.updateLeadNotification = updateLeadNotification;
const deleteLeadNotification = async id => {
  return await _appsCore.axios.delete(`${NEW_CRM_ENDPOINT}/leadNotificationConfigs/${id}`);
};
exports.deleteLeadNotification = deleteLeadNotification;