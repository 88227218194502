"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transformLeadToBackend = exports.transformLeadFromBackend = void 0;
var _commonsUtils = require("@sm360/commons-utils");
// Transform the FE lead structure to the BE lead structure
const transformLeadToBackend = entry => {
  const {
    leadSourceType,
    leadStatusIds,
    leadTypeIds,
    leadServiceVehicle,
    salespersonIds,
    contact
  } = entry;
  const {
    contactFinancing,
    contactLanguage
  } = contact;
  const {
    id: contactFinancingId,
    financingType,
    approvalStatus,
    jobType,
    rentType
  } = contactFinancing || {};
  const checkTypeModified = type => {
    if (!type) return null;
    return typeof type === 'number' ? {
      id: parseInt(type)
    } : type;
  };
  const parsedFinancingType = checkTypeModified(financingType);
  const parsedApprovalStatus = checkTypeModified(approvalStatus);
  const parsedJobType = checkTypeModified(jobType);
  const parsedRentType = checkTypeModified(rentType);
  const contactFinancingEmpty = !parsedFinancingType && !parsedApprovalStatus && !parsedJobType && !parsedRentType;
  const leadServiceVehicleEmpty = Object.values(leadServiceVehicle).every(v => !v);
  const formattedEntry = {
    ...entry,
    contact: {
      ...contact,
      contactLanguage: contactLanguage,
      contactFinancing: !contactFinancingId && contactFinancingEmpty ? null : {
        ...contactFinancing,
        financingType: parsedFinancingType,
        approvalStatus: parsedApprovalStatus,
        jobType: parsedJobType,
        rentType: parsedRentType
      }
    },
    leadServiceVehicle: leadServiceVehicleEmpty ? null : leadServiceVehicle,
    salesmanUserId: salespersonIds,
    leadSourceType: {
      id: parseInt(leadSourceType)
    },
    leadType: {
      id: parseInt(leadTypeIds)
    },
    leadStatus: {
      id: parseInt(leadStatusIds)
    }
  };
  if (formattedEntry.contact?.contactFinancing && !parsedFinancingType) delete formattedEntry.contact.contactFinancing.financingType;
  if (formattedEntry.contact?.contactFinancing && !parsedApprovalStatus) delete formattedEntry.contact.contactFinancing.approvalStatus;
  if (formattedEntry.contact?.contactFinancing && !parsedJobType) delete formattedEntry.contact.contactFinancing.jobType;
  if (formattedEntry.contact?.contactFinancing && !parsedRentType) delete formattedEntry.contact.contactFinancing.rentType;
  delete formattedEntry.salespersonIds;
  delete formattedEntry.leadTypeIds;
  delete formattedEntry.leadStatusIds;
  delete formattedEntry.leadCategoryId;
  return formattedEntry;
};

// Transform the BE lead structure to the FE lead structure
exports.transformLeadToBackend = transformLeadToBackend;
const transformLeadFromBackend = entry => {
  const {
    leadSourceType,
    leadType,
    leadStatus,
    leadServiceVehicle,
    salesmanUserId,
    contact
  } = entry;
  const {
    mobilePhone,
    homePhone,
    workPhone,
    workPhoneExtension,
    contactFinancing
  } = contact || {};
  const {
    approvalStatus,
    jobType,
    rentType
  } = contactFinancing || {};
  const formattedEntry = {
    ...entry,
    contact: {
      ...contact,
      mobilePhone: (0, _commonsUtils.validPhoneNumber)(mobilePhone) ? (0, _commonsUtils.cleanPhoneNumber)(mobilePhone) : '',
      homePhone: (0, _commonsUtils.validPhoneNumber)(homePhone) ? (0, _commonsUtils.cleanPhoneNumber)(homePhone) : '',
      workPhone: (0, _commonsUtils.validPhoneNumber)(workPhone) ? (0, _commonsUtils.cleanPhoneNumber)(workPhone) : '',
      workPhoneExtension: workPhoneExtension ? Number(workPhoneExtension) : null,
      contactFinancing: {
        ...contactFinancing,
        approvalStatus: (0, _commonsUtils.nullOrNumber)(approvalStatus?.id),
        jobType: (0, _commonsUtils.nullOrNumber)(jobType?.id),
        rentType: (0, _commonsUtils.nullOrNumber)(rentType?.id)
      }
    },
    leadServiceVehicle: {
      appointmentDate: leadServiceVehicle?.appointmentDate || null,
      id: leadServiceVehicle?.id || null,
      make: leadServiceVehicle?.make || null,
      model: leadServiceVehicle?.model || null,
      odometer: leadServiceVehicle?.odometer || null,
      serviceDetails: leadServiceVehicle?.serviceDetails || null,
      vin: leadServiceVehicle?.vin || null,
      year: leadServiceVehicle?.year || null
    },
    salespersonIds: (0, _commonsUtils.nullOrNumber)(salesmanUserId),
    leadSourceType: (0, _commonsUtils.nullOrNumber)(leadSourceType?.id),
    leadStatusIds: (0, _commonsUtils.nullOrNumber)(leadStatus?.id),
    leadTypeIds: (0, _commonsUtils.nullOrNumber)(leadType?.id),
    leadCategoryId: (0, _commonsUtils.nullOrNumber)(leadType?.leadCategory?.id)
  };
  delete formattedEntry.salesmanUserId;
  delete formattedEntry.leadType;
  delete formattedEntry.leadStatus;
  return formattedEntry;
};
exports.transformLeadFromBackend = transformLeadFromBackend;