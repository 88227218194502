"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SORT_VALUE = exports.SORT_TYPE = exports.SORT_DIR = exports.SORT_DATA = exports.MIN_PRICE = exports.MIN_ODOMETER = exports.MAX_PRICE = exports.MAX_ODOMETER = exports.KEYS = void 0;
const KEYS = exports.KEYS = {
  BODY: 'body',
  DRIVE: 'drive',
  ENGINE: 'engine',
  EXTERNAL_COLOR: 'externalColor',
  FUEL: 'fuel',
  INTERNAL_COLOR: 'internalColor',
  MAKE: 'make',
  MODEL: 'model',
  TRIM: 'trim',
  TRANSMISSION: 'transmission',
  YEAR: 'year'
};
const MIN_ODOMETER = exports.MIN_ODOMETER = 0;
const MAX_ODOMETER = exports.MAX_ODOMETER = 1000000;
const MIN_PRICE = exports.MIN_PRICE = 0;
const MAX_PRICE = exports.MAX_PRICE = 2000000;
const SORT_VALUE = exports.SORT_VALUE = {
  DATE_ASC: 'dateAsc',
  DATE_DESC: 'dateDesc',
  PRICE_ASC: 'priceAsc',
  PRICE_DESC: 'priceDesc',
  ODOMETER_ASC: 'odometerAsc',
  ODOMETER_DESC: 'odometerDesc'
};
const SORT_TYPE = exports.SORT_TYPE = {
  DATE: 'DATE_ENTRY',
  PRICE: 'SALE_PRICE',
  ODOMETER: 'ODOMETER'
};
const SORT_DIR = exports.SORT_DIR = {
  ASC: 'ASC',
  DESC: 'DESC'
};
const SORT_DATA = exports.SORT_DATA = [{
  VALUE: SORT_VALUE.DATE_ASC,
  DIR: SORT_DIR.ASC,
  TYPE: SORT_TYPE.DATE
}, {
  VALUE: SORT_VALUE.DATE_DESC,
  DIR: SORT_DIR.DESC,
  TYPE: SORT_TYPE.DATE
}, {
  VALUE: SORT_VALUE.PRICE_ASC,
  DIR: SORT_DIR.ASC,
  TYPE: SORT_TYPE.PRICE
}, {
  VALUE: SORT_VALUE.PRICE_DESC,
  DIR: SORT_DIR.DESC,
  TYPE: SORT_TYPE.PRICE
}, {
  VALUE: SORT_VALUE.ODOMETER_ASC,
  DIR: SORT_DIR.ASC,
  TYPE: SORT_TYPE.ODOMETER
}, {
  VALUE: SORT_VALUE.ODOMETER_DESC,
  DIR: SORT_DIR.DESC,
  TYPE: SORT_TYPE.ODOMETER
}];