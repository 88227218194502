"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.stateOptions = exports.provinceOptions = exports.monthData = exports.genderOptions = exports.countryOptions = void 0;
const monthData = exports.monthData = [{
  value: 1,
  id: 'january',
  label: 'deskingDesking.contact.dateInfo.january'
}, {
  value: 2,
  id: 'february',
  label: 'deskingDesking.contact.dateInfo.february'
}, {
  value: 3,
  id: 'march',
  label: 'deskingDesking.contact.dateInfo.march'
}, {
  value: 4,
  id: 'april',
  label: 'deskingDesking.contact.dateInfo.april'
}, {
  value: 5,
  id: 'may',
  label: 'deskingDesking.contact.dateInfo.may'
}, {
  value: 6,
  id: 'june',
  label: 'deskingDesking.contact.dateInfo.june'
}, {
  value: 7,
  id: 'july',
  label: 'deskingDesking.contact.dateInfo.july'
}, {
  value: 8,
  id: 'august',
  label: 'deskingDesking.contact.dateInfo.august'
}, {
  value: 9,
  id: 'september',
  label: 'deskingDesking.contact.dateInfo.september'
}, {
  value: 10,
  id: 'october',
  label: 'deskingDesking.contact.dateInfo.october'
}, {
  value: 11,
  id: 'november',
  label: 'deskingDesking.contact.dateInfo.november'
}, {
  value: 12,
  id: 'december',
  label: 'deskingDesking.contact.dateInfo.december'
}];
const genderOptions = exports.genderOptions = [{
  id: 'option-male',
  label: 'deskingDesking.contact.formFields.male',
  value: '1'
}, {
  id: 'option-female',
  label: 'deskingDesking.contact.formFields.female',
  value: '2'
}, {
  id: 'option-notCommunicate',
  label: 'deskingDesking.contact.formFields.preferNotToSay',
  value: '3'
}, {
  id: 'option-other',
  label: 'deskingDesking.contact.formFields.other',
  value: '0'
}];
const countryOptions = exports.countryOptions = [{
  id: 'US',
  value: 'US',
  label: 'deskingDesking.contact.countries.usa'
}, {
  id: 'CA',
  value: 'CA',
  label: 'deskingDesking.contact.countries.canada'
}];
const provinceOptions = exports.provinceOptions = [{
  id: 'AB',
  value: 'AB',
  label: 'deskingDesking.contact.provinces.alberta'
}, {
  id: 'BC',
  value: 'BC',
  label: 'deskingDesking.contact.provinces.britishColumbia'
}, {
  id: 'MB',
  value: 'MB',
  label: 'deskingDesking.contact.provinces.manitoba'
}, {
  id: 'NB',
  value: 'NB',
  label: 'deskingDesking.contact.provinces.newBrunswick'
}, {
  id: 'NL',
  value: 'NL',
  label: 'deskingDesking.contact.provinces.newfoundLand'
}, {
  id: 'NT',
  value: 'NT',
  label: 'deskingDesking.contact.provinces.northWestTerritories'
}, {
  id: 'NS',
  value: 'NS',
  label: 'deskingDesking.contact.provinces.novaScotia'
}, {
  id: 'NU',
  value: 'NU',
  label: 'deskingDesking.contact.provinces.nunavut'
}, {
  id: 'ON',
  value: 'ON',
  label: 'deskingDesking.contact.provinces.ontario'
}, {
  id: 'PE',
  value: 'PE',
  label: 'deskingDesking.contact.provinces.princeEdwardIsland'
}, {
  id: 'QC',
  value: 'QC',
  label: 'deskingDesking.contact.provinces.quebec'
}, {
  id: 'SK',
  value: 'SK',
  label: 'deskingDesking.contact.provinces.saskatchewan'
}, {
  id: 'YT',
  value: 'YT',
  label: 'deskingDesking.contact.provinces.yukon'
}];
const stateOptions = exports.stateOptions = [{
  id: 'AL',
  value: 'AL',
  label: 'deskingDesking.contact.states.alabama'
}, {
  id: 'AK',
  value: 'AK',
  label: 'deskingDesking.contact.states.alaska'
}, {
  id: 'AS',
  value: 'AS',
  label: 'deskingDesking.contact.states.americanSamoa'
}, {
  id: 'AZ',
  value: 'AZ',
  label: 'deskingDesking.contact.states.arizona'
}, {
  id: 'AR',
  value: 'AR',
  label: 'deskingDesking.contact.states.arkansas'
}, {
  id: 'CA',
  value: 'CA',
  label: 'deskingDesking.contact.states.california'
}, {
  id: 'CO',
  value: 'CO',
  label: 'deskingDesking.contact.states.colorado'
}, {
  id: 'CT',
  value: 'CT',
  label: 'deskingDesking.contact.states.connecticut'
}, {
  id: 'DE',
  value: 'DE',
  label: 'deskingDesking.contact.states.delaware'
}, {
  id: 'DC',
  value: 'DC',
  label: 'deskingDesking.contact.states.districtOfColumbia'
}, {
  id: 'FM',
  value: 'FM',
  label: 'deskingDesking.contact.states.federatedStatesOfMicronesia'
}, {
  id: 'FL',
  value: 'FL',
  label: 'deskingDesking.contact.states.florida'
}, {
  id: 'GA',
  value: 'GA',
  label: 'deskingDesking.contact.states.georgia'
}, {
  id: 'GU',
  value: 'GU',
  label: 'deskingDesking.contact.states.guam'
}, {
  id: 'HI',
  value: 'HI',
  label: 'deskingDesking.contact.states.hawaii'
}, {
  id: 'ID',
  value: 'ID',
  label: 'deskingDesking.contact.states.idaho'
}, {
  id: 'IL',
  value: 'IL',
  label: 'deskingDesking.contact.states.illinois'
}, {
  id: 'IN',
  value: 'IN',
  label: 'deskingDesking.contact.states.indiana'
}, {
  id: 'IA',
  value: 'IA',
  label: 'deskingDesking.contact.states.iowa'
}, {
  id: 'KS',
  value: 'KS',
  label: 'deskingDesking.contact.states.kansas'
}, {
  id: 'KY',
  value: 'KY',
  label: 'deskingDesking.contact.states.kentucky'
}, {
  id: 'LA',
  value: 'LA',
  label: 'deskingDesking.contact.states.louisiana'
}, {
  id: 'ME',
  value: 'ME',
  label: 'deskingDesking.contact.states.maine'
}, {
  id: 'MH',
  value: 'MH',
  label: 'deskingDesking.contact.states.marshallIslands'
}, {
  id: 'MD',
  value: 'MD',
  label: 'deskingDesking.contact.states.maryland'
}, {
  id: 'MA',
  value: 'MA',
  label: 'deskingDesking.contact.states.massachusetts'
}, {
  id: 'MI',
  value: 'MI',
  label: 'deskingDesking.contact.states.michigan'
}, {
  id: 'MN',
  value: 'MN',
  label: 'deskingDesking.contact.states.minnesota'
}, {
  id: 'MS',
  value: 'MS',
  label: 'deskingDesking.contact.states.mississippi'
}, {
  id: 'MO',
  value: 'MO',
  label: 'deskingDesking.contact.states.missouri'
}, {
  id: 'MT',
  value: 'MT',
  label: 'deskingDesking.contact.states.montana'
}, {
  id: 'NE',
  value: 'NE',
  label: 'deskingDesking.contact.states.nebraska'
}, {
  id: 'NV',
  value: 'NV',
  label: 'deskingDesking.contact.states.nevada'
}, {
  id: 'NH',
  value: 'NH',
  label: 'deskingDesking.contact.states.newHampshire'
}, {
  id: 'NJ',
  value: 'NJ',
  label: 'deskingDesking.contact.states.newJersey'
}, {
  id: 'NM',
  value: 'NM',
  label: 'deskingDesking.contact.states.newMexico'
}, {
  id: 'NY',
  value: 'NY',
  label: 'deskingDesking.contact.states.newYork'
}, {
  id: 'NC',
  value: 'NC',
  label: 'deskingDesking.contact.states.northCarolina'
}, {
  id: 'ND',
  value: 'ND',
  label: 'deskingDesking.contact.states.northDakota'
}, {
  id: 'MP',
  value: 'MP',
  label: 'deskingDesking.contact.states.northernMarianaIslands'
}, {
  id: 'OH',
  value: 'OH',
  label: 'deskingDesking.contact.states.ohio'
}, {
  id: 'OK',
  value: 'OK',
  label: 'deskingDesking.contact.states.oklahoma'
}, {
  id: 'OR',
  value: 'OR',
  label: 'deskingDesking.contact.states.oregon'
}, {
  id: 'PW',
  value: 'PW',
  label: 'deskingDesking.contact.states.palau'
}, {
  id: 'PA',
  value: 'PA',
  label: 'deskingDesking.contact.states.pennsylvania'
}, {
  id: 'PR',
  value: 'PR',
  label: 'deskingDesking.contact.states.puertoRico'
}, {
  id: 'RI',
  value: 'RI',
  label: 'deskingDesking.contact.states.rhodeIsland'
}, {
  id: 'SC',
  value: 'SC',
  label: 'deskingDesking.contact.states.southCarolina'
}, {
  id: 'SD',
  value: 'SD',
  label: 'deskingDesking.contact.states.southDakota'
}, {
  id: 'TN',
  value: 'TN',
  label: 'deskingDesking.contact.states.tennessee'
}, {
  id: 'TX',
  value: 'TX',
  label: 'deskingDesking.contact.states.texas'
}, {
  id: 'UT',
  value: 'UT',
  label: 'deskingDesking.contact.states.utah'
}, {
  id: 'VT',
  value: 'VT',
  label: 'deskingDesking.contact.states.vermont'
}, {
  id: 'VI',
  value: 'VI',
  label: 'deskingDesking.contact.states.virginIslands'
}, {
  id: 'VA',
  value: 'VA',
  label: 'deskingDesking.contact.states.virginia'
}, {
  id: 'WA',
  value: 'WA',
  label: 'deskingDesking.contact.states.washington'
}, {
  id: 'WV',
  value: 'WV',
  label: 'deskingDesking.contact.states.westVirginia'
}, {
  id: 'WI',
  value: 'WI',
  label: 'deskingDesking.contact.states.wisconsin'
}, {
  id: 'WY',
  value: 'WY',
  label: 'deskingDesking.contact.states.wyoming'
}];