"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _reactRedux = require("react-redux");
var _actions = require("../../../../redux/actions");
var _paymentOptions = require("../../../../redux/selectors/paymentOptions");
var _PaymentBarTab = _interopRequireDefault(require("./PaymentBarTab"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} state
 * @returns {Object}
 */
const mapStateToProps = state => {
  const list = (0, _paymentOptions.getPurchaseMethodList)(state);
  const selected = list.find(_ref => {
    let {
      isSelected
    } = _ref;
    return isSelected;
  });
  return {
    checked: selected ? selected.name : null,
    list
  };
};

/**
 * @param {Function} dispatch
 * @returns {Object}
 */
const mapDispatchToProps = dispatch => ({
  selectPurchaseMethod: purchaseMethod => dispatch(_actions.Creators.selectPurchaseMethod(purchaseMethod))
});
var _default = exports.default = (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_PaymentBarTab.default);