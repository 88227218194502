"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SERVICE_FORM = void 0;
const sourceString = 'leadServiceVehicle';
const translationPrefix = 'leads.updateView.serviceForm';
const SERVICE_FORM = exports.SERVICE_FORM = [{
  legend: `${translationPrefix}.VEHICLE`,
  fieldset: '',
  fields: [{
    input: 'input',
    className: 'small-12 medium-5 large-3',
    name: `${sourceString}.make`,
    label: `${translationPrefix}.MAKE`
  }, {
    input: 'input',
    className: 'small-12 medium-5 large-3',
    name: `${sourceString}.model`,
    label: `${translationPrefix}.MODEL`
  }, {
    input: 'select',
    className: 'small-12 medium-2 large-2',
    name: `${sourceString}.year`,
    label: `${translationPrefix}.YEAR`,
    optionLabelValue: 'label'
  }, {}, {
    input: 'input',
    className: 'small-12 medium-6 large-4',
    name: `${sourceString}.vin`,
    label: `${translationPrefix}.VIN`,
    customValidation: /^[A-HJ-NPR-Z0-9]{17}$/i,
    customMessage: {
      customValidation: `${translationPrefix}.INVALID_VIN`
    }
  }, {
    input: 'inputNumber',
    className: 'small-12 medium-6 large-4',
    name: `${sourceString}.odometer`,
    label: `${translationPrefix}.MILEAGE`,
    addon: 'km',
    isNatural: true
  }]
}, {
  legend: `${translationPrefix}.SERVICE_REQUEST`,
  fieldset: '',
  fields: [{
    input: 'datePicker',
    className: 'small-12 medium-5 large-3',
    name: `${sourceString}.appointmentDate`,
    label: `${translationPrefix}.APPOINTMENT_DATE`,
    showTimeInput: true
  }, {}, {
    input: 'textarea',
    className: 'small-12 medium-12 large-8',
    name: `${sourceString}.serviceDetails`,
    label: `${translationPrefix}.SERVICE_DETAILS`
  }]
}];