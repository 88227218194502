"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "VehiclesLayout", {
  enumerable: true,
  get: function () {
    return _Layout.default;
  }
});
Object.defineProperty(exports, "VehiclesPagination", {
  enumerable: true,
  get: function () {
    return _Pagination.default;
  }
});
var _Layout = _interopRequireDefault(require("./Layout"));
var _Pagination = _interopRequireDefault(require("./Pagination"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }