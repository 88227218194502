"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _deskingCommons = require("@sm360/desking-commons");
var _selectors = require("../selectors");
var _paymentOptions = require("../selectors/paymentOptions");
var _common = require("../selectors/common");
function _default(_ref) {
  let {
    vehicle
  } = _ref;
  return function* () {
    const navigate = yield (0, _effects.getContext)('navigate');
    const {
      leadId
    } = yield (0, _effects.select)(_selectors.getConfig);
    const paymentOptions = yield (0, _effects.select)(_paymentOptions.getPaymentOptions);
    const includeTaxes = yield (0, _effects.select)(_common.getHasTaxesIncluded);
    const priceIncreaseRollCount = yield (0, _effects.select)(_deskingCommons.priceIncreaseSelectors.getPriceIncreaseRollCount);
    const deskingWidgetParams = yield (0, _effects.select)(_selectors.getDeskingWidgetParams);
    const params = {
      ...deskingWidgetParams,
      make: vehicle.make.slug,
      model: vehicle.model.slug,
      year: vehicle.year,
      bodyStyle: vehicle.bodyStyle.slug,
      leadId,
      includeTaxes,
      upg: priceIncreaseRollCount,
      ...paymentOptions
    };
    navigate((0, _deskingCommons.getBuilderPath)(params));
  }();
}