"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.downloadDocument = void 0;
var _appsCore = require("@sm360/apps-core");
const CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;
const downloadDocument = async _ref => {
  let {
    documentId
  } = _ref;
  return await _appsCore.axios.get(`${CRM_ENDPOINT}/contactDocuments/${documentId}/download`, {
    responseType: 'blob'
  });
};
exports.downloadDocument = downloadDocument;