"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.PHONE_TYPE = void 0;
const PHONE_TYPE = exports.PHONE_TYPE = [{
  value: 'Day Phone',
  translationKey: 'FINANCING.APPLICANT_INFO.PHONE_TYPE.DAY'
}, {
  value: 'Evening Phone',
  translationKey: 'FINANCING.APPLICANT_INFO.PHONE_TYPE.EVENING'
}, {
  value: 'Cell Phone',
  translationKey: 'FINANCING.APPLICANT_INFO.PHONE_TYPE.CELL'
}];
var _default = exports.default = {
  PHONE_TYPE
};