"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _actions = require("../actions");
var _selectors = require("../selectors");
function _default(_ref) {
  let {
    formInstanceId,
    checkedLang
  } = _ref;
  return function* () {
    const quoteId = yield (0, _effects.select)(_selectors.getQuoteId);
    const queryParams = yield (0, _effects.select)(_selectors.getShowroomQueryParams);
    if (checkedLang) {
      queryParams.lang = checkedLang;
    }
    try {
      const response = yield (0, _effects.call)(_commonsApi.ShowroomService.desking.getTransactionPdfCustomFields, quoteId, formInstanceId, queryParams);
      yield (0, _effects.put)(_actions.Creators.setTransactionPrintCustomFields(response));
      return response;
    } catch (error) {
      yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'getPdfFilesList'));
      return false;
    }
  }();
}