"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "GarageContent", {
  enumerable: true,
  get: function () {
    return _GarageContent.default;
  }
});
Object.defineProperty(exports, "Header", {
  enumerable: true,
  get: function () {
    return _GarageHeader.default;
  }
});
var _GarageContent = _interopRequireDefault(require("./GarageContent"));
var _GarageHeader = _interopRequireDefault(require("./GarageHeader"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }