"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setStatus = exports.VEHICLE_STATUS = exports.STATUS_UNMATCHING_LIST = exports.STATUS_MATCHING_LIST = exports.QUOTE_STATUS = exports.API_STATUS = void 0;
var _request = require("../../tools/request");
const VEHICLE_STATUS_API_URL = process.env.REACT_APP_STATUS_API_URL;
const QUOTE_STATUS = exports.QUOTE_STATUS = {
  APPROVAL: 'approval',
  BOOKED: 'booked',
  CANCELLED: 'cancelled',
  DELIVERED: 'delivered',
  QUOTE: 'quote',
  SOLD: 'sold'
};
const VEHICLE_STATUS = exports.VEHICLE_STATUS = {
  DELIVERED: 'DELIVERED',
  FOR_SALE: 'FOR_SALE',
  ON_HOLD: 'ON_HOLD',
  SOLD: 'SOLD'
};
const API_STATUS = exports.API_STATUS = {
  ACCEPT_OFFER: 'ACCEPT_OFFER',
  CANCEL_DELIVERY: 'CANCEL_DELIVERY',
  CANCEL_SALE: 'CANCEL_SALE',
  CONFIRM_DELIVERY: 'CONFIRM_DELIVERY',
  CREATE_UPDATE_QUOTE: 'CREATE_UPDATE_QUOTE',
  REJECT_OFFER: 'REJECT_OFFER',
  RETURN_TO_SALES_MANAGER: 'RETURN_TO_SALES_MANAGER',
  SEND_TO_SALES_MANAGER: 'SEND_TO_SALES_MANAGER'
};

/**
 * List of matching status between vehicle and quote, indicating if
 * data are properly synced between the quote and the associated vehicle.
 */
const STATUS_MATCHING_LIST = exports.STATUS_MATCHING_LIST = [{
  quoteStatus: QUOTE_STATUS.QUOTE,
  vehicleStatus: VEHICLE_STATUS.FOR_SALE
}, {
  quoteStatus: QUOTE_STATUS.APPROVAL,
  vehicleStatus: VEHICLE_STATUS.ON_HOLD
}, {
  quoteStatus: QUOTE_STATUS.SOLD,
  vehicleStatus: VEHICLE_STATUS.SOLD
}, {
  quoteStatus: QUOTE_STATUS.DELIVERED,
  vehicleStatus: VEHICLE_STATUS.DELIVERED
}];

/**
 * List of non-matching status between vehicle and quote, indicating that data are de-synchronized
 * between the quote and the associated vehicle. The vehicle status is in advance.
 */
const STATUS_UNMATCHING_LIST = exports.STATUS_UNMATCHING_LIST = [{
  quoteStatus: QUOTE_STATUS.QUOTE,
  vehicleStatus: VEHICLE_STATUS.ON_HOLD
}, {
  quoteStatus: QUOTE_STATUS.QUOTE,
  vehicleStatus: VEHICLE_STATUS.SOLD
}, {
  quoteStatus: QUOTE_STATUS.QUOTE,
  vehicleStatus: VEHICLE_STATUS.DELIVERED
}, {
  quoteStatus: QUOTE_STATUS.APPROVAL,
  vehicleStatus: VEHICLE_STATUS.SOLD
}, {
  quoteStatus: QUOTE_STATUS.APPROVAL,
  vehicleStatus: VEHICLE_STATUS.DELIVERED
}, {
  quoteStatus: QUOTE_STATUS.SOLD,
  vehicleStatus: VEHICLE_STATUS.DELIVERED
}];

/**
 * @param {String} status
 * @param {Object} body
 * @returns {Promise}
 */
const setStatus = (status, body) => {
  if (Object.values(API_STATUS).indexOf(status) === -1) {
    throw new TypeError(`@sm360/commons-api/ShowroomService/desking/status-service - status '${status}' unknown`);
  }
  return (0, _request.post)(`${VEHICLE_STATUS_API_URL}/status`, {
    ...body,
    status
  });
};
exports.setStatus = setStatus;