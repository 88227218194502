"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingCommons = require("@sm360/desking-commons");
var _deskingError = require("@sm360/desking-error");
var _selectors = require("../../selectors");
var _status = require("../../selectors/status");
var _vehicle = require("../../selectors/vehicle");
function* _default(vehicle) {
  const leadId = yield (0, _effects.select)(_selectors.getLeadId);
  const accountId = yield (0, _effects.select)(_selectors.getAccountId);
  const quoteId = yield (0, _effects.select)(_selectors.getQuoteId);
  const quoteStatus = yield (0, _effects.select)(_status.getQuoteStatus);
  const isCatalog = yield (0, _effects.select)(_vehicle.getIsCatalog);
  const vehicleId = yield (0, _effects.select)(_vehicle.getVehicleId);
  const {
    location,
    organizationId,
    organizationUnitId,
    lang
  } = yield (0, _effects.select)(_selectors.getConfig);
  const paymentOptions = yield (0, _effects.select)(_selectors.getPaymentOptions);
  const priceIncreaseRollCount = yield (0, _effects.select)(_deskingCommons.priceIncreaseSelectors.getPriceIncreaseRollCount);
  const body = {
    leadId,
    accountId,
    year: vehicle.year,
    make: vehicle.make.name,
    model: vehicle.model.name,
    vehicleId: vehicle.vehicleId,
    vin: vehicle.serialNo,
    stockNumber: vehicle.stockNo,
    retail: vehicle.listPrice,
    odometer: vehicle.odometer,
    configurationId: vehicle.configurationId,
    modelTrimCode: vehicle.modelTrimCode,
    cashDown: paymentOptions.cashDown,
    financePlan: paymentOptions.financePlan,
    kmPerYearPlan: paymentOptions.kmPerYearPlan,
    lien: paymentOptions.lien,
    paymentFrequency: paymentOptions.paymentFrequency,
    purchaseMethod: paymentOptions.purchaseMethod,
    saleType: paymentOptions.saleType,
    taxPlan: paymentOptions.taxPlan,
    term: paymentOptions.term,
    tradeIn: paymentOptions.tradeIn,
    vehicleCondition: paymentOptions.vehicleCondition,
    transactionTokenCopy: paymentOptions.transactionToken,
    originalQuote: {
      id: quoteId,
      status: quoteStatus,
      vehicleId: isCatalog ? null : vehicleId
    }
  };
  const params = {
    organizationUnitId,
    organizationId,
    location,
    lang,
    priceIncreaseRollCount
  };
  try {
    return yield (0, _effects.call)(_commonsApi.QuoteService.addNewVehicle, params, body, vehicle.showroomVehicleId);
  } catch (error) {
    return yield (0, _effects.call)(_deskingError.errorSagas.handleAddVehicleError, error, 'selectVehicle');
  }
}