"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _deskingError = require("@sm360/desking-error");
var _actions = require("../../redux/actions");
var _finance = require("../../redux/selectors/finance");
var _selectors = require("../../redux/selectors");
var _IncentiveProgramModal = _interopRequireDefault(require("./IncentiveProgramModal"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const mapStateToProps = state => {
  const incentives = (0, _finance.getIncentives)(state);
  const incentiveOptions = incentives.map(_ref => {
    let {
      id,
      description
    } = _ref;
    return {
      id,
      value: id,
      label: description
    };
  });
  // const isCashDownIncludesTaxes = getIsCashDownIncludesTaxes(state);
  const isCashDownIncludesTaxes = (0, _finance.getIsCashDownIncludesTaxes)(state);
  const isRebatesIncludesTaxes = (0, _finance.getIsRebatesIncludesTaxes)(state);
  const securityDepositIsWaived = (0, _finance.getSecurityDepositIsWaived)(state);
  const salesDeposit = (0, _finance.getSalesDeposit)(state);
  // const finance = getQuoteFinance(state);
  // const { cashDownTaxesDetails } = finance;
  const cashDown = (0, _selectors.getCashDown)(state);
  return {
    incentive: (0, _finance.getEffectiveIncentiveProgram)(state),
    incentives,
    incentiveOptions,
    cashOnlyRebates: (0, _finance.getCashOnlyRebates)(state),
    cashOnlyRebatesAdjustment: (0, _finance.getCashOnlyRebatesAdjustment)(state),
    adminFeesNotChargedToCashAdjustment: (0, _finance.getAdminFeesNotChargedToCashAdjustment)(state),
    // cashDown: isCashDownIncludesTaxes ? cashDownTaxesDetails.totalCashDown : cashDownTaxesDetails.cashDown,
    cashDown,
    isCashDownIncludesTaxes,
    isRebatesIncludesTaxes,
    securityDepositIsWaived,
    salesDeposit
  };
};
const mapDispatchToProps = dispatch => ({
  onChangeIncentiveProgram: (id, incentives, quoteFlagsParams) => {
    const incentive = incentives.find(item => item.id === id);
    dispatch(_actions.Creators.changeIncentive(incentive, quoteFlagsParams));
  }
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps), _deskingError.withErrorWrapper)(_IncentiveProgramModal.default);