"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _appsCore = require("@sm360/apps-core");
var _utils = require("../tools/utils");
const useResumeOptions = _ref => {
  let {
    unavailableValue,
    fullOptions,
    restrictedOptions,
    canAccessRestrictedData
  } = _ref;
  const {
    t
  } = (0, _appsCore.useTranslation)();
  return (0, _utils.getResumeOptions)({
    unavailableValue,
    fullOptions,
    restrictedOptions: restrictedOptions || fullOptions,
    t,
    canAccessRestrictedData
  });
};
var _default = exports.default = useResumeOptions;