"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _effects = require("redux-saga/effects");
var _actions = require("./actions");
var _sagas = require("./sagas");
var _api = require("./sagas/api");
function* rootSaga() {
  yield (0, _effects.all)([(0, _effects.takeLatest)(_actions.Types.INITIALIZE_STORE, _sagas.initializeStore), (0, _effects.takeLatest)(_actions.Types.SELECT_QUOTE, _api.selectQuote), (0, _effects.takeLatest)(_actions.Types.SET_PREFERRED_QUOTE, _api.setPreferredQuote), (0, _effects.takeLatest)(_actions.Types.SET_CUSTOMER_VISIBILITY, _api.setCustomerVisibility), (0, _effects.takeLatest)(_actions.Types.OPEN_VEHICLES, _sagas.openVehicles), (0, _effects.takeLatest)(_actions.Types.GET_GARAGE_AND_QUOTE, _sagas.getGarageAndQuote), (0, _effects.takeLatest)(_actions.Types.ASSIGN_LEAD, _api.assignLead), (0, _effects.takeLatest)(_actions.Types.GET_UNLISTED_VEHICLE_MAKES, _api.getUnlistedVehicleMakes), (0, _effects.takeLatest)(_actions.Types.GET_UNLISTED_VEHICLE_MODELS, _api.getUnlistedVehicleModels), (0, _effects.takeLatest)(_actions.Types.GET_UNLISTED_VEHICLE_TRIMS, _api.getUnlistedVehicleTrims), (0, _effects.takeLatest)(_actions.Types.OPEN_UNLISTED_IN_DESKING, _api.openUnlistedInDesking)]);
}
var _default = exports.default = rootSaga;