"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _content = require("../../../redux/selectors/content");
var _stepTrim = require("../../../redux/selectors/stepTrim");
var _StepPerformance = _interopRequireDefault(require("./StepPerformance"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} state
 * @returns {Object}
 */
const mapStateToProps = state => ({
  list: (0, _content.getDrivePowerTrains)(state),
  modelName: (0, _content.getModelName)(state),
  modelTrim: (0, _stepTrim.getModelTrim)(state)
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps))(_StepPerformance.default);