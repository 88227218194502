"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.statusColor = void 0;
const statusColor = statusCode => {
  switch (statusCode) {
    case 0:
    case 4:
    default:
      return 'warning';
    case 1:
    case 5:
      return 'secondary';
    case 2:
      return 'success';
    case 3:
    case 6:
      return 'alert';
  }
};
exports.statusColor = statusColor;