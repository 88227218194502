"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useUsers = void 0;
var _react = require("react");
var _appsCore = require("@sm360/apps-core");
var _UserService = require("../../api/UserService");
var _utils = require("../../tools/utils");
var _utils2 = require("./utils");
/**
 * Returns a list of users having a specific user right and having access to specific dealer(s)
 * @param {String} right
 * @param {Array.<number>} organizationUnitIds
 * @param {boolean} [active]
 * @returns {Object} a Query object
 */
const useUsers = params => {
  const {
    right,
    organizationUnitIds,
    activeOnly = true,
    select,
    ...rest
  } = params || {};
  const {
    t
  } = (0, _appsCore.useTranslation)();
  const sanitizedOrganizationUnitIds = (0, _utils.sanitizeOrganizationUnitIds)(organizationUnitIds);
  const additionalOptions = (0, _react.useMemo)(() => (0, _utils2.getAdditionalUsers)(right), [right]);
  const queryKey = ['users', {
    right,
    organizationUnitIds: sanitizedOrganizationUnitIds,
    activeOnly
  }];
  const queryFn = () => (0, _UserService.getUsers)(right, organizationUnitIds, activeOnly);
  const enabled = !!right && sanitizedOrganizationUnitIds.length > 0;
  const defaultSelect = (0, _react.useCallback)(data => [...additionalOptions, ...(0, _utils2.transformUsersToOptions)(data, t)], [additionalOptions, t]);
  return (0, _appsCore.useQuery)({
    queryKey,
    queryFn,
    enabled,
    select: select || defaultSelect,
    ...rest
  });
};
exports.useUsers = useUsers;