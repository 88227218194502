"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _selectors = require("../selectors");
var _paymentOptions = require("../selectors/paymentOptions");
var _content = require("../selectors/content");
/**
 * @param {Object} state
 * @param {Object} payload - { year }
 */
var _default = (state, _ref) => {
  let {
    year
  } = _ref;
  const currentYear = (0, _content.getYear)(state);
  let baseProps;
  if (currentYear !== year) {
    // If new year, reset baseProps
    baseProps = (0, _selectors.composeNewBaseProps)(state);
  } else {
    // Keep the same props if the user kept the same year
    baseProps = (0, _selectors.getBaseProps)(state);
  }
  return {
    ...state,
    baseProps,
    nav: {
      ...(0, _selectors.getNav)(state),
      isNextAvailable: true
    },
    paymentOptions: {
      ...(0, _paymentOptions.getPaymentOptions)(state),
      financeOptions: []
    },
    content: {
      ...(0, _content.getContent)(state),
      year
    }
  };
};
exports.default = _default;