"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSelectedSortInfo = exports.getSelectedItem = exports.getSelected = exports.getSearchText = exports.getPriceRange = exports.getOptionsItem = exports.getOptions = exports.getOdometerRange = exports.getIsOnlineVehiclesOnly = exports.getIsDealerVehiclesOnly = exports.getIsCategoryUsed = exports.getIsCategoryNew = exports.getIsCategoryInventory = exports.getIsCategoryCatalog = exports.getFilters = exports.getCategory = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _selectors = require("../selectors");
var _vehicles = require("../../constants/vehicles");
var _filters = require("../../constants/filters");
/**
 * @param {Object} state
 * @returns {Object}
 */
const getFilters = state => {
  const {
    filters
  } = state;
  return (0, _commonsUtils.isPlainObject)(filters) ? filters : {};
};

/**
 * The reducers have to validate the category before updating the store
 *
 * @param {Object} state
 * @returns {String}
 */
exports.getFilters = getFilters;
const getCategory = state => {
  const {
    category
  } = getFilters(state);
  return category;
};

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getCategory = getCategory;
const getIsCategoryCatalog = state => getCategory(state) === _vehicles.CATEGORY_CATALOG;

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getIsCategoryCatalog = getIsCategoryCatalog;
const getIsCategoryInventory = state => !getIsCategoryCatalog(state);

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getIsCategoryInventory = getIsCategoryInventory;
const getIsCategoryNew = state => getCategory(state) === _vehicles.CATEGORY_NEW;

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getIsCategoryNew = getIsCategoryNew;
const getIsCategoryUsed = state => getCategory(state) === _vehicles.CATEGORY_USED;

/**
 * @param {Object} state
 * @returns {String}
 */
exports.getIsCategoryUsed = getIsCategoryUsed;
const getSearchText = state => {
  const {
    searchText
  } = getFilters(state);
  return typeof searchText === 'string' ? searchText : '';
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getSearchText = getSearchText;
const getOptions = state => {
  const {
    options
  } = getFilters(state);
  return (0, _commonsUtils.isPlainObject)(options) ? options : {};
};

/**
 * Prepare the options for the react select component
 *
 * @param {Object} state
 * @param {String} name
 * @returns {Object[]} { value, label }
 */
exports.getOptions = getOptions;
const getOptionsItem = (state, name) => {
  const options = getOptions(state);
  return Array.isArray(options[name]) ? options[name] : [];
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getOptionsItem = getOptionsItem;
const getSelected = state => {
  const {
    selected
  } = getFilters(state);
  return (0, _commonsUtils.isPlainObject)(selected) ? selected : {};
};

/**
 * @param {Object} state
 * @param {String} name
 * @returns {Any[]} Nummber[] for the ids, or String[] for year
 */
exports.getSelected = getSelected;
const getSelectedItem = (state, name) => {
  const selected = getSelected(state);
  return Array.isArray(selected[name]) ? selected[name] : [];
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getSelectedItem = getSelectedItem;
const getPriceRange = state => {
  const {
    priceRange
  } = getFilters(state);
  return (0, _commonsUtils.isPlainObject)(priceRange) ? priceRange : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getPriceRange = getPriceRange;
const getOdometerRange = state => {
  const {
    odometerRange
  } = getFilters(state);
  return (0, _commonsUtils.isPlainObject)(odometerRange) ? odometerRange : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getOdometerRange = getOdometerRange;
const getIsOnlineVehiclesOnly = state => {
  const {
    onlineVehiclesOnly
  } = getFilters(state);
  return onlineVehiclesOnly === true;
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getIsOnlineVehiclesOnly = getIsOnlineVehiclesOnly;
const getIsDealerVehiclesOnly = state => {
  const {
    dealerVehiclesOnly
  } = getFilters(state);
  return dealerVehiclesOnly === true;
};

/**
 * @param {Object} state
 * @returns {String}
 */
exports.getIsDealerVehiclesOnly = getIsDealerVehiclesOnly;
const getSelectedSortInfo = state => {
  const {
    vehicleSortParameter,
    direction
  } = (0, _selectors.getSort)(state);
  const selectedData = _filters.SORT_DATA.find(item => vehicleSortParameter === item.TYPE && direction === item.DIR);
  return selectedData ? selectedData.VALUE : '';
};
exports.getSelectedSortInfo = getSelectedSortInfo;