"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
var _hooks = require("@sm360/hooks");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
// Will call callback 'onVisible' whenever the tab becomes visible (and was hidden before)
const AutoRefresher = _ref => {
  let {
    enabled,
    onVisible
  } = _ref;
  const isHidden = (0, _hooks.useDocumentVisibility)();
  _react.default.useEffect(() => {
    if (!isHidden && enabled && typeof onVisible === 'function') {
      onVisible();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHidden]);
};
var _default = exports.default = AutoRefresher;