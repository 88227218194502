"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.INPUT_TYPES = exports.COMMISSION_SECTIONS = exports.COMMISSION_ADJUSTMENT_TYPES = exports.COMMISSION_ADJUSTMENT = void 0;
const COMMISSION_SECTIONS = exports.COMMISSION_SECTIONS = {
  SALES_TEAM: 'salesTeamSection',
  BUSINESS_MANAGER: 'businessManagerSection',
  ACCOUNTING_COST: 'accountingCostSection',
  DISCREPANCY: 'discrepancySection',
  DESKING_COST: 'deskingCostSection'
};
const INPUT_TYPES = exports.INPUT_TYPES = {
  COST: 'cost',
  PROFIT: 'profit',
  RATE: 'rate',
  COMMISSION: 'commission',
  COMMISSION_FNI_SPLIT: 'commissionFNISplitToSales',
  PROFIT_PAD: 'profitPad'
};
const COMMISSION_ADJUSTMENT_TYPES = exports.COMMISSION_ADJUSTMENT_TYPES = {
  BUSINESS_OFFICE: 'businessOffice',
  SALES: 'sales'
};
const COMMISSION_ADJUSTMENT = exports.COMMISSION_ADJUSTMENT = 'commissionAdjustment';