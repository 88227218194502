"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _deskingCommons = require("@sm360/desking-commons");
var _requestAddToGarage = require("../selectors/requestAddToGarage");
var _selectors = require("../selectors");
var _addToGarage = _interopRequireDefault(require("./api/addToGarage"));
var _assignLead = _interopRequireDefault(require("./api/assignLead"));
var _addVehicleToGarage = _interopRequireDefault(require("./api/addVehicleToGarage"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} payload - { vehicle }
 */
function _default(_ref) {
  let {
    vehicle
  } = _ref;
  return function* () {
    let quote;
    const {
      priceIncreaseRollCount
    } = yield (0, _effects.select)(_requestAddToGarage.getQueryParams);
    const deskingWidgetParams = yield (0, _effects.select)(_selectors.getDeskingWidgetParams);
    const salesPersonnel = yield (0, _effects.select)(_selectors.getSalesPersonnel);
    const navigate = yield (0, _effects.getContext)('navigate');
    const {
      salesPerson
    } = salesPersonnel;
    const hasSalesPerson = salesPerson && Object.entries(salesPerson).length > 0;
    if (!hasSalesPerson) {
      yield (0, _effects.call)(_assignLead.default, _deskingCommons.SALES_PERSONNEL.SALES_PERSON);
    }
    if (vehicle) {
      quote = yield (0, _effects.call)(_addVehicleToGarage.default, vehicle);
    } else {
      quote = yield (0, _effects.call)(_addToGarage.default);
    }
    if (quote) {
      const {
        id
      } = quote;
      (0, _deskingCommons.triggerDisableExitConfirmation)();
      navigate((0, _deskingCommons.getDeskingPath)({
        ...deskingWidgetParams,
        quoteId: id,
        upg: priceIncreaseRollCount
      }));
    }
  }();
}