"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _effects = require("redux-saga/effects");
var _selectors = require("../selectors");
var _updatePayOptAndTransDetails = _interopRequireDefault(require("./api/updatePayOptAndTransDetails"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} payload - Data from action
 */
function* selectPayment(payload) {
  const paymentOptions = yield (0, _effects.select)(_selectors.getPaymentOptions);
  const financeOptions = yield (0, _effects.select)(_selectors.getFinanceOptions);
  const {
    cashDown,
    index,
    term
  } = payload;

  // Deep copy
  const transformedFinanceOptions = structuredClone(financeOptions);

  // Update isSelected values
  transformedFinanceOptions.forEach((fo, i) => {
    fo.isSelected = i === index;
  });

  // Update selected financeOptions
  transformedFinanceOptions[index].selectedTerm = term;
  transformedFinanceOptions[index].selectedCashDown = cashDown;

  // Take data from the targeted financeOptions
  const {
    financePlan,
    kmPerYearPlan,
    paymentFrequency,
    purchaseMethod
  } = transformedFinanceOptions[index];
  yield (0, _effects.call)(_updatePayOptAndTransDetails.default, {
    ...paymentOptions,
    cashDown,
    financeOptions: transformedFinanceOptions,
    financePlan,
    kmPerYearPlan,
    paymentFrequency,
    purchaseMethod,
    term
  });
}
var _default = exports.default = selectPayment;