"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TRANSACTION_TOTAL = exports.TRANSACTION_TOPAY = exports.TRANSACTION_DEPOSIT = exports.TAX_OUT = exports.TAX_IN = exports.RESIDUAL_RETAIL = exports.RESIDUAL_RATE = exports.RESIDUAL_BUYBACK_AMOUNT = exports.RESIDUAL_AMOUNT = exports.RESIDUAL_ADJUSTMENT_FIELDS = exports.PURCHASE_METHODS = exports.PROVINCES = exports.PROFIT_ADJUSTMENT_TYPE = exports.PAYMENT_ADJUSTMENT_TYPE = exports.KEY_TRADEIN = exports.KEY_TERM = exports.KEY_ODOMETER = exports.KEY_META_TERM = exports.KEY_META_KMPLAN = exports.KEY_META_FREQUENCY = exports.KEY_LIEN = exports.KEY_KMPLAN = exports.KEY_FREQUENCY = exports.KEY_CASHDOWN = exports.DUE_AT_DELIVERY_ADJUSTMENT_TYPE = void 0;
const TRANSACTION_TOPAY = exports.TRANSACTION_TOPAY = 'toPay';
const TRANSACTION_DEPOSIT = exports.TRANSACTION_DEPOSIT = 'deposit';
const TRANSACTION_TOTAL = exports.TRANSACTION_TOTAL = 'total';
const PURCHASE_METHODS = exports.PURCHASE_METHODS = {
  CASH: 'cash',
  LEASE: 'lease',
  FINANCE: 'finance',
  FINANCE_WITH_BALLOON: 'financeWithBalloon',
  SINGLE_PAYMENT_LEASE: 'singlePaymentLease'
};
const KEY_CASHDOWN = exports.KEY_CASHDOWN = 'cashDown';
const KEY_FREQUENCY = exports.KEY_FREQUENCY = 'paymentFrequency';
const KEY_LIEN = exports.KEY_LIEN = 'lien';
const KEY_KMPLAN = exports.KEY_KMPLAN = 'kmPerYearPlan';
const KEY_TERM = exports.KEY_TERM = 'term';
const KEY_TRADEIN = exports.KEY_TRADEIN = 'tradeIn';
const KEY_ODOMETER = exports.KEY_ODOMETER = 'odometer';
const KEY_META_FREQUENCY = exports.KEY_META_FREQUENCY = 'paymentFrequencies';
const KEY_META_KMPLAN = exports.KEY_META_KMPLAN = 'kmPerYearPlans';
const KEY_META_TERM = exports.KEY_META_TERM = 'term';
const PAYMENT_ADJUSTMENT_TYPE = exports.PAYMENT_ADJUSTMENT_TYPE = {
  CASH_DOWN: 'cashDown',
  SELLING_PRICE: 'sellingPrice',
  TRADE_IN: 'tradeIn'
};
const PROFIT_ADJUSTMENT_TYPE = exports.PROFIT_ADJUSTMENT_TYPE = {
  SELLING_PRICE: 'sellingPrice',
  SELLING_PRICE_NO_DISCOUNT: 'sellingPriceNoDiscount',
  RETAIL_DISCOUNT: 'retailDiscount',
  RETAIL_DISCOUNT_RATE: 'retailDiscountRate',
  COST_MARKUP: 'costMarkup',
  COST_MARKUP_RATE: 'costMarkupRate'
};
const DUE_AT_DELIVERY_ADJUSTMENT_TYPE = exports.DUE_AT_DELIVERY_ADJUSTMENT_TYPE = {
  CASH_DOWN: 'cashDown',
  PROFIT: 'profit'
};
const RESIDUAL_RETAIL = exports.RESIDUAL_RETAIL = 'residualRetail';
const RESIDUAL_RATE = exports.RESIDUAL_RATE = 'residualRate';
const RESIDUAL_AMOUNT = exports.RESIDUAL_AMOUNT = 'residualAmount';
const RESIDUAL_BUYBACK_AMOUNT = exports.RESIDUAL_BUYBACK_AMOUNT = 'buyBackAmount';
const RESIDUAL_ADJUSTMENT_FIELDS = exports.RESIDUAL_ADJUSTMENT_FIELDS = [RESIDUAL_RETAIL, RESIDUAL_RATE, RESIDUAL_AMOUNT, RESIDUAL_BUYBACK_AMOUNT];
const PROVINCES = exports.PROVINCES = {
  AB: 'AB',
  BC: 'BC',
  MN: 'MN',
  NB: 'NB',
  NL: 'NL',
  NS: 'NS',
  NT: 'NT',
  NU: 'NU',
  ON: 'ON',
  PE: 'PE',
  QC: 'QC',
  SK: 'SK',
  YK: 'YT'
};
const TAX_IN = exports.TAX_IN = 'taxIn';
const TAX_OUT = exports.TAX_OUT = 'taxOut';