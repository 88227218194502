"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _selectors = require("../../selectors");
var _requestModelYear = require("../../selectors/requestModelYear");
function* _default() {
  const pathParams = yield (0, _effects.select)(_requestModelYear.getPathParams);
  const queryParams = yield (0, _effects.select)(_selectors.getQueryParams);
  const body = yield (0, _effects.select)(_requestModelYear.getBody);
  try {
    return yield (0, _effects.call)(_commonsApi.ShowroomService.builder.getModelYears, pathParams, queryParams, body);
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'getModelYears'));
    return null;
  }
}