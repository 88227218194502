"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _selectors = require("../../selectors");
var _default = (state, payload) => {
  const {
    dates
  } = payload;
  const {
    saleDate,
    deliveryDate,
    firstPaymentDate,
    lastPaymentDate,
    buyBackDate
  } = dates;
  return {
    ...state,
    quote: {
      ...(0, _selectors.getQuote)(state),
      saleDate,
      deliveryDate,
      firstPaymentDate,
      lastPaymentDate,
      buyBackDate
    }
  };
};
exports.default = _default;