"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _selectors = require("../selectors");
const {
  setPreferredQuote
} = _commonsApi.QuoteService;
function* _default(payload) {
  const quoteServiceQueryParams = yield (0, _effects.select)(_selectors.getQuoteServiceQueryParams);
  try {
    yield (0, _effects.call)(setPreferredQuote, quoteServiceQueryParams, payload);
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'setPreferred'));
  }
}