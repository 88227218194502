"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _selectors = require("../../selectors");
/**
 * @param {Object} state
 * @param {Object} boolean
 * @returns {Object}
 */
var _default = (state, _ref) => {
  let {
    isCreditApplicationDecisionRefreshing
  } = _ref;
  return {
    ...state,
    creditApplication: {
      ...(0, _selectors.getCreditApplication)(state),
      isCreditApplicationDecisionRefreshing
    }
  };
};
exports.default = _default;