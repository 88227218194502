"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _selectors = require("../selectors");
/**
 * @param {Object} state
 * @param {Object} payload - { step }
 * @returns {Object}
 */
var _default = (state, _ref) => {
  let {
    step,
    lastVisitedStep
  } = _ref;
  const nav = (0, _selectors.getNav)(state);
  return {
    ...state,
    nav: {
      ...nav,
      currentStep: step,
      lastVisitedStep
    }
  };
};
exports.default = _default;