"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/**
 * @param {Object} state
 * @param {Object} payload - { leadInfo }
 * @returns {Object}
 */
const setLeadInfo = (state, _ref) => {
  let {
    leadInfo
  } = _ref;
  return {
    ...state,
    salesPersonnel: {
      ...state.salesPersonnel,
      ...leadInfo
    }
  };
};
var _default = exports.default = setLeadInfo;