"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _actions = require("../actions");
var _selectors = require("../selectors");
function* _default() {
  const queryParams = yield (0, _effects.select)(_selectors.getLoadQuoteQueryParams);
  const id = yield (0, _effects.select)(_selectors.getQuoteId);
  try {
    const {
      vehicle
    } = yield (0, _effects.call)(_commonsApi.QuoteService.getQuoteDetails, id, queryParams);
    yield (0, _effects.put)(_actions.Creators.reloadVehicleSuccess(vehicle));
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'getQuote'));
  }
}