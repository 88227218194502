"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _paymentOptions = require("../../selectors/paymentOptions");
var _common = require("./common");
/**
 * @param {Object} state
 * @param {Object} payload - { metadata }
 * @returns {Object}
 */
var _default = (state, _ref) => {
  let {
    metadata
  } = _ref;
  const paymentOptions = (0, _paymentOptions.getPaymentOptions)(state);
  const {
    kmPerYearPlan: selectedKmPerYearPlan,
    paymentFrequency: selectedPaymentFrequency,
    purchaseMethod: selectedPurchaseMethod,
    term: selectedTerm
  } = paymentOptions;
  const validatedValues = (0, _common.validateValuesFromMetadata)(metadata, selectedKmPerYearPlan, selectedPaymentFrequency, selectedPurchaseMethod, selectedTerm);
  return {
    ...state,
    paymentOptions: {
      ...paymentOptions,
      ...validatedValues,
      metadata
    }
  };
};
exports.default = _default;