"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _selectors = require("../../selectors");
var _paymentOptions = require("../../selectors/paymentOptions");
/**
 * @param {Object} state
 * @param {Object} payload - { response }
 */
var _default = (state, _ref) => {
  let {
    response
  } = _ref;
  const {
    cashDown,
    cashPurchase,
    configurationId,
    finance,
    financeOptions,
    kmPerYearPlan,
    lien,
    modelTrimCode,
    odometer,
    paymentFrequency,
    purchaseMethod,
    saleType,
    taxPlan,
    term,
    tradeIn,
    transactionToken,
    vehicleCondition,
    disclaimer,
    __paymentOptionsMeta
  } = response;
  const baseProps = (0, _selectors.composeNewBaseProps)(state, configurationId, modelTrimCode, transactionToken);
  const newMetaData = Array.isArray(__paymentOptionsMeta) ? __paymentOptionsMeta : [];
  let metaData = (0, _paymentOptions.getMetaData)(state);

  // Don't replace metaData if it's the same, avoiding to rerender components depending on it
  if (!(0, _commonsUtils.isEqual)(metaData, newMetaData)) {
    metaData = newMetaData;
  }
  return {
    ...state,
    baseProps,
    plan: {
      odometer,
      saleType,
      taxPlan,
      vehicleCondition
    },
    purchaseMethod: {
      cashDown,
      kmPerYearPlan,
      paymentFrequency,
      purchaseMethod,
      term
    },
    tradeIn: {
      lien,
      tradeIn
    },
    paymentOptions: {
      cashPurchase,
      finance,
      financeOptions,
      disclaimer,
      metaData
    }
  };
};
exports.default = _default;