"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getQueryParams = exports.getBodyVehicleMatch = exports.getBody = void 0;
var _deskingCommons = require("@sm360/desking-commons");
var _selectors = require("../selectors");
var _paymentOptions = require("./paymentOptions");
var _content = require("./content");
/**
 * @param {Object} state
 * @returns {Object}
 */
const getBody = state => {
  const {
    accountId,
    make
  } = (0, _selectors.getConfig)(state);
  const {
    configurationId,
    modelTrimCode,
    transactionToken
  } = (0, _selectors.getBaseProps)(state);
  const year = (0, _content.getYear)(state);
  const financePlan = (0, _paymentOptions.getSelectedFinancePlan)(state);
  return {
    ...(0, _selectors.getPlan)(state),
    ...(0, _selectors.getPurchaseMethod)(state),
    ...(0, _selectors.getTradeIn)(state),
    accountId,
    configurationId,
    financePlan,
    make,
    modelTrimCode,
    transactionToken,
    year
  };
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getBody = getBody;
const getQueryParams = state => {
  const {
    location,
    organizationId,
    organizationUnitId
  } = (0, _selectors.getConfig)(state);
  return {
    location,
    organizationId,
    organizationUnitId,
    priceIncreaseRollCount: _deskingCommons.priceIncreaseSelectors.getPriceIncreaseRollCount(state)
  };
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getQueryParams = getQueryParams;
const getBodyVehicleMatch = (state, vehicle) => {
  const {
    accountId,
    leadId
  } = (0, _selectors.getConfig)(state);
  const selectedFinancePlan = (0, _paymentOptions.getSelectedFinancePlan)(state);
  const {
    configurationId,
    make,
    modelTrimCode,
    model,
    odometer,
    paymentOptions,
    retail,
    serialNo: vin,
    stockNo: stockNumber,
    vehicleId,
    year
  } = vehicle;
  const {
    cashDown,
    kmPerYearPlan,
    lien,
    paymentFrequency,
    purchaseMethod,
    saleType,
    taxPlan,
    term,
    tradeIn,
    vehicleCondition
  } = paymentOptions;
  return {
    accountId,
    cashDown,
    configurationId,
    financePlan: selectedFinancePlan,
    kmPerYearPlan,
    leadId,
    lien,
    make: make.name,
    model: model.name,
    modelTrimCode,
    odometer,
    paymentFrequency,
    purchaseMethod,
    retail,
    saleType,
    stockNumber,
    taxPlan,
    term,
    tradeIn,
    vehicleCondition,
    vehicleId,
    vin,
    year,
    transactionTokenCopy: paymentOptions.transactionToken
  };
};
exports.getBodyVehicleMatch = getBodyVehicleMatch;