"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducers = exports.Creators = void 0;
var _reduxsauce = require("reduxsauce");
const {
  Types,
  Creators
} = (0, _reduxsauce.createActions)({
  handleError: ['error', 'i18nKey'],
  handleVehicleNotFound: ['showModal', 'status'],
  resetError: []
});
exports.Creators = Creators;
const handleError = (state, _ref) => {
  let {
    error,
    i18nKey
  } = _ref;
  return {
    ...state,
    error: {
      message: error.message,
      i18nKey
    }
  };
};
const handleVehicleNotFound = (state, _ref2) => {
  let {
    showModal,
    status
  } = _ref2;
  return {
    ...state,
    selectedVehicleInfo: {
      showVehicleNotFoundModal: showModal,
      vehicleStatus: status
    }
  };
};
const resetError = state => ({
  ...state,
  error: null
});
const reducers = exports.reducers = {
  [Types.HANDLE_ERROR]: handleError,
  [Types.RESET_ERROR]: resetError,
  [Types.HANDLE_VEHICLE_NOT_FOUND]: handleVehicleNotFound
};