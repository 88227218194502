"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useActivity = void 0;
var _appsCore = require("@sm360/apps-core");
var _ActivityService = require("../../api/ActivityService");
/**
 * Returns an activity
 * @param {number} activityId
 * @returns {Object} a Query object
 */

const useActivity = params => {
  const {
    activityId,
    ...rest
  } = params || {};
  return (0, _appsCore.useQuery)({
    queryKey: ['activities', activityId],
    queryFn: () => (0, _ActivityService.getActivity)(activityId),
    enabled: !!activityId,
    ...rest
  });
};
exports.useActivity = useActivity;