"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useVehicleModels = void 0;
var _appsCore = require("@sm360/apps-core");
var _VehicleStaticDataService = require("../../api/VehicleStaticDataService");
/**
 * @returns {Object} a Query object
 */

const useVehicleModels = params => {
  const {
    organizationId,
    makeId,
    ...rest
  } = params || {};
  return (0, _appsCore.useQuery)({
    queryKey: ['models', {
      organizationId,
      makeId
    }],
    queryFn: () => (0, _VehicleStaticDataService.getVehicleModels)(organizationId, makeId),
    ...rest
  });
};
exports.useVehicleModels = useVehicleModels;