"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _filters = require("../../redux/selectors/filters");
var _actions = require("../../redux/actions");
var _FilterBar = _interopRequireDefault(require("./FilterBar"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} state
 * @returns {Object}
 */
const mapStateToProps = state => ({
  isInventory: (0, _filters.getIsCategoryInventory)(state),
  isCategoryUsed: (0, _filters.getIsCategoryUsed)(state)
});

/**
 * @param {Function} dispatch
 * @returns {Object}
 */
const mapDispatchToProps = dispatch => ({
  applyFilters: () => dispatch(_actions.Creators.applyFilters()),
  resetFilters: () => dispatch(_actions.Creators.resetFilters())
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps))(_FilterBar.default);