"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useSubscriptionContexts = void 0;
var _react = require("react");
var _appsCore = require("@sm360/apps-core");
var _SubscriptionService = require("../../api/SubscriptionService");
var _utils = require("./utils");
/**
 * Returns a list of available subscription context for a given dealer group
 * @param {Number} organizationId
 * @returns {Object} a Query object
 */
const useSubscriptionContexts = params => {
  const {
    locale
  } = (0, _appsCore.useTranslation)();
  const {
    organizationId,
    select,
    ...rest
  } = params || {};
  const defaultSelect = (0, _react.useCallback)(data => (0, _utils.transformSubscriptionContextsToOptions)(data, locale), [locale]);
  return (0, _appsCore.useQuery)({
    queryKey: ['subscriptions', 'contexts', {
      organizationId
    }],
    queryFn: () => (0, _SubscriptionService.getSubscriptionContexts)(organizationId),
    enabled: organizationId > 0,
    select: select || defaultSelect,
    ...rest
  });
};
exports.useSubscriptionContexts = useSubscriptionContexts;