"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _deskingError = require("@sm360/desking-error");
var _deskingCommons = require("@sm360/desking-commons");
var _actions = require("../../redux/actions");
var _selectors = require("../../redux/selectors");
var _PaymentAdjustmentModal = _interopRequireDefault(require("./PaymentAdjustmentModal"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Different behaviors when cash is selected, we look for the data in different places
 *
 * @param {Object} state - Global state
 * @returns {Object} injected props
 */
const mapStateToProps = state => {
  const isCashSelected = (0, _selectors.getIsCashSelected)(state);
  const isLeaseSelected = (0, _selectors.getIsLeaseSelected)(state);
  const {
    transaction
  } = (0, _selectors.getQuote)(state);
  const toPayCategory = transaction.find(item => item.category === _deskingCommons.ITEM_CATEGORY.TO_PAY);
  let savedPaymentAmount = null;
  let baseSellingPrice = null;
  let dueAtDelivery = null;
  let calculatedAdjustments = {};
  if (isCashSelected) {
    baseSellingPrice = (0, _selectors.getCashBaseSellingPrice)(state);
  } else {
    const hasTaxesIncluded = (0, _selectors.getHasTaxesIncluded)(state);
    const financeOptions = (0, _selectors.getSelectedFinanceOptions)(state);
    calculatedAdjustments = (0, _selectors.getCalculatedAdjustments)(state);
    const {
      selectedPayment
    } = financeOptions;
    ({
      baseSellingPrice,
      dueAtDelivery
    } = financeOptions);
    if (selectedPayment) {
      savedPaymentAmount = hasTaxesIncluded ? selectedPayment.totalPayment : selectedPayment.payment;
    }
  }
  return {
    isCashSelected,
    isLeaseSelected,
    savedPaymentAmount,
    calculatedAdjustments,
    baseSellingPrice,
    savedDueAtDelivery: dueAtDelivery,
    savedAmountToPay: toPayCategory.pricing.amount
  };
};
const mapDispatchToProps = dispatch => ({
  changePayment: (targetType, value, handleIsValid) => dispatch(_actions.Creators.changeDesiredPayment(targetType, value, handleIsValid)),
  updateProfitRoll: (targetType, value, handleIsValid) => dispatch(_actions.Creators.updateProfitRoll(targetType, value, handleIsValid)),
  updateDueAtDeliveryRoll: (targetType, value, handleIsValid) => dispatch(_actions.Creators.updateDueAtDeliveryRoll(targetType, value, handleIsValid)),
  updateAmountToPayRoll: (targetType, value, handleIsValid) => dispatch(_actions.Creators.updateAmountToPayRoll(targetType, value, handleIsValid)),
  validateProfitRollChange: (targetType, value, changeType) => dispatch(_actions.Creators.validateProfitRollChange(targetType, value, changeType)),
  validateDueAtDeliveryRollChange: (targetType, value, changeType) => dispatch(_actions.Creators.validateDueAtDeliveryRollChange(targetType, value, changeType)),
  validateAmountToPayRollChange: (targetType, value, changeType) => dispatch(_actions.Creators.validateAmountToPayRollChange(targetType, value, changeType)),
  validatePaymentChange: (targetType, value, changeType) => dispatch(_actions.Creators.validatePaymentChange(targetType, value, changeType)),
  resetPayAdjustmentValidation: () => dispatch(_actions.Creators.resetPayAdjustmentValidation()),
  initializeCalculatedAdjustments: (targetType, value) => dispatch(_actions.Creators.initializeCalculatedAdjustments(targetType, value))
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps), _deskingError.withErrorWrapper)(_PaymentAdjustmentModal.default);