"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tabProtection = require("../../constants/tabProtection");
var _tabProtection2 = require("../selectors/tabProtection");
/**
 * @param {Object} state
 * @param {Object} payload - { name, items }
 * @returns {Object}
 */
var _default = (state, _ref) => {
  let {
    name,
    items
  } = _ref;
  let newTabItem = {};
  switch (name) {
    case _tabProtection.TAB_PROTECTION.PROTECTION:
      newTabItem = {
        tabProtectionProtection: {
          ...(0, _tabProtection2.getTabProtectionProtection)(state),
          content: items,
          isUpdated: true
        }
      };
      break;
    case _tabProtection.TAB_PROTECTION.WARRANTY:
      newTabItem = {
        tabProtectionWarranty: {
          ...(0, _tabProtection2.getTabProtectionWarranty)(state),
          content: items,
          isUpdated: true
        }
      };
      break;
    case _tabProtection.TAB_PROTECTION.INSURANCE:
      newTabItem = {
        tabProtectionInsurance: {
          ...(0, _tabProtection2.getTabProtectionInsurance)(state),
          content: items,
          isUpdated: true
        }
      };
      break;
    case _tabProtection.TAB_PROTECTION.LGM:
      newTabItem = {
        tabProtectionLGM: {
          ...(0, _tabProtection2.getTabProtectionLGM)(state),
          content: items,
          isUpdated: true
        }
      };
      break;
    case _tabProtection.TAB_PROTECTION.BMW:
      newTabItem = {
        tabProtectionBMW: {
          ...(0, _tabProtection2.getTabProtectionBMW)(state),
          content: items,
          isUpdated: true
        }
      };
      break;
    default:
  }
  return {
    ...state,
    ...newTabItem
  };
};
exports.default = _default;