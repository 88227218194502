"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useVehicleCategories = void 0;
var _appsCore = require("@sm360/apps-core");
var _VehicleStaticDataService = require("../../api/VehicleStaticDataService");
var _utils = require("../../tools/utils");
const transformCategoriesToOptions = data => (0, _utils.transformDataToOptions)(data, 'categoryDesc', 'id', 'id', true);

/**
 * @param {String} industry
 * @param {Boolean} allAccessIndustry
 * @returns {Object} a Query object
 */

const useVehicleCategories = params => {
  const {
    industry,
    allAccessIndustry,
    select,
    ...rest
  } = params || {};
  return (0, _appsCore.useQuery)({
    queryKey: ['vehicleCategories', {
      industry,
      allAccessIndustry
    }],
    queryFn: () => (0, _VehicleStaticDataService.getVehicleCategories)(industry, allAccessIndustry),
    select: select || transformCategoriesToOptions,
    ...rest
  });
};
exports.useVehicleCategories = useVehicleCategories;