"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _effects = require("redux-saga/effects");
var _commonsUtils = require("@sm360/commons-utils");
var _selectors = require("../selectors");
var _updatePayOptAndTransDetails = _interopRequireDefault(require("./api/updatePayOptAndTransDetails"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} payload - Data from action
 */
function* changePaymentCardCashDown(payload) {
  const paymentOptions = yield (0, _effects.select)(_selectors.getPaymentOptions);
  const {
    cashDown: mainCashDown
  } = paymentOptions;
  const financeOptions = yield (0, _effects.select)(_selectors.getFinanceOptions);
  const {
    cashDown,
    cashDownIndex,
    isCashDownSelected
  } = payload;

  // Deep copy
  const transformedFinanceOptions = structuredClone(financeOptions);
  // Update
  transformedFinanceOptions.forEach(fo => {
    (0, _commonsUtils.set)(fo, `cashDownOptions[${cashDownIndex}].cashDown`, cashDown);
  });
  yield (0, _effects.call)(_updatePayOptAndTransDetails.default, {
    ...paymentOptions,
    // Keep the original cashDown at the root if we change the cashDown from "unselected" column
    cashDown: isCashDownSelected ? cashDown : mainCashDown,
    financeOptions: transformedFinanceOptions
  });
}
var _default = exports.default = changePaymentCardCashDown;