"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setVisibleCustomer = exports.setUnlistedVehicleTrims = exports.setUnlistedVehicleModels = exports.setUnlistedVehicleMakes = exports.setUnlistedVehicleFormData = exports.setLocation = exports.setLoadingSuccess = exports.setLeadInfo = exports.setIsUnlistedVehicle = exports.setIsUnlistedNew = exports.setDeskingAccess = exports.setCanCreateUnlistedVehicle = exports.resetUnlistedVehicleTrims = exports.resetUnlistedVehicleMakes = exports.resetUnlistedVehicleFormData = exports.loadQuoteSuccess = exports.loadGarageSuccess = exports.initializeStore = exports.default = exports.INITIAL_STATE = void 0;
var _reduxsauce = require("reduxsauce");
var _deskingError = require("@sm360/desking-error");
var _deskingCommons = require("@sm360/desking-commons");
var _actions = require("./actions");
var _selectors = require("./selectors");
const INITIAL_STATE = exports.INITIAL_STATE = {
  ..._deskingError.errorInitialState,
  ..._deskingCommons.priceIncreaseInitialState,
  isLoading: true,
  // Config
  config: {
    lang: 'en',
    location: null,
    organizationId: 0,
    organizationUnitId: 0,
    leadId: 0,
    accountId: 0
  },
  // Check some rights
  hasDeskingAccess: false,
  canCreateUnlistedVehicle: false,
  // VehicleDetails
  quote: {},
  garage: {},
  unlistedVehicle: {
    isUnlistedVehicle: false,
    isUnlistedNew: false,
    unlistedVehicleMakes: [],
    unlistedVehicleModels: [],
    unlistedVehicleTrims: [],
    vehicleFormData: {
      make: null,
      model: null,
      trim: null,
      year: new Date().getFullYear(),
      odometer: 0,
      sellingPrice: 0
    }
  }
};
const initializeStore = (state, payload) => {
  const {
    queryParams
  } = payload;
  const {
    lang,
    location = null,
    organizationId,
    organizationUnitId,
    leadId,
    accountId = null,
    contactId
  } = queryParams;
  return {
    ...state,
    config: {
      ...state.config,
      lang,
      location,
      organizationId,
      organizationUnitId,
      leadId,
      accountId,
      contactId
    }
  };
};
exports.initializeStore = initializeStore;
const setLoadingSuccess = state => ({
  ...state,
  isLoading: false
});
exports.setLoadingSuccess = setLoadingSuccess;
const loadGarageSuccess = (state, _ref) => {
  let {
    garage
  } = _ref;
  return {
    ...state,
    garage
  };
};
exports.loadGarageSuccess = loadGarageSuccess;
const loadQuoteSuccess = (state, _ref2) => {
  let {
    quote
  } = _ref2;
  return {
    ...state,
    quote
  };
};
exports.loadQuoteSuccess = loadQuoteSuccess;
const setDeskingAccess = (state, _ref3) => {
  let {
    hasDeskingAccess
  } = _ref3;
  return {
    ...state,
    hasDeskingAccess
  };
};
exports.setDeskingAccess = setDeskingAccess;
const setCanCreateUnlistedVehicle = (state, _ref4) => {
  let {
    canCreateUnlistedVehicle
  } = _ref4;
  return {
    ...state,
    canCreateUnlistedVehicle
  };
};
exports.setCanCreateUnlistedVehicle = setCanCreateUnlistedVehicle;
const setLocation = (state, _ref5) => {
  let {
    location
  } = _ref5;
  return {
    ...state,
    config: {
      ...(0, _selectors.getConfig)(state),
      location
    }
  };
};
exports.setLocation = setLocation;
const setVisibleCustomer = (state, _ref6) => {
  let {
    isVisibleCustomer
  } = _ref6;
  return {
    ...state,
    quote: {
      ...(0, _selectors.getQuote)(state),
      isVisibleCustomer
    }
  };
};
exports.setVisibleCustomer = setVisibleCustomer;
const setIsUnlistedVehicle = (state, _ref7) => {
  let {
    isUnlistedVehicle
  } = _ref7;
  return {
    ...state,
    unlistedVehicle: {
      ...(0, _selectors.getUnlistedVehicle)(state),
      isUnlistedVehicle
    }
  };
};
exports.setIsUnlistedVehicle = setIsUnlistedVehicle;
const setIsUnlistedNew = (state, _ref8) => {
  let {
    isUnlistedNew
  } = _ref8;
  return {
    ...state,
    unlistedVehicle: {
      ...(0, _selectors.getUnlistedVehicle)(state),
      isUnlistedNew
    }
  };
};
exports.setIsUnlistedNew = setIsUnlistedNew;
const setUnlistedVehicleMakes = (state, _ref9) => {
  let {
    unlistedVehicleMakes
  } = _ref9;
  return {
    ...state,
    unlistedVehicle: {
      ...(0, _selectors.getUnlistedVehicle)(state),
      unlistedVehicleMakes
    }
  };
};
exports.setUnlistedVehicleMakes = setUnlistedVehicleMakes;
const setUnlistedVehicleModels = (state, _ref10) => {
  let {
    unlistedVehicleModels
  } = _ref10;
  return {
    ...state,
    unlistedVehicle: {
      ...(0, _selectors.getUnlistedVehicle)(state),
      unlistedVehicleModels
    }
  };
};
exports.setUnlistedVehicleModels = setUnlistedVehicleModels;
const setUnlistedVehicleTrims = (state, _ref11) => {
  let {
    unlistedVehicleTrims
  } = _ref11;
  return {
    ...state,
    unlistedVehicle: {
      ...(0, _selectors.getUnlistedVehicle)(state),
      unlistedVehicleTrims
    }
  };
};
exports.setUnlistedVehicleTrims = setUnlistedVehicleTrims;
const setUnlistedVehicleFormData = (state, _ref12) => {
  let {
    formField,
    formValue
  } = _ref12;
  const formData = Object.assign((0, _selectors.getUnlistedVehicleFormData)(state), {
    [formField]: formValue
  });
  return {
    ...state,
    unlistedVehicle: {
      ...(0, _selectors.getUnlistedVehicle)(state),
      vehicleFormData: {
        ...formData
      }
    }
  };
};
exports.setUnlistedVehicleFormData = setUnlistedVehicleFormData;
const resetUnlistedVehicleMakes = state => ({
  ...state,
  unlistedVehicle: {
    ...(0, _selectors.getUnlistedVehicle)(state),
    unlistedVehicleModels: [],
    vehicleFormData: {
      ...state.unlistedVehicle.vehicleFormData,
      model: null,
      trim: null
    }
  }
});
exports.resetUnlistedVehicleMakes = resetUnlistedVehicleMakes;
const resetUnlistedVehicleTrims = state => ({
  ...state,
  unlistedVehicle: {
    ...(0, _selectors.getUnlistedVehicle)(state),
    unlistedVehicleTrims: [],
    vehicleFormData: {
      ...state.unlistedVehicle.vehicleFormData,
      trim: null
    }
  }
});
exports.resetUnlistedVehicleTrims = resetUnlistedVehicleTrims;
const resetUnlistedVehicleFormData = state => ({
  ...state,
  unlistedVehicle: {
    ...(0, _selectors.getUnlistedVehicle)(state),
    unlistedVehicleMakes: [],
    unlistedVehicleModels: [],
    unlistedVehicleTrims: [],
    vehicleFormData: {
      make: null,
      model: null,
      trim: null,
      year: new Date().getFullYear(),
      odometer: 0,
      sellingPrice: 0
    }
  }
});
exports.resetUnlistedVehicleFormData = resetUnlistedVehicleFormData;
const setLeadInfo = (state, _ref13) => {
  let {
    leadInfo
  } = _ref13;
  return {
    ...state,
    salesPersonnel: {
      ...leadInfo
    }
  };
};
exports.setLeadInfo = setLeadInfo;
var _default = exports.default = (0, _reduxsauce.createReducer)(INITIAL_STATE, {
  ..._deskingError.errorReducers,
  ..._deskingCommons.priceIncreaseReducers,
  [_actions.Types.INITIALIZE_STORE]: initializeStore,
  [_actions.Types.LOAD_QUOTE_SUCCESS]: loadQuoteSuccess,
  [_actions.Types.LOAD_GARAGE_SUCCESS]: loadGarageSuccess,
  [_actions.Types.SET_LOADING_SUCCESS]: setLoadingSuccess,
  [_actions.Types.SET_DESKING_ACCESS]: setDeskingAccess,
  [_actions.Types.SET_CAN_CREATE_UNLISTED_VEHICLE]: setCanCreateUnlistedVehicle,
  [_actions.Types.SET_LOCATION]: setLocation,
  [_actions.Types.SET_VISIBLE_CUSTOMER]: setVisibleCustomer,
  [_actions.Types.SET_IS_UNLISTED_VEHICLE]: setIsUnlistedVehicle,
  [_actions.Types.SET_IS_UNLISTED_NEW]: setIsUnlistedNew,
  [_actions.Types.SET_UNLISTED_VEHICLE_MAKES]: setUnlistedVehicleMakes,
  [_actions.Types.SET_UNLISTED_VEHICLE_MODELS]: setUnlistedVehicleModels,
  [_actions.Types.SET_UNLISTED_VEHICLE_TRIMS]: setUnlistedVehicleTrims,
  [_actions.Types.SET_UNLISTED_VEHICLE_FORM_DATA]: setUnlistedVehicleFormData,
  [_actions.Types.RESET_UNLISTED_VEHICLE_FORM_DATA]: resetUnlistedVehicleFormData,
  [_actions.Types.RESET_UNLISTED_VEHICLE_MAKES]: resetUnlistedVehicleMakes,
  [_actions.Types.RESET_UNLISTED_VEHICLE_TRIMS]: resetUnlistedVehicleTrims,
  [_actions.Types.SET_LEAD_INFO]: setLeadInfo
});