"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _actions = require("../../actions");
var _selectors = require("../../selectors");
/**
 * @param {String} id
 */
function* _default(id) {
  const queryParams = yield (0, _effects.select)(_selectors.getLoadQuoteQueryParams);
  yield (0, _effects.put)(_actions.Creators.setIsQuoteUpdating());
  try {
    const quote = yield (0, _effects.call)(_commonsApi.QuoteService.getQuoteDetails, id, queryParams);
    yield (0, _effects.put)(_actions.Creators.loadQuoteSuccess(quote));
    return quote;
  } catch (error) {
    yield (0, _effects.put)(_actions.Creators.setIsQuoteUpdatingDone());
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'getQuote'));
    return null;
  }
}