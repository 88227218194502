"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tabItems = require("../../constants/tabItems");
var _tabItems2 = require("../selectors/tabItems");
/**
 * @param {Object} state
 * @param {Object} payload - { name, items }
 * @returns {Object}
 */
var _default = (state, _ref) => {
  let {
    name,
    items
  } = _ref;
  let newTabItem = {};
  switch (name) {
    case _tabItems.TAB_ITEMS.ACCESSORY:
      newTabItem = {
        tabItemsAccessory: {
          ...(0, _tabItems2.getTabAccessory)(state),
          content: items,
          isUpdated: true
        }
      };
      break;
    case _tabItems.TAB_ITEMS.COLOUR:
      newTabItem = {
        tabItemsColour: {
          ...(0, _tabItems2.getTabColour)(state),
          content: items,
          isUpdated: true
        }
      };
      break;
    case _tabItems.TAB_ITEMS.OPTION:
      newTabItem = {
        tabItemsOption: {
          ...(0, _tabItems2.getTabOption)(state),
          content: items,
          isUpdated: true
        }
      };
      break;
    case _tabItems.TAB_ITEMS.REBATE:
      newTabItem = {
        tabItemsRebate: {
          ...(0, _tabItems2.getTabRebate)(state),
          content: items,
          isUpdated: true
        }
      };
      break;
    case _tabItems.TAB_ITEMS.NOTE:
      newTabItem = {
        tabItemsNote: {
          ...(0, _tabItems2.getTabNote)(state),
          content: items,
          isUpdated: true
        }
      };
      break;
    default:
  }
  return {
    ...state,
    ...newTabItem
  };
};
exports.default = _default;