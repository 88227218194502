"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/**
 * @param {Object} state
 * @param {Object} payload - { response }
 * @returns {Object}
 */
var _default = (state, _ref) => {
  let {
    response,
    changeType
  } = _ref;
  const {
    isValid,
    validationMessage
  } = response;
  return {
    ...state,
    paymentAdjustmentValidation: {
      changeType,
      isValid,
      validationMessage
    }
  };
};
exports.default = _default;