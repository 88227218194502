"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useLeadStatuses = void 0;
var _react = require("react");
var _appsCore = require("@sm360/apps-core");
var _LeadService = require("../../api/LeadService");
var _utils = require("../../tools/utils");
var _utils2 = require("./utils");
/**
 * Returns a list of lead statuses (those configured for a dealer group if specified)
 * @param {number} [organizationId]
 * @returns {Object} a Query object
 */
const useLeadStatuses = params => {
  const {
    organizationId,
    select,
    ...rest
  } = params || {};
  const {
    locale
  } = (0, _appsCore.useTranslation)();
  const queryKey = ['leads', 'statuses', {
    organizationId
  }];
  const queryFn = () => (0, _LeadService.getLeadStatuses)(organizationId);
  const defaultSelect = (0, _react.useCallback)(data => organizationId ? (0, _utils2.transformConfiguredStatusesToOptions)(data, locale) : (0, _utils.transformDataToOptions)(data, 'status'), [organizationId, locale]);
  return (0, _appsCore.useQuery)({
    queryKey,
    queryFn,
    select: select || defaultSelect,
    ...rest
  });
};
exports.useLeadStatuses = useLeadStatuses;