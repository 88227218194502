"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useEmail = void 0;
var _appsCore = require("@sm360/apps-core");
var _ActivityService = require("../../api/ActivityService");
/**
 * Returns an email activity
 * @param {number} activityId
 * @returns {Object} a Query object
 */
const useEmail = params => {
  const {
    activityId,
    ...rest
  } = params || {};
  return (0, _appsCore.useQuery)({
    queryKey: ['emails', {
      activityId
    }],
    queryFn: () => (0, _ActivityService.getEmailByActivityId)(activityId),
    enabled: !!activityId,
    ...rest
  });
};
exports.useEmail = useEmail;