"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transformDataToOptions = exports.sanitizeOrganizationUnitIds = exports.compareByLabel = void 0;
var _commonsUtils = require("@sm360/commons-utils");
const sanitizeOrganizationUnitIds = organizationUnitIds => Array.isArray(organizationUnitIds) ? organizationUnitIds.filter(o => Number.isInteger(o)) : [];
exports.sanitizeOrganizationUnitIds = sanitizeOrganizationUnitIds;
const compareByLabel = (a, b) => (0, _commonsUtils.compareObjectByKey)(a, b, 'label');

/**
 * Transform an array into an option list (for Select component)
 * @param {Array} data
 * @param {String} labelKey will default to `idKey`
 * @param {String} idKey will default to 'id'
 * @param {String} valueKey will default to `idKey`
 * @param {Boolean} skipSorting will default to `false`
 * @returns {Array} an array of options that a 'Select' component can understand
 */
exports.compareByLabel = compareByLabel;
const transformDataToOptions = function (data, labelKey, idKey, valueKey) {
  let skipSorting = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  if (!Array.isArray(data)) return [];
  const _idKey = idKey || 'id';
  const options = data.map(d => ({
    id: d?.[_idKey],
    value: (0, _commonsUtils.nullOrString)(d?.[valueKey || _idKey]),
    label: d?.[labelKey || _idKey]
  }));
  return skipSorting ? options : options.sort(compareByLabel);
};
exports.transformDataToOptions = transformDataToOptions;