"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getNoneUser = void 0;
const getNoneUser = t => ({
  label: t('crm-commons.leadsFilter.noLabelAssigned', {
    placeholder: t('crm-commons.leadsFilter.salesRep')
  }),
  id: -1,
  value: -1
});
exports.getNoneUser = getNoneUser;