"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const getNow = () => {
  const date = new Date();
  const d = date.getDate();
  const m = date.getMonth() + 1;
  const day = d < 10 ? '0'.concat(d).toString() : d.toString();
  const month = m < 10 ? '0'.concat(m).toString() : m.toString();
  const year = date.getFullYear().toString();
  const hh24 = date.getHours();
  const mm = date.getMinutes();
  const min = mm < 10 ? '0'.concat(mm).toString() : mm.toString();
  const hour = hh24 < 10 ? '0'.concat(hh24).toString() : hh24.toString();
  return year.concat('/', month, '/', day, ' ', hour, ':', min);
};
var _default = exports.default = getNow;