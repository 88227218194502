"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _reactRedux = require("react-redux");
var _redux = require("redux");
var _actions = require("../../redux/actions");
var _BlendedRateModal = _interopRequireDefault(require("./BlendedRateModal"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const mapDispatchToProps = dispatch => ({
  // eslint-disable-next-line max-len
  updateBlendedRateRoll: (calculationOverAmount, calculationOverAmountApr) => dispatch(_actions.Creators.updateBlendedRateRoll(calculationOverAmount, calculationOverAmountApr))
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(null, mapDispatchToProps))(_BlendedRateModal.default);