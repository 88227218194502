"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MERGEABLE_CONTACT_FIELD_SECTIONS = exports.INTERCHANGEABLE_FIELDS = void 0;
const MERGEABLE_CONTACT_FIELD_SECTIONS = exports.MERGEABLE_CONTACT_FIELD_SECTIONS = [{
  name: 'names',
  iconClass: 'fa-solid fa-user',
  fields: ['lastname', 'firstname', 'businessName'],
  alwaysDisplayGrouped: true
}, {
  name: 'address',
  iconClass: 'fa-solid fa-location-dot',
  fields: ['address', 'address2', 'city', 'province', 'country', 'postalCode'],
  alwaysDisplayGrouped: true
}, {
  name: 'emails',
  iconClass: 'fa-solid fa-envelope',
  fields: ['email1', 'email2'],
  alwaysDisplayGrouped: true
}, {
  name: 'phones',
  iconClass: 'fa-solid fa-phone',
  fields: ['homePhone', 'mobilePhone', 'workPhone', 'workPhoneExtension'],
  alwaysDisplayGrouped: true
}, {
  name: 'other',
  iconClass: 'fa-solid fa-user-pen',
  fields: ['dateOfBirth', 'sexId', 'notes'],
  alwaysDisplayGrouped: false
}];
const INTERCHANGEABLE_FIELDS = exports.INTERCHANGEABLE_FIELDS = [['homePhone', 'mobilePhone', 'workPhone'], ['email1', 'email2']];