"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _actions = require("../actions");
var _getDefaultQuote = _interopRequireDefault(require("./api/getDefaultQuote"));
var _getGarage = _interopRequireDefault(require("./api/getGarage"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function* _default() {
  const garage = yield (0, _effects.call)(_getGarage.default);
  yield (0, _effects.put)(_actions.Creators.loadGarageSuccess(garage));
  if (garage) {
    const defaultQuote = yield (0, _effects.call)(_getDefaultQuote.default, garage);
    yield (0, _effects.put)(_actions.Creators.loadQuoteSuccess(defaultQuote));
  }
}