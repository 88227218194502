"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _loadPaymentOptions = _interopRequireDefault(require("./loadPaymentOptions"));
var _reloadCurrentStep = _interopRequireDefault(require("./reloadCurrentStep"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function* _default() {
  yield (0, _effects.call)(_reloadCurrentStep.default);
  yield (0, _effects.call)(_loadPaymentOptions.default);
}