"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _actions = require("../../actions");
var _tabProtection = require("../../selectors/tabProtection");
var _selectors = require("../../selectors");
var _dealer = require("../../selectors/dealer");
var _updatePayOptAndTransDetails = _interopRequireDefault(require("../api/updatePayOptAndTransDetails"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {String} fniType
 * @param {String} contentType
 * @param {String} persistentId
 * @param {String} planPersistentId
 * @param {Object} payload
 * @returns {Object}
 */
function _default(_ref) {
  let {
    fniType,
    contentType,
    persistentId,
    planPersistentId,
    payload
  } = _ref;
  return function* () {
    yield (0, _effects.put)(_actions.Creators.setIsFetchingFNIItems());
    const validationItem = yield (0, _effects.select)(_tabProtection.getValidationFNIItemPlan, fniType, contentType, persistentId, planPersistentId, payload);
    const quoteId = yield (0, _effects.select)(_selectors.getQuoteId);
    const queryParams = yield (0, _effects.select)(_selectors.getShowroomQueryParams);
    const quote = yield (0, _effects.select)(_selectors.getQuote);
    const paymentOptions = yield (0, _effects.select)(_selectors.getPaymentOptions);
    const saleType = yield (0, _effects.select)(_dealer.getSaleType);
    const body = {
      item: {
        dataTypeId: validationItem.dataTypeId,
        typeId: validationItem.typeId,
        supplier: validationItem.supplier,
        code: validationItem.code,
        isDueAtDelivery: validationItem.plan.pricing.isDueAtDelivery,
        isOwnedByBusinessManager: validationItem.plan.pricing.isOwnedByBusinessManager,
        isOutsideTransaction: validationItem.plan.pricing.isOutsideTransaction,
        amount: validationItem.plan.pricing.amount,
        amountAtDelivery: validationItem.plan.pricing.amountAtDelivery,
        amountOutsideTransaction: validationItem.plan.pricing.amountOutsideTransaction,
        cost: validationItem.plan.pricing.cost,
        profit: validationItem.plan.pricing.profit,
        description: validationItem.plan.description,
        displayCode: validationItem.plan.displayCode
      },
      transactionToken: paymentOptions.transactionToken,
      saleType,
      taxPlan: quote.taxPlan,
      vehicleCondition: quote.vehicleCondition,
      purchaseMethod: paymentOptions.purchaseMethod,
      financePlan: paymentOptions.financePlan,
      kmPerYearPlan: paymentOptions.kmPerYearPlan,
      paymentFrequency: paymentOptions.paymentFrequency,
      term: paymentOptions.term,
      cashDown: paymentOptions.cashDown,
      tradeIn: paymentOptions.tradeIn,
      lien: paymentOptions.lien
    };
    try {
      const response = yield (0, _effects.call)(_commonsApi.ShowroomService.desking.validateTransactionAmount, quoteId, queryParams, body);
      yield (0, _effects.call)(_updatePayOptAndTransDetails.default, {
        ...paymentOptions,
        transactionToken: response.negociationTransaction.transactionToken,
        callCommission: true
      });
      yield (0, _effects.put)(_actions.Creators.updateFNIItemPlanSuccess(fniType, contentType, persistentId, planPersistentId, validationItem));
      yield (0, _effects.put)(_actions.Creators.setIsFetchingFNIItemsDone());
    } catch (error) {
      yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'updateFNIItemPlanPricing'));
      yield (0, _effects.put)(_actions.Creators.setIsFetchingFNIItemsDone());
    }
  }();
}