"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _deskingCommons = require("@sm360/desking-commons");
var _selectors = require("../selectors");
function _default(_ref) {
  let {
    category
  } = _ref;
  return function* () {
    const navigate = yield (0, _effects.getContext)('navigate');
    const hasDeskingAccess = yield (0, _effects.select)(_selectors.getDeskingAccess);
    const vehiclesWidgetParams = yield (0, _effects.select)(_selectors.getVehiclesWidgetParams);
    if (!hasDeskingAccess) {
      return;
    }
    const priceIncreaseRollCount = yield (0, _effects.select)(_deskingCommons.priceIncreaseSelectors.getPriceIncreaseRollCount);
    navigate((0, _deskingCommons.getVehiclesPath)({
      ...vehiclesWidgetParams,
      category,
      upg: priceIncreaseRollCount
    }));
  }();
}