"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.USER_ROLES = exports.USER_RIGHTS = exports.SALES_PERSONNEL = void 0;
const USER_RIGHTS = exports.USER_RIGHTS = {
  // Right to know if the user has access to Desking
  DESKING_ACCESS: 'DeskingAccess',
  // Right to let the user see the prices in desking builder
  BUILDER_PRICES_READ: 'BuilderPricesRead',
  // Right to know if the user have access to see the cost and Profit
  COST_PROFIT_ACCESS: 'CostAndProfitAccess',
  // Right to edit rebates
  EDIT_REBATES: 'EditRebates',
  // Right to edit Insurance
  EDIT_INSURANCE: 'EditInsurance',
  // Right to edit Protections
  EDIT_PROTECTIONS: 'EditProtections',
  // Right to edit Warranties
  EDIT_WARRANTIES: 'EditWarranties',
  // Right to edit the interest rate
  EDIT_INTEREST_RATE: 'DeskingEditInterestRate',
  // Right to let the user edit the lease calculations
  EDIT_LEASE_CALCULATIONS: 'DeskingEditLeaseCalculations',
  // Right to display all options in the dropdown (Sale Type) not just the 'Retail' option
  MANAGE_NON_RETAIL_TRANSACTION: 'DeskingManageNonRetailTransaction',
  // Right to let the user be able to select a province other than the province of the dealer
  SELECT_DIFFERENT_PROVINCE: 'DeskingAllowSelectDifferentProvince',
  // Right to display all options in the dropdown (Tax plan) not just the 'Standard' option
  SELECT_ANY_AV_TAX_PLAN: 'DeskingAllowSelectAnyAvailableTaxPlan',
  // Right to allow the user to lower the selling price
  ALLOW_TO_LOWER_SELLING_PRICE: 'DeskingAllowToLowerSellingPrice',
  // Right to access Credit-Application App
  MANAGE_CREDIT_APPLICATIONS: 'DeskingManageCreditApplications',
  // Right to display pad value
  DISPLAY_PAD_VALUE: 'DeskingDisplayPadValue',
  // Right to display the profit code
  DISPLAY_PROFIT_CODE: 'DeskingDisplayProfitCode',
  // Right to move item outside transaction
  CAN_MOVE_ITEM_OUTSIDE_TRANSACTION: 'DeskingMoveItemOutsideTransaction',
  CAN_CHANGE_SOLD_AND_DELIVERED_QUOTE_STATUS: 'DeskingCanChangeSoldAndDeliveredQuoteStatus',
  CAN_ACCESS_ACCOUNTING: 'DeskingCanAccessAccounting'
};
const USER_ROLES = exports.USER_ROLES = {
  SALES_PERSON: 'CRMUser',
  SALES_MANAGER: 'SalesManager',
  BUSINESS_MANAGER: 'BusinessManager',
  VEHICLE_APPRAISER: 'VehicleAppraiser',
  ADMIN: 'Admin'
};
const SALES_PERSONNEL = exports.SALES_PERSONNEL = {
  SALES_PERSON: 'salesperson',
  SALES_MANAGER: 'salesManager',
  BUSINESS_MANAGER: 'businessManager'
};