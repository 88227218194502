"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFullNameFromEntry = void 0;
const getFullNameFromEntry = entry => {
  const contactFirstname = entry?.firstname;
  const contactLastname = entry?.lastname;
  return entry?.business ? entry.businessName : `${contactFirstname || ''}  ${contactLastname || ''}`.trim();
};
exports.getFullNameFromEntry = getFullNameFromEntry;