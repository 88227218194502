"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _actions = require("../../redux/actions");
var _selectors = require("../../redux/selectors");
var _MatchCards = _interopRequireDefault(require("./MatchCards"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} state
 * @returns {Object}
 */
const mapStateToProps = state => {
  const hasTaxesIncluded = (0, _selectors.getHasTaxesIncluded)(state);
  return {
    hasTaxesIncluded
  };
};
const mapDispatchToProps = dispatch => ({
  openInDesking: vehicle => dispatch(_actions.Creators.openInDesking(vehicle))
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps))(_MatchCards.default);