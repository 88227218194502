"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _selectors = require("../selectors");
var _refreshQuote = _interopRequireDefault(require("./refreshQuote"));
var _helpers = require("./api/helpers");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} params
 */
function* updateQuoteInformation() {
  const quoteId = yield (0, _effects.select)(_selectors.getQuoteId);
  const showroomQueryParams = yield (0, _effects.select)(_selectors.getShowroomQueryParams);
  const quote = yield (0, _effects.select)(_selectors.getQuote);
  const {
    cashPurchaseOption,
    financeOptions,
    ...paymentOptions
  } = yield (0, _effects.select)(_selectors.getPaymentOptions);
  const body = {
    id: quoteId,
    leadId: quote.leadId,
    accountId: quote.accountId,
    quoteRevision: quote.quoteRevision,
    vehicleCondition: quote.vehicle.condition,
    financeOptions: (0, _helpers.transformFinanceOptionsRequest)(financeOptions),
    ...paymentOptions
  };
  try {
    yield (0, _effects.call)(_commonsApi.ShowroomService.desking.updateQuoteInformation, quoteId, showroomQueryParams, body);
    yield (0, _effects.call)(_refreshQuote.default);
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'updateQuote'));
  }
}
var _default = exports.default = updateQuoteInformation;