"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _steps = require("../../constants/steps");
var _actions = require("../actions");
var _selectors = require("../selectors");
var _content = require("../selectors/content");
var _loadStepYear = _interopRequireDefault(require("./steps/loadStepYear"));
var _loadStepOptionItems = _interopRequireDefault(require("./steps/loadStepOptionItems"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} payload - { step }
 */
function _default(_ref) {
  let {
    step
  } = _ref;
  return function* () {
    let hasSentExitConfirmation;
    const {
      currentStep,
      lastVisitedStep
    } = yield (0, _effects.select)(_selectors.getNav);
    const configurations = yield (0, _effects.select)(_content.getConfigurations);
    let moveValue = 0;
    if (currentStep === _steps.STEP_COMFORT && configurations.length < 2) {
      if (lastVisitedStep > currentStep) {
        moveValue = -1;
      } else {
        moveValue = 1;
      }
    }
    switch (step) {
      case _steps.STEP_YEAR:
        yield (0, _effects.call)(_loadStepYear.default);
        break;
      case _steps.STEP_TRIM:
        ({
          hasSentExitConfirmation
        } = yield (0, _effects.select)(_selectors.getNav));
        if (!hasSentExitConfirmation) {
          yield (0, _effects.put)(_actions.Creators.sendExitConfirmation());
        }
        break;
      case _steps.STEP_COMFORT:
        if (moveValue !== 0) {
          yield (0, _effects.put)(_actions.Creators.setCurrentStep(currentStep + moveValue, currentStep));
        }
        break;
      case _steps.STEP_OPTIONS:
        yield (0, _effects.call)(_loadStepOptionItems.default);
        break;

      // Do nothing for other steps
      default:
    }
  }();
}