"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/**
 * @param {Object} state
 * @param {Object} payload - { vehicle }
 * @returns {Object}
 */
var _default = (state, _ref) => {
  let {
    selectedVehicle
  } = _ref;
  return {
    ...state,
    matches: {
      perfectList: state.matches.perfectList,
      similarList: state.matches.similarList,
      selectedVehicle
    }
  };
};
exports.default = _default;