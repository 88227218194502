"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _actions = require("../../../redux/actions");
var _selectors = require("../../../redux/selectors");
var _content = require("../../../redux/selectors/content");
var _stepOptionItems = require("../../../redux/selectors/stepOptionItems");
var _paymentOptions = require("../../../redux/selectors/paymentOptions");
var _stepTrim = require("../../../redux/selectors/stepTrim");
var _StepOptions = _interopRequireDefault(require("./StepOptions"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} state
 * @returns {Object}
 */
const mapStateToProps = state => {
  const modelName = (0, _content.getModelName)(state);
  const {
    paymentFrequency
  } = (0, _selectors.getPurchaseMethod)(state);
  return {
    hasTaxesIncluded: (0, _selectors.getHasTaxesIncluded)(state),
    isCashSelected: (0, _paymentOptions.getIsCashSelected)(state),
    options: (0, _stepOptionItems.getItems)(state),
    modelName,
    modelTrim: (0, _stepTrim.getModelTrim)(state),
    paymentFrequency
  };
};
const mapDispatchToProps = dispatch => ({
  addDeleteStepOptionItems: itemData => dispatch(_actions.Creators.addDeleteStepOptionItems(itemData)),
  selectOptionItem: selected => dispatch(_actions.Creators.selectOptionItem(selected))
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps))(_StepOptions.default);