"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.STEP_YEAR = exports.STEP_TRIM = exports.STEP_SUMMARY = exports.STEP_PERFORMANCE = exports.STEP_OPTIONS = exports.STEP_DEFAULT = exports.STEP_COMFORT = exports.STEPS = exports.FINAL_STEP = void 0;
const STEP_YEAR = exports.STEP_YEAR = 0;
const STEP_TRIM = exports.STEP_TRIM = 1;
const STEP_PERFORMANCE = exports.STEP_PERFORMANCE = 2;
const STEP_COMFORT = exports.STEP_COMFORT = 3;
const STEP_OPTIONS = exports.STEP_OPTIONS = 4;
const STEP_SUMMARY = exports.STEP_SUMMARY = 5;
const STEP_DEFAULT = exports.STEP_DEFAULT = STEP_YEAR;
const FINAL_STEP = exports.FINAL_STEP = 1;
const STEPS = exports.STEPS = [STEP_YEAR, STEP_TRIM, STEP_PERFORMANCE, STEP_COMFORT, STEP_OPTIONS, STEP_SUMMARY];