"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transformSubscriptionContextsToOptions = void 0;
const transformSubscriptionContextsToOptions = function () {
  let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  let lang = arguments.length > 1 ? arguments[1] : undefined;
  return data.map(d => ({
    id: d.slug,
    label: d.names[lang] || d.slug,
    value: d.slug
  }));
};
exports.transformSubscriptionContextsToOptions = transformSubscriptionContextsToOptions;