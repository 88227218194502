"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.DOCUMENT_TYPE = void 0;
const DOCUMENT_TYPE = exports.DOCUMENT_TYPE = [{
  translationKey: 'FINANCING.DOCUMENT_AND_CONSENT_INFOS.DOCUMENT_TYPE.NATIONAL_ID',
  value: 'NationalId'
},
// {
//     translationKey: 'FINANCING.DOCUMENT_AND_CONSENT_INFOS.DOCUMENT_TYPE.PREVIOUS_ACCOUNT_NUMBER',
//     value: 'PreviousAccountnumber'
// },
{
  translationKey: 'FINANCING.DOCUMENT_AND_CONSENT_INFOS.DOCUMENT_TYPE.DRIVERS_LICENSE',
  value: 'DriversLicense'
}, {
  translationKey: 'FINANCING.DOCUMENT_AND_CONSENT_INFOS.DOCUMENT_TYPE.BIRTH_CERTIFICATE',
  value: 'BirthCertificate'
}, {
  translationKey: 'FINANCING.DOCUMENT_AND_CONSENT_INFOS.DOCUMENT_TYPE.PASSPORT',
  value: 'Passport'
}, {
  translationKey: 'FINANCING.DOCUMENT_AND_CONSENT_INFOS.DOCUMENT_TYPE.INDIAN_STATUS',
  value: 'CertificateOfIndianStatus'
}];
var _default = exports.default = {
  DOCUMENT_TYPE
};