"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _selectors = require("../selectors");
var _vehicle = require("../selectors/vehicle");
var _tabItems = require("../selectors/tabItems");
var _tabProtection = require("../selectors/tabProtection");
var _resetTabItems = _interopRequireDefault(require("./common/resetTabItems"));
var _resetTabProtection = _interopRequireDefault(require("./common/resetTabProtection"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} state
 * @param {Object} newQuote
 * @returns
 */
const prepareTabItems = (state, newQuote) => {
  const isCatalog = (0, _vehicle.getIsCatalog)({
    quote: newQuote
  });
  const tabItems = {
    ...(0, _tabItems.getTabItems)(state)
  };

  // Re-arrange the tab items if not catalog
  if (!isCatalog) {
    tabItems.selectedIndex = tabItems.selectedIndex !== 0 ? tabItems.selectedIndex : 1;
    tabItems.isTabColourDisabled = true;
  }
  return {
    ...(0, _resetTabItems.default)(state),
    tabItems
  };
};

/**
 * @param {Object} state
 * @param {Object} newQuote
 * @returns
 */
const prepareTabProtection = (state, newQuote) => {
  const isCatalog = (0, _vehicle.getIsCatalog)({
    quote: newQuote
  });
  const tabProtection = {
    ...(0, _tabProtection.getTabProtection)(state)
  };

  // Re-arrange the tab protection if not catalog
  if (!isCatalog) {
    tabProtection.selectedIndex = tabProtection.selectedIndex !== 0 ? tabProtection.selectedIndex : 1;
  }
  return {
    ...(0, _resetTabProtection.default)(state),
    tabProtection
  };
};

/**
 * @param {Object} state
 * @param {Object} payload - quote
 * @returns {Object} new state
 */
var _default = (state, _ref) => {
  let {
    quote
  } = _ref;
  const {
    cashDown,
    cashPurchaseOption,
    financeOptions,
    financePlan,
    kmPerYearPlan,
    lien,
    odometer,
    paymentFrequency,
    purchaseMethod,
    saleType,
    taxPlan,
    term,
    tradeIn,
    transactionToken,
    vehicleCondition,
    location,
    negativeEquityOffsetType,
    positiveEquityOffsetType,
    showNegativeEquityOffset,
    showPositiveEquityOffset,
    disclaimer,
    __quoteMeta: meta
  } = quote;

  // Sort DESC the terms in financePlan definitions
  const financePlans = meta.financePlans.map(plan => ({
    ...plan,
    term: plan.term.sort().reverse()
  }));
  const newQuote = {
    ...(0, _selectors.getQuote)(state),
    ...quote
  };
  const tabItemsData = prepareTabItems(state, newQuote);
  const tabProtectionData = prepareTabProtection(state, newQuote);
  return {
    ...state,
    ...tabItemsData,
    ...tabProtectionData,
    isQuoteChanged: false,
    isFinancingDatesChanged: false,
    quote: newQuote,
    meta: {
      ...state.meta,
      ...meta,
      financePlans
    },
    config: {
      ...(0, _selectors.getConfig)(state),
      location
    },
    paymentOptions: {
      ...(0, _selectors.getPaymentOptions)(state),
      cashDown,
      cashPurchaseOption,
      financeOptions,
      financePlan,
      kmPerYearPlan,
      lien,
      odometer,
      paymentFrequency,
      purchaseMethod,
      saleType,
      taxPlan,
      term,
      tradeIn,
      transactionToken,
      vehicleCondition,
      negativeEquityOffsetType,
      positiveEquityOffsetType,
      showNegativeEquityOffset,
      showPositiveEquityOffset,
      disclaimer
    }
  };
};
exports.default = _default;