"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/**
 * @param {Object} state - global state
 * @param {Object} payload - type, hasTaxesIncluded
 * @returns {Object} new state
 */
var _default = (state, _ref) => {
  let {
    hasTaxesIncluded
  } = _ref;
  return {
    ...state,
    hasTaxesIncluded: hasTaxesIncluded === true
  };
};
exports.default = _default;