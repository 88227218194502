"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _payment = require("../../../constants/payment");
var _selectors = require("../../selectors");
var _paymentOptions = require("../../selectors/paymentOptions");
/**
 * When changing a method, we have to update the root parameters,
 * from the parameters of the financeOption item, of the selected purchaseMethod
 *
 * @param {Object} state
 * @param {Object} payload - { purchaseMethod }
 * @returns {Object}
 */
var _default = (state, _ref) => {
  let {
    purchaseMethod
  } = _ref;
  // When cash, pretty simple, we don't need to update/reset other parameters in relation with finance/lease
  if (purchaseMethod === _payment.PURCHASE_METHOD_CASH) {
    const purchaseMethodProps = (0, _selectors.getPurchaseMethod)(state);
    return {
      ...state,
      isPaymentUpdating: true,
      purchaseMethod: {
        ...purchaseMethodProps,
        purchaseMethod
      }
    };
  }
  const plan = (0, _paymentOptions.getFinanceOptionsPlan)(state, purchaseMethod);

  // Safety check, it should never happen, we can't let the user choose a purchaseMethod which is not in financeOptions
  if (!(0, _commonsUtils.isPlainObject)(plan)) {
    throw new TypeError(`The selected purchase method is not defined: ${purchaseMethod}`);
  }
  const {
    kmPerYearPlan,
    paymentFrequency,
    selectedCashDown: cashDown,
    selectedTerm: term
  } = plan;
  return {
    ...state,
    isPaymentUpdating: true,
    purchaseMethod: {
      cashDown,
      kmPerYearPlan,
      paymentFrequency,
      purchaseMethod,
      term
    }
  };
};
exports.default = _default;