"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UiSchema = exports.Schema = void 0;
const taskPrefix = 'actions.task.form';
const placeholderPrefix = 'actions.placeholders';
const sharedPrefix = 'actions.shared';
const Schema = exports.Schema = {
  type: 'object',
  properties: {
    startDate: {
      title: `${taskPrefix}.START_DATE`,
      type: 'string',
      showTimeInput: true,
      ioFormat: 'Timestamp',
      shouldCloseOnSelect: false,
      timeInputLabel: `${taskPrefix}.TIME`,
      datePickerStartYear: 2000,
      extraSelectableYears: 10
    },
    dueDate: {
      title: `${taskPrefix}.DUE_DATE`,
      type: 'string',
      showTimeInput: true,
      ioFormat: 'Timestamp',
      shouldCloseOnSelect: false,
      timeInputLabel: `${taskPrefix}.TIME`,
      datePickerStartYear: 2000,
      extraSelectableYears: 10
    },
    assignedUserId: {
      title: `${taskPrefix}.ASSIGNED_EMPLOYEE`,
      type: 'string',
      enum: [],
      placeholder: `${placeholderPrefix}.SELECT_EMPLOYEE`
    },
    taskType: {
      title: '',
      type: 'object',
      properties: {
        id: {
          title: `${taskPrefix}.TASK_TYPE`,
          type: 'string',
          enum: [],
          enumNames: []
        }
      },
      required: ['id']
    },
    activityStatusId: {
      title: `${sharedPrefix}.activityStatuses.TITLE`,
      type: 'string',
      default: 1,
      enum: [1, 3, 4, 5, 6, 7, 8],
      enumNames: [`${sharedPrefix}.activityStatuses.OPTIONS.ACTIVE`, `${sharedPrefix}.activityStatuses.OPTIONS.CANCELED`, `${sharedPrefix}.activityStatuses.OPTIONS.COMPLETED`, `${sharedPrefix}.activityStatuses.OPTIONS.NA`, `${sharedPrefix}.activityStatuses.OPTIONS.DELETED`, `${sharedPrefix}.activityStatuses.OPTIONS.INVALID`, `${sharedPrefix}.activityStatuses.OPTIONS.DUPLICATE`]
    },
    priority: {
      title: '',
      type: 'object',
      properties: {
        id: {
          title: `${taskPrefix}.PRIORITY.TITLE`,
          type: 'string',
          default: 2,
          enum: [1, 2],
          enumNames: [`${taskPrefix}.PRIORITY.OPTIONS.HIGH_PRIORITY`, `${taskPrefix}.PRIORITY.OPTIONS.NORMAL_PRIORITY`]
        }
      }
    },
    subject: {
      title: `${sharedPrefix}.SUBJECT`,
      type: 'string',
      name: 'task-subject',
      autocomplete: true
    },
    details: {
      title: `${taskPrefix}.DETAILS`,
      type: 'string'
    }
  },
  required: ['details', 'subject', 'startDate', 'dueDate', 'assignedUserId']
};
const UiSchema = exports.UiSchema = {
  startDate: {
    'ui:widget': 'dateWidget'
  },
  dueDate: {
    'ui:widget': 'dateWidget'
  },
  details: {
    'ui:widget': 'textareaWidget'
  },
  assignedUserId: {},
  taskType: {},
  activityStatusId: {},
  priority: {},
  subject: {}
};