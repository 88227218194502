"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getQueryParams = exports.getCatalogBody = exports.getBody = void 0;
var _deskingCommons = require("@sm360/desking-commons");
var _selectors = require("../selectors");
var _filters = require("./filters");
var _paymentOptions = require("./paymentOptions");
/**
 * @param {Object} state
 * @returns {Object}
 */
const getQueryParams = state => {
  const {
    location,
    organizationId,
    organizationUnitId
  } = (0, _selectors.getConfig)(state);
  return {
    includeMetadata: true,
    location,
    organizationId,
    organizationUnitId
  };
};
exports.getQueryParams = getQueryParams;
const getPaymentOptionRequest = state => {
  const {
    cashDown,
    kmPerYearPlan,
    lien,
    paymentFrequency,
    purchaseMethod,
    term,
    tradeIn
  } = (0, _paymentOptions.getPaymentOptions)(state);
  return {
    cashDown,
    kmPerYearPlan,
    lien,
    paymentFrequency,
    priceIncreaseRollCount: _deskingCommons.priceIncreaseSelectors.getPriceIncreaseRollCount(state),
    purchaseMethod,
    term,
    tradeIn
  };
};

/**
 * @param {Object} state
 * @returns {Object}
 */
const getBody = state => {
  return {
    ...getPaymentOptionRequest(state)
  };
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getBody = getBody;
const getCatalogBody = state => {
  return {
    vehicle: {
      featuredOnly: false,
      textSearch: (0, _filters.getSearchText)(state)
    },
    paymentOptionRequest: getPaymentOptionRequest(state)
  };
};
exports.getCatalogBody = getCatalogBody;