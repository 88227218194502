"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _reactRedux = require("react-redux");
var _actions = require("../../../../redux/actions");
var _paymentOptions = require("../../../../redux/selectors/paymentOptions");
var _InputLien = _interopRequireDefault(require("./InputLien"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} state - Global state
 * @returns {Object} injected props
 */
const mapStateToProps = state => {
  const {
    lien
  } = (0, _paymentOptions.getPaymentBarData)(state);
  return {
    value: lien
  };
};

/**
 * @param {Function} dispatch
 * @returns {Object} injected props
 */
const mapDispatchToProps = dispatch => ({
  updateLien: lien => dispatch(_actions.Creators.updateLien(parseFloat(lien)))
});
var _default = exports.default = (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_InputLien.default);