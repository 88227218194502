"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.estimation = exports.default = exports.conditionForm = void 0;
var _propTypes = _interopRequireDefault(require("prop-types"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const estimation = exports.estimation = _propTypes.default.shape({
  amount: _propTypes.default.number,
  high: _propTypes.default.number,
  low: _propTypes.default.number
});
const conditionForm = exports.conditionForm = _propTypes.default.shape({
  originalOwner: _propTypes.default.bool,
  vehiclePurchase: _propTypes.default.oneOf(['private', 'dealer']),
  vehicleCondition: _propTypes.default.oneOf(['perfect', 'ok', 'rough']),
  corporateUse: _propTypes.default.bool,
  corporateUseExplanation: _propTypes.default.string,
  rustProof: _propTypes.default.bool,
  mechanicalProtection: _propTypes.default.bool,
  coverageBalance: _propTypes.default.string,
  hadAnAccident: _propTypes.default.oneOf(['none', 'lessThan3000', '3000AndMore']),
  needReparationOtherExplanation: _propTypes.default.string,
  clarifyDamage: _propTypes.default.string,
  airBagsOperational: _propTypes.default.bool,
  modifiedParts: _propTypes.default.bool,
  partsToReplace: _propTypes.default.arrayOf(_propTypes.default.string),
  replacedTires: _propTypes.default.bool,
  replacedBrakes: _propTypes.default.bool,
  anyScratches: _propTypes.default.bool,
  damageExceeding3000: _propTypes.default.bool,
  majorExteriorInteriorDamage: _propTypes.default.bool,
  clarifyAccident: _propTypes.default.string,
  bodyPartReplaced: _propTypes.default.bool,
  absOperational: _propTypes.default.bool
});
var _default = exports.default = _propTypes.default.shape({
  _id: _propTypes.default.string,
  leadId: _propTypes.default.number,
  make: _propTypes.default.string,
  model: _propTypes.default.string,
  trim: _propTypes.default.string,
  year: _propTypes.default.number,
  bodyStyle: _propTypes.default.string,
  exteriorColor: _propTypes.default.string,
  interiorColor: _propTypes.default.string,
  vin: _propTypes.default.string,
  kilometers: _propTypes.default.oneOfType([_propTypes.default.number, _propTypes.default.string]),
  details: _propTypes.default.string,
  dueYear: _propTypes.default.number,
  dueMonth: _propTypes.default.number,
  payment: _propTypes.default.number,
  balance: _propTypes.default.number,
  lienHolderName: _propTypes.default.string,
  vehicleCondition: _propTypes.default.oneOf(['excellent', 'veryGood', 'good', 'ok']),
  exchangeType: _propTypes.default.oneOf(['exchange', 'leaseTransfer', 'unknown']),
  accidentStatus: _propTypes.default.oneOf(['never', 'minorAccident', 'major']),
  paymentType: _propTypes.default.oneOf(['paid', 'loan', 'lease', 'unknown']),
  estimation,
  conditionForm
});