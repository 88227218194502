"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Types = exports.Creators = void 0;
var _reduxsauce = require("reduxsauce");
const {
  Types,
  Creators
} = (0, _reduxsauce.createActions)({
  loadQuoteSuccess: ['quote'],
  loadGarageSuccess: ['garage'],
  setLoadingSuccess: [],
  setDeskingAccess: ['hasDeskingAccess'],
  setCanCreateUnlistedVehicle: ['canCreateUnlistedVehicle'],
  setLocation: ['location'],
  getLeadInfo: [],
  setLeadInfo: ['leadInfo'],
  setVisibleCustomer: ['isVisibleCustomer'],
  setIsUnlistedVehicle: ['isUnlistedVehicle'],
  setIsUnlistedNew: ['isUnlistedNew'],
  setUnlistedVehicleMakes: ['unlistedVehicleMakes'],
  setUnlistedVehicleModels: ['unlistedVehicleModels'],
  setUnlistedVehicleTrims: ['unlistedVehicleTrims'],
  setUnlistedVehicleFormData: ['formField', 'formValue'],
  resetUnlistedVehicleFormData: [],
  resetUnlistedVehicleMakes: [],
  resetUnlistedVehicleTrims: [],
  // Sagas
  initializeStore: ['queryParams'],
  selectQuote: ['id'],
  setPreferredQuote: ['quote'],
  setCustomerVisibility: ['isVisible'],
  assignLead: ['personnelType'],
  openVehicles: ['category'],
  getGarageAndQuote: [],
  getUnlistedVehicleMakes: ['vehicleCondition'],
  getUnlistedVehicleModels: ['vehicleCondition', 'makeSlug'],
  getUnlistedVehicleTrims: ['vehicleCondition', 'makeSlug', 'modelSlug'],
  openUnlistedInDesking: []
});
exports.Creators = Creators;
exports.Types = Types;