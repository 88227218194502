"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _content = require("../selectors/content");
var _stepOptionItems = require("../selectors/stepOptionItems");
var _default = (state, _ref) => {
  let {
    response
  } = _ref;
  let items = (0, _stepOptionItems.getItems)(state);

  // Don't replace response if it's the same, avoiding to rerender components depending on it
  if (!(0, _commonsUtils.isEqual)(items, response)) {
    items = response;
  }
  return {
    ...state,
    isPaymentUpdating: false,
    content: {
      ...(0, _content.getContent)(state),
      items
    }
  };
};
exports.default = _default;