"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _actions = require("../actions");
var _selectors = require("../selectors");
function* _default() {
  const quoteId = yield (0, _effects.select)(_selectors.getQuoteId);
  const queryParams = yield (0, _effects.select)(_selectors.getShowroomQueryParams);
  yield (0, _effects.put)(_actions.Creators.setIsTransactionListLoading());
  try {
    const response = yield (0, _effects.call)(_commonsApi.ShowroomService.desking.getTransactionPdfList, quoteId, queryParams);
    yield (0, _effects.put)(_actions.Creators.setTransactionPrintList(response.forms));

    // return response;
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'getPdfFilesList'));
    yield (0, _effects.put)(_actions.Creators.setIsTransactionListLoadingDone());
  }
  yield (0, _effects.put)(_actions.Creators.setIsTransactionListLoadingDone());
}