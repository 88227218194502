"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPathParams = exports.getBody = void 0;
var _selectors = require("../selectors");
var _requestItems = require("./requestItems");
/**
 * @param {Object} state
 * @param {Object} item
 * @returns {Object}
 */
const getBody = (state, item) => {
  const {
    configurationId,
    modelTrimCode,
    transactionToken
  } = (0, _selectors.getBaseProps)(state);
  const baseBody = (0, _selectors.getBaseBodyParams)(state);
  return {
    configurationId,
    modelTrimCode,
    transactionToken,
    ...baseBody,
    item
  };
};

/**
 * Same than request items
 *
 * @param {Object} state
 * @returns {Array}
 */
exports.getBody = getBody;
const getPathParams = state => (0, _requestItems.getPathParams)(state);
exports.getPathParams = getPathParams;