"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
var _uiLibrary = require("@sm360/ui-library");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {String} context
 * @param {String} key
 * @param {String|Number} defaultValue
 * @param {boolean} isNumber
 * @param {boolean} isRange
 * @param {boolean} isBoolean
 */
const useParamsOrLocalStorage = (context, key, defaultValue, isNumber, isRange, isBoolean) => {
  const urlParams = new URLSearchParams(window.location.search);
  const hasParams = urlParams.toString().length > 0;
  const [userData, setUserData] = (0, _uiLibrary.useUserData)(context, key, defaultValue);
  const [value, setValue] = _react.default.useState(() => {
    const urlParam = new URLSearchParams(window.location.search).get(key);
    if (urlParam) {
      let params = urlParam.split(',');
      if (isNumber) params = params.map(id => Number(id));
      if (isRange) {
        if (params.length === 1) params = {
          rangeId: params[0]
        };
        if (params.length === 2) params = {
          min: Number(params[0]),
          max: Number(params[1])
        };
      }
      if (isBoolean) params = !!params[0];
      return params;
    }
    if (hasParams) return defaultValue;
    return userData;
  });
  const setKey = newValue => {
    if (!urlParams.get(key)) setUserData(newValue);
    setValue(newValue);
  };
  return [value, setKey];
};
var _default = exports.default = useParamsOrLocalStorage;