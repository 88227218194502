"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useDealTypes = void 0;
var _react = require("react");
var _appsCore = require("@sm360/apps-core");
var _DealService = require("../../api/DealService");
var _utils = require("../../tools/utils");
/**
 * Returns a list of deal types
 * @returns {Object} a Query object
 */

const useDealTypes = params => {
  const {
    select,
    ...rest
  } = params || {};
  const {
    Locale
  } = (0, _appsCore.useTranslation)();
  const defaultSelect = (0, _react.useCallback)(data => (0, _utils.transformDataToOptions)(data, `name${Locale}`, 'slug'), [Locale]);
  return (0, _appsCore.useQuery)({
    queryKey: ['deals', 'types'],
    queryFn: _DealService.getDealTypes,
    select: select || defaultSelect,
    ...rest
  });
};
exports.useDealTypes = useDealTypes;