"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useDealFinancialInstitutions = void 0;
var _appsCore = require("@sm360/apps-core");
var _DealService = require("../../api/DealService");
var _utils = require("../../tools/utils");
const transformBanksToOptions = data => data.map(d => ({
  id: d,
  value: d,
  label: d
}));

/**
 * Returns a list of deal financial institutions (banks)
 * @param {number} organizationId
 * @param {Array.<number>} organizationUnitIds
 * @returns {Object} a Query object
 */

const useDealFinancialInstitutions = params => {
  const {
    organizationId,
    organizationUnitIds,
    select,
    ...rest
  } = params || {};
  const sanitizedOrganizationUnitIds = (0, _utils.sanitizeOrganizationUnitIds)(organizationUnitIds);
  const queryKey = ['deals', 'financialInstitutions', {
    organizationId,
    organizationUnitIds: sanitizedOrganizationUnitIds
  }];
  const queryFn = () => (0, _DealService.getDealFinancialInstitutions)(organizationId, sanitizedOrganizationUnitIds);
  const enabled = organizationId > 0 && sanitizedOrganizationUnitIds.length > 0;
  return (0, _appsCore.useQuery)({
    queryKey,
    queryFn,
    enabled,
    select: select || transformBanksToOptions,
    ...rest
  });
};
exports.useDealFinancialInstitutions = useDealFinancialInstitutions;