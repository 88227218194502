"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useProvinces = void 0;
var _appsCore = require("@sm360/apps-core");
var _DealerInfoService = require("../../api/DealerInfoService");
/**
 * Returns a list of states/provinces per country
 * @returns {Object} a Query object
 */
const useProvinces = params => {
  return (0, _appsCore.useQuery)({
    queryKey: ['provinces'],
    queryFn: _DealerInfoService.getProvinces,
    ...params
  });
};
exports.useProvinces = useProvinces;