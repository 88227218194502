"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _selectors = require("../selectors");
/**
 * @param {Object} state
 * @param {Object} payload - { configurationId, modelTrimCode }
 */
var _default = (state, _ref) => {
  let {
    configurationId,
    modelTrimCode
  } = _ref;
  const baseProps = (0, _selectors.composeNewBaseProps)(state, configurationId, modelTrimCode);
  return {
    ...state,
    baseProps
  };
};
exports.default = _default;