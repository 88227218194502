"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _actions = require("../actions");
var _validateItem = _interopRequireDefault(require("./api/validateItem"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} payload - { itemData }
 */
function _default(_ref) {
  let {
    itemData
  } = _ref;
  return function* () {
    const response = yield (0, _effects.call)(_validateItem.default, itemData);
    if (!response) {
      return;
    }
    const {
      isValid,
      negociationTransaction
    } = response;
    if (isValid) {
      yield (0, _effects.put)(_actions.Creators.validateSuccess(negociationTransaction));
    } else {
      yield (0, _effects.put)(_actions.Creators.validateFailure(response));
    }
  }();
}