"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _actions = require("../../actions");
var _selectors = require("../../selectors");
var _payment = require("../../../constants/payment");
/**
 * @param {Object} payload - Data from action
 */
function* initializeCalculatedAdjustments(payload) {
  const quoteId = yield (0, _effects.select)(_selectors.getQuoteId);
  const queryParams = yield (0, _effects.select)(_selectors.getShowroomQueryParams);
  const paymentOptions = yield (0, _effects.select)(_selectors.getPaymentOptions);
  const hasTaxesIncluded = yield (0, _effects.select)(_selectors.getHasTaxesIncluded);
  const {
    cashPurchaseOption,
    financeOptions,
    ...paymentOptionsData
  } = paymentOptions;
  const body = {
    calculationTargetType: payload.targetType,
    calculationTargetValue: parseFloat(payload.value),
    includeTaxes: hasTaxesIncluded,
    ...paymentOptionsData
  };
  try {
    const response = yield (0, _effects.call)(_commonsApi.ShowroomService.desking.paymentRoll, quoteId, queryParams, body);
    yield (0, _effects.put)(_actions.Creators.setCalculatedAdjustments({
      [_payment.PAYMENT_ADJUSTMENT_TYPE.CASH_DOWN]: response.calculatedCashDown,
      [_payment.PAYMENT_ADJUSTMENT_TYPE.SELLING_PRICE]: response.calculatedDealDiscount,
      [_payment.PAYMENT_ADJUSTMENT_TYPE.TRADE_IN]: response.calculatedTradeIn
    }));
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'initializeCalculatedAdjustments'));
  }
}
var _default = exports.default = initializeCalculatedAdjustments;