"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLeadIdsFromEasyDataReport = void 0;
var _appsCore = require("@sm360/apps-core");
const CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;
const getLeadIdsFromEasyDataReport = async _ref => {
  let {
    userId,
    organizationId,
    reportParameters
  } = _ref;
  return await _appsCore.axios.post(`${CRM_ENDPOINT}/easydata/leads/${userId}/${organizationId}`, reportParameters);
};
exports.getLeadIdsFromEasyDataReport = getLeadIdsFromEasyDataReport;