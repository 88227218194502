"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _effects = require("redux-saga/effects");
var _selectors = require("../selectors");
var _updatePayOptAndTransDetails = _interopRequireDefault(require("./api/updatePayOptAndTransDetails"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} payload - Data from action
 */
function* changePaymentCard(payload) {
  const paymentOptions = yield (0, _effects.select)(_selectors.getPaymentOptions);
  const financeOptions = yield (0, _effects.select)(_selectors.getFinanceOptions);
  const {
    index,
    options
  } = payload;

  // Deep copy
  const transformedFinanceOptions = structuredClone(financeOptions);
  // Update
  Object.assign(transformedFinanceOptions[index], options);
  yield (0, _effects.call)(_updatePayOptAndTransDetails.default, {
    ...paymentOptions,
    financeOptions: transformedFinanceOptions
  });
}
var _default = exports.default = changePaymentCard;