"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SYSTEM_WEB_USER = exports.PHONE_TYPES = exports.LEAD_TYPES_SUGGESTION_GROUPING = exports.LEAD_SERVICE_CATEGORIES = exports.COUNTRIES = void 0;
const COUNTRIES = exports.COUNTRIES = ['US', 'CA'];
const PHONE_TYPES = exports.PHONE_TYPES = {
  DEFAULT: 'mobilePhone',
  MOBILE_PHONE: 'mobilePhone',
  WORK_PHONE: 'workPhone',
  HOME_PHONE: 'homePhone'
};
const LEAD_TYPES = {
  MEETING_WITH_SALES_NEW: 35,
  MEETING_WITH_SALES_USED: 36,
  INFORMATION_REQUEST_USED: 38,
  INFORMATION_REQUEST_NEW: 39
};
const LEAD_SOURCES = {
  PHONE: 1,
  WALK_IN: 6
};
const LEAD_SERVICE_CATEGORIES = exports.LEAD_SERVICE_CATEGORIES = [4, 5]; // Service / Parts

const LEAD_TYPES_SUGGESTION_GROUPING = exports.LEAD_TYPES_SUGGESTION_GROUPING = {
  [LEAD_SOURCES.WALK_IN]: [LEAD_TYPES.MEETING_WITH_SALES_NEW, LEAD_TYPES.MEETING_WITH_SALES_USED],
  [LEAD_SOURCES.PHONE]: [LEAD_TYPES.INFORMATION_REQUEST_NEW, LEAD_TYPES.INFORMATION_REQUEST_USED]
};
const SYSTEM_WEB_USER = exports.SYSTEM_WEB_USER = {
  label: `System Web`,
  id: 75,
  value: 75
};