"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSort = exports.getSalesPersonnel = exports.getQuoteServiceQueryParams = exports.getPurchaseMethod = exports.getPermissions = exports.getPagination = exports.getLeadId = exports.getIsLoading = exports.getDeskingWidgetParams = exports.getDealersList = exports.getConfig = void 0;
var _commonsUtils = require("@sm360/commons-utils");
/**
 * @param {Object} state
 * @returns {Object}
 */
const getIsLoading = state => state.isLoading;

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getIsLoading = getIsLoading;
const getPaymentOptions = state => {
  const {
    paymentOptions
  } = state;
  return (0, _commonsUtils.isPlainObject)(paymentOptions) ? paymentOptions : {};
};

/**
 * @param {Object} state
 * @returns {String}
 */
const getPurchaseMethod = state => {
  const {
    purchaseMethod
  } = getPaymentOptions(state);
  return purchaseMethod;
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getPurchaseMethod = getPurchaseMethod;
const getConfig = state => {
  const {
    config
  } = state;
  return (0, _commonsUtils.isPlainObject)(config) ? config : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getConfig = getConfig;
const getPermissions = state => {
  const {
    permissions
  } = state;
  return (0, _commonsUtils.isPlainObject)(permissions) ? permissions : {};
};

/**
 * @param {Object} state
 * @returns {Number|null}
 */
exports.getPermissions = getPermissions;
const getLeadId = state => {
  const {
    leadId
  } = getConfig(state);
  return Number.isInteger(leadId) ? leadId : null;
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getLeadId = getLeadId;
const getPagination = state => {
  const {
    pagination
  } = state;
  return (0, _commonsUtils.isPlainObject)(pagination) ? pagination : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getPagination = getPagination;
const getSort = state => {
  const {
    sort
  } = state;
  return (0, _commonsUtils.isPlainObject)(sort) ? sort : {};
};

/** @params {Object} state
 * @returns {Object}
 */
exports.getSort = getSort;
const getSalesPersonnel = state => {
  const {
    salesPersonnel
  } = state;
  return salesPersonnel;
};

/** @params {Object} state
 * @returns {Object}
 */
exports.getSalesPersonnel = getSalesPersonnel;
const getDealersList = state => {
  const {
    dealersList
  } = state;
  return dealersList;
};
exports.getDealersList = getDealersList;
const getQuoteServiceQueryParams = state => {
  const {
    location,
    lang,
    organizationId,
    organizationUnitId
  } = getConfig(state);
  return {
    location,
    lang,
    organizationId,
    organizationUnitId
  };
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getQuoteServiceQueryParams = getQuoteServiceQueryParams;
const getDeskingWidgetParams = state => {
  const {
    accountId,
    contactId,
    organizationId,
    organizationUnitId
  } = getConfig(state);
  const leadId = getLeadId(state);
  return {
    leadId,
    accountId,
    contactId,
    organizationId,
    organizationUnitId
  };
};
exports.getDeskingWidgetParams = getDeskingWidgetParams;