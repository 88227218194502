"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _reactRedux = require("react-redux");
var _steps = require("../../constants/steps");
var _actions = require("../../redux/actions");
var _selectors = require("../../redux/selectors");
var _YearSelectionCard = _interopRequireDefault(require("./YearSelectionCard"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} state
 * @returns {Object}
 */
const mapStateToProps = state => {
  return {
    isHidingPrices: (0, _selectors.getIsHidingPrices)(state)
  };
};

/**
 * @param {Function} dispatch
 * @returns {Object}
 */
const mapDispatchToProps = dispatch => ({
  goNextStep: () => dispatch(_actions.Creators.setCurrentStep(_steps.STEP_TRIM)),
  selectModelYear: year => dispatch(_actions.Creators.selectModelYear(year))
});
var _default = exports.default = (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_YearSelectionCard.default);