"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.triggerSavePaymentBarOptions = exports.triggerRefreshLead = exports.triggerEnableExitConfirmation = exports.triggerDisableExitConfirmation = exports.getVehiclesPath = exports.getGaragePath = exports.getDeskingPath = exports.getBuilderPath = void 0;
/**
 * We want to come back in the quote app, so in the lead of the CRM
 *
 * @param {Number} leadId
 */
const getGaragePath = leadId => `/leads/edit/${leadId}?tab=quote`;

/**
 * We want to open desking from a quote
 *
 * @param {String} quoteId
 */
exports.getGaragePath = getGaragePath;
const getDeskingPath = params => {
  const {
    quoteId,
    accountId,
    contactId,
    organizationId,
    organizationUnitId,
    upg,
    leadId
  } = params;
  const optionalParams = {
    accountId,
    contactId,
    organizationId,
    organizationUnitId,
    upg,
    leadId
  };
  let path = `/desking-desking?quoteId=${quoteId}`;
  Object.entries(optionalParams).forEach(_ref => {
    let [key, value] = _ref;
    if (value) path = path.concat(`&${key}=${value}`);
  });
  return path;
};

/**
 * We want to open the vehicles app with a category already chosen
 *
 * @param {Object} params
 */
exports.getDeskingPath = getDeskingPath;
const getVehiclesPath = params => {
  const {
    organizationId,
    organizationUnitId,
    location,
    accountId,
    contactId,
    leadId,
    category,
    upg
  } = params;
  const optionalParams = {
    organizationUnitId,
    location,
    accountId,
    contactId,
    leadId,
    category,
    upg
  };
  let path = `/desking-vehicles?organizationId=${organizationId}`;
  Object.entries(optionalParams).forEach(_ref2 => {
    let [key, value] = _ref2;
    if (value) path = path.concat(`&${key}=${value}`);
  });
  return path;
};
exports.getVehiclesPath = getVehiclesPath;
const getBuilderPath = params => {
  const {
    organizationId,
    organizationUnitId,
    bodyStyle,
    includeTaxes,
    cashDown,
    kmPerYearPlan,
    leadId,
    lien,
    make,
    model,
    paymentFrequency,
    purchaseMethod,
    saleType,
    taxPlan,
    term,
    tradeIn,
    upg,
    accountId,
    contactId
  } = params;
  return `/desking-builder?bodyStyle=${bodyStyle}&cashDown=${cashDown}&includeTaxes=${includeTaxes}&kmPerYearPlan=${kmPerYearPlan}&leadId=${leadId}&lien=${lien}&make=${make}&model=${model}&organizationId=${organizationId}&organizationUnitId=${organizationUnitId}&paymentFrequency=${paymentFrequency}&purchaseMethod=${purchaseMethod}&saleType=${saleType}&taxPlan=${taxPlan}&term=${term}&tradeIn=${tradeIn}&upg=${upg}&accountId=${accountId}&contactId=${contactId}`;
};

/**
 * We updated the lead, telling the CRM to reload data of the lead
 */
exports.getBuilderPath = getBuilderPath;
const triggerRefreshLead = () => {};
exports.triggerRefreshLead = triggerRefreshLead;
const triggerSavePaymentBarOptions = paymentOptions => {
  // triggerEvent('save-payment-bar-options', { paymentOptions });
};
exports.triggerSavePaymentBarOptions = triggerSavePaymentBarOptions;
const triggerEnableExitConfirmation = message => {
  // triggerEvent('enable-exit-widget-confirmation', { message });
};
exports.triggerEnableExitConfirmation = triggerEnableExitConfirmation;
const triggerDisableExitConfirmation = () => {
  // triggerEvent('disable-exit-widget-confirmation');
};
exports.triggerDisableExitConfirmation = triggerDisableExitConfirmation;