"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUserAccountsByContactId = void 0;
var _appsCore = require("@sm360/apps-core");
const CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;

// Get user accounts by contactId
const getUserAccountsByContactId = async _ref => {
  let {
    id,
    dispatch
  } = _ref;
  return await _appsCore.axios.get(`${CRM_ENDPOINT}/accounts/findByContactId?contactId=${id}`, {
    ignoreAppsCoreCatch: true
  }).then(response => dispatch({
    type: 'setUserAccounts',
    userAccounts: response.data
  })).catch(() => dispatch({
    type: 'setUserAccounts',
    userAccounts: []
  }));
};
exports.getUserAccountsByContactId = getUserAccountsByContactId;