"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UiSchema = exports.Schema = void 0;
const sendEmailPrefix = 'actions.sendEmail.form';
const creatableSelectProps = {
  noDropdownIcon: true,
  multiple: true
};
const Schema = exports.Schema = {
  type: 'object',
  properties: {
    from: {
      title: `${sendEmailPrefix}.FROM`,
      type: 'string'
    },
    to: {
      title: `${sendEmailPrefix}.TO`,
      type: 'string'
    },
    cc: {
      title: `${sendEmailPrefix}.CC`,
      type: 'string',
      ...creatableSelectProps
    },
    bcc: {
      title: `${sendEmailPrefix}.BCC`,
      type: 'string',
      ...creatableSelectProps
    },
    subject: {
      title: `${sendEmailPrefix}.SUBJECT`,
      type: 'string'
    },
    mailContent: {
      title: `${sendEmailPrefix}.MAIL_CONTENT`,
      type: 'string',
      config: 'full'
    }
  }
};
const UiSchema = exports.UiSchema = {
  from: {
    'ui:disabled': true
  },
  to: {
    'ui:disabled': true
  },
  cc: {
    'ui:disabled': true,
    'ui:widget': 'CreatableSelectWidget'
  },
  bcc: {
    'ui:disabled': true,
    'ui:widget': 'CreatableSelectWidget'
  },
  subject: {
    'ui:disabled': true
  },
  mailContent: {
    'ui:widget': 'EditorWidget',
    'ui:disabled': true
  }
};