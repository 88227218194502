"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.splitOptionsBySuggested = exports.parseBasicInfoToHtml = exports.isVehicleSold = exports.isVehicleOnHold = exports.isVehicleDeleted = exports.groupTypesByCategory = exports.getResumeOptions = exports.getFullNameFromEntry = void 0;
var _commonsUtils = require("@sm360/commons-utils");
// Convert a flat list of leadTypes into a list of leadCategories which contain leadTypes
const groupTypesByCategory = types => {
  return types.reduce((acc, type) => {
    const groupIndex = acc.findIndex(group => group.groupId === String(type.leadCategory.id));
    if (groupIndex >= 0) {
      // group already exists, push new lead type in it
      const group = acc[groupIndex];
      const updatedGroup = {
        ...group,
        options: [...group.options, {
          id: String(type.id),
          label: type.type
        }]
      };
      const updatedAcc = [...acc];
      updatedAcc.splice(groupIndex, 1, updatedGroup);
      return updatedAcc;
    } else {
      // group doesn't exist yet, create it then push new lead type in it
      return [...acc, {
        groupId: String(type.leadCategory.id),
        groupLabel: type.leadCategory.category,
        options: [{
          id: String(type.id),
          label: type.type
        }]
      }];
    }
  }, []).sort((a, b) => a.groupId.localeCompare(b.groupId));
};
exports.groupTypesByCategory = groupTypesByCategory;
const getFullNameFromEntry = entry => {
  const contactFirstname = entry?.contact?.firstname;
  const contactLastname = entry?.contact?.lastname;
  return entry?.contact?.business ? entry.contact.businessName : `${contactFirstname || ''}  ${contactLastname || ''}`.trim();
};
exports.getFullNameFromEntry = getFullNameFromEntry;
const parseBasicInfoToHtml = info => {
  if (typeof info !== 'string') return info;
  return (0, _commonsUtils.replaceLinksByHtmlTags)(info, true).replaceAll(/\n/g, '<br/>');
};
exports.parseBasicInfoToHtml = parseBasicInfoToHtml;
const isVehicleOnHold = vehicle => vehicle?.vehicleInventoryStatus !== 'SOLD' && vehicle?.vehicleInventoryStatus !== 'DELETED' && vehicle?.onHold;
exports.isVehicleOnHold = isVehicleOnHold;
const isVehicleSold = vehicle => vehicle?.vehicleInventoryStatus === 'SOLD';
exports.isVehicleSold = isVehicleSold;
const isVehicleDeleted = vehicle => vehicle?.vehicleInventoryStatus === 'DELETED';
exports.isVehicleDeleted = isVehicleDeleted;
const getResumeOptions = _ref => {
  let {
    unavailableValue,
    fullOptions = [],
    restrictedOptions = [],
    t,
    canAccessRestrictedData = false
  } = _ref;
  const _restrictedOptions = restrictedOptions || fullOptions;

  // if first option has an 'options' field => it is a sub-options use case
  const hasSubOptions = Array.isArray(_restrictedOptions?.[0]?.options);
  let optionsWithRestrictedValueDisabled = [];
  if (hasSubOptions) {
    optionsWithRestrictedValueDisabled = _restrictedOptions.map(o => ({
      ...o,
      options: o.options.filter(subO => subO.restrictedAccess ? canAccessRestrictedData : true).map(subO => ({
        ...subO,
        value: (0, _commonsUtils.nullOrString)(subO.value)
      }))
    }));
  } else {
    optionsWithRestrictedValueDisabled = _restrictedOptions.filter(o => o.restrictedAccess ? canAccessRestrictedData : true).map(o => ({
      ...o,
      value: (0, _commonsUtils.nullOrString)(o.value)
    }));
  }

  // Add disabled option for value (if this value is not present in options)
  let additionalOption = null;
  const parsedValue = (0, _commonsUtils.nullOrString)(unavailableValue);
  if (hasSubOptions) {
    const subOptions = optionsWithRestrictedValueDisabled.reduce((acc, o) => [...acc, ...(o.options || [])], []);
    const valueExists = parsedValue ? !!subOptions.find(o => String(o.value) === parsedValue) : true;
    if (!valueExists) {
      additionalOption = {
        id: 'sourcesExcluded',
        isSuggested: true,
        label: t('leads.customFields.EXCLUDED'),
        options: [{
          label: fullOptions.find(o => String(o.value) === parsedValue)?.label || '',
          id: unavailableValue,
          value: parsedValue,
          isDisabled: true
        }]
      };
    }
  } else {
    const valueExists = parsedValue ? !!optionsWithRestrictedValueDisabled.find(o => String(o.value) === parsedValue) : true;
    if (!valueExists) {
      additionalOption = {
        label: fullOptions.find(o => String(o.value) === parsedValue)?.label || '',
        id: unavailableValue,
        value: parsedValue,
        isDisabled: true
      };
    }
  }
  return [...(additionalOption ? [additionalOption] : []), ...optionsWithRestrictedValueDisabled];
};
exports.getResumeOptions = getResumeOptions;
const splitOptionsBySuggested = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  let suggestedOptions = [];
  let otherOptions = [];
  if (Array.isArray(options?.[0]?.options)) {
    suggestedOptions = options?.filter(o => o.isSuggested)?.reduce((acc, a) => [...acc, ...(a?.options || [])], []) || [];
    otherOptions = options?.filter(o => !o.isSuggested)?.reduce((acc, a) => [...acc, ...(a?.options || [])], []) || [];
  } else {
    suggestedOptions = options;
  }
  return {
    suggestedOptions,
    otherOptions
  };
};
exports.splitOptionsBySuggested = splitOptionsBySuggested;