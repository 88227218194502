"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _selectors = require("../../selectors");
function* getGarage() {
  const {
    leadId
  } = yield (0, _effects.select)(_selectors.getConfig);
  const pathParams = yield (0, _effects.select)(_selectors.getQuoteServiceQueryParams);
  try {
    return yield (0, _effects.call)(_commonsApi.QuoteService.getGarageData, leadId, pathParams);
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'getGarage'));
    return null;
  }
}
var _default = exports.default = getGarage;