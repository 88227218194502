"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatOptions = exports.ITEM_CATEGORY = void 0;
var _commonsUtils = require("@sm360/commons-utils");
const ITEM_CATEGORY = exports.ITEM_CATEGORY = {
  ACCESSORY: 'accessory',
  DISCOUNT: 'discount',
  EXTERIOR_COLOUR: 'exteriorColour',
  INTERIOR_TRIM: 'interiorTrim',
  OPTION: 'option',
  REBATE: 'rebate',
  INSURANCE: 'insurance',
  WARRANTY: 'warranty',
  PROTECTION: 'protection',
  COST_ADJUSTMENT: 'costAdjustment',
  WO_COST_ADJUSTMENT: 'WOCostAdjustment',
  COST: 'cost',
  PROFIT_ADJUSTMENT: 'profitAdjustment',
  PROFIT: 'profit',
  COMMISSION: 'commission',
  CLOSED_RO: 'closedRO',
  OPEN_RO: 'openRO',
  NOTE: 'note',
  INTERNAL_NOTE: 'internalNote',
  CUSTOMER_NOTE: 'customerNote',
  DEPOSIT: 'deposit',
  TO_PAY: 'toPay'
};

/**
 * Group items by category, and sort them by description.
 * And sort categories by given list.
 *
 * @param {Object[]} content
 * @param {String[]} sortedCategories
 * @returns {Object[]}
 */
const formatOptions = function (content) {
  let sortedCategories = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  let groupedDetails = (0, _commonsUtils.groupBy)(content, detail => detail.category);
  groupedDetails = Object.keys(groupedDetails).map(category => ({
    category,
    groupdetails: (0, _commonsUtils.sortBy)(groupedDetails[category], 'description')
  }));
  if (sortedCategories.length === 0) {
    return groupedDetails;
  }
  const result = [];
  let foundIndex;

  // Put on the top what's asked to be sorted
  sortedCategories.forEach(category => {
    foundIndex = groupedDetails.findIndex(detail => detail.category === category);
    if (foundIndex !== -1) {
      result.push(...groupedDetails.splice(foundIndex, 1));
    }
  });

  // Put the rest of it, not sorted
  result.push(...groupedDetails);
  return result;
};
exports.formatOptions = formatOptions;