"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TAB_PROTECTION = exports.COLUMN_TYPE = void 0;
// Internal values, used by desking-app only
const TAB_PROTECTION = exports.TAB_PROTECTION = {
  INSURANCE: 'insurance',
  PROTECTION: 'protection',
  WARRANTY: 'warranty',
  LGM: 'lgm',
  BMW: 'bmw'
};
const COLUMN_TYPE = exports.COLUMN_TYPE = {
  SELECT: 'select',
  AMOUNT: 'amount',
  AMOUNT_AT_DELIVERY: 'amountAtDelivery',
  COST: 'cost',
  PROFIT: 'profit',
  DESCRIPTION: 'description',
  OUTSIDE: 'outside',
  TERMS: 'terms',
  KMS: 'kms',
  DEDUCTIBLE_AMOUNTS: 'deductibleAmounts',
  MAXIMUM_COVERAGE_AMOUNT: 'maximumCoverageAmount'
};