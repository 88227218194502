"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _actions = require("../../actions");
function* _default() {
  try {
    const hasAccess = yield (0, _effects.call)(_commonsApi.AuthorizationService.hasBuilderPricesAccess);
    yield (0, _effects.put)(_actions.Creators.setPermission({
      isHidingPrices: !hasAccess
    }));
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'getRights'));
  }
}