"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CONST_TABS = void 0;
var _schema = require("./tabs/schema.profile");
const CONST_TABS = exports.CONST_TABS = [{
  title: 'contacts.forms.profile.TAB_LABEL',
  id: 'info',
  body: _schema.PROFILE_FORM
},
// {
//     title: 'contacts.forms.activities.TAB_LABEL',
//     id: 'activities',
//     body: [
//         {
//             fields: [
//                 {
//                     input: 'activities',
//                     className: 'small-12 medium-4 large-3',
//                     label: 'activities',
//                     name: 'firstname',
//                     skipLayout: true,
//                 },
//             ],
//         },
//     ],
// },
{
  title: 'contacts.forms.leads.TAB_LABEL',
  id: 'leads',
  body: [{
    legend: 'contacts.forms.leads.TITLE',
    fields: [{
      input: 'leads',
      className: 'small-12 medium-4 large-3',
      label: 'leads',
      name: 'firstname',
      skipLayout: true
    }]
  }]
}, {
  title: 'contacts.forms.dmsClient.TAB_LABEL',
  id: 'vehicles',
  body: [{
    legend: 'contacts.forms.dmsClient.clients.TITLE',
    fields: [{
      input: 'dms',
      className: 'small-12'
    }]
  }, {
    legend: 'contacts.forms.dmsClient.vehicles.TITLE',
    fields: [{
      input: 'vehicles',
      className: 'small-12',
      skipLayout: true
    }]
  }]
},
// {
//     title: 'Possible Duplicates',
//     id: 'duplicates',
//     body: [
//         {
//             fields: [
//                 {
//                     input: 'duplicates',
//                     className: 'small-12 medium-4 large-3',
//                     label: 'duplicates',
//                     name: 'duplicates',
//                     skipLayout: true,
//                 },
//             ],
//         },
//     ],
// },
{
  title: 'contacts.forms.userAccount.TAB_LABEL',
  id: 'userAccount',
  body: [{
    fields: [{
      input: 'userAccount',
      className: 'small-12 medium-4 large-3',
      label: 'userAccount',
      name: 'userAccount',
      skipLayout: true
    }]
  }]
}, {
  title: 'contacts.forms.documents.TAB_LABEL',
  id: 'documents',
  body: [{
    fields: [{
      input: 'documents',
      className: 'small-12 medium-4 large-3',
      label: 'documents',
      name: 'documents',
      skipLayout: true
    }]
  }]
}, {
  title: 'contacts.forms.subscriptions.TAB_LABEL',
  id: 'antiSpam',
  body: [{
    fields: [{
      input: 'subscriptions',
      className: 'small-12 medium-4 large-3',
      label: 'subscriptions',
      name: 'subscriptions',
      skipLayout: true
    }]
  }]
}];