"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  arrows: true,
  speed: 300
  // responsive: [
  //     {
  //         breakpoint: 1200,
  //         settings: {
  //             slidesToShow: 2.5,
  //             slidesToScroll: 0.5,
  //             dots: false,
  //         },
  //     },
  //     {
  //         breakpoint: 1024,
  //         settings: {
  //             slidesToShow: 2,
  //             slidesToScroll: 1,
  //         },
  //     },
  //     {
  //         breakpoint: 480,
  //         settings: {
  //             slidesToShow: 1,
  //             slidesToScroll: 1,
  //         },
  //     },
  // ],
};