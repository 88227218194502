"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _appsCore = require("@sm360/apps-core");
var _utils = require("../tools/utils");
var _constants = require("../tools/constants");
const tPrefix = 'profile-imap-config';
const tPrefixConnection = 'profile-imap-config.connectionIssue';

// Returns the details of the issue with user connection (if applicable)
const useUserConnectionIssue = user => {
  const {
    t
  } = (0, _appsCore.useTranslation)();
  const userConnectionStatus = (0, _utils.getUserConnectionStatus)(user);
  const userProviderId = (0, _utils.getUserOAuthProvider)(user);
  const userConnectionExpireSoon = (0, _utils.willUserConnectionExpireSoon)(user);
  const remainingDaysBeforeExpiration = (0, _utils.getUserConnectionRemainingDays)(user);
  const isExpired = remainingDaysBeforeExpiration < 0;
  const provider = t(`${tPrefix}.providerLabels.${userProviderId || 'MANUAL'}`);
  switch (userConnectionStatus) {
    case _constants.CONNECTION_STATUS.CONNECTED:
      if (userConnectionExpireSoon) {
        return {
          issueType: isExpired ? _constants.ISSUE_TYPE.OAUTH_TOKEN_EXPIRED : _constants.ISSUE_TYPE.OAUTH_TOKEN_WILL_EXPIRE,
          severity: isExpired ? _constants.ISSUE_SEVERITY.ERROR : _constants.ISSUE_SEVERITY.WARNING,
          description: t(`${tPrefixConnection}.${isExpired ? 'OAUTH_TOKEN_EXPIRED' : 'OAUTH_TOKEN_WILL_EXPIRE'}`, {
            provider,
            count: remainingDaysBeforeExpiration
          }),
          retryLabel: t(`${tPrefixConnection}.RENEW_ACTION`)
        };
      }
      break;
    case _constants.CONNECTION_STATUS.BAD_CREDENTIALS:
    case _constants.CONNECTION_STATUS.SERVER_UNAVAILABLE:
    case _constants.CONNECTION_STATUS.UNKNOWN:
    default:
      return {
        issueType: _constants.ISSUE_TYPE.CONNECTION_FAILED,
        severity: _constants.ISSUE_SEVERITY.ERROR,
        description: t(`${tPrefixConnection}.${userConnectionStatus}`, {
          provider
        }),
        retryLabel: t(`${tPrefixConnection}.SYNC_ACTION`)
      };
    case _constants.CONNECTION_STATUS.DISCONNECTED:
      break;
  }
  return {
    issueType: null
  };
};
var _default = exports.default = useUserConnectionIssue;