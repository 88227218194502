"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _selectors = require("../selectors");
/**
 * @param {Object} state
 * @param {Object} payload - quote
 * @returns {Object} new state
 */
var _default = (state, _ref) => {
  let {
    quote
  } = _ref;
  const newQuote = {
    ...(0, _selectors.getQuote)(state),
    ...quote
  };
  return {
    ...state,
    quote: newQuote
  };
};
exports.default = _default;