"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TAB_ITEMS = void 0;
// Internal values, used by desking-app only
const TAB_ITEMS = exports.TAB_ITEMS = {
  ACCESSORY: 'accessory',
  COLOUR: 'colour',
  INSURANCE: 'insurance',
  OPTION: 'option',
  PROTECTION: 'protection',
  WARRANTY: 'warranty',
  REBATE: 'rebate',
  COST_ADJUSTMENT: 'costAdjustment',
  NOTE: 'note'
};