"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _tabProtection = require("../selectors/tabProtection");
/**
 * @param {Object} state
 * @param {String} fniType
 * @param {String} contentType
 * @param {String} persistentId
 * @param {String} planPersistentId
 * @param {Object} validationItem
 * @returns {Object}
 */
var _default = (state, _ref) => {
  let {
    fniType,
    contentType,
    persistentId,
    planPersistentId,
    validationItem
  } = _ref;
  const fniTab = (0, _tabProtection.getFNITab)(state, fniType);
  const items = (0, _tabProtection.getFNIItems)(state, fniType, contentType);
  if ((0, _commonsUtils.isEmpty)(fniTab) || (0, _commonsUtils.isEmpty)(items)) {
    return state;
  }
  const {
    isSelected,
    protectionCoverageParameters,
    insuranceCoverageParameters,
    pricing
  } = validationItem;
  items.every((item, itemsIndex) => {
    if (item.persistentId === persistentId) {
      item.plans.every((plan, planIndex) => {
        if (plan.persistentId === planPersistentId) {
          items[itemsIndex].plans[planIndex].isSelected = isSelected;
          if (isSelected) {
            if (contentType === 'warranties' || contentType === 'protections') {
              items[itemsIndex].plans[planIndex].protectionCoverageParameters = (0, _tabProtection.getPlanProtectionCoverageParameters)(state, fniType, contentType, persistentId, planPersistentId, protectionCoverageParameters);
            } else if (contentType === 'insurances') {
              items[itemsIndex].plans[planIndex].insuranceCoverageParameters = (0, _tabProtection.getPlanInsuranceCoverageParameters)(state, fniType, contentType, persistentId, planPersistentId, insuranceCoverageParameters);
            }
            items[itemsIndex].plans[planIndex].pricing = {
              ...items[itemsIndex].plans[planIndex].pricing,
              ...pricing
            };
          }
          return false;
        }
        return true;
      });
      return false;
    }
    return true;
  });
  fniTab.content[contentType] = items;
  return {
    ...state,
    ...fniTab
  };
};
exports.default = _default;