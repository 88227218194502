"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _handleChangeValidation = _interopRequireDefault(require("../common/handleChangeValidation"));
var _reloadVehicle = _interopRequireDefault(require("../reloadVehicle"));
var _validateItem = _interopRequireDefault(require("./validateItem"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} payload - item, picture
 */
function _default(_ref) {
  let {
    item,
    picture
  } = _ref;
  return function* () {
    const response = yield (0, _effects.call)(_validateItem.default, item);
    if (response) {
      yield (0, _effects.call)(_handleChangeValidation.default, response, picture);
      yield (0, _effects.fork)(_reloadVehicle.default);
    }
  }();
}