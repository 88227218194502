"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSelectedItem = exports.getItems = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _deskingCommons = require("@sm360/desking-commons");
var _config = require("../../constants/config");
var _content = require("./content");
/**
 * @param {Object} state
 * @returns {Object[]}
 */
const getItems = state => {
  const {
    items
  } = (0, _content.getContent)(state);
  return Array.isArray(items) ? (0, _deskingCommons.formatOptions)(items, _config.STEP_ITEM_CATEGORIES) : [];
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getItems = getItems;
const getSelectedItem = state => {
  const {
    selectedItem
  } = (0, _content.getContent)(state);
  return (0, _commonsUtils.isPlainObject)(selectedItem) ? selectedItem : {};
};
exports.getSelectedItem = getSelectedItem;