"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tabProtection = require("../../constants/tabProtection");
/**
 * @param {Object} state
 * @param {Object} payload - { fniType, error, errMsg }
 * @returns {Object}
 */
var _default = (state, _ref) => {
  let {
    fniType,
    error,
    errMsg
  } = _ref;
  switch (fniType) {
    case _tabProtection.TAB_PROTECTION.LGM:
      return {
        ...state,
        lgmItemsError: error ?? 0,
        lgmItemsErrorMessage: errMsg ?? ''
      };
    case _tabProtection.TAB_PROTECTION.BMW:
      return {
        ...state,
        bmwItemsError: error ?? 0,
        bmwItemsErrorMessage: errMsg ?? ''
      };
    default:
      return state;
  }
};
exports.default = _default;