"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _actions = require("../../actions");
var _selectors = require("../../selectors");
function* _default() {
  const {
    profile: {
      firstname,
      lastname
    }
  } = yield (0, _effects.getContext)('user');
  const {
    organizationUnitId
  } = yield (0, _effects.select)(_selectors.getConfig);
  try {
    const dealerInfoBase = yield (0, _effects.call)(_commonsApi.DealerInfoService.getDealerInfo, organizationUnitId);
    const dealerInfo = {
      salesPerson: {
        firstname,
        lastname
      },
      ...dealerInfoBase
    };
    yield (0, _effects.put)(_actions.Creators.setDealerInfo(dealerInfo));
    return null;
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'getDealerInfo'));
    return false;
  }
}