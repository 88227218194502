"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _effects = require("redux-saga/effects");
var _selectors = require("../selectors");
var _updatePayOptAndTransDetails = _interopRequireDefault(require("./api/updatePayOptAndTransDetails"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * There is no need to update the financeOptions of the selected payment.
 * The API will take care of update the data.
 * Basically, it's the same behavior than when we change something in the paymentBar.
 *
 * @param {Object} payload - Data from action
 */
function* changePaymentCardSelected(payload) {
  const paymentOptions = yield (0, _effects.select)(_selectors.getPaymentOptions);
  const {
    options
  } = payload;
  yield (0, _effects.call)(_updatePayOptAndTransDetails.default, {
    ...paymentOptions,
    ...options
  });
}
var _default = exports.default = changePaymentCardSelected;