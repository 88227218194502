"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _deskingError = require("@sm360/desking-error");
var _selectors = require("../../redux/selectors");
var _actions = require("../../redux/actions");
var _Garage = _interopRequireDefault(require("./Garage"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const mapStateToProps = state => {
  return {
    garage: (0, _selectors.getGarageInfo)(state),
    hasDeskingAccess: (0, _selectors.getDeskingAccess)(state),
    canCreateUnlistedVehicle: (0, _selectors.getCanCreateUnlistedVehicle)(state),
    vehiclesWidgetParams: (0, _selectors.getVehiclesWidgetParams)(state),
    state
  };
};
const mapDispatchToProps = dispatch => ({
  selectQuote: id => dispatch(_actions.Creators.selectQuote(id))
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps), _deskingError.withErrorWrapper)(_Garage.default);