"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _actions = require("../../actions");
var _getOptionItems = _interopRequireDefault(require("../api/getOptionItems"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function* _default() {
  const response = yield (0, _effects.call)(_getOptionItems.default);
  if (response) {
    yield (0, _effects.put)(_actions.Creators.loadStepOptionItemsSuccess(response));
  }
}