"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _reactRedux = require("react-redux");
var _steps = require("../../constants/steps");
var _actions = require("../../redux/actions");
var _ComfortSelectionCard = _interopRequireDefault(require("./ComfortSelectionCard"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Function} dispatch
 * @returns {Object}
 */
const mapDispatchToProps = dispatch => ({
  setNextStep: () => dispatch(_actions.Creators.setCurrentStep(_steps.STEP_COMFORT + 1)),
  updateModelTrim: (configurationId, modelTrimCode) => dispatch(_actions.Creators.updateModelTrim(configurationId, modelTrimCode))
});
var _default = exports.default = (0, _reactRedux.connect)(null, mapDispatchToProps)(_ComfortSelectionCard.default);