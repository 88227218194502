"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _selectors = require("../../selectors");
var _requestResidual = require("../../selectors/requestResidual");
var _handleChangeValidation = _interopRequireDefault(require("../common/handleChangeValidation"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} payload
 */
function _default(_ref) {
  let {
    category,
    value
  } = _ref;
  return function* () {
    const quoteId = yield (0, _effects.select)(_selectors.getQuoteId);
    const queryParams = yield (0, _effects.select)(_selectors.getShowroomQueryParams);
    const body = yield (0, _effects.select)(_requestResidual.getRollBody, category, value);
    try {
      const response = yield (0, _effects.call)(_commonsApi.ShowroomService.desking.residualRoll, quoteId, queryParams, body);
      yield (0, _effects.call)(_handleChangeValidation.default, response);
    } catch (error) {
      yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'updateQuote'));
    }
  }();
}