"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.COLUMN_TYPE = void 0;
const COLUMN_TYPE = exports.COLUMN_TYPE = {
  AMOUNT: 'amount',
  AMOUNT_AT_DELIVERY: 'amountAtDelivery',
  COST: 'cost',
  PROFIT: 'profit',
  DESCRIPTION: 'description',
  OUTSIDE: 'outside'
};