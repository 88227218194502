"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const yesNoChoices = [{
  value: true,
  labelKey: 'QUESTIONNAIRE.YES'
}, {
  value: false,
  labelKey: 'QUESTIONNAIRE.NO'
}];
const history = {
  questions: {
    originalOwner: {
      type: 'radio',
      labelKey: 'QUESTIONNAIRE.HISTORY.ORIGINALOWNER',
      choices: yesNoChoices
    },
    vehiclePurchase: {
      type: 'radio',
      labelKey: 'QUESTIONNAIRE.HISTORY.VEHICLEPURCHASE',
      choices: [{
        value: 'private',
        labelKey: 'QUESTIONNAIRE.HISTORY.VEHICLEPURCHASE_PRIVATE'
      }, {
        value: 'dealer',
        labelKey: 'QUESTIONNAIRE.HISTORY.VEHICLEPURCHASE_DEALER'
      }]
    },
    vehicleCondition: {
      type: 'radio',
      labelKey: 'QUESTIONNAIRE.HISTORY.VEHICLECONDITION',
      choices: [{
        value: 'perfect',
        labelKey: 'QUESTIONNAIRE.HISTORY.VEHICLECONDITION_PERFECT'
      }, {
        value: 'ok',
        labelKey: 'QUESTIONNAIRE.HISTORY.VEHICLECONDITION_OK'
      }, {
        value: 'rough',
        labelKey: 'QUESTIONNAIRE.HISTORY.VEHICLECONDITION_ROUGH'
      }]
    },
    corporateUse: {
      type: 'radio',
      labelKey: 'QUESTIONNAIRE.HISTORY.CORPORATEUSE',
      choices: yesNoChoices,
      questions: {
        corporateUseExplanation: {
          showQuestionText: false,
          linkToParent: {
            onlyFor: true
          },
          displayNestedInsideParent: true,
          type: 'textarea',
          labelKey: 'QUESTIONNAIRE.HISTORY.CORPORATEUSEEXPLANATION'
        }
      }
    },
    rustProof: {
      type: 'radio',
      labelKey: 'QUESTIONNAIRE.HISTORY.RUSTPROOF',
      choices: yesNoChoices
    },
    mechanicalProtection: {
      type: 'radio',
      labelKey: 'QUESTIONNAIRE.HISTORY.MECHANICALPROTECTION',
      choices: yesNoChoices,
      questions: {
        coverageBalance: {
          linkToParent: {
            onlyFor: true
          },
          labelKey: 'QUESTIONNAIRE.HISTORY.COVERAGEBALANCE',
          type: 'text'
        }
      }
    }
  }
};
const condition = {
  questions: {
    hadAnAccident: {
      type: 'radio',
      labelKey: 'QUESTIONNAIRE.CONDITION.HADANACCIDENT',
      choices: [{
        value: 'none',
        labelKey: 'QUESTIONNAIRE.CONDITION.HADANACCIDENT_NONE'
      }, {
        value: 'lessThan3000',
        labelKey: 'QUESTIONNAIRE.CONDITION.HADANACCIDENT_LESSTHAN3000'
      }, {
        value: '3000AndMore',
        labelKey: 'QUESTIONNAIRE.CONDITION.HADANACCIDENT_3000ANDMORE'
      }],
      questions: {
        clarifyAccident: {
          labelKey: 'QUESTIONNAIRE.CONDITION.CLARIFYACCIDENT',
          displayNestedInsideParent: true,
          linkToParent: {
            onlyFor: '3000AndMore'
          },
          type: 'textarea'
        },
        bodyPartReplaced: {
          labelKey: 'QUESTIONNAIRE.CONDITION.BODYPARTREPLACED',
          linkToParent: {
            onlyFor: '3000AndMore'
          },
          type: 'radio',
          choices: yesNoChoices
        }
      }
    },
    absOperational: {
      labelKey: 'QUESTIONNAIRE.CONDITION.ABSOPERATIONAL',
      type: 'radio',
      choices: yesNoChoices
    },
    airBagsOperational: {
      type: 'radio',
      labelKey: 'QUESTIONNAIRE.CONDITION.AIRBAGSOPERATIONAL',
      choices: yesNoChoices
    },
    modifiedParts: {
      type: 'radio',
      labelKey: 'QUESTIONNAIRE.CONDITION.MODIFIEDPARTS',
      choices: yesNoChoices
    },
    partsToReplace: {
      type: 'checkbox',
      labelKey: 'QUESTIONNAIRE.CONDITION.PARTSTOREPLACE',
      choices: [{
        value: 'none',
        disableOthers: true,
        labelKey: 'QUESTIONNAIRE.CONDITION.PARTSTOREPLACE_NONE'
      }, {
        value: 'engine',
        labelKey: 'QUESTIONNAIRE.CONDITION.PARTSTOREPLACE_ENGINE'
      }, {
        value: 'suspension',
        labelKey: 'QUESTIONNAIRE.CONDITION.PARTSTOREPLACE_SUSPENSION'
      }, {
        value: 'transmission',
        labelKey: 'QUESTIONNAIRE.CONDITION.PARTSTOREPLACE_TRANSMISSION'
      }, {
        value: 'powertrain',
        labelKey: 'QUESTIONNAIRE.CONDITION.PARTSTOREPLACE_POWERTRAIN'
      }, {
        value: 'computer',
        labelKey: 'QUESTIONNAIRE.CONDITION.PARTSTOREPLACE_COMPUTER'
      }, {
        value: 'electrical',
        labelKey: 'QUESTIONNAIRE.CONDITION.PARTSTOREPLACE_ELECTRICAL'
      }, {
        value: 'fuelOperationSystem',
        labelKey: 'QUESTIONNAIRE.CONDITION.PARTSTOREPLACE_FUELOPERATIONSYSTEM'
      }, {
        value: 'airConditioningSystem',
        labelKey: 'QUESTIONNAIRE.CONDITION.PARTSTOREPLACE_AIRCONDITIONINGSYSTEM'
      }, {
        value: 'other',
        labelKey: 'QUESTIONNAIRE.CONDITION.PARTSTOREPLACE_OTHER'
      }],
      questions: {
        needReparationOtherExplanation: {
          linkToParent: {
            anyExcept: 'none'
          },
          displayNestedInsideParent: true,
          type: 'textarea',
          labelKey: 'QUESTIONNAIRE.CONDITION.NEEDREPARATIONOTHEREXPLANATION'
        }
      }
    },
    replacedTires: {
      type: 'radio',
      labelKey: 'QUESTIONNAIRE.CONDITION.REPLACEDTIRES',
      choices: yesNoChoices
    },
    replacedBrakes: {
      type: 'radio',
      labelKey: 'QUESTIONNAIRE.CONDITION.REPLACEDBRAKES',
      choices: yesNoChoices
    },
    anyScratches: {
      type: 'radio',
      labelKey: 'QUESTIONNAIRE.CONDITION.ANYSCRATCHES',
      choices: yesNoChoices
    },
    damageExceeding3000: {
      type: 'radio',
      labelKey: 'QUESTIONNAIRE.CONDITION.DAMAGEEXCEEDING3000',
      choices: yesNoChoices
    },
    majorExteriorInteriorDamage: {
      type: 'radio',
      labelKey: 'QUESTIONNAIRE.CONDITION.MAJOREXTERIORINTERIORDAMAGE',
      choices: yesNoChoices,
      questions: {
        clarifyDamage: {
          linkToParent: {
            onlyFor: true
          },
          displayNestedInsideParent: true,
          type: 'textarea',
          labelKey: 'QUESTIONNAIRE.CONDITION.CLARIFYDAMAGE'
        }
      }
    }
  }
};
const model = {
  iconName: 'rapport',
  sections: {
    history,
    condition
  }
};
var _default = exports.default = model;