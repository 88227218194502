"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _selectors = require("../../selectors");
var _default = (state, _ref) => {
  let {
    decision
  } = _ref;
  return {
    ...state,
    contractValidation: {
      ...(0, _selectors.getContractValidation)(state),
      decision
    }
  };
};
exports.default = _default;