"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _effects = require("redux-saga/effects");
var _deskingCommons = require("@sm360/desking-commons");
var _actions = require("./actions");
var _sagas = _interopRequireDefault(require("./sagas"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function* rootSaga() {
  yield (0, _effects.all)([(0, _effects.takeLatest)(_actions.Types.SELECT_VEHICLE, _sagas.default.selectVehicle), (0, _effects.takeLatest)(_actions.Types.OPEN_VEHICLE_IN_DESKING, _sagas.default.openVehicleInDesking), (0, _effects.takeLatest)(_actions.Types.OPEN_VEHICLE_IN_BUILDER, _sagas.default.openVehicleInBuilder), (0, _effects.takeLatest)(_actions.Types.INITIALIZE_STORE, _sagas.default.initializeStore), (0, _effects.takeLatest)(_actions.Types.SET_CURRENT_PAGE, _sagas.default.setCurrentPage), (0, _effects.takeLatest)(_actions.Types.RELOAD_VEHICLES_LIST, _sagas.default.loadVehicles), (0, _effects.takeLatest)(_actions.Types.GET_LEAD_INFO, _sagas.default.getLeadInfo), (0, _effects.takeLatest)(_deskingCommons.priceIncreaseTypes.SET_PRICE_INCREASE_ROLL_COUNT, _sagas.default.loadVehicles), (0, _effects.takeLatest)(_actions.Types.SELECT_DEALER, _sagas.default.selectDealer), (0, _effects.takeLatest)(_actions.Types.SET_PREFERRED_QUOTE, _sagas.default.setPreferredQuote),
  // Payment options
  (0, _effects.takeLatest)(_actions.Types.UPDATE_CASH_DOWN, _sagas.default.updatePaymentOptions), (0, _effects.takeLatest)(_actions.Types.UPDATE_LIEN, _sagas.default.updatePaymentOptions), (0, _effects.takeLatest)(_actions.Types.UPDATE_TRADE_IN, _sagas.default.updatePaymentOptions), (0, _effects.takeLatest)(_actions.Types.UPDATE_PAYMENT_FREQUENCY, _sagas.default.updatePaymentOptions), (0, _effects.takeLatest)(_actions.Types.UPDATE_TERM, _sagas.default.updatePaymentOptions), (0, _effects.takeLatest)(_actions.Types.UPDATE_KM_PLAN, _sagas.default.updatePaymentOptions), (0, _effects.takeLatest)(_actions.Types.UPDATE_TAXES_INCLUDED, _sagas.default.updatePaymentOptions), (0, _effects.takeLatest)(_actions.Types.SELECT_PURCHASE_METHOD, _sagas.default.updatePaymentOptions),
  // Filters
  (0, _effects.takeLatest)(_actions.Types.APPLY_FILTERS, _sagas.default.applyFilters), (0, _effects.takeLatest)(_actions.Types.APPLY_SEARCH_TEXT, _sagas.default.applySearchText), (0, _effects.takeLatest)(_actions.Types.RESET_FILTERS, _sagas.default.reload), (0, _effects.takeLatest)(_actions.Types.UPDATE_CATEGORY, _sagas.default.reload), (0, _effects.takeLatest)(_actions.Types.UPDATE_FILTER_OPTIONS, _sagas.default.updateFilterOptions), (0, _effects.takeLatest)(_actions.Types.UPDATE_PRICE_RANGE, _sagas.default.updateFilterOptions), (0, _effects.takeLatest)(_actions.Types.UPDATE_ODOMETER_RANGE, _sagas.default.updateFilterOptions), (0, _effects.takeLatest)(_actions.Types.SORT_VEHICLES, _sagas.default.loadVehicles)]);
}
var _default = exports.default = rootSaga;