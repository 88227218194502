"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _actions = require("../../actions");
function* _default() {
  try {
    const userInfos = yield (0, _effects.call)(_commonsApi.AuthorizationService.getUserInfosRequest);
    if (userInfos) {
      const {
        rights,
        roles
      } = userInfos;
      yield (0, _effects.put)(_actions.Creators.setUserRights({
        rights
      }));
      yield (0, _effects.put)(_actions.Creators.setUserRoles({
        roles
      }));
    }
    return userInfos;
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'getUserInfos'));
    return {};
  }
}