"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.MIN_EMPLOYMENT_PERIOD = exports.FINANCING_FREQUENCIES = exports.EMPLOYMENT_TYPE = exports.EMPLOYMENT_STATUS_DEALER_TRACK = exports.EMPLOYMENT_STATUS = void 0;
const EMPLOYMENT_STATUS = exports.EMPLOYMENT_STATUS = [{
  translationKey: 'FINANCING.EMPLOYMENT_INFOS.EMPLOYMENT_STATUSES.MILITARY',
  value: 'Military'
}, {
  translationKey: 'FINANCING.EMPLOYMENT_INFOS.EMPLOYMENT_STATUSES.RETIRED',
  value: 'Retired'
}, {
  translationKey: 'FINANCING.EMPLOYMENT_INFOS.EMPLOYMENT_STATUSES.EMPLOYED',
  value: 'Employed'
}, {
  translationKey: 'FINANCING.EMPLOYMENT_INFOS.EMPLOYMENT_STATUSES.SELF_EMPLOYED',
  value: 'Self Employed'
}, {
  translationKey: 'FINANCING.EMPLOYMENT_INFOS.EMPLOYMENT_STATUSES.STUDENT',
  value: 'Full-time Student'
}, {
  translationKey: 'FINANCING.EMPLOYMENT_INFOS.EMPLOYMENT_STATUSES.OTHER',
  value: 'Other'
}];
const EMPLOYMENT_STATUS_DEALER_TRACK = exports.EMPLOYMENT_STATUS_DEALER_TRACK = [{
  translationKey: 'FINANCING.EMPLOYMENT_INFOS.EMPLOYMENT_STATUSES.FULL_TIME',
  value: 'FT'
}, {
  translationKey: 'FINANCING.EMPLOYMENT_INFOS.EMPLOYMENT_STATUSES.FULL_TIME_PROBATION',
  value: 'FTP'
}, {
  translationKey: 'FINANCING.EMPLOYMENT_INFOS.EMPLOYMENT_STATUSES.PART_TIME_CASUAL',
  value: 'PTC'
}, {
  translationKey: 'FINANCING.EMPLOYMENT_INFOS.EMPLOYMENT_STATUSES.PART_TIME_REGULAR',
  value: 'PTR'
}, {
  translationKey: 'FINANCING.EMPLOYMENT_INFOS.EMPLOYMENT_STATUSES.SEASONAL_SUMMER',
  value: 'SEAS'
}, {
  translationKey: 'FINANCING.EMPLOYMENT_INFOS.EMPLOYMENT_STATUSES.SEASONAL_WINTER',
  value: 'SEAW'
}];
const EMPLOYMENT_TYPE = exports.EMPLOYMENT_TYPE = [{
  translationKey: 'FINANCING.EMPLOYMENT_INFOS.EMPLOYMENT_TYPES.AT_HOME',
  value: 'At home'
}, {
  translationKey: 'FINANCING.EMPLOYMENT_INFOS.EMPLOYMENT_TYPES.EXECUTIVE',
  value: 'Executive'
}, {
  translationKey: 'FINANCING.EMPLOYMENT_INFOS.EMPLOYMENT_TYPES.LABOURER',
  value: 'Labourer'
}, {
  translationKey: 'FINANCING.EMPLOYMENT_INFOS.EMPLOYMENT_TYPES.MANAGEMENT',
  value: 'Management'
}, {
  translationKey: 'FINANCING.EMPLOYMENT_INFOS.EMPLOYMENT_TYPES.OFFICE_STAFF',
  value: 'Office Staff'
}, {
  translationKey: 'FINANCING.EMPLOYMENT_INFOS.EMPLOYMENT_TYPES.PRODUCTION',
  value: 'Production'
}, {
  translationKey: 'FINANCING.EMPLOYMENT_INFOS.EMPLOYMENT_TYPES.PROFESSIONAL',
  value: 'Professional'
}, {
  translationKey: 'FINANCING.EMPLOYMENT_INFOS.EMPLOYMENT_TYPES.RETIRED',
  value: 'Retired'
}, {
  translationKey: 'FINANCING.EMPLOYMENT_INFOS.EMPLOYMENT_TYPES.SALES',
  value: 'Sales'
}, {
  translationKey: 'FINANCING.EMPLOYMENT_INFOS.EMPLOYMENT_TYPES.SELF_EMPLOYED',
  value: 'Self-Employed'
}, {
  translationKey: 'FINANCING.EMPLOYMENT_INFOS.EMPLOYMENT_TYPES.SERVICE',
  value: 'Service'
}, {
  translationKey: 'FINANCING.EMPLOYMENT_INFOS.EMPLOYMENT_TYPES.STUDENT',
  value: 'Student'
}, {
  translationKey: 'FINANCING.EMPLOYMENT_INFOS.EMPLOYMENT_TYPES.TRADES',
  value: 'Trades'
}, {
  translationKey: 'FINANCING.EMPLOYMENT_INFOS.EMPLOYMENT_TYPES.UNEMPLOYED',
  value: 'Unemployed'
}, {
  translationKey: 'FINANCING.EMPLOYMENT_INFOS.EMPLOYMENT_TYPES.OTHER',
  value: 'Other'
}];
const FINANCING_FREQUENCIES = exports.FINANCING_FREQUENCIES = [{
  translationKey: 'FINANCING.EMPLOYMENT_INFOS.INCOME_FREQUENCIES.YEARLY',
  value: '1'
}, {
  translationKey: 'FINANCING.EMPLOYMENT_INFOS.INCOME_FREQUENCIES.MONTHLY',
  value: '12'
}, {
  translationKey: 'FINANCING.EMPLOYMENT_INFOS.INCOME_FREQUENCIES.WEEKLY',
  value: '52'
}];
// Minimum of months to not ask for a previous employment
const MIN_EMPLOYMENT_PERIOD = exports.MIN_EMPLOYMENT_PERIOD = 24;
var _default = exports.default = {
  EMPLOYMENT_STATUS,
  EMPLOYMENT_STATUS_DEALER_TRACK,
  EMPLOYMENT_TYPE,
  FINANCING_FREQUENCIES,
  MIN_EMPLOYMENT_PERIOD
};