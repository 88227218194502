"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.showInvalidResponseModal = exports.isFetchingFNIItems = exports.invalidResponseTitle = exports.invalidResponseMessages = exports.getValidationFNIItemPlan = exports.getTabWarrantyContent = exports.getTabProtectionWarranty = exports.getTabProtectionProtection = exports.getTabProtectionLGM = exports.getTabProtectionInsurance = exports.getTabProtectionContent = exports.getTabProtectionBMW = exports.getTabProtection = exports.getTabLGMContent = exports.getTabInsuranceContent = exports.getTabBMWContent = exports.getSelectedTab = exports.getSelectedIndex = exports.getSelectedFNIItemPlans = exports.getRequestBookFNIItemsBody = exports.getPlanProtectionCoverageParameters = exports.getPlanInsuranceCoverageParameters = exports.getLGMItemsErrorMessage = exports.getLGMItemsError = exports.getFNITab = exports.getFNIItemsToBook = exports.getFNIItems = exports.getFNIItemPlan = exports.getFNIItem = exports.getBMWItemsErrorMessage = exports.getBMWItemsError = exports.areLGMItemsLoaded = exports.areBMWItemsLoaded = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _tabProtection = require("../../constants/tabProtection");
var _selectors = require("../selectors");
var _tabItems = require("./tabItems");
const isNil = val => val == null;

/**
 * @param {Object} state
 * @returns {Boolean}
 */
const isFetchingFNIItems = state => state.isFetchingFNIItems;

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.isFetchingFNIItems = isFetchingFNIItems;
const getLGMItemsError = state => state.lgmItemsError;

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getLGMItemsError = getLGMItemsError;
const getLGMItemsErrorMessage = state => state.lgmItemsErrorMessage;

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getLGMItemsErrorMessage = getLGMItemsErrorMessage;
const areLGMItemsLoaded = state => state.lgmItemsAreLoaded;

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.areLGMItemsLoaded = areLGMItemsLoaded;
const getBMWItemsError = state => state.bmwItemsError;

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getBMWItemsError = getBMWItemsError;
const getBMWItemsErrorMessage = state => state.bmwItemsErrorMessage;

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getBMWItemsErrorMessage = getBMWItemsErrorMessage;
const areBMWItemsLoaded = state => state.bmwItemsAreLoaded;

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.areBMWItemsLoaded = areBMWItemsLoaded;
const showInvalidResponseModal = state => state.showInvalidResponseModal;

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.showInvalidResponseModal = showInvalidResponseModal;
const invalidResponseMessages = state => state.invalidResponseMessages;

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.invalidResponseMessages = invalidResponseMessages;
const invalidResponseTitle = state => state.invalidResponseTitle;

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.invalidResponseTitle = invalidResponseTitle;
const getTabProtection = state => {
  const {
    tabProtection
  } = state;
  return (0, _commonsUtils.isPlainObject)(tabProtection) ? tabProtection : {};
};

/**
 * @param {Object} state
 * @returns {Number}
 */
exports.getTabProtection = getTabProtection;
const getSelectedIndex = state => {
  const {
    selectedIndex
  } = getTabProtection(state);
  return Number.isInteger(selectedIndex) ? selectedIndex : 0;
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getSelectedIndex = getSelectedIndex;
const getTabProtectionProtection = state => {
  const {
    tabProtectionProtection
  } = state;
  return (0, _commonsUtils.isPlainObject)(tabProtectionProtection) ? tabProtectionProtection : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getTabProtectionProtection = getTabProtectionProtection;
const getTabProtectionWarranty = state => {
  const {
    tabProtectionWarranty
  } = state;
  return (0, _commonsUtils.isPlainObject)(tabProtectionWarranty) ? tabProtectionWarranty : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getTabProtectionWarranty = getTabProtectionWarranty;
const getTabProtectionInsurance = state => {
  const {
    tabProtectionInsurance
  } = state;
  return (0, _commonsUtils.isPlainObject)(tabProtectionInsurance) ? tabProtectionInsurance : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getTabProtectionInsurance = getTabProtectionInsurance;
const getTabProtectionLGM = state => {
  const {
    meta,
    tabProtectionLGM
  } = state;
  const {
    fniProviders
  } = meta;
  const lgmProvider = fniProviders.find(fniProvider => fniProvider.providerId === 'FNI.LGM');
  return (0, _commonsUtils.isPlainObject)(tabProtectionLGM) ? {
    ...tabProtectionLGM,
    isAvailable: !isNil(lgmProvider) && lgmProvider.isAvailable,
    providerName: !isNil(lgmProvider) ? lgmProvider.providerName : '',
    uiIndex: !isNil(lgmProvider) && lgmProvider.isAvailable ? 3 : 4
  } : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getTabProtectionLGM = getTabProtectionLGM;
const getTabProtectionBMW = state => {
  const {
    meta,
    tabProtectionBMW
  } = state;
  const {
    fniProviders
  } = meta;
  const lgmProvider = fniProviders.find(fniProvider => fniProvider.providerId === 'FNI.LGM');
  const bmwProvider = fniProviders.find(fniProvider => fniProvider.providerId === 'FNI.BMW');

  // TODO: Change backend meta so that provider name is 'SI/SI+'
  return (0, _commonsUtils.isPlainObject)(tabProtectionBMW) ? {
    ...tabProtectionBMW,
    isAvailable: !isNil(bmwProvider) && bmwProvider.isAvailable,
    providerName: !isNil(bmwProvider) ? 'SI/SI+' : '',
    uiIndex: !isNil(lgmProvider) && lgmProvider.isAvailable ? 4 : 3
  } : {};
};

/**
 * Prepare data for UI
 *
 * @param {String} contentType
 * @param {Object} item
 * @returns {Object}
 */
exports.getTabProtectionBMW = getTabProtectionBMW;
const formatFNIItemData = (contentType, item) => {
  const {
    code,
    dataTypeId,
    description,
    supplier,
    typeId
  } = item;
  const formattedItem = structuredClone(item);
  formattedItem.plans = formattedItem.plans.map(plan => {
    plan.description = (0, _tabItems.ucFirst)(plan.description);
    return plan;
  }).sort((a, b) => a.rank - b.rank);
  return {
    ...formattedItem,
    description: (0, _tabItems.ucFirst)(description),
    payload: {
      contentType,
      code,
      dataTypeId,
      supplier,
      typeId
    }
  };
};

/**
 * @param {Object} state
 * @param {Object} items
 * @returns {Object[]}
 */
const formatFNIItems = (state, items) => {
  const formattedItems = {};
  if (Array.isArray(items.protections)) {
    formattedItems.protections = items.protections.sort((a, b) => a.rank - b.rank).map(item => formatFNIItemData('protections', item)).flat();
  }
  if (Array.isArray(items.warranties)) {
    formattedItems.warranties = items.warranties.sort((a, b) => a.rank - b.rank).map(item => formatFNIItemData('warranties', item)).flat();
  }
  if (Array.isArray(items.insurances)) {
    formattedItems.insurances = items.insurances.sort((a, b) => a.rank - b.rank).map(item => formatFNIItemData('insurances', item)).flat();
  }
  return formattedItems;
};

/**
 * @param {Object} state
 * @param {Function} selector
 * @returns {Object}
 */
const getTabContentFromFNI = (state, selector) => {
  const {
    content
  } = selector.call(null, state);
  return (0, _commonsUtils.isPlainObject)(content) && Object.values(content).every(value => Array.isArray(value)) ? formatFNIItems(state, content) : {};
};

/**
 * @param {Object} state
 * @param {String} fniType
 * @returns {Object}
 */
const getFNITab = (state, fniType) => {
  let fniTab = {};
  if (fniType === _tabProtection.TAB_PROTECTION.LGM) {
    fniTab = getTabProtectionLGM(state);
  } else if (fniType === _tabProtection.TAB_PROTECTION.BMW) {
    fniTab = getTabProtectionBMW(state);
  }
  return fniTab;
};

/**
 * @param {Object} state
 * @param {String} fniType
 * @param {String} contentType
 * @returns {Object}
 */
exports.getFNITab = getFNITab;
const getFNIItems = (state, fniType, contentType) => {
  const fniTab = getFNITab(state, fniType);
  if (!(0, _commonsUtils.isEmpty)(fniTab) && fniTab.content[contentType]) {
    return fniTab.content[contentType];
  }
  return [];
};

/**
 * @param {Object} state
 * @param {string} fniType
 * @param {string} contentType
 * @param {string} persistentId
 * @returns {Object}
 */
exports.getFNIItems = getFNIItems;
const getFNIItem = (state, fniType, contentType, persistentId) => {
  const items = getFNIItems(state, fniType, contentType);
  const item = items.find(currentItem => currentItem.persistentId === persistentId);
  return item ?? {};
};

/**
 * @param {Object} state
 * @param {string} fniType
 * @param {string} contentType
 * @param {string} persistentId
 * @param {string} planPersistentId
 * @returns {Object}
 */
exports.getFNIItem = getFNIItem;
const getFNIItemPlan = (state, fniType, contentType, persistentId, planPersistentId) => {
  const item = getFNIItem(state, fniType, contentType, persistentId);
  let plan;
  if (!(0, _commonsUtils.isEmpty)(item)) {
    plan = item.plans.find(currentPlan => currentPlan.persistentId === planPersistentId);
  }
  return plan ?? {};
};

/**
 * @param {Object} state
 * @param {string} fniType
 * @param {string} contentType
 * @param {string} persistentId
 * @returns {Object}
 */
exports.getFNIItemPlan = getFNIItemPlan;
const getSelectedFNIItemPlans = (state, fniType, contentType, persistentId) => {
  const item = getFNIItem(state, fniType, contentType, persistentId);
  let plans = [];
  if (!(0, _commonsUtils.isEmpty)(item)) {
    plans = item.plans.filter(currentPlan => currentPlan.isSelected);
  }
  return plans;
};

/**
 * @param {Object} state
 * @param {string} fniType
 * @param {string} contentType
 * @param {string} persistentId
 * @param {string} planPersistentId
 * @param {Object} selectedProtectionCoverageParameters
 * @returns {Object}
 */
exports.getSelectedFNIItemPlans = getSelectedFNIItemPlans;
const getPlanProtectionCoverageParameters = (state, fniType, contentType, persistentId, planPersistentId, selectedProtectionCoverageParameters) => {
  const plan = getFNIItemPlan(state, fniType, contentType, persistentId, planPersistentId);
  if ((0, _commonsUtils.isEmpty)(plan)) {
    return {
      terms: [],
      kms: [],
      deductibleAmounts: [],
      maximumCoverageAmount: 0
    };
  }
  if (!(0, _commonsUtils.isEmpty)(selectedProtectionCoverageParameters)) {
    const {
      term,
      km,
      deductibleAmount
    } = selectedProtectionCoverageParameters;
    const protectionCoverageParameters = structuredClone(plan.protectionCoverageParameters);
    protectionCoverageParameters.terms = protectionCoverageParameters.terms.map(currentTerm => {
      currentTerm.isSelected = currentTerm.term === term;
      return currentTerm;
    });
    protectionCoverageParameters.kms = protectionCoverageParameters.kms.map(currentKm => {
      currentKm.isSelected = currentKm.km === km;
      return currentKm;
    });
    protectionCoverageParameters.deductibleAmounts = protectionCoverageParameters.deductibleAmounts.map(currentDeductibleAmount => {
      currentDeductibleAmount.isSelected = currentDeductibleAmount.amount === deductibleAmount;
      return currentDeductibleAmount;
    });
    return protectionCoverageParameters;
  }
  return plan.protectionCoverageParameters;
};

/**
 * @param {Object} state
 * @param {string} fniType
 * @param {string} contentType
 * @param {string} persistentId
 * @param {string} planPersistentId
 * @returns {Object}
 */
exports.getPlanProtectionCoverageParameters = getPlanProtectionCoverageParameters;
const getSelectedPlanProtectionCoverageParameters = (state, fniType, contentType, persistentId, planPersistentId) => {
  const plan = getFNIItemPlan(state, fniType, contentType, persistentId, planPersistentId);
  if ((0, _commonsUtils.isEmpty)(plan)) {
    return {
      term: 0,
      km: 0,
      deductibleAmount: 0,
      maximumCoverageAmount: 0
    };
  }
  const {
    terms,
    kms,
    deductibleAmounts,
    maximumCoverageAmount
  } = plan.protectionCoverageParameters;
  let term = terms.find(currentTerm => currentTerm.isSelected);
  term = term ? term.term : 0;
  let km = kms.find(currentKm => currentKm.isSelected);
  km = km ? km.km : 0;
  let deductibleAmount = deductibleAmounts.find(currentDeductibleAmount => currentDeductibleAmount.isSelected);
  deductibleAmount = deductibleAmount ? deductibleAmount.amount : 0;
  return {
    term,
    km,
    deductibleAmount,
    maximumCoverageAmount
  };
};

/**
 * @param {Object} state
 * @param {string} fniType
 * @param {string} contentType
 * @param {string} persistentId
 * @param {string} planPersistentId
 * @param {Object} selectedInsuranceCoverageParameters
 * @returns {Object}
 */
const getPlanInsuranceCoverageParameters = (state, fniType, contentType, persistentId, planPersistentId, selectedInsuranceCoverageParameters) => {
  const plan = getFNIItemPlan(state, fniType, contentType, persistentId, planPersistentId);
  if ((0, _commonsUtils.isEmpty)(plan)) {
    return {
      truncatedTerms: [],
      buyersCovered: [],
      coverageWaitingPeriod: []
    };
  }
  if (!(0, _commonsUtils.isEmpty)(selectedInsuranceCoverageParameters)) {
    const {
      truncatedTerm,
      buyersCoveredType,
      buyersCoveredThirdPartyType,
      coverageWaitingPeriodDays
    } = selectedInsuranceCoverageParameters;
    const insuranceCoverageParameters = structuredClone(plan.insuranceCoverageParameters);
    insuranceCoverageParameters.truncatedTerms = insuranceCoverageParameters.truncatedTerms.map(currentTerm => {
      currentTerm.isSelected = currentTerm.term === truncatedTerm;
      return currentTerm;
    });
    insuranceCoverageParameters.buyersCovered = insuranceCoverageParameters.buyersCovered.map(currentBuyerCovered => {
      currentBuyerCovered.isSelected = currentBuyerCovered.type === buyersCoveredType && currentBuyerCovered.thirdPartyType === buyersCoveredThirdPartyType;
      return currentBuyerCovered;
    });
    insuranceCoverageParameters.coverageWaitingPeriod = insuranceCoverageParameters.coverageWaitingPeriod.map(currentPeriod => {
      currentPeriod.isSelected = currentPeriod.days === coverageWaitingPeriodDays;
      return currentPeriod;
    });
    return insuranceCoverageParameters;
  }
  return plan.insuranceCoverageParameters;
};

/**
 * @param {Object} state
 * @param {string} fniType
 * @param {string} contentType
 * @param {string} persistentId
 * @param {string} planPersistentId
 * @returns {Object}
 */
exports.getPlanInsuranceCoverageParameters = getPlanInsuranceCoverageParameters;
const getSelectedPlanInsuranceCoverageParameters = (state, fniType, contentType, persistentId, planPersistentId) => {
  const plan = getFNIItemPlan(state, fniType, contentType, persistentId, planPersistentId);
  if ((0, _commonsUtils.isEmpty)(plan)) {
    return {
      buyersCoveredType: 'single',
      buyersCoveredThirdPartyType: '',
      coverageWaitingPeriodDays: 0,
      coverageisRetroactive: true,
      coverageThirdPartyType: '',
      truncatedTerm: 0
    };
  }
  const {
    truncatedTerms,
    buyersCovered,
    coverageWaitingPeriod
  } = plan.insuranceCoverageParameters;
  let truncatedTerm = truncatedTerms.find(currentTerm => currentTerm.isSelected);
  truncatedTerm = truncatedTerm ? truncatedTerm.term : 0;
  const buyerCovered = buyersCovered.find(currentBuyerCovered => currentBuyerCovered.isSelected);
  const buyersCoveredType = buyerCovered ? buyerCovered.type : 'single';
  const buyersCoveredThirdPartyType = buyerCovered ? buyerCovered.thirdPartyType : '';
  const selectedCoverageWaitingPeriod = coverageWaitingPeriod.find(currentPeriod => currentPeriod.isSelected);
  const coverageWaitingPeriodDays = selectedCoverageWaitingPeriod ? selectedCoverageWaitingPeriod.days : 0;
  const coverageisRetroactive = selectedCoverageWaitingPeriod ? selectedCoverageWaitingPeriod.isRetroactive : true;
  const coverageThirdPartyType = selectedCoverageWaitingPeriod ? selectedCoverageWaitingPeriod.thirdPartyType : '';
  return {
    truncatedTerm,
    buyersCoveredType,
    buyersCoveredThirdPartyType,
    coverageWaitingPeriodDays,
    coverageisRetroactive,
    coverageThirdPartyType
  };
};

/**
 * @param {Object} state
 * @param {string} fniType
 * @param {string} contentType
 * @param {string} persistentId
 * @param {string} planPersistentId
 * @param {Object} payload
 * @returns {Object[]}
 */
const getValidationFNIItemPlan = (state, fniType, contentType, persistentId, planPersistentId, payload) => {
  const item = getFNIItem(state, fniType, contentType, persistentId);
  let validateItem = {};
  if (!(0, _commonsUtils.isEmpty)(item)) {
    const plan = item.plans.find(currentPlan => currentPlan.persistentId === planPersistentId);
    if (plan) {
      validateItem = structuredClone(plan);
      if (contentType === 'warranties' || contentType === 'protections') {
        validateItem.protectionCoverageParameters = getSelectedPlanProtectionCoverageParameters(state, fniType, contentType, persistentId, planPersistentId);
      } else if (contentType === 'insurances') {
        validateItem.insuranceCoverageParameters = getSelectedPlanInsuranceCoverageParameters(state, fniType, contentType, persistentId, planPersistentId);
      }
      if (payload && payload.pricing) {
        validateItem.pricing = {
          ...payload.pricing
        };
      }
    }
  }
  return validateItem;
};

/**
 * @param {Object} state
 * @param {String} fniType
 * @returns {Object[]}
 */
exports.getValidationFNIItemPlan = getValidationFNIItemPlan;
const getFNIItemsToBook = (state, fniType) => {
  const protections = getFNIItems(state, fniType, 'protections');
  const warranties = getFNIItems(state, fniType, 'warranties');
  const insurances = getFNIItems(state, fniType, 'insurances');
  const itemsToBook = [];
  protections.forEach(protection => {
    const selectedPlans = getSelectedFNIItemPlans(state, fniType, 'protections', protection.persistentId);
    selectedPlans.forEach(selectedPlan => {
      if (!selectedPlan.isBooked) {
        itemsToBook.push({
          supplier: protection.supplier,
          ...selectedPlan,
          protectionCoverageParameters: getSelectedPlanProtectionCoverageParameters(state, fniType, 'protections', protection.persistentId, selectedPlan.persistentId)
        });
      }
    });
  });
  warranties.forEach(warranty => {
    const selectedPlans = getSelectedFNIItemPlans(state, fniType, 'warranties', warranty.persistentId);
    selectedPlans.forEach(selectedPlan => {
      if (!selectedPlan.isBooked) {
        itemsToBook.push({
          supplier: warranty.supplier,
          ...selectedPlan,
          protectionCoverageParameters: getSelectedPlanProtectionCoverageParameters(state, fniType, 'warranties', warranty.persistentId, selectedPlan.persistentId)
        });
      }
    });
  });
  insurances.forEach(insurance => {
    const selectedPlans = getSelectedFNIItemPlans(state, fniType, 'insurances', insurance.persistentId);
    selectedPlans.forEach(selectedPlan => {
      if (!selectedPlan.isBooked) {
        itemsToBook.push({
          supplier: insurance.supplier,
          ...selectedPlan,
          protectionCoverageParameters: getSelectedPlanInsuranceCoverageParameters(state, fniType, 'insurances', insurance.persistentId, selectedPlan.persistentId)
        });
      }
    });
  });
  return itemsToBook;
};

/**
 * @param {Object} state
 * @param {Object[]} items
 * @returns {Object}
 */
exports.getFNIItemsToBook = getFNIItemsToBook;
const getRequestBookFNIItemsBody = (state, items) => {
  const {
    cashPurchaseOption,
    financeOptions,
    ...paymentOptionsData
  } = (0, _selectors.getPaymentOptions)(state);
  return {
    ...paymentOptionsData,
    items
  };
};
exports.getRequestBookFNIItemsBody = getRequestBookFNIItemsBody;
const getTabProtectionContent = state => (0, _tabItems.getTabContent)(state, getTabProtectionProtection);
exports.getTabProtectionContent = getTabProtectionContent;
const getTabWarrantyContent = state => (0, _tabItems.getTabContent)(state, getTabProtectionWarranty);
exports.getTabWarrantyContent = getTabWarrantyContent;
const getTabInsuranceContent = state => (0, _tabItems.getTabContent)(state, getTabProtectionInsurance);
exports.getTabInsuranceContent = getTabInsuranceContent;
const getTabLGMContent = state => getTabContentFromFNI(state, getTabProtectionLGM);
exports.getTabLGMContent = getTabLGMContent;
const getTabBMWContent = state => getTabContentFromFNI(state, getTabProtectionBMW);

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getTabBMWContent = getTabBMWContent;
const getSelectedTab = state => {
  const selectedIndex = getSelectedIndex(state);
  const list = [getTabProtectionWarranty(state), getTabProtectionProtection(state), getTabProtectionInsurance(state), getTabProtectionLGM(state), getTabProtectionBMW(state)];
  const found = list.find(_ref => {
    let {
      uiIndex
    } = _ref;
    return uiIndex === selectedIndex;
  });
  return (0, _commonsUtils.isPlainObject)(found) ? found : {};
};
exports.getSelectedTab = getSelectedTab;