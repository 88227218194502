"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reference = exports.partyId = exports.otherIncome = exports.employerAddress = exports.employer = exports.default = exports.creditApplication = exports.coApplicantAddress = exports.coApplicant = exports.applicantAddress = void 0;
var _location = require("../../tools/constants/location");
var _financing = require("../../tools/constants/financing");
const applicantAddress = exports.applicantAddress = {
  addressLine: [],
  city: '',
  stateOrProvince: '',
  country: _location.CANADA,
  postalCode: '',
  periodOfResidence: [],
  residenceType: _location.RESIDENCE_TYPE_DEFAULT
};
const coApplicantAddress = exports.coApplicantAddress = {
  addressLine: [],
  city: '',
  stateOrProvince: '',
  country: _location.CANADA,
  postalCode: '',
  periodOfResidence: [],
  residenceType: _location.RESIDENCE_TYPE_DEFAULT,
  sameAsApplicant: false // Co-Applicant Only
};
const employerAddress = exports.employerAddress = {
  addressLine: [],
  city: '',
  stateOrProvince: '',
  country: _location.CANADA,
  postalCode: ''
};
const employer = exports.employer = {
  employerName: '',
  address: [structuredClone(employerAddress)],
  contact: {
    phoneNumber: [],
    income: 0,
    incomeCurrency: _financing.CANADIAN_CURRENCY,
    incomePeriod: '',
    occupation: '',
    periodOfEmployment: []
  }
};
const partyId = exports.partyId = {
  id: '',
  assigningPartyId: '',
  expiryDate: 0
};
const otherIncome = exports.otherIncome = {
  otherIncomeAmount: 0,
  ortherIncomeAmountCurrency: _financing.CANADIAN_CURRENCY,
  otherIncomeAmountPeriod: ''
};
const creditApplication = exports.creditApplication = {
  id: '',
  leadId: 0,
  type: 'individual',
  submissionType: 'new',
  applicants: [{
    type: 'individual',
    isMainApplicant: true,
    partyId: [structuredClone(partyId)],
    name: {
      givenName: '',
      middleName: '',
      familyName: ''
      // businessName: '', // Organization Only
      // isMainDriver: false // Organization Only
    },
    address: [structuredClone(applicantAddress)],
    contact: {
      phoneNumber: [],
      email: []
    },
    individual: {
      demographics: {
        birthDate: null,
        employmentStatus: ''
      },
      employer: [structuredClone(employer)],
      personalPayments: {
        paymentType: '',
        paymentAmount: 0,
        paymentCurrency: _financing.CANADIAN_CURRENCY,
        paymentPeriod: ''
      },
      otherIncome: [structuredClone(otherIncome)],
      disbursement: [{
        disbursementType: '',
        disbursementAmount: 0,
        disbursementAmountPeriod: '',
        disbursementCurrency: _financing.CANADIAN_CURRENCY
      }]
    },
    organization: {
      financialPosition: {
        totalAssetsAmount: 0,
        totalLiabilitiesAmount: 0,
        annualSalesAmount: 0,
        grossProfitAmount: 0,
        amountsCurrency: _financing.CANADIAN_CURRENCY
      }
    },
    privacy: {
      consentToCreditCheck: false,
      privacyInd: 'unanswered',
      marketingMailInd: 'unanswered'
    },
    reference: []
  }]
};

// Only for a primary applicant
const reference = exports.reference = {
  relationshipCode: '',
  name: {
    givenName: '',
    middleName: '',
    familyName: '',
    businessName: '',
    isMainDriver: false
  },
  contact: {
    phoneNumber: [],
    email: []
  }
};
const coApplicant = exports.coApplicant = {
  type: 'individual',
  isMainApplicant: false,
  coApplicantType: 'co-applicant',
  partyId: [structuredClone(partyId)],
  name: {
    givenName: '',
    middleName: '',
    familyName: ''
  },
  address: [structuredClone(coApplicantAddress)],
  contact: {
    phoneNumber: [],
    email: []
  },
  individual: {
    demographics: {
      birthDate: null,
      employmentStatus: '',
      relationshipCode: '' // Co-Applicant Only
    },
    employer: [{
      employerName: '',
      address: [structuredClone(employerAddress)],
      contact: {
        phoneNumber: [],
        income: 0,
        incomeCurrency: _financing.CANADIAN_CURRENCY,
        incomePeriod: '',
        occupation: '',
        periodOfEmployment: []
      }
    }],
    personalPayments: {
      paymentType: '',
      paymentAmount: 0,
      paymentCurrency: _financing.CANADIAN_CURRENCY,
      paymentPeriod: ''
    },
    otherIncome: [structuredClone(otherIncome)],
    disbursement: [{
      disbursementType: '',
      disbursementAmount: 0,
      disbursementAmountPeriod: '',
      disbursementCurrency: _financing.CANADIAN_CURRENCY
    }]
  },
  organization: {
    financialPosition: {
      totalAssetsAmount: 0,
      totalLiabilitiesAmount: 0,
      annualSalesAmount: 0,
      grossProfitAmount: 0,
      amountsCurrency: _financing.CANADIAN_CURRENCY
    }
  },
  privacy: {
    consentToCreditCheck: false,
    privacyInd: 'unanswered',
    marketingMailInd: 'unanswered'
  }
};
var _default = exports.default = creditApplication;