"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _deskingError = require("@sm360/desking-error");
var _actions = require("../../../../redux/actions");
var _selectors = require("../../../../redux/selectors");
var _finance = require("../../../../redux/selectors/finance");
var _SecurityDepositModal = _interopRequireDefault(require("./SecurityDepositModal"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} state - Global state
 * @returns {Object} injected props
 */
const mapStateToProps = state => {
  const msd = (0, _selectors.getMsd)(state);
  return {
    msd,
    securityDepositDetails: (0, _finance.getSecurityDepositDetails)(state)
  };
};
const mapDispatchToProps = dispatch => ({
  getMsdRoll: () => dispatch(_actions.Creators.getMsdRoll()),
  updateMsdRoll: options => dispatch(_actions.Creators.updateMsdRoll(options))
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps), _deskingError.withErrorWrapper)(_SecurityDepositModal.default);