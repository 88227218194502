"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _actions = require("../../../redux/actions");
var _StepLayoutNav = _interopRequireDefault(require("./StepLayoutNav"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Function} dispatch
 * @returns {Object}
 */
const mapDispatchToProps = dispatch => ({
  setCurrentStep: (step, lastVisitedStep) => dispatch(_actions.Creators.setCurrentStep(step, lastVisitedStep)),
  openInDesking: () => dispatch(_actions.Creators.openInDesking())
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(null, mapDispatchToProps))(_StepLayoutNav.default);