"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("react");
var _appsCore = require("@sm360/apps-core");
var _state = require("../config/state.manager");
var _common = require("../api/common.service");
var _utils = require("../tools/utils");
const useEmailNotifications = () => {
  const {
    state: {
      emails,
      new_emails: newNotification,
      last_emails_received: lastEmailReceived
    }
  } = (0, _react.useContext)(_state.CrudContext);
  const {
    state: {
      organization,
      user
    }
  } = (0, _react.useContext)(_appsCore.AppContext);

  // Filter only emails the user is allowed to see
  const filteredEmails = (0, _react.useMemo)(() => {
    if (emails.length > 0) {
      const restrictedList = emails.filter(email => {
        if (!email) return false;
        return email?.ownerId === user.id || email?.salesmanId === user.id || user.roles.includes('AccountManager') || user.roles.includes('Admin') || user.roles.includes('Consultant');
      });
      return restrictedList;
    } else return [];
  }, [emails, user.id, user.roles]);

  // Delete all Email notification with a given leadId for all the dealer group
  const deleteNotification = (0, _react.useCallback)((leadId, onSuccess) => {
    const activityIdsToDelete = (0, _utils.getNotificationIdsByLeadId)(filteredEmails, leadId);
    return Promise.allSettled(activityIdsToDelete.map(id => (0, _common.deleteEmail)({
      organization,
      id
    }))).then(results => {
      const successCount = results.filter(r => r.status === 'fulfilled').length;
      const failedCount = results.filter(r => r.status === 'rejected').length;
      if (typeof onSuccess === 'function') onSuccess({
        successCount,
        failedCount
      });
    });
  }, [filteredEmails, organization]);
  return {
    allEmails: filteredEmails,
    newNotification,
    lastEmailReceived,
    deleteNotification
  };
};
var _default = exports.default = useEmailNotifications;