"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateTradeIn = exports.getTradeIn = exports.evaluationRequest = exports.deleteTradeIn = exports.default = exports.createTradeIn = void 0;
var _appsCore = require("@sm360/apps-core");
const TRADE_IN_ENDPOINT = process.env.REACT_APP_TRADEIN_API_URL;
const createTradeIn = async tradeIn => {
  if (!tradeIn) {
    throw new Error('No trade-in to create.');
  }
  try {
    const tradeInResponse = await _appsCore.axios.post(`${TRADE_IN_ENDPOINT}/tradeIn`, tradeIn);
    const {
      data: insertedTradeIn
    } = tradeInResponse;
    return insertedTradeIn;
  } catch (error) {
    throw new Error(`Failed to create trade-in: ${JSON.stringify(tradeIn)}`);
  }
};
exports.createTradeIn = createTradeIn;
const updateTradeIn = async tradeIn => {
  if (!tradeIn) {
    throw new Error('No trade-in to update.');
  }
  try {
    const tradeInResponse = await _appsCore.axios.put(`${TRADE_IN_ENDPOINT}/tradeIn`, tradeIn);
    const {
      data: updatedTradeIn
    } = tradeInResponse;
    return updatedTradeIn;
  } catch (error) {
    throw new Error(`Failed to update trade-in: ${JSON.stringify(tradeIn)}`);
  }
};
exports.updateTradeIn = updateTradeIn;
const deleteTradeIn = async id => {
  try {
    await _appsCore.axios.delete(`${TRADE_IN_ENDPOINT}/tradeIn/${id}`);
    return true;
  } catch (error) {
    throw new Error(`Failed to delete trade-in ${id}`);
  }
};
exports.deleteTradeIn = deleteTradeIn;
const getTradeIn = async (leadId, orgId, orgUnitId) => {
  try {
    const tradeInResponse = await _appsCore.axios.get(`${TRADE_IN_ENDPOINT}/tradeIn?leadId=${leadId}&orgId=${orgId}&orgUnitId=${orgUnitId}`, {
      ignoreAppsCoreCatch: true
    });
    const {
      data: tradeIn
    } = tradeInResponse;
    return tradeIn;
  } catch (error) {
    throw new Error('Failed getting trade-in');
  }
};
exports.getTradeIn = getTradeIn;
const evaluationRequest = async (userId, leadId) => {
  const params = {
    userId,
    leadId
  };
  try {
    const response = await _appsCore.axios.post(`${TRADE_IN_ENDPOINT}/evaluationRequest`, params);
    return response ? response.data : null;
  } catch (error) {
    throw new Error('Failed requesting an evaluation');
  }
};
exports.evaluationRequest = evaluationRequest;
var _default = exports.default = {
  createTradeIn,
  updateTradeIn,
  deleteTradeIn,
  getTradeIn,
  evaluationRequest
};