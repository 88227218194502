"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getBody = void 0;
var _selectors = require("../selectors");
/**
 * @param {Object} state
 * @returns {Object}
 */
const getBody = (state, payload) => {
  const {
    cashPurchaseOption,
    financeOptions,
    ...paymentOptionsData
  } = (0, _selectors.getPaymentOptions)(state);
  return {
    ...paymentOptionsData,
    negativeEquityOffsetType: payload.selectedType,
    equityReimbursementAmount: payload.reimbursementAmount
  };
};
exports.getBody = getBody;