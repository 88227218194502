"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("react");
var _hooks = require("@sm360/hooks");
var _appsCore = require("@sm360/apps-core");
var _activities = require("../api/activities.service");
const useActivities = _ref => {
  let {
    leadId,
    contactId,
    leadOnly,
    typeFilters,
    enabled
  } = _ref;
  const [activities, setActivities] = (0, _react.useState)([]);
  const [loading, setLoading] = (0, _react.useState)(true);
  const fetchActivities = (0, _react.useCallback)(() => {
    if (!enabled) return;
    setLoading(true);
    (0, _activities.getActivities)(contactId, leadId, leadOnly).then(response => {
      const filteredActivities = response.data.results.filter(a => typeFilters?.length > 0 ? typeFilters.includes(a.activity.type) : true);
      setActivities(filteredActivities);
    }).catch(() => {}).finally(() => setLoading(false));
  }, [contactId, leadId, leadOnly, typeFilters, enabled]);
  const debounceFetchActivities = (0, _hooks.useDebounceCallback)(fetchActivities, 1000);
  (0, _react.useEffect)(() => {
    fetchActivities();
  }, [fetchActivities]);

  // Pusher channel 'contact_${contactId}' or 'lead_${leadId}'
  const channel = (0, _appsCore.useChannel)(contactId ? `contact_${contactId}` : leadId ? `lead_${leadId}` : '');
  (0, _appsCore.useEvent)(channel, 'refresh_activity', debounceFetchActivities);
  return [activities, loading];
};
var _default = exports.default = useActivities;