"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.stripOptOutLinks = exports.concatenateWithSpace = void 0;
const EN_OPT_IN_HTML = new RegExp(/<span>If you wish to stop receiving our emails<\/span>, <a href="http:\/\/klik\.sm360\.ca\/optout\/view.*<\/a>/g);
const FR_OPT_IN_HTML = new RegExp(/<span>Si vous ne souhaitez plus recevoir nos courriels<\/span>, <a href="http:\/\/klik\.sm360\.ca\/optout\/view.*<\/a>/g);
const stripOptOutLinks = str => {
  if (typeof str !== 'string') return '';
  return str.replace(EN_OPT_IN_HTML, '').replace(FR_OPT_IN_HTML, '');
};
exports.stripOptOutLinks = stripOptOutLinks;
const concatenateWithSpace = (str1, str2) => {
  return `${str1 || ''} ${str2 || ''}`.trim();
};
exports.concatenateWithSpace = concatenateWithSpace;