"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getShowPositiveEquityOffset = exports.getShowNegativeEquityOffset = exports.getPositiveEquityOffsets = exports.getPositiveEquityOffsetType = exports.getNegativeEquityOffsets = exports.getNegativeEquityOffsetType = exports.getEquityReimbursementAmount = exports.getEquity = void 0;
var _selectors = require("../selectors");
const EQUITY_TYPE_IGNORE = 'ignore';
/**
 * @param {Object} state
 * @returns {Boolean}
 */
const getShowNegativeEquityOffset = state => (0, _selectors.getPaymentOptions)(state).showNegativeEquityOffset;

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getShowNegativeEquityOffset = getShowNegativeEquityOffset;
const getShowPositiveEquityOffset = state => (0, _selectors.getPaymentOptions)(state).showPositiveEquityOffset;

/**
 * @param {Object} state
 * @returns {Number} equity value
 */
exports.getShowPositiveEquityOffset = getShowPositiveEquityOffset;
const getEquity = state => {
  const {
    tradeIn,
    lien
  } = (0, _selectors.getPaymentOptions)(state);
  return tradeIn - lien;
};

/**
 * @param {Object} state
 * @returns {Array}
 */
exports.getEquity = getEquity;
const getNegativeEquityOffsets = state => {
  const {
    negativeEquityOffsets
  } = (0, _selectors.getMetaData)(state);
  return Array.isArray(negativeEquityOffsets) ? negativeEquityOffsets : [];
};

/**
 * @param {Object} state
 * @returns {Array}
 */
exports.getNegativeEquityOffsets = getNegativeEquityOffsets;
const getPositiveEquityOffsets = state => {
  const {
    positiveEquityOffsets
  } = (0, _selectors.getMetaData)(state);
  return Array.isArray(positiveEquityOffsets) ? positiveEquityOffsets : [];
};

/**
 * @param {Object} state
 * @returns {String}
 */
exports.getPositiveEquityOffsets = getPositiveEquityOffsets;
const getNegativeEquityOffsetType = state => {
  const {
    negativeEquityOffsetType
  } = (0, _selectors.getPaymentOptions)(state);
  return negativeEquityOffsetType || EQUITY_TYPE_IGNORE;
};

/**
 * @param {Object} state
 * @returns {String}
 */
exports.getNegativeEquityOffsetType = getNegativeEquityOffsetType;
const getPositiveEquityOffsetType = state => {
  const {
    positiveEquityOffsetType
  } = (0, _selectors.getPaymentOptions)(state);
  return positiveEquityOffsetType || EQUITY_TYPE_IGNORE;
};
exports.getPositiveEquityOffsetType = getPositiveEquityOffsetType;
const getEquityReimbursementAmount = state => {
  const {
    equityReimbursementAmount
  } = (0, _selectors.getQuote)(state);
  return equityReimbursementAmount || 0;
};
exports.getEquityReimbursementAmount = getEquityReimbursementAmount;