"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getBody = void 0;
var _selectors = require("../selectors");
/**
 * @param {Object} state
 * @param {Number} calculationTargetValue
 * @returns {Object}
 */
const getBody = (state, item, transactionToken) => {
  const {
    saleType,
    taxPlan,
    vehicleCondition,
    odometer,
    purchaseMethod,
    financePlan,
    kmPerYearPlan,
    paymentFrequency,
    term,
    cashDown,
    tradeIn,
    lien
  } = (0, _selectors.getPaymentOptions)(state);
  return {
    item,
    transactionToken,
    saleType,
    taxPlan,
    vehicleCondition,
    odometer,
    purchaseMethod,
    financePlan,
    kmPerYearPlan,
    paymentFrequency,
    term,
    cashDown,
    tradeIn,
    lien
  };
};
exports.getBody = getBody;