"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _actions = require("../actions");
var _selectors = require("../selectors");
const {
  getDealerInfo
} = _commonsApi.DealerInfoService;
function* _default() {
  const config = yield (0, _effects.select)(_selectors.getConfig);
  const {
    organizationUnitId
  } = config;
  try {
    const dealerInfo = yield (0, _effects.call)(getDealerInfo, organizationUnitId);
    const location = dealerInfo.province1.provinceCode.toLowerCase();
    yield (0, _effects.put)(_actions.Creators.setLocation(location));
    return location;
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'getDealerInfo'));
    return false;
  }
}