"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsUtils = require("@sm360/commons-utils");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _selectors = require("../../selectors");
function* _default() {
  const {
    organizationId,
    organizationUnitId
  } = yield (0, _effects.select)(_selectors.getConfig);
  const leadId = yield (0, _effects.select)(_selectors.getLeadId);
  const TRADE_IN_NOT_FOUND = 'error.notfound.tradeIn';
  const leadParams = {
    leadId,
    orgId: organizationId,
    orgUnitId: organizationUnitId
  };
  try {
    return yield (0, _effects.call)(_commonsApi.TradeInService.getTradeIn, leadParams);
  } catch (error) {
    const parsedError = JSON.parse(error.message);
    if ((0, _commonsUtils.isPlainObject)(parsedError) && (0, _commonsUtils.isPlainObject)(parsedError.responseData) && parsedError.responseData.code !== TRADE_IN_NOT_FOUND) {
      yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'getTradeIn'));
    }
    return null;
  }
}