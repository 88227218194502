"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _actions = require("../../actions");
var _selectors = require("../../selectors");
function* getMsdRoll() {
  const quoteId = yield (0, _effects.select)(_selectors.getQuoteId);
  const queryParams = yield (0, _effects.select)(_selectors.getShowroomQueryParams);
  const paymentOptions = yield (0, _effects.select)(_selectors.getPaymentOptions);
  const {
    ...paymentOptionsData
  } = paymentOptions;
  const body = {
    ...paymentOptionsData
  };
  try {
    const response = yield (0, _effects.call)(_commonsApi.ShowroomService.desking.msdRoll, quoteId, queryParams, body);
    yield (0, _effects.put)(_actions.Creators.setMsd(response));
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'msdRoll'));
  }
}
var _default = exports.default = getMsdRoll;