"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _deskingError = require("@sm360/desking-error");
var _deskingCommons = require("@sm360/desking-commons");
var _actions = require("../../redux/actions");
var _selectors = require("../../redux/selectors");
var _VehicleSummary = _interopRequireDefault(require("./VehicleSummary"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const mapStateToProps = state => ({
  hasDeskingAccess: (0, _selectors.getDeskingAccess)(state),
  quote: (0, _selectors.getQuote)(state),
  salesPersonnel: (0, _selectors.getSalesPersonnel)(state),
  deskingWidgetParams: (0, _selectors.getDeskingWidgetParams)(state),
  upg: _deskingCommons.priceIncreaseSelectors.getPriceIncreaseRollCount(state)
});
const mapDispatchToProps = dispatch => ({
  setPreferredQuote: quote => dispatch(_actions.Creators.setPreferredQuote(quote)),
  setCustomerVisibility: isVisible => dispatch(_actions.Creators.setCustomerVisibility(isVisible)),
  getGarageAndQuote: () => dispatch(_actions.Creators.getGarageAndQuote()),
  assignLead: personnelType => dispatch(_actions.Creators.assignLead(personnelType))
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps), _deskingError.withErrorWrapper)(_VehicleSummary.default);