"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = () => ({
  validation: {
    isValid: true,
    mustResetSelection: false,
    negociationTransaction: {},
    photo: '',
    validationItem: {},
    validationMessage: '',
    validResolutionCombinations: []
  }
});
exports.default = _default;