import React from 'react';

const AppLogo = () => {
    return (
        <>
            <img src="/img/logo.svg" alt="LogoImage" className="brand-logo" />
            <img src="/img/logo.svg" alt="LogoImage" className="brand-logo--mobile" />
            <img src="/img/logo-single.svg" alt="LogoImage" className="brand-logo--collapsed" />

            <img src="/img/logo-crm.svg" alt="LogoImage" className="brand-logo brand-logo-crm" />
            <img src="/img/logo-crm.svg" alt="LogoImage" className="brand-logo--mobile brand-logo-crm" />
            <img src="/img/logo-crm-single.svg" alt="LogoImage" className="brand-logo--collapsed brand-logo-crm" />

            <img src="/img/logo-desking.svg" alt="LogoImage" className="brand-logo brand-logo-desking" />
            <img src="/img/logo-desking.svg" alt="LogoImage" className="brand-logo--mobile brand-logo-desking" />
            <img src="/img/logo-desking-single.svg" alt="LogoImage" className="brand-logo--collapsed brand-logo-desking" />

            <img src="/img/logo-phonetrack.svg" alt="LogoImage" className="brand-logo brand-logo-phonetrack" />
            <img src="/img/logo-phonetrack.svg" alt="LogoImage" className="brand-logo--mobile brand-logo-phonetrack" />
            <img src="/img/logo-phonetrack-single.svg" alt="LogoImage" className="brand-logo--collapsed brand-logo-phonetrack" />

            <img src="/img/logo-sms.svg" alt="LogoImage" className="brand-logo brand-logo-sms" />
            <img src="/img/logo-sms.svg" alt="LogoImage" className="brand-logo--mobile brand-logo-sms" />
            <img src="/img/logo-sms-single.svg" alt="LogoImage" className="brand-logo--collapsed brand-logo-sms" />
        </>
    );
};

export default AppLogo;
