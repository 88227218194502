"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.INCOME_PERIOD = void 0;
const INCOME_PERIOD = exports.INCOME_PERIOD = [{
  translationKey: 'FINANCING.INCOME_AND_PAYMENT_INFOS.INCOME_PERIOD.WEEKLY',
  value: 'Weekly'
}, {
  translationKey: 'FINANCING.INCOME_AND_PAYMENT_INFOS.INCOME_PERIOD.BI_WEEKLY',
  value: 'Bi-Weekly'
}, {
  translationKey: 'FINANCING.INCOME_AND_PAYMENT_INFOS.INCOME_PERIOD.MONTHLY',
  value: 'Monthly'
}, {
  translationKey: 'FINANCING.INCOME_AND_PAYMENT_INFOS.INCOME_PERIOD.YEARLY',
  value: 'Annually'
}, {
  translationKey: 'FINANCING.INCOME_AND_PAYMENT_INFOS.INCOME_PERIOD.SEMI_MONTHLY',
  value: 'Semi-Monthly'
}, {
  translationKey: 'FINANCING.INCOME_AND_PAYMENT_INFOS.INCOME_PERIOD.SEMI_ANNUALY',
  value: 'Semi-Annually'
}];
var _default = exports.default = {
  INCOME_PERIOD
};