"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _deskingError = require("@sm360/desking-error");
var _actions = require("../../redux/actions");
var _selectors = require("../../redux/selectors");
var _TradeInModal = _interopRequireDefault(require("./TradeInModal"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} state - Global state
 * @returns {Object} injected props
 */
const mapStateToProps = state => {
  const leadId = (0, _selectors.getLeadId)(state);
  const {
    organizationId,
    organizationUnitId
  } = (0, _selectors.getConfig)(state);
  return {
    leadId,
    organizationId,
    organizationUnitId
  };
};
const mapDispatchToProps = dispatch => ({
  reloadTradeIn: () => dispatch(_actions.Creators.reloadTradeIn())
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps), _deskingError.withErrorWrapper)(_TradeInModal.default);