"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _selectors = require("../../redux/selectors");
var _actions = require("../../redux/actions");
var _TransactionRow = _interopRequireDefault(require("./TransactionRow"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} state
 * @returns {Object}
 */
const mapStateToProps = state => {
  const hasSalesManagerRole = (0, _selectors.getHasSalesManagerRole)(state);
  const hasBusinessManagerRole = (0, _selectors.getHasBusinessManagerRole)(state);
  return {
    hasSalesManagerRole,
    hasBusinessManagerRole
  };
};
const mapDispatchToProps = dispatch => ({
  transactionAmountValidation: itemData => dispatch(_actions.Creators.transactionAmountValidation(itemData))
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps))(_TransactionRow.default);