"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/**
 * @param {Object} state
 * @param {Object} payload - { dealJacket }
 * @returns {Object}
 */
var _default = (state, _ref) => {
  let {
    dealJacket
  } = _ref;
  return {
    ...state,
    tabForms: {
      dealJacket,
      isPrinting: state?.tabForms?.isPrinting ?? false
    }
  };
};
exports.default = _default;