"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _selectors = require("../selectors");
const isNil = val => val == null;
const toNumberOrZero = number => Number(number) || 0;
const omitBy = (obj, check) => {
  obj = {
    ...obj
  };
  Object.entries(obj).forEach(_ref => {
    let [key, value] = _ref;
    return check(value) && delete obj[key];
  });
  return obj;
};
var _default = (state, payload) => {
  const {
    queryParams
  } = payload;
  const {
    accountId = null,
    bodyStyle,
    cashDown,
    contactId = null,
    includeTaxes,
    kmPerYearPlan,
    lang,
    leadId,
    lien,
    location = null,
    make,
    model,
    organizationId,
    organizationUnitId,
    paymentFrequency,
    purchaseMethod,
    saleType,
    taxPlan,
    term,
    tradeIn
  } = queryParams;

  // Force true, have to be exactly "false" in query param to disable it
  let hasTaxesIncluded = true;
  if (includeTaxes && includeTaxes.toLowerCase() === 'false') {
    hasTaxesIncluded = false;
  }
  return {
    ...state,
    config: {
      accountId: toNumberOrZero(accountId),
      bodyStyle,
      lang,
      leadId: toNumberOrZero(leadId),
      location,
      make,
      model,
      organizationId,
      organizationUnitId,
      contactId
    },
    plan: {
      ...(0, _selectors.getPlan)(state),
      ...omitBy({
        saleType,
        taxPlan
      }, isNil)
    },
    purchaseMethod: {
      ...(0, _selectors.getPurchaseMethod)(state),
      kmPerYearPlan: toNumberOrZero(kmPerYearPlan) || state.purchaseMethod.kmPerYearPlan,
      paymentFrequency: toNumberOrZero(paymentFrequency) || state.purchaseMethod.paymentFrequency,
      term: toNumberOrZero(term) || state.purchaseMethod.term,
      cashDown: toNumberOrZero(cashDown),
      ...omitBy({
        purchaseMethod
      }, isNil)
    },
    tradeIn: {
      ...(0, _selectors.getTradeIn)(state),
      tradeIn: toNumberOrZero(tradeIn),
      lien: toNumberOrZero(lien)
    },
    hasTaxesIncluded
  };
};
exports.default = _default;