"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _deskingError = require("@sm360/desking-error");
var _selectors = require("../../redux/selectors");
var _content = require("../../redux/selectors/content");
var _actions = require("../../redux/actions");
var _StepLayout = _interopRequireDefault(require("./StepLayout"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const mapStateToProps = state => {
  const {
    currentStep,
    isNextAvailable
  } = (0, _selectors.getNav)(state);
  return {
    currentStep,
    isNextAvailable: isNextAvailable === true,
    configurations: (0, _content.getConfigurations)(state),
    isHidingPrices: (0, _selectors.getIsHidingPrices)(state),
    currentModel: (0, _content.getModelName)(state),
    currentMake: (0, _content.getMakeName)(state),
    currentYear: (0, _content.getYear)(state)
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  // dispatch the action if found year in URL
  if (ownProps.yearFromUrl) dispatch(_actions.Creators.selectModelYear(ownProps.yearFromUrl));
  return {
    setCurrentStep: (step, lastVisitedStep) => dispatch(_actions.Creators.setCurrentStep(step, lastVisitedStep))
  };
};
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps), _deskingError.withErrorWrapper)(_StepLayout.default);