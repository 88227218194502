"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateSelectedOptions = exports.extractFilterData = exports.default = exports.computeFieldOptions = exports.clearCurrentOptions = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _commonsApi = require("@sm360/commons-api");
var _filters = require("../../constants/filters");
var _filters2 = require("../selectors/filters");
const {
  KEYS: FILTER_KEYS
} = _commonsApi.FilterService;

/**
 * Find the field name in the response, filter and sort
 *
 * @param {Object[]} response
 * @param {String} filterKey - name of the field
 * @returns {Object[]}
 */
const extractFilterData = (response, filterKey) => {
  const found = response.find(_ref => {
    let {
      name
    } = _ref;
    return name === filterKey;
  });
  if (!found || !Array.isArray(found.filterResults)) {
    return [];
  }
  const data = [];
  found.filterResults.forEach(_ref2 => {
    let {
      id,
      label,
      total
    } = _ref2;
    if (total > 0 && !(0, _commonsUtils.isEmpty)(label)) {
      data.push({
        // Put the label as id, if no id (example for years)
        id: id || label,
        label,
        total
      });
    }
  });
  return (0, _commonsUtils.sortBy)(data, 'label');
};

/**
 * We want to keep the current options, but if a total=0 is not selected anymore, we want it out
 *
 * @param {Object[]} currentOptions
 * @param {Number[]} currentSelected - Coming from selector, assuring the Array type
 * @returns {Object[]}
 */
exports.extractFilterData = extractFilterData;
const clearCurrentOptions = (currentOptions, currentSelected) => {
  return currentOptions.filter(_ref3 => {
    let {
      id,
      total
    } = _ref3;
    return total !== 0 || currentSelected.includes(id);
  });
};

/**
 * Update the total of selected options, inside a field which was not the last updated
 *
 * @param {Object[]} currentOptions
 * @param {Number[]} currentSelected
 * @param {Object[]} responseOptions
 * @returns {Object[]}
 */
exports.clearCurrentOptions = clearCurrentOptions;
const updateSelectedOptions = (currentOptions, currentSelected, responseOptions) => {
  let found;
  let total;
  let options;
  if (currentOptions.length > 0) {
    options = currentOptions
    // Remove not selected options
    .filter(_ref4 => {
      let {
        id
      } = _ref4;
      return currentSelected.includes(id);
    })
    // Update the total inside the current selected options
    .map(_ref5 => {
      let {
        id,
        label
      } = _ref5;
      found = responseOptions.find(opt => opt.id === id);
      if (found && Number.isInteger(found.total)) {
        ({
          total
        } = found);
      } else {
        total = 0;
      }
      return {
        id,
        label,
        total
      };
    });
  } else {
    options = responseOptions;
  }
  return options;
};

/**
 * For every dropdown fields, have to compute the options differently from multiple use cases
 *
 * @param {Object} state - current state
 * @param {Object} response - Data from API
 * @param {String} lastUpdatedName - Name of the field the user just updated
 * @param {String} internalKey - Key of the field in the store
 * @param {String} filterKey - Key of the field in the API response
 * @return {Object[]} Computed options for the field
 */
exports.updateSelectedOptions = updateSelectedOptions;
const computeFieldOptions = (state, response, lastUpdatedName, internalKey, filterKey) => {
  const currentOptions = (0, _filters2.getOptionsItem)(state, internalKey);
  const currentSelected = (0, _filters2.getSelectedItem)(state, internalKey);
  const responseOptions = extractFilterData(response, filterKey);
  let options;

  // If already something selected
  if (currentSelected.length > 0) {
    // If last changed, we keep the current choices, but remove total=0 if not selected
    if (lastUpdatedName === internalKey) {
      options = clearCurrentOptions(currentOptions, currentSelected);
    } else {
      // If not last changed, we update the choices (remove unused, and update totals)
      options = updateSelectedOptions(currentOptions, currentSelected, responseOptions);
    }
  } else {
    // Refresh all options if no selected data
    options = responseOptions;
  }
  return options;
};

/**
 * The response is always an array, checked in libs/filter-service
 *
 * @param {Object} state
 * @param {Object} payload - { lastUpdatedName, response }
 * @returns {Object}
 */
exports.computeFieldOptions = computeFieldOptions;
var _default = (state, _ref6) => {
  let {
    lastUpdatedName,
    response
  } = _ref6;
  const filters = (0, _filters2.getFilters)(state);
  const options = Object.keys(_filters.KEYS).reduce((result, key) => {
    result[_filters.KEYS[key]] = computeFieldOptions(state, response, lastUpdatedName, _filters.KEYS[key], FILTER_KEYS[key]);
    return result;
  }, {});
  return {
    ...state,
    filters: {
      ...filters,
      options
    }
  };
};
exports.default = _default;