"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _deskingError = require("@sm360/desking-error");
var _actions = require("../../actions");
var _tabProtection = require("../../selectors/tabProtection");
var _reloadVehicle = _interopRequireDefault(require("../reloadVehicle"));
var _selectors = require("../../selectors");
var _updatePayOptAndTransDetails = _interopRequireDefault(require("../api/updatePayOptAndTransDetails"));
var _validateFNIItem = _interopRequireDefault(require("./validateFNIItem"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {String} fniType
 * @param {String} contentType
 * @param {String} persistentId
 * @param {String} planPersistentId
 * @param {Boolean} isSelected
 * @returns {Object}
 */
function _default(_ref) {
  let {
    fniType,
    contentType,
    persistentId,
    planPersistentId,
    isSelected
  } = _ref;
  return function* () {
    yield (0, _effects.put)(_actions.Creators.setIsFetchingFNIItems());
    const item = yield (0, _effects.select)(_tabProtection.getValidationFNIItemPlan, fniType, contentType, persistentId, planPersistentId);
    item.isSelected = isSelected;
    try {
      const response = yield (0, _effects.call)(_validateFNIItem.default, item);
      if (response) {
        if (response.isValid) {
          const paymentOptions = yield (0, _effects.select)(_selectors.getPaymentOptions);
          yield (0, _effects.call)(_updatePayOptAndTransDetails.default, {
            ...paymentOptions,
            transactionToken: response.negociationTransaction.transactionToken,
            callCommission: true
          });
          yield (0, _effects.call)(_reloadVehicle.default);
          const validationItem = {
            isSelected,
            ...response.negociationTransaction
          };
          yield (0, _effects.put)(_actions.Creators.updateFNIItemPlanSuccess(fniType, contentType, persistentId, planPersistentId, validationItem));
        } else {
          yield (0, _effects.put)(_actions.Creators.openInvalidResponseModal('', [response.validationMessage]));
        }
      }
      yield (0, _effects.put)(_actions.Creators.setIsFetchingFNIItemsDone());
    } catch (error) {
      yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'validateFNIItem'));
      yield (0, _effects.put)(_actions.Creators.setIsFetchingFNIItemsDone());
    }
  }();
}