"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatCurrencyNumber = void 0;
const formatCurrencyNumber = function (number) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  let lang = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'en';
  if (number === undefined) return null;
  return Intl.NumberFormat(lang, options).format(String(number));
};
exports.formatCurrencyNumber = formatCurrencyNumber;