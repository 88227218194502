"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getBody = void 0;
var _selectors = require("../selectors");
var _vehicle = require("./vehicle");
var _status = require("./status");
/**
 * @param {Object} state
 * @returns {Object}
 */
const getBody = state => {
  const {
    accountId,
    location,
    organizationId,
    organizationUnitId
  } = (0, _selectors.getConfig)(state);
  const {
    quoteStatus: currentQuoteStatus,
    vehicleStatus: currentVehicleStatus
  } = (0, _status.getStatusDetails)(state);
  const body = {
    currentQuoteStatus,
    currentVehicleStatus,
    leadId: (0, _selectors.getLeadId)(state),
    location,
    organizationId,
    organizationUnitId,
    quoteId: (0, _selectors.getQuoteId)(state),
    quoteRevision: (0, _selectors.getQuoteRevision)(state)
  };
  const {
    id
  } = (0, _vehicle.getVehicle)(state);
  const vehicleId = parseInt(id, 10);
  if (!Number.isNaN(vehicleId) && vehicleId > 0) {
    body.vehicleId = vehicleId;
  }
  if (accountId && accountId > 0) {
    body.accountId = accountId;
  }
  return body;
};
exports.getBody = getBody;