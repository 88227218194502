"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _reactRedux = require("react-redux");
var _commonsApi = require("@sm360/commons-api");
var _payment = require("../../../../constants/payment");
var _actions = require("../../../../redux/actions");
var _selectors = require("../../../../redux/selectors");
var _InputTradeIn = _interopRequireDefault(require("./InputTradeIn"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} state - Global state
 * @returns {Object} injected props
 */
const mapStateToProps = state => {
  const tradeIn = (0, _selectors.getTradeIn)(state);
  const {
    cashPurchase,
    finance
  } = (0, _selectors.getQuote)(state);
  const {
    tradeInTaxCreditsDetails
  } = cashPurchase || finance || {};
  const isReadOnly = (0, _selectors.getIsReadOnly)(state);
  const isPaymentTypeLease = tradeIn.paymentType === _commonsApi.TradeInService.PAYMENT_TYPE.LEASE;
  return {
    isReadOnly,
    isPaymentTypeLease,
    tradeInTaxCreditsDetails
  };
};

/**
 * @param {Function} dispatch
 * @returns {Object} injected props
 */
const mapDispatchToProps = dispatch => ({
  changeTradeIn: value => dispatch(_actions.Creators.changePaymentOptions({
    [_payment.KEY_TRADEIN]: value
  }))
});
var _default = exports.default = (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_InputTradeIn.default);