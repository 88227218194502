"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPathParams = exports.getBody = void 0;
var _selectors = require("../selectors");
/**
 * @param {Object} state
 * @returns {Object}
 */
const getBody = state => (0, _selectors.getBaseBodyParams)(state);

/**
 * @param {Object} state
 * @returns {Array}
 */
exports.getBody = getBody;
const getPathParams = state => (0, _selectors.getBasePathParams)(state);
exports.getPathParams = getPathParams;