"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.prefixOptions = exports.prefixNewColor = exports.prefixNewCloth = exports.prefixNew = exports.prefixInventory = exports.getVehiclePictures = exports.getVehicleMainPictureUrl = exports.getVehicleMainPictureFromVIC = void 0;
var _commonsUtils = require("@sm360/commons-utils");
const IMAGE_SERVER_URL = process.env.REACT_APP_IMAGESERVER_URL;

/**
 * Add image server URL prefix
 *
 * @param {String} path
 * @returns {String}
 */
const prefixPicture = path => `${IMAGE_SERVER_URL}/${path}`;

/**
 * @param {String} path
 * @returns {String}
 */
const prefixInventory = path => prefixPicture(`images/inventory${path}`);

/**
 * Prefix with resizing, width 960, height 450, crop
 *
 * @param {String} path
 * @returns {String}
 */
exports.prefixInventory = prefixInventory;
const prefixNew = path => prefixPicture(`ir/w960h450c/images/newcar/${path}`);

/**
 * @param {String} path
 * @returns {String}
 */
exports.prefixNew = prefixNew;
const prefixNewCloth = path => prefixNew(`colors/clothes/${path}`);

/**
 * @param {String} path
 * @returns {String}
 */
exports.prefixNewCloth = prefixNewCloth;
const prefixNewColor = path => prefixNew(`colors/${path}`);

/**
 * @param {String} path
 * @returns {String}
 */
exports.prefixNewColor = prefixNewColor;
const prefixOptions = path => prefixPicture(`images/showroom360/${path}`);

/**
 * Used in vehicle summary card, data coming from quote-microservice (VIC)
 *
 * @param {Object} vehicle
 * @returns {Object}
 */
exports.prefixOptions = prefixOptions;
const getVehicleMainPictureFromVIC = vehicle => {
  if (vehicle.exteriorColourPhotos && vehicle.exteriorColourPhotos.front) {
    return {
      isLineUp: true,
      url: prefixNew(vehicle.exteriorColourPhotos.front)
    };
  }
  if (vehicle.inventoryMainPictureUrl) {
    return {
      isLineUp: false,
      url: prefixInventory(vehicle.inventoryMainPictureUrl)
    };
  }
  return {};
};

/**
 * Used when coming from vehicle MS (libs/vehicle-service)
 *
 * @param {Object} vehicle
 * @returns {Object}
 */
exports.getVehicleMainPictureFromVIC = getVehicleMainPictureFromVIC;
const getVehicleMainPictureUrl = vehicle => {
  const lineUpImagePath = vehicle?.multimedia?.exterior?.side ?? null;
  const inventoryImagePath = vehicle?.multimedia?.mainPicture ?? null;
  const lineUpImageUrl = lineUpImagePath ? prefixNew(lineUpImagePath) : null;
  const inventoryImageUrl = inventoryImagePath ? prefixInventory(inventoryImagePath) : null;
  if (lineUpImageUrl) {
    return {
      url: lineUpImageUrl,
      isLineUp: true
    };
  }
  return {
    url: inventoryImageUrl,
    isLineUp: false
  };
};

/**
 * @param {String} str
 * @returns {Boolean}
 */
exports.getVehicleMainPictureUrl = getVehicleMainPictureUrl;
const isNotEmptyString = str => typeof str === 'string' && str.length > 0;

/**
 * @param {Object} vehicle
 * @returns {String[]}
 */
const getVehiclePictures = vehicle => {
  const {
    gallery
  } = vehicle;
  if (Array.isArray(gallery)) {
    return gallery.map(picture => prefixInventory(picture));
  }
  const list = [];
  const {
    exteriorColourPhotos
  } = vehicle;
  if (!(0, _commonsUtils.isPlainObject)(exteriorColourPhotos)) {
    return list;
  }
  const {
    front,
    rear,
    side
  } = exteriorColourPhotos;
  [front, rear, side].forEach(picture => {
    if (isNotEmptyString(picture)) {
      list.push(prefixNew(picture));
    }
  });
  return list;
};
exports.getVehiclePictures = getVehiclePictures;