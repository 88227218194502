"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VEHICLE_YEAR = exports.VEHICLE_TRIM = exports.VEHICLE_MODEL = exports.VEHICLE_MAKE = exports.VEHICLE_CATEGORY_USED = exports.VEHICLE_CATEGORY_NEW = exports.VEHICLE_CATEGORY_CATALOG = exports.SELLING_PRICE = exports.ODOMETER = void 0;
const VEHICLE_CATEGORY_USED = exports.VEHICLE_CATEGORY_USED = 'used';
const VEHICLE_CATEGORY_NEW = exports.VEHICLE_CATEGORY_NEW = 'new';
const VEHICLE_CATEGORY_CATALOG = exports.VEHICLE_CATEGORY_CATALOG = 'catalog';
const VEHICLE_MAKE = exports.VEHICLE_MAKE = 'make';
const VEHICLE_MODEL = exports.VEHICLE_MODEL = 'model';
const VEHICLE_TRIM = exports.VEHICLE_TRIM = 'trim';
const VEHICLE_YEAR = exports.VEHICLE_YEAR = 'year';
const ODOMETER = exports.ODOMETER = 'odometer';
const SELLING_PRICE = exports.SELLING_PRICE = 'sellingPrice';