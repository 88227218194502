"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("react");
const useRjsfFormValidation = initialData => {
  const [callback, setCallback] = (0, _react.useState)(null);
  const [data, setData] = (0, _react.useState)(initialData || {});
  const errorsRef = (0, _react.useRef)({});
  (0, _react.useEffect)(() => {
    if (callback) {
      setCallback(null);
      if (Object.keys(errorsRef.current).length === 0) {
        callback(data);
      }
    }
  }, [callback, data]);

  // need it to be stable as it will be passed via formContext object
  // and any new validation error will cause whole form rerendering
  const handleError = (0, _react.useCallback)(error => {
    if (error.errors.length) {
      errorsRef.current[error.name] = error.errors;
    } else {
      delete errorsRef.current[error.name];
    }
  }, [errorsRef]);
  const handleSubmit = (event, nextAction) => {
    event.preventDefault();
    event.persist();

    // Trigger each element to validate
    // There is probably a better way of doing it
    [...event.target.elements].forEach(element => {
      element.focus();
    });
    setCallback(() => {
      return () => nextAction(data);
    });
  };
  return [{
    handleError,
    handleSubmit
  }, data, setData, errorsRef.current, Object.keys(errorsRef.current).length === 0];
};
var _default = exports.default = useRjsfFormValidation;