"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getVehicle = exports.getSimilarMatches = exports.getPerfectMacthes = exports.getPaymentDetails = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _selectors = require("../selectors");
var _paymentOptions = require("./paymentOptions");
var _content = require("./content");
/**
 * @param {Object} state
 * @returns {Object}
 */
const getPaymentDetails = state => {
  const {
    purchaseMethod,
    kmPerYearPlan,
    paymentFrequency,
    cashDown
  } = (0, _selectors.getPurchaseMethod)(state);
  const {
    tradeIn
  } = (0, _selectors.getTradeIn)(state);
  const isCashSelected = (0, _paymentOptions.getIsCashSelected)(state);
  const {
    cashPurchase,
    finance
  } = (0, _paymentOptions.getPaymentOptions)(state);
  let exclusionsDisclaimer;
  let incentives;
  let inclusionsDisclaimer;
  let rebate;
  let sellingPrice;
  let term = {};
  if (isCashSelected && (0, _commonsUtils.isPlainObject)(cashPurchase)) {
    ({
      feesDisclaimer: inclusionsDisclaimer,
      incentives,
      taxesDisclaimer: exclusionsDisclaimer,
      sellingPrice
    } = cashPurchase);
  } else if ((0, _commonsUtils.isPlainObject)(finance)) {
    ({
      feesDisclaimer: inclusionsDisclaimer,
      incentives,
      taxesDisclaimer: exclusionsDisclaimer,
      term
    } = finance);
  }
  if ((0, _commonsUtils.isPlainObject)(incentives)) {
    ({
      total: rebate
    } = incentives);
  }
  return {
    cashDown,
    exclusionsDisclaimer,
    inclusionsDisclaimer,
    kmPerYearPlan,
    paymentFrequency,
    purchaseMethod,
    rebate,
    sellingPrice,
    term,
    tradeIn
  };
};

/**
 * @param {Object} model
 * @returns {Object}
 */
exports.getPaymentDetails = getPaymentDetails;
const getDrivePowerTrain = model => {
  const {
    drivePowerTrains
  } = model;
  if (!Array.isArray(drivePowerTrains)) {
    return {};
  }
  const found = drivePowerTrains.find(_ref => {
    let {
      isSelected
    } = _ref;
    return isSelected === true;
  });
  return found || {};
};

/**
 * @param {Object} model
 * @returns {Object}
 */
const getExteriorColourPhotos = model => {
  const {
    exteriorColourPhotos
  } = model;
  if (!Array.isArray(exteriorColourPhotos)) {
    return {};
  }
  const found = exteriorColourPhotos.find(_ref2 => {
    let {
      isSelected
    } = _ref2;
    return isSelected === true;
  });
  return found || {};
};

/**
 * @param {Object} model
 * @return {Object}
 */
const getModelTrim = model => {
  const {
    modelTrims
  } = model;
  if (!Array.isArray(modelTrims)) {
    return {};
  }
  const found = modelTrims.find(_ref3 => {
    let {
      isSelected
    } = _ref3;
    return isSelected === true;
  });
  return found || {};
};

/**
 * @param {Object} modelTrim
 * @returns {String[]}
 */
const getFeatures = modelTrim => {
  const {
    addOnFeatures
  } = modelTrim;
  if (!Array.isArray(addOnFeatures)) {
    return [];
  }
  return addOnFeatures.map(_ref4 => {
    let {
      description
    } = _ref4;
    return description;
  });
};

/**
 * @param {Object} state
 * @returns {Object}
 */
const getVehicle = state => {
  const model = (0, _content.getModel)(state);
  const modelTrim = getModelTrim(model);
  const baseProps = (0, _selectors.getBaseProps)(state);
  const {
    driveTrain,
    engine,
    transmission
  } = getDrivePowerTrain(model);
  const {
    front,
    rear,
    side
  } = getExteriorColourPhotos(model);
  return {
    bodyStyle: model.bodyStyle,
    driveTrain,
    engine,
    exteriorColourPhotos: {
      front,
      rear,
      side
    },
    features: getFeatures(modelTrim),
    make: model.make,
    model: model.model,
    modelTrim: {
      name: modelTrim.name
    },
    modelTrimCode: baseProps.modelTrimCode,
    transmission,
    year: model.year
  };
};

/**
 * @param {Object} state
 * @returns {Object[]}
 */
exports.getVehicle = getVehicle;
const getPerfectMacthes = state => {
  const {
    perfectMatches
  } = (0, _content.getContent)(state);
  return Array.isArray(perfectMatches) ? perfectMatches : [];
};

/**
 * @param {Object} state
 * @returns {Object[]}
 */
exports.getPerfectMacthes = getPerfectMacthes;
const getSimilarMatches = state => {
  const {
    similarMatches
  } = (0, _content.getContent)(state);
  return Array.isArray(similarMatches) ? similarMatches : [];
};
exports.getSimilarMatches = getSimilarMatches;