"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.optOutContact = exports.getContactSubscriptions = void 0;
var _appsCore = require("@sm360/apps-core");
const NEW_CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;

// get subscriptions
const getContactSubscriptions = async id => {
  return await _appsCore.axios.get(`${NEW_CRM_ENDPOINT}/contacts/${id}/subscriptions`);
};
exports.getContactSubscriptions = getContactSubscriptions;
const optOutContact = async _ref => {
  let {
    contactId,
    subscriptionId,
    body
  } = _ref;
  return _appsCore.axios.post(`${NEW_CRM_ENDPOINT}/contacts/${contactId}/unsubscribe?subscriptionId=${subscriptionId}`, body);
};
exports.optOutContact = optOutContact;