"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _selectors = require("../../selectors");
var _dealer = require("../../selectors/dealer");
var _updatePayOptAndTransDetails = _interopRequireDefault(require("./updatePayOptAndTransDetails"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} payload - Data from action
 */
function* transactionAmountValidation(payload) {
  const quoteId = yield (0, _effects.select)(_selectors.getQuoteId);
  const queryParams = yield (0, _effects.select)(_selectors.getShowroomQueryParams);
  const {
    itemData
  } = payload;
  const quote = yield (0, _effects.select)(_selectors.getQuote);
  const paymentOptions = yield (0, _effects.select)(_selectors.getPaymentOptions);
  const saleType = yield (0, _effects.select)(_dealer.getSaleType);
  const body = {
    item: {
      dataTypeId: itemData.dataTypeId,
      typeId: itemData.typeId,
      supplier: itemData.supplier,
      code: itemData.code,
      isDueAtDelivery: itemData.isDueAtDelivery || false,
      isOwnedByBusinessManager: itemData.isOwnedByBusinessManager,
      isOutsideTransaction: itemData.isOutsideTransaction || false,
      amount: itemData.newAmount,
      amountAtDelivery: itemData.newAmountAtDelivery,
      amountOutsideTransaction: itemData.newAmountOutsideTransaction,
      cost: itemData.newCost,
      profit: itemData.newProfit,
      description: itemData.description,
      displayCode: itemData.displayCode
    },
    transactionToken: paymentOptions.transactionToken,
    saleType,
    taxPlan: quote.taxPlan,
    vehicleCondition: quote.vehicleCondition,
    purchaseMethod: paymentOptions.purchaseMethod,
    financePlan: paymentOptions.financePlan,
    kmPerYearPlan: paymentOptions.kmPerYearPlan,
    paymentFrequency: paymentOptions.paymentFrequency,
    term: paymentOptions.term,
    cashDown: paymentOptions.cashDown,
    tradeIn: paymentOptions.tradeIn,
    lien: paymentOptions.lien
  };
  const callCommission = itemData.callCommission === true;
  try {
    const response = yield (0, _effects.call)(_commonsApi.ShowroomService.desking.validateTransactionAmount, quoteId, queryParams, body);
    yield (0, _effects.call)(_updatePayOptAndTransDetails.default, {
      ...paymentOptions,
      transactionToken: response.negociationTransaction.transactionToken,
      callCommission
    });
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'updateQuote'));
  }
}
var _default = exports.default = transactionAmountValidation;