"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _reactRedux = require("react-redux");
var _commonsUtils = require("@sm360/commons-utils");
var _stepTrim = require("../../redux/selectors/stepTrim");
var _TrimFeatures = _interopRequireDefault(require("./TrimFeatures"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} state
 * @returns {Object}
 */
const mapStateToProps = state => {
  const modelTrim = (0, _stepTrim.getModelTrim)(state);
  const {
    addOnFeatures: features,
    addOnModelTrim,
    name: trimName
  } = modelTrim;
  return {
    addOnTrimName: (0, _commonsUtils.isEmpty)(addOnModelTrim) ? null : addOnModelTrim,
    features,
    trimName
  };
};

/**
 * @param {Function} dispatch
 * @returns {Object}
 */
const mapDispatchToProps = () => ({});
var _default = exports.default = (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_TrimFeatures.default);