"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _effects = require("redux-saga/effects");
var _payment = require("../../constants/payment");
var _selectors = require("../selectors");
var _updatePayOptAndTransDetails = _interopRequireDefault(require("./api/updatePayOptAndTransDetails"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function* selectCashPlan() {
  const paymentOptions = yield (0, _effects.select)(_selectors.getPaymentOptions);
  const financeOptions = yield (0, _effects.select)(_selectors.getFinanceOptions);

  // Deep copy
  const transformedFinanceOptions = structuredClone(financeOptions);

  // Update isSelected values
  transformedFinanceOptions.forEach(fo => {
    fo.isSelected = false;
  });
  yield (0, _effects.call)(_updatePayOptAndTransDetails.default, {
    ...paymentOptions,
    financePlan: null,
    purchaseMethod: _payment.PURCHASE_METHODS.CASH,
    financeOptions: transformedFinanceOptions
  });
}
var _default = exports.default = selectCashPlan;