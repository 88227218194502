"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _reactRedux = require("react-redux");
var _actions = require("../../redux/actions");
var _selectors = require("../../redux/selectors");
var _PaymentCard = _interopRequireDefault(require("./PaymentCard"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const mapStateToProps = state => ({
  hasTaxesIncluded: (0, _selectors.getHasTaxesIncluded)(state),
  isReadOnly: (0, _selectors.getIsReadOnly)(state)
});
const mapDispatchToProps = dispatch => ({
  changePaymentCard: (options, index) => dispatch(_actions.Creators.changePaymentCard(options, index)),
  changePaymentCardCashDown: (cashDown, cashDownIndex, isCashDownSelected, financeOptionsIndex) => dispatch(_actions.Creators.changePaymentCardCashDown(cashDown, cashDownIndex, isCashDownSelected, financeOptionsIndex)),
  changePaymentCardSelected: options => dispatch(_actions.Creators.changePaymentCardSelected(options)),
  changePlan: (financePlan, purchaseMethod, isSelected, index) => dispatch(_actions.Creators.changePlan(financePlan, purchaseMethod, isSelected, index)),
  selectPayment: (cashDown, term, index) => dispatch(_actions.Creators.selectPayment(cashDown, term, index))
});
var _default = exports.default = (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_PaymentCard.default);