"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateVehicleDetail = exports.updateLeadUser = exports.updateLeadType = exports.updateLeadStatus = exports.updateLeadSalesman = exports.updateLeadSalesManager = exports.updateLeadOrganizationUnit = exports.updateLeadBusinessManager = exports.updateLead = exports.sendLeadToMerlin = exports.getVehicleDetail = exports.getUserSignaturesByUserId = exports.getTrackingTypes = exports.getThirdpartyPushBySourcesAndLeadId = exports.getSubscriptionContexts = exports.getMailTemplatesByOrganizationUnitId = exports.getListByLeadIds = exports.getList = exports.getLead = exports.getContacts = exports.getContact = exports.findContactLeads = exports.createLead = void 0;
var _appsCore = require("@sm360/apps-core");
var _commonsUtils = require("@sm360/commons-utils");
var _contact = require("../helpers/contact");
var _payload = require("../config/payload.transform");
const CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;

// Get leads list
const getList = async _ref => {
  let {
    body,
    pageSize,
    pageIndex,
    request = '',
    sortBy,
    salesManagerIdCount = 0
  } = _ref;
  const {
    leadsDateRange,
    leadSourceType,
    userId,
    salesManagerIds,
    salespersonIds,
    ...restOfFilters
  } = body.filters;
  const newQuery = {
    filters: {
      ...restOfFilters,
      contactIds: [],
      leadSourceIds: leadSourceType,
      ownerIds: userId || [],
      salesManagerIds: salesManagerIdCount === salesManagerIds.length || salesManagerIdCount === 0 ? [] : salesManagerIds,
      salespersonIds: salespersonIds || [],
      searchTerms: request ? (0, _commonsUtils.sanitizeSearch)(request).split(' ') : []
    },
    meta: {
      from: pageIndex,
      size: pageSize,
      sort: sortBy
    }
  };
  if (leadsDateRange?.min && leadsDateRange?.max) {
    newQuery.filters.leadsDateRange = {
      min: leadsDateRange.min,
      max: leadsDateRange.max
    };
  } else {
    delete newQuery.filters.leadsDateRange;
  }
  return await _appsCore.axios.post(`${CRM_ENDPOINT}/leads/search`, newQuery);
};
exports.getList = getList;
const getListByLeadIds = async data => {
  const {
    body,
    pageSize,
    pageIndex,
    request,
    sortBy
  } = data;
  const {
    displayArchived,
    leadIds,
    organizationId,
    organizationUnitIds,
    leadsDateRange,
    ownerIds,
    salespersonIds,
    salesManagerIds,
    leadTypeIds,
    leadStatusIds,
    leadSourceIds,
    displayWithQuote
  } = body;
  const newQuery = {
    filters: {
      displayArchived,
      leadIds,
      organizationId,
      organizationUnitIds,
      searchTerms: request ? (0, _commonsUtils.sanitizeSearch)(request).split(' ') : [],
      // additional filters
      ownerIds: ownerIds || [],
      salespersonIds: salespersonIds || [],
      salesManagerIds: salesManagerIds || [],
      leadTypeIds,
      leadStatusIds,
      leadSourceIds,
      displayWithQuote
    },
    meta: {
      from: pageIndex,
      size: pageSize,
      sort: sortBy
    }
  };
  if (leadsDateRange?.min && leadsDateRange?.max) {
    newQuery.filters.leadsDateRange = {
      min: leadsDateRange.min,
      max: leadsDateRange.max
    };
  } else {
    delete newQuery.filters.leadsDateRange;
  }
  return await _appsCore.axios.post(`${CRM_ENDPOINT}/leads/findByIds`, newQuery);
};

// get a lead only the logged in user is allowed to see it
// returns HTTP 400 if not
exports.getListByLeadIds = getListByLeadIds;
const getLead = id => {
  return _appsCore.axios.get(`${CRM_ENDPOINT}/leads/${id}`).then(results => {
    if (!results.data) return Promise.reject({
      response: {
        status: 404
      }
    });
    return (0, _payload.transformLeadFromBackend)(results.data);
  });
};

// create a new lead
exports.getLead = getLead;
const createLead = async _ref2 => {
  let {
    body,
    orgId
  } = _ref2;
  let contact;
  const {
    leadSourceType,
    leadStatusIds,
    leadTypeIds,
    salespersonIds,
    salesManagerIds
  } = body;

  // if no contact is present in body, this will create one
  if (!body.contact) {
    contact = (0, _contact.formatContact)(body);
  } else {
    contact = body.contact;
  }

  // cleans body of fields that should be in contact object
  const cleanedBody = (0, _contact.removeFields)(Object.assign({}, body));
  const requestBody = {
    ...cleanedBody,
    contact,
    organizationId: orgId,
    salesManagerId: salesManagerIds,
    salesmanUserId: salespersonIds,
    leadSourceType: {
      id: parseInt(leadSourceType)
    },
    leadType: {
      id: parseInt(leadTypeIds)
    },
    leadStatus: {
      id: parseInt(leadStatusIds)
    },
    active: true
  };
  return await _appsCore.axios.post(`${CRM_ENDPOINT}/leads`, requestBody);
};

// update lead
exports.createLead = createLead;
const updateLead = async _ref3 => {
  let {
    body,
    userId
  } = _ref3;
  const requestBody = (0, _payload.transformLeadToBackend)(body);
  await _appsCore.axios.put(`${CRM_ENDPOINT}/leads/${body.id}${userId ? `?loggedUserId=${userId}` : ''}`, requestBody);
  return getLead(body.id);
};
exports.updateLead = updateLead;
const getContacts = async _ref4 => {
  let {
    queryParams,
    body
  } = _ref4;
  const queryString = Object.keys(queryParams).map(key => `${key}=${queryParams[key]}`).join('&');
  return await _appsCore.axios.post(`${CRM_ENDPOINT}/contacts/search/disambiguated?${queryString}`, body, {
    ignoreAppsCoreCatch: true
  });
};
exports.getContacts = getContacts;
const findContactLeads = async contactId => {
  return await _appsCore.axios.get(`${CRM_ENDPOINT}/leads/findByContactId?contactId=${contactId}`);
};
exports.findContactLeads = findContactLeads;
const getContact = async id => {
  return await _appsCore.axios.get(`${CRM_ENDPOINT}/contacts/${id}`);
};

// Get subscriptions contexts by organization id
exports.getContact = getContact;
const getSubscriptionContexts = async id => {
  return await _appsCore.axios.get(`${CRM_ENDPOINT}/subscriptionContexts?organizationId=${id}`);
};

// Get a list of mail templates by organization unit id
exports.getSubscriptionContexts = getSubscriptionContexts;
const getMailTemplatesByOrganizationUnitId = async unitsQuery => {
  return await _appsCore.axios.get(`${CRM_ENDPOINT}/search/mailTemplates?${unitsQuery}`);
};

// Get list of user signatures by user id
exports.getMailTemplatesByOrganizationUnitId = getMailTemplatesByOrganizationUnitId;
const getUserSignaturesByUserId = async id => {
  return await _appsCore.axios.get(`${CRM_ENDPOINT}/users/signatures?userId=${id}`);
};
exports.getUserSignaturesByUserId = getUserSignaturesByUserId;
const getVehicleDetail = async id => {
  return await _appsCore.axios.get(`${CRM_ENDPOINT}/clientVehicles/${id}`);
};
exports.getVehicleDetail = getVehicleDetail;
const updateVehicleDetail = async (id, body) => {
  return await _appsCore.axios.put(`${CRM_ENDPOINT}/clientVehicles/${id}`, body);
};
exports.updateVehicleDetail = updateVehicleDetail;
const updateLeadType = async (leadId, newTypeId, userId, desc) => {
  const encodedDescription = desc ? `&description=${encodeURIComponent(desc)}` : '';
  return await _appsCore.axios.put(`${CRM_ENDPOINT}/leads/${leadId}/leadType?newLeadTypeId=${newTypeId}&loggedUserId=${userId}${encodedDescription}`);
};
exports.updateLeadType = updateLeadType;
const updateLeadStatus = async (leadId, newStatusId, userId, desc) => {
  const encodedDescription = desc ? `&description=${encodeURIComponent(desc)}` : '';
  return await _appsCore.axios.put(`${CRM_ENDPOINT}/leads/${leadId}/leadStatus?leadStatusId=${newStatusId}&loggedUserId=${userId}${encodedDescription}`);
};
exports.updateLeadStatus = updateLeadStatus;
const updateLeadOrganizationUnit = async (leadId, organizationUnitId, userId) => {
  return await _appsCore.axios.put(`${CRM_ENDPOINT}/leads/${leadId}/assignOrganizationUnit?organizationUnitId=${organizationUnitId}&userId=${userId}`);
};
exports.updateLeadOrganizationUnit = updateLeadOrganizationUnit;
const updateLeadBusinessManager = async (leadId, newBusinessManagerId, userId) => {
  return await _appsCore.axios.put(`${CRM_ENDPOINT}/leads/${leadId}/assignBusinessManager?newBusinessManagerId=${newBusinessManagerId}&userId=${userId}`);
};
exports.updateLeadBusinessManager = updateLeadBusinessManager;
const updateLeadSalesManager = async (leadId, newSalesManagerId, userId) => {
  return await _appsCore.axios.put(`${CRM_ENDPOINT}/leads/${leadId}/assignSalesManager?newSalesManagerId=${newSalesManagerId}&userId=${userId}`);
};
exports.updateLeadSalesManager = updateLeadSalesManager;
const updateLeadSalesman = async (leadId, newSalesmanUserId, userId) => {
  return await _appsCore.axios.put(`${CRM_ENDPOINT}/leads/${leadId}/assignSalesman?newSalesmanUserId=${newSalesmanUserId}&userId=${userId}`);
};
exports.updateLeadSalesman = updateLeadSalesman;
const updateLeadUser = async (leadId, newUserId, userId, orgId) => {
  return await _appsCore.axios.put(`${CRM_ENDPOINT}/leads/${leadId}/assignUser?newUserId=${newUserId}&userId=${userId}&currentOrganizationUnitId=${orgId}`);
};
exports.updateLeadUser = updateLeadUser;
const getTrackingTypes = async () => {
  return await _appsCore.axios.get(`${CRM_ENDPOINT}/leads/static/trackingTypes`);
};
exports.getTrackingTypes = getTrackingTypes;
const getThirdpartyPushBySourcesAndLeadId = async (leadId, thirdpartySources) => {
  const thirdPartySourceQuery = thirdpartySources ? thirdpartySources.map(s => `&thirdpartySources=${s}`) : '';
  return await _appsCore.axios.get(`${CRM_ENDPOINT}/thirdparty/push?leadId=${leadId}${thirdPartySourceQuery}`, {
    ignoreAppsCoreCatch: true
  });
};
exports.getThirdpartyPushBySourcesAndLeadId = getThirdpartyPushBySourcesAndLeadId;
const sendLeadToMerlin = async lead => {
  return await _appsCore.axios.post(`${CRM_ENDPOINT}/leads/thirdparty/publish`, (0, _payload.transformLeadToBackend)(lead));
};
exports.sendLeadToMerlin = sendLeadToMerlin;