"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.KEYS = void 0;
const KEYS = exports.KEYS = {
  BODY: 'frameStyleId',
  DRIVE: 'driveId',
  ENGINE: 'engineId',
  EXTERNAL_COLOR: 'externalColorId',
  FUEL: 'fuelId',
  INTERNAL_COLOR: 'internalColorId',
  MAKE: 'makeId',
  MODEL: 'modelId',
  TEXT: 'text',
  TRIM: 'trimId',
  TRANSMISSION: 'transmissionId',
  YEAR: 'vehicleYear',
  MIN_PRICE: 'minPrice',
  MAX_PRICE: 'maxPrice',
  ODOMETER: 'odometer',
  MIN_ODOMETER: 'minOdometer',
  MAX_ODOMETER: 'maxOdometer'
};