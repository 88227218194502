"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _deskingCommons = require("@sm360/desking-commons");
var _paymentOptions = require("../selectors/paymentOptions");
var _common = require("../selectors/common");
function* _default() {
  const paymentOptions = yield (0, _effects.select)(_paymentOptions.getPaymentOptions);
  const includeTaxes = yield (0, _effects.select)(_common.getHasTaxesIncluded);
  (0, _deskingCommons.triggerSavePaymentBarOptions)({
    ...paymentOptions,
    includeTaxes
  });
}