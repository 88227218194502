"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _uiLibrary = require("@sm360/ui-library");
var _selectors = require("../selectors");
const {
  toast
} = _uiLibrary.Toast;
function* _default() {
  const t = yield (0, _effects.getContext)('t');
  const {
    organizationUnitId,
    organizationId,
    location,
    lang
  } = yield (0, _effects.select)(_selectors.getConfig);
  const quoteId = yield (0, _effects.select)(_selectors.getQuoteId);
  const queryParams = {
    orgId: organizationId,
    orgUnitId: organizationUnitId,
    location,
    lang
  };
  try {
    const response = yield (0, _effects.call)(_commonsApi.ShowroomService.desking.sendQuoteToSerti, quoteId, queryParams);
    if (response.id) {
      toast.success(t('deskingDesking.financing.creditApplication.sendToSertiSuccess'));
    } else if (response.errMsg) {
      toast.error(response.errMsg);
    }
    return response;
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'sendToSerti'));
    return null;
  }
}