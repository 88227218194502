"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _reactRedux = require("react-redux");
var _selectors = require("../../redux/selectors");
var _QuoteLayout = _interopRequireDefault(require("./QuoteLayout"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const mapStateToProps = state => ({
  quote: (0, _selectors.getQuote)(state)
});
var _default = exports.default = (0, _reactRedux.connect)(mapStateToProps)(_QuoteLayout.default);