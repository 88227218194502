"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _actions = require("../../actions");
function* _default() {
  try {
    const canCreateUnlisted = yield (0, _effects.call)(_commonsApi.AuthorizationService.canCreateUnlistedVehicle);
    yield (0, _effects.put)(_actions.Creators.setCanCreateUnlistedVehicle(canCreateUnlisted));
    return canCreateUnlisted;
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'canCreateUnlistedVehicle'));
    return false;
  }
}