"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _selectors = require("../selectors");
/**
 * @param {Object} state
 * @param {Object} payload - { orgRights }
 * @returns {Object}
 */
var _default = (state, _ref) => {
  let {
    orgRights
  } = _ref;
  const organizationRights = orgRights.orgRights;
  const newOrgRights = {};
  organizationRights.forEach(element => {
    newOrgRights[element] = true;
  });
  return {
    ...state,
    orgRights: {
      ...(0, _selectors.getOrgRights)(state),
      ...newOrgRights
    }
  };
};
exports.default = _default;