"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useDealers = void 0;
var _appsCore = require("@sm360/apps-core");
var _DealerInfoService = require("../../api/DealerInfoService");
var _utils = require("./utils");
/**
 * Returns a list of dealers of a dealer group
 * @param {number} organizationId
 * @returns {Object} a Query object
 */
const useDealers = params => {
  const {
    organizationId,
    select,
    ...rest
  } = params || {};
  const enabled = organizationId > 0;
  const queryKey = ['dealers', {
    organizationId
  }];
  const queryFn = () => (0, _DealerInfoService.getDealers)(organizationId);
  return (0, _appsCore.useQuery)({
    queryKey,
    queryFn,
    enabled,
    select: select || _utils.transformDealersToOptions,
    ...rest
  });
};
exports.useDealers = useDealers;