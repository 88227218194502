"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useActivityTypes = void 0;
var _appsCore = require("@sm360/apps-core");
var _ActivityService = require("../../api/ActivityService");
/**
 * Returns a list of activity types
 * @returns {Object} a Query object
 */

const useActivityTypes = params => {
  return (0, _appsCore.useQuery)({
    queryKey: ['activities', 'types'],
    queryFn: _ActivityService.getActivityTypes,
    ...params
  });
};
exports.useActivityTypes = useActivityTypes;