"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTaxPlanOptions = exports.getTaxPlan = exports.getSaleTypeOptions = exports.getSaleType = exports.getSaleIsOutsideLocation = exports.getProvinceOptions = exports.getProvince = exports.getOutsideTaxPlanOptions = exports.getOutsideTaxPlan = exports.getOutsideProvinceOptions = exports.getOutsideProvince = exports.getDealerInfo = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _selectors = require("../selectors");
/**
 * @param {Object[]} list
 * @returns {Object[]}
 */
const formatOptions = list => {
  if (!Array.isArray(list)) {
    return [];
  }
  return list.map(_ref => {
    let {
      code,
      name,
      value
    } = _ref;
    return {
      value: value || code,
      label: name || value || code
    };
  });
};

/**
 * @param {Object[]} options
 * @param {String} value
 * @param {Boolean} hasRight
 * @returns {Object[]}
 */
const availableOptions = (options, value, hasRight) => {
  const newOptions = [];
  const formattedOptions = formatOptions(options);
  const index = formattedOptions.findIndex(o => o.value === value.toString());
  if (index !== -1) {
    if (!hasRight) {
      newOptions.push(formattedOptions[index]);
      return newOptions;
    }
  }
  return formattedOptions;
};

/**
 * @param {Object} state
 * @returns {String}
 */
const getProvince = state => {
  const {
    location
  } = (0, _selectors.getConfig)(state);
  return typeof location === 'string' ? location : '';
};

/**
 * @param {Object} state
 * @returns {Object[]}
 */
exports.getProvince = getProvince;
const getProvinceOptions = state => {
  const {
    provinces
  } = (0, _selectors.getMetaData)(state);
  const dealerProvince = getProvince(state);
  const canSelectDifferentProvince = (0, _selectors.getCanSelectDifferentProvince)(state);
  return availableOptions(provinces, dealerProvince, canSelectDifferentProvince);
};

/**
 * @param {Object} state
 * @returns {String}
 */
exports.getProvinceOptions = getProvinceOptions;
const getSaleType = state => {
  const {
    saleType
  } = (0, _selectors.getPaymentOptions)(state);
  return typeof saleType === 'string' ? saleType : '';
};

/**
 * @param {Object} state
 * @returns {Object[]}
 */
exports.getSaleType = getSaleType;
const getSaleTypeOptions = state => {
  const {
    saleTypes
  } = (0, _selectors.getMetaData)(state);
  const canManageNonRetailTransaction = (0, _selectors.getCanManageNonRetailTransaction)(state);
  return availableOptions(saleTypes, 'retail', canManageNonRetailTransaction);
};

/**
 * @param {Object} state
 * @returns {String}
 */
exports.getSaleTypeOptions = getSaleTypeOptions;
const getTaxPlan = state => {
  const {
    taxPlan
  } = (0, _selectors.getPaymentOptions)(state);
  return typeof taxPlan === 'string' ? taxPlan : '';
};

/**
 * @param {Object} state
 * @returns {Object[]}
 */
exports.getTaxPlan = getTaxPlan;
const getTaxPlanOptions = state => {
  const {
    taxPlans
  } = (0, _selectors.getMetaData)(state);
  const canSelectAnyAvailableTaxPlan = (0, _selectors.getCanSelectAnyAvailableTaxPlan)(state);
  return availableOptions(taxPlans, 'standard', canSelectAnyAvailableTaxPlan);
};

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getTaxPlanOptions = getTaxPlanOptions;
const getSaleIsOutsideLocation = state => {
  const {
    saleIsOutsideLocation
  } = (0, _selectors.getQuote)(state);
  return saleIsOutsideLocation === true;
};

/**
 * @param {Object} state
 * @returns {String}
 */
exports.getSaleIsOutsideLocation = getSaleIsOutsideLocation;
const getOutsideProvince = state => {
  const {
    registrationLocation
  } = (0, _selectors.getQuote)(state);
  return typeof registrationLocation === 'string' ? registrationLocation : '';
};

/**
 * @param {Object} state
 * @returns {Object[]}
 */
exports.getOutsideProvince = getOutsideProvince;
const getOutsideProvinceOptions = state => {
  const {
    outsideProvinces
  } = (0, _selectors.getMetaData)(state);
  return formatOptions(outsideProvinces);
};

/**
 * @param {Object} state
 * @returns {String}
 */
exports.getOutsideProvinceOptions = getOutsideProvinceOptions;
const getOutsideTaxPlan = state => {
  const {
    registrationLocationTaxPlan
  } = (0, _selectors.getQuote)(state);
  return typeof registrationLocationTaxPlan === 'string' ? registrationLocationTaxPlan : '';
};

/**
 * @param {Object} state
 * @returns {Object[]}
 */
exports.getOutsideTaxPlan = getOutsideTaxPlan;
const getOutsideTaxPlanOptions = state => {
  const {
    registrationLocationTaxPlans
  } = (0, _selectors.getMetaData)(state);
  return formatOptions(registrationLocationTaxPlans);
};

/**
 * @params {Object} state
 * @returns {Object}
 */
exports.getOutsideTaxPlanOptions = getOutsideTaxPlanOptions;
const getDealerInfo = state => {
  const {
    dealerInfo
  } = state;
  const {
    province1,
    address,
    city,
    postalCode,
    countryCode,
    name,
    phone,
    email,
    url,
    organizationUnitThirdPartyConfiguration
  } = dealerInfo;
  const provinceCode = (0, _commonsUtils.isPlainObject)(province1) ? province1.provinceCode : '';
  const fullAddress = [address, city, provinceCode, postalCode, countryCode].filter(Boolean).join(' ');
  return {
    fullAddress,
    phone,
    email,
    url,
    name,
    provinceCode,
    organizationUnitThirdPartyConfiguration
  };
};
exports.getDealerInfo = getDealerInfo;