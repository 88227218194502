"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  PAYMENT_TYPE: 'paymentType',
  ACCIDENT_STATUS: 'accidentStatus',
  VEHICLE_CONDITION: 'vehicleCondition'
};