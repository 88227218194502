"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/**
 * @param {Object} state
 * @param {Object} payload - { updatedForm }
 * @returns {Object}
 */
var _default = (state, _ref) => {
  let {
    updatedForm
  } = _ref;
  return {
    ...state,
    tabForms: {
      dealJacket: {
        ...(state?.tabForms?.dealJacket || {}),
        forms: state?.tabForms?.dealJacket?.forms.map(form => form.formId === updatedForm.formId && form.instanceId === updatedForm.instanceId ? updatedForm : form) ?? []
      },
      isPrinting: state?.tabForms?.isPrinting ?? false
    }
  };
};
exports.default = _default;