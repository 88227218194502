"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useEmailTemplate = void 0;
var _appsCore = require("@sm360/apps-core");
var _commonsUtils = require("@sm360/commons-utils");
var _EmailTemplateService = require("../../api/EmailTemplateService");
/**
 * Returns a mail template (including its french/english content)
 * @param {Number} id
 * @returns {Object} a Query object
 */
const useEmailTemplate = params => {
  const {
    id,
    ...rest
  } = params || {};
  const _id = (0, _commonsUtils.nullOrNumber)(id);
  return (0, _appsCore.useQuery)({
    queryKey: ['emailTemplates', _id],
    queryFn: () => (0, _EmailTemplateService.getEmailTemplate)(_id),
    enabled: Number.isInteger(_id),
    ...rest
  });
};
exports.useEmailTemplate = useEmailTemplate;