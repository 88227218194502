"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSimilarMatchesCount = exports.getSimilarMatches = exports.getSelectedMatchVehicle = exports.getPerfectMatchesCount = exports.getPerfectMatches = exports.getMatches = exports.getHasMatches = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _commonsApi = require("@sm360/commons-api");
var _vehicle = require("./vehicle");
/**
 * @param {Object} state
 * @returns {Object}
 */
const getMatches = state => {
  const {
    matches
  } = state;
  return (0, _commonsUtils.isPlainObject)(matches) ? matches : {};
};

/**
 * @param {Object[]} vehicles
 * @returns {Object[]}
 */
exports.getMatches = getMatches;
const filterMatches = vehicles => {
  const filteredMatches = vehicles.filter(vehicle => vehicle.vehicleInventoryStatus === _commonsApi.StatusService.VEHICLE_STATUS.FOR_SALE);
  return filteredMatches;
};

/**
 * @param {Object} state
 * @returns {Object[]}
 */
const getPerfectMatches = state => {
  const {
    perfectList
  } = getMatches(state);
  const id = (0, _vehicle.getVehicleId)(state);
  const filteredMatches = filterMatches(perfectList);
  return Array.isArray(filteredMatches) ? filteredMatches.filter(_ref => {
    let {
      vehicleId
    } = _ref;
    return vehicleId !== id;
  }) : [];
};

/**
 * @param {Object} state
 * @returns {Object[]}
 */
exports.getPerfectMatches = getPerfectMatches;
const getSimilarMatches = state => {
  const {
    similarList
  } = getMatches(state);
  const id = (0, _vehicle.getVehicleId)(state);
  const filteredMatches = filterMatches(similarList);
  return Array.isArray(filteredMatches) ? filteredMatches.filter(_ref2 => {
    let {
      vehicleId
    } = _ref2;
    return vehicleId !== id;
  }) : [];
};

/**
 * @param {Object} state
 * @returns {Number}
 */
exports.getSimilarMatches = getSimilarMatches;
const getPerfectMatchesCount = state => getPerfectMatches(state).length;

/**
 * @param {Object} state
 * @returns {Number}
 */
exports.getPerfectMatchesCount = getPerfectMatchesCount;
const getSimilarMatchesCount = state => getSimilarMatches(state).length;

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getSimilarMatchesCount = getSimilarMatchesCount;
const getHasMatches = state => {
  const hasPerfectMatches = getPerfectMatchesCount(state) > 0;
  const hasSimilarMatches = getSimilarMatchesCount(state) > 0;
  const isUsed = (0, _vehicle.getIsUsed)(state);
  return !isUsed && (hasPerfectMatches || hasSimilarMatches);
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getHasMatches = getHasMatches;
const getSelectedMatchVehicle = state => {
  const {
    selectedVehicle
  } = getMatches(state);
  return (0, _commonsUtils.isPlainObject)(selectedVehicle) ? selectedVehicle : {};
};
exports.getSelectedMatchVehicle = getSelectedMatchVehicle;