"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = (state, _ref) => {
  let {
    tradeIn
  } = _ref;
  return {
    ...state,
    tradeIn
  };
};
exports.default = _default;