"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sagas = void 0;
var _effects = require("redux-saga/effects");
var _commonsUtils = require("@sm360/commons-utils");
var _reducers = require("./reducers");
function* handleAddVehicleError(error, errorType) {
  let errorData;
  try {
    errorData = JSON.parse(error.message).responseData;
  } catch (innerError) {
    yield (0, _effects.put)(_reducers.Creators.handleError(error, 'invalidFormat'));
    return null;
  }
  if ((0, _commonsUtils.isPlainObject)(errorData) && errorData.code === 'NotFound') {
    yield (0, _effects.put)(_reducers.Creators.handleVehicleNotFound(true, errorData.message));
  } else {
    yield (0, _effects.put)(_reducers.Creators.handleError(error, errorType));
  }
  return null;
}
const sagas = exports.sagas = {
  handleAddVehicleError
};