"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSubscriptionContexts = void 0;
var _request = require("../../tools/request");
const CRM_API_URL = process.env.REACT_APP_CRM_API_URL;

/**
 * @param {Number} organizationId
 * @returns {Promise}
 */
const getSubscriptionContexts = organizationId => {
  return (0, _request.get)(`${CRM_API_URL}/subscriptionContexts?organizationId=${organizationId}`);
};
exports.getSubscriptionContexts = getSubscriptionContexts;