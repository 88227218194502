"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _deskingCommons = require("@sm360/desking-commons");
var _actions = require("../../../redux/actions");
var _content = require("../../../redux/selectors/content");
var _stepTrim = require("../../../redux/selectors/stepTrim");
var _StepLayoutBar = _interopRequireDefault(require("./StepLayoutBar"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const mapStateToProps = state => {
  const {
    name
  } = (0, _stepTrim.getModelTrim)(state);
  return {
    currentModel: (0, _content.getModelName)(state),
    currentMake: (0, _content.getMakeName)(state),
    currentTrim: name,
    currentYear: (0, _content.getYear)(state),
    currentVehicleImage: (0, _content.getVehicleImage)(state),
    upg: _deskingCommons.priceIncreaseSelectors.getPriceIncreaseRollCount(state)
  };
};
/**
 * @param {Function} dispatch
 * @returns {Object}
 */
const mapDispatchToProps = dispatch => ({
  setCurrentStep: (step, lastVisitedStep) => dispatch(_actions.Creators.setCurrentStep(step, lastVisitedStep))
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps))(_StepLayoutBar.default);