"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _effects = require("redux-saga/effects");
var _selectors = require("../selectors");
var _updatePayOptAndTransDetails = _interopRequireDefault(require("./api/updatePayOptAndTransDetails"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} payload - Data from action
 */
function* selectPlan(payload) {
  const paymentOptions = yield (0, _effects.select)(_selectors.getPaymentOptions);
  const financeOptions = yield (0, _effects.select)(_selectors.getFinanceOptions);
  const {
    index
  } = payload;

  // Deep copy
  const transformedFinanceOptions = structuredClone(financeOptions);

  // Update isSelected values
  transformedFinanceOptions.forEach((fo, i) => {
    fo.isSelected = i === index;
  });

  // Take params from the selected financeOptions, to put them at the root
  const {
    financePlan,
    kmPerYearPlan,
    paymentFrequency,
    purchaseMethod,
    selectedTerm,
    selectedCashDown
  } = transformedFinanceOptions[index];
  yield (0, _effects.call)(_updatePayOptAndTransDetails.default, {
    ...paymentOptions,
    cashDown: selectedCashDown,
    financeOptions: transformedFinanceOptions,
    financePlan,
    kmPerYearPlan,
    paymentFrequency,
    purchaseMethod,
    term: selectedTerm
  });
}
var _default = exports.default = selectPlan;