"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getVehicle = exports.getSimilarInventory = exports.getInventory = exports.getInventoriesIds = exports.getInteriorPhotos = exports.getInteriorColors = exports.getExteriorPhotos = exports.getExteriorColors = exports.getCatalogVehicleDetails = exports.getCatalogVehicleByMakeModelYear = exports.getCatalogVehicle = exports.getCatalogVdpUrl = void 0;
var _appsCore = require("@sm360/apps-core");
const XMS_ENDPOINT = process.env.REACT_APP_XMS_API_URL;
const NEW_CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;
const getInventoriesIds = async orgUnitsIds => {
  const query = orgUnitsIds.map(o => `organizationUnitId=${o}`).join('&');
  const results = await _appsCore.axios.get(`${NEW_CRM_ENDPOINT}/inventories?${query}`);
  return results;
};
exports.getInventoriesIds = getInventoriesIds;
const getInventory = async (ids, request, pageSize, pageNumber) => {
  const body = {
    filters: {
      inventoryIds: ids.map(inv => inv.id),
      searchTerms: request ? request.replace(/\s+/g, ' ').split(' ') : [],
      searchableProperties: ['model.make.name', 'model.name', 'carYear', 'trim.name', 'price', 'odometer', 'stockNo', 'serialNo', 'newVehicleText', 'vehicleInventoryStatusText']
    },
    meta: {
      from: pageNumber,
      size: pageSize,
      sort: [{
        id: 'dateCreated',
        desc: false
      }]
    }
  };
  return await _appsCore.axios.post(`${NEW_CRM_ENDPOINT}/vehicleInventories/searchByInventory`, body);
};
exports.getInventory = getInventory;
const getSimilarInventory = _ref => {
  let {
    invId,
    page,
    limit,
    orgUnitId
  } = _ref;
  const queryString = `organizationUnitId=${orgUnitId}&page=${page}&limit=${limit}`;
  return _appsCore.axios.get(`${NEW_CRM_ENDPOINT}/vehicleInventories/${invId}/similars?${queryString}`);
};
exports.getSimilarInventory = getSimilarInventory;
const getVehicle = async inventoryId => {
  const results = await _appsCore.axios.get(`${XMS_ENDPOINT}/delta/vehicles/${inventoryId}`, {
    ignoreAppsCoreCatch: true
  });
  return results.data;
};
exports.getVehicle = getVehicle;
const getCatalogVehicle = async inventoryId => {
  return await _appsCore.axios.get(`${NEW_CRM_ENDPOINT}/catalogVehicles/${inventoryId}`, {
    ignoreAppsCoreCatch: true
  }).then(response => {
    if (response.status === 204) return Promise.reject(`No catalog vehicle found for inventoryId ${inventoryId}`);
    return Promise.resolve(response);
  });
};
exports.getCatalogVehicle = getCatalogVehicle;
const getExteriorPhotos = _ref2 => {
  let {
    newCarTrimId,
    exteriorColor
  } = _ref2;
  if (!newCarTrimId || !exteriorColor) return Promise.reject('Missing parameters');
  const colorIdQuery = exteriorColor ? `&colorId=${exteriorColor}` : '';
  return _appsCore.axios.get(`${XMS_ENDPOINT}/catalog/exteriorPhotos?newCarTrimId=${newCarTrimId}${colorIdQuery}`, {
    ignoreAppsCoreCatch: true
  });
};
exports.getExteriorPhotos = getExteriorPhotos;
const getInteriorPhotos = _ref3 => {
  let {
    newCarTrimId,
    interiorColor
  } = _ref3;
  if (!newCarTrimId || !interiorColor) return Promise.reject('Missing parameters');
  return _appsCore.axios.get(`${XMS_ENDPOINT}/catalog/interiorPhotos?newCarTrimId=${newCarTrimId}&interiorColorId=${interiorColor}`, {
    ignoreAppsCoreCatch: true
  });
};
exports.getInteriorPhotos = getInteriorPhotos;
const getCatalogVehicleByMakeModelYear = _ref4 => {
  let {
    makeId,
    modelId,
    year
  } = _ref4;
  return _appsCore.axios.get(`${NEW_CRM_ENDPOINT}/catalogVehicles/vehicleByMakeYearModel?makeId=${makeId}&modelId=${modelId}&year=${year}`, {
    ignoreAppsCoreCatch: true
  });
};
exports.getCatalogVehicleByMakeModelYear = getCatalogVehicleByMakeModelYear;
const getExteriorColors = newCarTrimId => {
  if (!newCarTrimId) return Promise.reject('Missing parameters');
  return _appsCore.axios.get(`${XMS_ENDPOINT}/catalog/exteriorColors?newCarTrimId=${newCarTrimId}`, {
    ignoreAppsCoreCatch: true
  });
};
exports.getExteriorColors = getExteriorColors;
const getInteriorColors = newCarTrimId => {
  if (!newCarTrimId) return Promise.reject('Missing parameters');
  return _appsCore.axios.get(`${XMS_ENDPOINT}/catalog/interiorColors?newCarTrimId=${newCarTrimId}`, {
    ignoreAppsCoreCatch: true
  });
};
exports.getInteriorColors = getInteriorColors;
const getCatalogVdpUrl = _ref5 => {
  let {
    newCarTrimId,
    orgUnitId,
    lang = 'en'
  } = _ref5;
  if (!newCarTrimId || !orgUnitId) return Promise.reject('Missing parameters');
  return _appsCore.axios.get(`${XMS_ENDPOINT}/newCarTrims/${newCarTrimId}/url?orgUnitId=${orgUnitId}&lang=${lang}`, {
    ignoreAppsCoreCatch: true
  });
};
exports.getCatalogVdpUrl = getCatalogVdpUrl;
const getCatalogVehicleDetails = _ref6 => {
  let {
    inventoryId,
    newCarTrimId,
    exteriorColor,
    interiorColor,
    orgUnitId,
    lang
  } = _ref6;
  return getCatalogVehicle(inventoryId).then(response => {
    const catalogVehicle = {
      ...response.data
    };
    const carTrimId = newCarTrimId || catalogVehicle?.vehicleTrims?.[0]?.id; // defaults to first available trim

    return Promise.allSettled([getExteriorColors(carTrimId)]).then(_ref7 => {
      let [exteriorColorsRes] = _ref7;
      // exterior color
      const allExteriorColors = Array.isArray(exteriorColorsRes?.value?.data) ? exteriorColorsRes.value.data : [];
      let selectedColorId = null;
      if (exteriorColorsRes.status === 'fulfilled') {
        const selectedColor = allExteriorColors.find(d => d.value === exteriorColor);
        selectedColorId = selectedColor?.value;
        catalogVehicle.exteriorColorName = selectedColor?.label || '';
      }

      // fallback to first possible color to ensure we can fetch an exterior photo
      if (!selectedColorId) selectedColorId = allExteriorColors?.[0]?.value;
      return Promise.allSettled([getExteriorPhotos({
        newCarTrimId: carTrimId,
        exteriorColor: selectedColorId
      }), getInteriorColors(carTrimId), getCatalogVdpUrl({
        newCarTrimId: carTrimId,
        orgUnitId,
        lang
      })]).then(_ref8 => {
        let [exteriorPhotosRes, interiorColorsRes, vdpUrlRes] = _ref8;
        // exterior photo
        if (exteriorPhotosRes.status === 'fulfilled') {
          catalogVehicle.exteriorCatalogPhoto = exteriorPhotosRes.value.data?.[0] || null;
        }

        // interior color
        if (interiorColorsRes.status === 'fulfilled') {
          catalogVehicle.interiorColorName = interiorColorsRes.value.data.find(d => d.value === interiorColor)?.label || '';
        }

        // vdp url
        if (vdpUrlRes.status === 'fulfilled') {
          catalogVehicle.vehicleDetailPageUrl = vdpUrlRes.value?.data || null;
        }
        return Promise.resolve(catalogVehicle);
      });
    });
  });
};
exports.getCatalogVehicleDetails = getCatalogVehicleDetails;