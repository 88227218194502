"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transformContactFromBackend = void 0;
var _commonsUtils = require("@sm360/commons-utils");
// Transform/clean the BE contact structure to the FE contact structure
const transformContactFromBackend = entry => {
  const {
    mobilePhone,
    homePhone,
    workPhone,
    workPhoneExtension
  } = entry;
  const formattedEntry = {
    ...entry,
    mobilePhone: (0, _commonsUtils.validPhoneNumber)(mobilePhone) ? (0, _commonsUtils.cleanPhoneNumber)(mobilePhone) : '',
    homePhone: (0, _commonsUtils.validPhoneNumber)(homePhone) ? (0, _commonsUtils.cleanPhoneNumber)(homePhone) : '',
    workPhone: (0, _commonsUtils.validPhoneNumber)(workPhone) ? (0, _commonsUtils.cleanPhoneNumber)(workPhone) : '',
    workPhoneExtension: workPhoneExtension ? Number(workPhoneExtension) : null
  };
  return formattedEntry;
};
exports.transformContactFromBackend = transformContactFromBackend;