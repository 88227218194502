"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _selectors = require("../../selectors");
/**
 * @param {Object} state
 * @param {Object} payload - { lien }
 * @returns {Object}
 */
var _default = (state, _ref) => {
  let {
    lien
  } = _ref;
  const tradeInParams = (0, _selectors.getTradeIn)(state);
  return {
    ...state,
    tradeIn: {
      ...tradeInParams,
      lien
    }
  };
};
exports.default = _default;