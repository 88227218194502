"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useLeadTrackingTypes = void 0;
var _appsCore = require("@sm360/apps-core");
var _LeadService = require("../../api/LeadService");
var _utils = require("../../tools/utils");
const transformTrackingTypesToOptions = data => (0, _utils.transformDataToOptions)(data, 'type');

/**
 * Returns a list of lead tracking types
 * @returns {Object} a Query object
 */
const useLeadTrackingTypes = params => {
  const {
    select,
    ...rest
  } = params || {};
  const queryKey = ['leads', 'trackingTypes'];
  const queryFn = _LeadService.getLeadTrackingTypes;
  return (0, _appsCore.useQuery)({
    queryKey,
    queryFn,
    select: select || transformTrackingTypesToOptions,
    ...rest
  });
};
exports.useLeadTrackingTypes = useLeadTrackingTypes;