"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPaymentFromVehicle = exports.getExteriorColourFromVehicle = exports.getAddedOptionsFromVehicle = exports.PURCHASE_METHOD_LEASE = exports.PURCHASE_METHOD_FINANCE = exports.PURCHASE_METHOD_CASH = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _picture = require("../../utils/picture");
const PURCHASE_METHOD_CASH = exports.PURCHASE_METHOD_CASH = 'cash';
const PURCHASE_METHOD_FINANCE = exports.PURCHASE_METHOD_FINANCE = 'finance';
const PURCHASE_METHOD_LEASE = exports.PURCHASE_METHOD_LEASE = 'lease';

/**
 * @param {Object} vehicle
 * @returns {Object|null}
 */
const getAddedOptionsFromVehicle = vehicle => {
  const {
    paymentOptions
  } = vehicle;
  if (!(0, _commonsUtils.isPlainObject)(paymentOptions)) {
    return null;
  }
  const {
    addedItems
  } = paymentOptions;
  if (!(0, _commonsUtils.isPlainObject)(addedItems)) {
    return null;
  }
  const {
    retail,
    items
  } = addedItems;
  return {
    retail,
    items
  };
};

/**
 * @param {Object} vehicle
 * @returns {Object|null}
 */
exports.getAddedOptionsFromVehicle = getAddedOptionsFromVehicle;
const getExteriorColourFromVehicle = vehicle => {
  const {
    paymentOptions
  } = vehicle;
  if (!(0, _commonsUtils.isPlainObject)(paymentOptions)) {
    return null;
  }
  const {
    exteriorColourPhoto
  } = paymentOptions;
  if (!(0, _commonsUtils.isPlainObject)(exteriorColourPhoto)) {
    return null;
  }
  const {
    code,
    description,
    tile
  } = exteriorColourPhoto;
  return {
    code,
    description,
    url: (0, _picture.prefixNewColor)(tile)
  };
};

/**
 * @param {Function} t
 * @param {Object} vehicle
 * @returns {Object|null}
 */
exports.getExteriorColourFromVehicle = getExteriorColourFromVehicle;
const getPaymentFromVehicle = (t, vehicle, hasTaxesIncluded) => {
  const {
    paymentOptions
  } = vehicle;
  if (!(0, _commonsUtils.isPlainObject)(paymentOptions)) {
    return null;
  }
  const {
    cashPurchase,
    finance,
    lease,
    purchaseMethod
  } = paymentOptions;
  if (purchaseMethod === PURCHASE_METHOD_CASH && (0, _commonsUtils.isPlainObject)(cashPurchase)) {
    return {
      value: cashPurchase.sellingPrice
    };
  }
  if (purchaseMethod === PURCHASE_METHOD_FINANCE && (0, _commonsUtils.isPlainObject)(finance)) {
    return {
      frequency: t(`deskingCommon.paymentFrequency.${finance.paymentFrequency}`),
      value: hasTaxesIncluded ? finance.term.totalPayment : finance.term.payment
    };
  }
  if (purchaseMethod === PURCHASE_METHOD_LEASE && (0, _commonsUtils.isPlainObject)(lease)) {
    return {
      frequency: t(`deskingCommon.paymentFrequency.${lease.paymentFrequency}`),
      value: hasTaxesIncluded ? lease.term.totalPayment : lease.term.payment
    };
  }
  return null;
};
exports.getPaymentFromVehicle = getPaymentFromVehicle;