"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getIsHidingPrices = exports.getHasTaxesIncluded = exports.getHasDeskingAccess = void 0;
var _selectors = require("../selectors");
var _filters = require("./filters");
/**
 * @param {Object} state
 * @returns {Boolean}
 */
const getHasTaxesIncluded = state => state.hasTaxesIncluded;

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getHasTaxesIncluded = getHasTaxesIncluded;
const getIsHidingPrices = state => {
  const {
    isHidingCatalogPrices
  } = (0, _selectors.getPermissions)(state);
  const isCatalog = (0, _filters.getIsCategoryCatalog)(state);
  return isCatalog && isHidingCatalogPrices === true;
};

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getIsHidingPrices = getIsHidingPrices;
const getHasDeskingAccess = state => {
  const {
    hasDeskingAccess
  } = (0, _selectors.getPermissions)(state);
  return hasDeskingAccess === true;
};
exports.getHasDeskingAccess = getHasDeskingAccess;