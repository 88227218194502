"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPriceIncreaseRollCount = exports.default = void 0;
/**
 * @param {Object} state
 * @returns {Number}
 */
const getPriceIncreaseRollCount = state => {
  const {
    priceIncreaseRollCount
  } = state;
  const parsedCount = parseInt(priceIncreaseRollCount, 10);
  return Number.isNaN(parsedCount) ? 0 : parsedCount;
};
exports.getPriceIncreaseRollCount = getPriceIncreaseRollCount;
var _default = exports.default = {
  getPriceIncreaseRollCount
};