"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getStopSaleMessage = exports.getMessageSeeNotes = exports.getManufacturerNotes = exports.getIsStopSaleActive = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _vehicle = require("./vehicle");
// eslint-disable-next-line import/no-cycle

/**
 * @param {Object} state
 * @returns {Object}
 */
const getStopSale = state => {
  const {
    stopSale
  } = (0, _vehicle.getVehicle)(state);
  return (0, _commonsUtils.isPlainObject)(stopSale) ? stopSale : {};
};

/**
 * @param {Object} state
 * @returns {Array}
 */
const getManufacturerNotes = state => {
  const {
    manufacturerNotes
  } = getStopSale(state);
  return Array.isArray(manufacturerNotes) ? manufacturerNotes : [];
};
exports.getManufacturerNotes = getManufacturerNotes;
const getIsStopSaleActive = state => {
  const {
    stopSaleActive
  } = getStopSale(state);
  return stopSaleActive === true;
};

/**
 * @param {Object} state
 * @returns {String}
 */
exports.getIsStopSaleActive = getIsStopSaleActive;
const getStopSaleMessage = state => {
  const {
    message
  } = getStopSale(state);
  return typeof message === 'string' ? message : '';
};

/**
 * @param {Object} state
 * @returns {String}
 */
exports.getStopSaleMessage = getStopSaleMessage;
const getMessageSeeNotes = state => {
  const {
    messageSeeNotes
  } = getStopSale(state);
  return typeof messageSeeNotes === 'string' ? messageSeeNotes : '';
};
exports.getMessageSeeNotes = getMessageSeeNotes;