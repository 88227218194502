"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _selectors = require("../../selectors");
var _updatePayOptAndTransDetails = _interopRequireDefault(require("./updatePayOptAndTransDetails"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} payload - Data from action
 */
function* setItemFlags(payload) {
  const quoteId = yield (0, _effects.select)(_selectors.getQuoteId);
  const queryParams = yield (0, _effects.select)(_selectors.getShowroomQueryParams);
  const quote = yield (0, _effects.select)(_selectors.getQuote);
  const paymentOptions = yield (0, _effects.select)(_selectors.getPaymentOptions);
  const body = {
    transactionToken: paymentOptions.transactionToken,
    saleType: quote.saleType,
    taxPlan: quote.taxPlan,
    vehicleCondition: quote.vehicleCondition,
    odometer: paymentOptions.odometer,
    purchaseMethod: paymentOptions.purchaseMethod,
    financePlan: paymentOptions.financePlan,
    kmPerYearPlan: paymentOptions.kmPerYearPlan,
    paymentFrequency: paymentOptions.paymentFrequency,
    term: paymentOptions.term,
    tradeIn: paymentOptions.tradeIn,
    lien: paymentOptions.lien,
    cashDown: paymentOptions.cashDown,
    item: payload.params.item
  };
  try {
    const response = yield (0, _effects.call)(_commonsApi.ShowroomService.desking.setItemFlags, quoteId, queryParams, body);
    yield (0, _effects.call)(_updatePayOptAndTransDetails.default, {
      ...paymentOptions,
      cashDown: response.negociationTransaction.cashDown,
      transactionToken: response.negociationTransaction.transactionToken
    });
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'setItemFlags'));
  }
}
var _default = exports.default = setItemFlags;