"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _steps = require("../../constants/steps");
var _selectors = require("../selectors");
var _loadModel = _interopRequireDefault(require("./loadModel"));
var _loadStepOptionItems = _interopRequireDefault(require("./steps/loadStepOptionItems"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Reload the current step
 */
function* _default() {
  const {
    currentStep
  } = yield (0, _effects.select)(_selectors.getNav);

  // Steps routing
  switch (currentStep) {
    case _steps.STEP_COMFORT:
    case _steps.STEP_PERFORMANCE:
    case _steps.STEP_SUMMARY:
    case _steps.STEP_TRIM:
    case _steps.STEP_YEAR:
      yield (0, _effects.call)(_loadModel.default);
      break;
    case _steps.STEP_OPTIONS:
      yield (0, _effects.call)(_loadStepOptionItems.default);
      break;
    default:
      throw new Error('[reloadCurrentStep] Step is not valid');
  }
}