"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTradeIn = exports.PAYMENT_TYPE = void 0;
var _request = require("../../tools/request");
const TRADE_IN_API_URL = process.env.REACT_APP_TRADEIN_API_URL;
const PAYMENT_TYPE = exports.PAYMENT_TYPE = {
  LEASE: 'lease',
  LOAN: 'loan',
  PAID: 'paid',
  UNKNOWN: 'unknown'
};

/**
 * @param {Object} queryParams
 * @returns {Promise}
 */
const getTradeIn = queryParams => {
  const url = `${TRADE_IN_API_URL}/tradeIn`;
  return (0, _request.get)(url, {
    params: queryParams,
    ignoreAppsCoreCatch: true
  });
};
exports.getTradeIn = getTradeIn;