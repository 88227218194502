"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.HOME_STATUS_TYPE = void 0;
const HOME_STATUS_TYPE = exports.HOME_STATUS_TYPE = [{
  translationKey: 'FINANCING.RESIDENCE_INFOS.HOME_STATUS_TYPE.OWN',
  value: 'Own'
}, {
  translationKey: 'FINANCING.RESIDENCE_INFOS.HOME_STATUS_TYPE.RENT',
  value: 'Rent'
}, {
  translationKey: 'FINANCING.RESIDENCE_INFOS.HOME_STATUS_TYPE.PARENTS',
  value: 'Parents/Relative'
}, {
  translationKey: 'FINANCING.RESIDENCE_INFOS.HOME_STATUS_TYPE.RESERVE_HOUSING',
  value: 'ReserveHousing'
}, {
  translationKey: 'FINANCING.RESIDENCE_INFOS.HOME_STATUS_TYPE.OWN_FREE_AND_CLEAR',
  value: 'OF'
}, {
  translationKey: 'FINANCING.RESIDENCE_INFOS.HOME_STATUS_TYPE.OWN_MOBILE_HOME',
  value: 'OM'
}, {
  translationKey: 'FINANCING.RESIDENCE_INFOS.HOME_STATUS_TYPE.OWN_WITH_MORTGAGE',
  value: 'OW'
}, {
  translationKey: 'FINANCING.RESIDENCE_INFOS.HOME_STATUS_TYPE.OTHER',
  value: 'OT'
}];
var _default = exports.default = {
  HOME_STATUS_TYPE
};