"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsUtils = require("@sm360/commons-utils");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _selectors = require("../../selectors");
var _requestAddItem = require("../../selectors/requestAddItem");
var _updatePayOptAndTransDetails = _interopRequireDefault(require("../api/updatePayOptAndTransDetails"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @TODO better handling when calls not valid
 *
 * @param {Object} payload - { items }
 */
function _default(_ref) {
  let {
    items
  } = _ref;
  return function* () {
    const quoteId = yield (0, _effects.select)(_selectors.getQuoteId);
    const queryParams = yield (0, _effects.select)(_selectors.getShowroomQueryParams);
    try {
      let body;
      let paymentOptions;
      let response;
      for (let i = 0; i < items.length; i += 1) {
        paymentOptions = yield (0, _effects.select)(_selectors.getPaymentOptions);
        body = yield (0, _effects.select)(_requestAddItem.getBody, items[i], paymentOptions.transactionToken);
        response = yield (0, _effects.call)(_commonsApi.ShowroomService.desking.addItem, quoteId, queryParams, body);
        if ((0, _commonsUtils.isPlainObject)(response) && response.isValid === true) {
          yield (0, _effects.call)(_updatePayOptAndTransDetails.default, {
            ...paymentOptions,
            ...response.negociationTransaction,
            callCommission: true
          });
        }
      }
    } catch (error) {
      yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'createItem'));
    }
  }();
}