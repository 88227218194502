"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/**
 * @param {Object} state
 * @param {Object} payload - { response }
 * @returns {Object}
 */
var _default = (state, _ref) => {
  let {
    response
  } = _ref;
  const {
    isValid,
    mustResetSelection,
    negociationTransaction,
    validationItem,
    validationMessage,
    validResolutionCombinations
  } = response;
  return {
    ...state,
    validation: {
      isValid,
      mustResetSelection,
      negociationTransaction,
      validationItem,
      validationMessage,
      validResolutionCombinations
    }
  };
};
exports.default = _default;