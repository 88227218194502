"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getVehiclesWidgetParams = exports.getUnlistedVehicleTrims = exports.getUnlistedVehicleModels = exports.getUnlistedVehicleMakes = exports.getUnlistedVehicleFormData = exports.getUnlistedVehicle = exports.getShowroomQueryParams = exports.getSalesPersonnel = exports.getQuoteServiceQueryParams = exports.getQuoteId = exports.getQuote = exports.getLeadId = exports.getIsUnlistedVehicle = exports.getIsUnlistedNew = exports.getIsLoading = exports.getIsGarageEmpty = exports.getGarageInfo = exports.getDeskingWidgetParams = exports.getDeskingAccess = exports.getConfig = exports.getCanCreateUnlistedVehicle = void 0;
var _commonsUtils = require("@sm360/commons-utils");
/**
 * @param {Object} state
 * @returns {Boolean}
 */
const getDeskingAccess = state => state.hasDeskingAccess;

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getDeskingAccess = getDeskingAccess;
const getConfig = state => {
  const {
    config
  } = state;
  return (0, _commonsUtils.isPlainObject)(config) ? config : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getConfig = getConfig;
const getIsLoading = state => state.isLoading;

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getIsLoading = getIsLoading;
const getGarage = state => state.garage;

/**
 * @param {Object} state
 * @returns {Object}
 */
const getQuote = state => state.quote;

/**
 * @param {Object} state
 * @returns {String|null}
 */
exports.getQuote = getQuote;
const getQuoteId = state => {
  const {
    id
  } = getQuote(state);
  return typeof id === 'string' ? id : null;
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getQuoteId = getQuoteId;
const getQuoteServiceQueryParams = state => {
  const {
    location,
    organizationId,
    organizationUnitId,
    accountId,
    lang
  } = getConfig(state);
  const params = {
    location,
    organizationId,
    organizationUnitId,
    accountId,
    lang
  };
  return (0, _commonsUtils.isPlainObject)(params) ? params : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getQuoteServiceQueryParams = getQuoteServiceQueryParams;
const getShowroomQueryParams = state => {
  const {
    lang,
    location,
    organizationId,
    organizationUnitId
  } = getConfig(state);
  return {
    lang,
    location,
    orgId: organizationId,
    orgUnitId: organizationUnitId,
    module: 1
  };
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getShowroomQueryParams = getShowroomQueryParams;
const getDeskingWidgetParams = state => {
  const {
    leadId,
    accountId,
    contactId,
    organizationId,
    organizationUnitId
  } = getConfig(state);
  return {
    leadId,
    accountId,
    contactId,
    organizationId,
    organizationUnitId
  };
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getDeskingWidgetParams = getDeskingWidgetParams;
const getVehiclesWidgetParams = state => {
  const {
    leadId,
    location
  } = getConfig(state);
  return {
    leadId,
    location,
    ...getDeskingWidgetParams(state)
  };
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getVehiclesWidgetParams = getVehiclesWidgetParams;
const getGarageInfo = state => {
  const garage = getGarage(state);
  return Array.isArray(garage) ? garage : [];
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getGarageInfo = getGarageInfo;
const getIsGarageEmpty = state => {
  const garage = getGarage(state);
  return !Array.isArray(garage) || garage.length === 0;
};

/** @params {Object} state
 * @returns {Object}
 */
exports.getIsGarageEmpty = getIsGarageEmpty;
const getSalesPersonnel = state => {
  const {
    salesPersonnel
  } = state;
  return salesPersonnel;
};

/**
 * @param {Object} state
 * @returns {Number|null}
 */
exports.getSalesPersonnel = getSalesPersonnel;
const getLeadId = state => {
  const {
    leadId
  } = getConfig(state);
  return leadId;
};
exports.getLeadId = getLeadId;
const getUnlistedVehicle = state => {
  const {
    unlistedVehicle
  } = state;
  return unlistedVehicle;
};
exports.getUnlistedVehicle = getUnlistedVehicle;
const getIsUnlistedVehicle = state => {
  const {
    isUnlistedVehicle
  } = getUnlistedVehicle(state);
  return isUnlistedVehicle;
};
exports.getIsUnlistedVehicle = getIsUnlistedVehicle;
const getIsUnlistedNew = state => {
  const {
    isUnlistedNew
  } = getUnlistedVehicle(state);
  return isUnlistedNew;
};
exports.getIsUnlistedNew = getIsUnlistedNew;
const getUnlistedVehicleMakes = state => {
  const {
    unlistedVehicleMakes
  } = getUnlistedVehicle(state);
  return unlistedVehicleMakes;
};
exports.getUnlistedVehicleMakes = getUnlistedVehicleMakes;
const getUnlistedVehicleModels = state => {
  const {
    unlistedVehicleModels
  } = getUnlistedVehicle(state);
  return unlistedVehicleModels;
};
exports.getUnlistedVehicleModels = getUnlistedVehicleModels;
const getUnlistedVehicleTrims = state => {
  const {
    unlistedVehicleTrims
  } = getUnlistedVehicle(state);
  return unlistedVehicleTrims;
};
exports.getUnlistedVehicleTrims = getUnlistedVehicleTrims;
const getUnlistedVehicleFormData = state => {
  const {
    vehicleFormData
  } = getUnlistedVehicle(state);
  return vehicleFormData;
};

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getUnlistedVehicleFormData = getUnlistedVehicleFormData;
const getCanCreateUnlistedVehicle = state => state.canCreateUnlistedVehicle;
exports.getCanCreateUnlistedVehicle = getCanCreateUnlistedVehicle;