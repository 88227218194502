"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _filters = require("../../../constants/filters");
var _filters2 = require("../../../redux/selectors/filters");
var _BaseSelect = _interopRequireDefault(require("./BaseSelect"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} state
 * @returns {Object}
 */
const mapStateToProps = state => ({
  options: (0, _filters2.getOptionsItem)(state, _filters.KEYS.DRIVE),
  value: (0, _filters2.getSelectedItem)(state, _filters.KEYS.DRIVE)
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps))(_BaseSelect.default);