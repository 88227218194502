"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/**
 * @param {Object} state
 * @param {Object} payload - { title, messages }
 * @returns {Object}
 */
var _default = (state, _ref) => {
  let {
    title,
    messages
  } = _ref;
  return {
    ...state,
    showInvalidResponseModal: true,
    invalidResponseTitle: title,
    invalidResponseMessages: messages
  };
};
exports.default = _default;