"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTrimPictures = exports.getTrimInteriorChoices = exports.getTrimColourChoices = exports.getSelectedInteriorTrim = exports.getModelTrims = exports.getModelTrim = exports.getBodyDimensions = exports.getBodyClassifications = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _deskingCommons = require("@sm360/desking-commons");
var _content = require("./content");
/**
 * @param {Object} state
 * @returns {Object[]}
 */
const getBodyClassifications = state => {
  const {
    bodyClassifications
  } = (0, _content.getModel)(state);
  if (!Array.isArray(bodyClassifications)) {
    return [];
  }
  return bodyClassifications.filter(item => !(0, _commonsUtils.isEmpty)(item.slug));
};

/**
 * @param {Object} state
 * @returns {Object[]}
 */
exports.getBodyClassifications = getBodyClassifications;
const getBodyDimensions = state => {
  const {
    bodyDimensions
  } = (0, _content.getModel)(state);
  if (!Array.isArray(bodyDimensions)) {
    return [];
  }
  return bodyDimensions.filter(item => !(0, _commonsUtils.isEmpty)(item.slug));
};

/**
 * @param {Object} state
 * @returns {Object[]}
 */
exports.getBodyDimensions = getBodyDimensions;
const getInteriorTrimPhotos = state => {
  const {
    interiorTrimPhotos
  } = (0, _content.getModel)(state);
  if (!Array.isArray(interiorTrimPhotos)) {
    return [];
  }
  return interiorTrimPhotos;
};

/**
 * @param {Object} state
 * @returns {Object[]}
 */
const getModelTrims = state => {
  const {
    modelTrims
  } = (0, _content.getModel)(state);
  if (!Array.isArray(modelTrims)) {
    return [];
  }
  return modelTrims;
};

/**
 * Get the selected model trim
 *
 * @param {Object} state
 * @returns {Object}
 */
exports.getModelTrims = getModelTrims;
const getModelTrim = state => {
  const found = getModelTrims(state).find(modelTrim => modelTrim.isSelected);
  return found || {};
};

/**
 * @param {Object} state
 * @returns {String[]}
 */
exports.getModelTrim = getModelTrim;
const getTrimPictures = state => {
  const exteriorColourPhotos = (0, _content.getExteriorColourPhotos)(state);
  if (exteriorColourPhotos.length === 0) {
    return [];
  }
  const found = exteriorColourPhotos.find(item => item.isSelected);
  if (!found) {
    return [];
  }
  const {
    front,
    rear,
    side
  } = found;
  return [front, side, rear].filter(path => !(0, _commonsUtils.isEmpty)(path)).map(path => (0, _deskingCommons.prefixNew)(path));
};

/**
 * @param {Object} state
 * @returns {Object[]}
 */
exports.getTrimPictures = getTrimPictures;
const getTrimColourChoices = state => {
  const exteriorColourPhotos = (0, _content.getExteriorColourPhotos)(state);
  if (exteriorColourPhotos.length === 0) {
    return [];
  }
  return exteriorColourPhotos.map(item => {
    const {
      isSelected,
      photo
    } = item;
    return {
      item,
      isSelected,
      url: (0, _deskingCommons.prefixNewColor)(photo)
    };
  });
};

/**
 * @param {Object} state
 * @returns {Object[]}
 */
exports.getTrimColourChoices = getTrimColourChoices;
const getTrimInteriorChoices = state => {
  const interiorTrimPhotos = getInteriorTrimPhotos(state);
  if (interiorTrimPhotos.length === 0) {
    return [];
  }
  return interiorTrimPhotos.map(_ref => {
    let {
      description,
      colours
    } = _ref;
    return {
      description,
      list: colours.map(item => {
        const {
          isSelected,
          photo
        } = item;
        return {
          isSelected,
          item,
          url: (0, _deskingCommons.prefixNewCloth)(photo)
        };
      })
    };
  });
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getTrimInteriorChoices = getTrimInteriorChoices;
const getSelectedInteriorTrim = state => {
  const interiorTrimPhotos = getInteriorTrimPhotos(state);
  let interiorTrim;
  let found;
  interiorTrimPhotos.forEach(itemTrim => {
    found = itemTrim.colours.find(_ref2 => {
      let {
        isSelected
      } = _ref2;
      return isSelected === true;
    });
    if (found) {
      interiorTrim = found;
    }
  });
  return (0, _commonsUtils.isPlainObject)(interiorTrim) ? interiorTrim : {};
};
exports.getSelectedInteriorTrim = getSelectedInteriorTrim;