"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _selectors = require("../selectors");
var _reloadTabItems = _interopRequireDefault(require("./items/reloadTabItems"));
var _reloadTabProtection = _interopRequireDefault(require("./protection/reloadTabProtection"));
var _loadMatches = _interopRequireDefault(require("./loadMatches"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function* _default() {
  const transactionToken = yield (0, _effects.select)(_selectors.getTransactionToken);
  yield (0, _effects.call)(_reloadTabItems.default);
  yield (0, _effects.call)(_reloadTabProtection.default);
  yield (0, _effects.call)(_loadMatches.default, transactionToken);
}