"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.yearData = exports.dateData = exports.buildOptions = void 0;
const buildOptions = (arr, t) => {
  return arr.map(item => {
    return {
      ...item,
      label: typeof item.label === 'string' ? t(item.label) : item.label
    };
  });
};
exports.buildOptions = buildOptions;
const yearData = () => {
  const currentYear = new Date().getFullYear();
  const range = [...Array(currentYear - 15 - 1900).keys()].reverse().map(i => {
    const yearOption = i + 1900;
    return {
      value: yearOption,
      label: yearOption
    };
  });
  return range;
};
exports.yearData = yearData;
const dateData = () => {
  return Array.from({
    length: 31
  }, (v, k) => k + 1).map(i => {
    return {
      value: i,
      id: i,
      label: i
    };
  });
};
exports.dateData = dateData;