"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tabProtection = require("../../selectors/tabProtection");
/**
 * @param {Object} state
 * @returns {Object}
 */
var _default = state => ({
  tabProtectionProtection: {
    ...(0, _tabProtection.getTabProtectionProtection)(state),
    isUpdated: false
  },
  tabProtectionWarranty: {
    ...(0, _tabProtection.getTabProtectionWarranty)(state),
    isUpdated: false
  },
  tabProtectionInsurance: {
    ...(0, _tabProtection.getTabProtectionInsurance)(state),
    isUpdated: false
  }
});
exports.default = _default;