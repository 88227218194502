"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _effects = require("redux-saga/effects");
var _deskingError = require("@sm360/desking-error");
var _actions = require("../../actions");
/**
 * @param {Object} payload - Data from action
 */
function* resetPayAdjustmentValidation() {
  try {
    yield (0, _effects.put)(_actions.Creators.resetPayAdjustmentValidationSuccess());
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'resetValidation'));
  }
}
var _default = exports.default = resetPayAdjustmentValidation;