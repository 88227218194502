"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _actions = require("../actions");
var _selectors = require("../selectors");
var _status = require("../selectors/status");
var _getMatches = _interopRequireDefault(require("./api/getMatches"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {String} transactionToken
 */
function* _default() {
  const transactionToken = yield (0, _effects.select)(_selectors.getTransactionToken);
  const isQuoteUpdating = yield (0, _effects.select)(_status.getIsQuoteUpdating);
  const response = yield (0, _effects.call)(_getMatches.default, transactionToken);
  if (response) {
    const [perfectList, similarList, selectedVehicle] = response;
    yield (0, _effects.put)(_actions.Creators.loadMatchesSuccess(perfectList, similarList, selectedVehicle));
  }
  // TODO Check with Chloé Barbier why this code is in this file
  if (isQuoteUpdating) {
    yield (0, _effects.put)(_actions.Creators.setIsQuoteUpdatingDone());
  }
}