"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.REG_EXP = exports.PARTY_INDEX_DEFAULT = exports.DEALER_TRACK_ID = void 0;
const REG_EXP = exports.REG_EXP = {
  MONTH: /^(1[0-1]|[1-9]|0)$/,
  // integer [0, 11]
  YEAR: /^(100|[1-9]?[0-9])$/,
  // integer [0, 100]
  NAS: /^\d{9}$/,
  // 9 digits
  PHONE: /^\d{10}$/ // 10 digits
};
const PARTY_INDEX_DEFAULT = exports.PARTY_INDEX_DEFAULT = 0;
// Credit portal id of dealer track
const DEALER_TRACK_ID = exports.DEALER_TRACK_ID = '01bde366-5a4f-4a0f-901a-37019f3a6341';
var _default = exports.default = {
  REG_EXP,
  PARTY_INDEX_DEFAULT
};