"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _filters = require("../../../redux/selectors/filters");
var _actions = require("../../../redux/actions");
var _PriceSlider = _interopRequireDefault(require("./PriceSlider"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} state
 * @returns {Object}
 */
const mapStateToProps = state => {
  const {
    maxPrice,
    selectedMaxPrice
  } = (0, _filters.getPriceRange)(state);
  return {
    maxPrice,
    selectedMaxPrice
  };
};

/**
 * @param {Function} dispatch
 * @returns {Object}
 */
const mapDispatchToProps = dispatch => ({
  updatePriceRange: (selectedMinPrice, selectedMaxPrice) => dispatch(_actions.Creators.updatePriceRange(selectedMinPrice, selectedMaxPrice))
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps))(_PriceSlider.default);