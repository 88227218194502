"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.PAYMENT_TYPE = exports.PAYMENT_PERIOD = void 0;
const PAYMENT_PERIOD = exports.PAYMENT_PERIOD = [{
  translationKey: 'FINANCING.INCOME_AND_PAYMENT_INFOS.PAYMENT_PERIOD.WEEKLY',
  value: 'Weekly'
}, {
  translationKey: 'FINANCING.INCOME_AND_PAYMENT_INFOS.PAYMENT_PERIOD.BI_WEEKLY',
  value: 'Bi-Weekly'
}, {
  translationKey: 'FINANCING.INCOME_AND_PAYMENT_INFOS.PAYMENT_PERIOD.MONTHLY',
  value: 'Monthly'
}, {
  translationKey: 'FINANCING.INCOME_AND_PAYMENT_INFOS.PAYMENT_PERIOD.SEMI_MONTHLY',
  value: 'Semi-Monthly'
}];
const PAYMENT_TYPE = exports.PAYMENT_TYPE = [{
  translationKey: 'FINANCING.INCOME_AND_PAYMENT_INFOS.PAYMENT_TYPE.RENT',
  value: 'Rent'
}, {
  translationKey: 'FINANCING.INCOME_AND_PAYMENT_INFOS.PAYMENT_TYPE.MORTGAGE',
  value: 'Mortgage'
}];
var _default = exports.default = {
  PAYMENT_PERIOD,
  PAYMENT_TYPE
};