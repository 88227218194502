"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.RELATION = exports.CO_APPLICANT_TYPE = void 0;
const CO_APPLICANT_TYPE = exports.CO_APPLICANT_TYPE = [{
  value: 'co-applicant',
  translationKey: 'FINANCING.APPLICANT_INFO.CO_APPLICANT_TYPE.CO_APPLICANT'
}, {
  value: 'joint',
  translationKey: 'FINANCING.APPLICANT_INFO.CO_APPLICANT_TYPE.JOINT'
}, {
  value: 'guarantor',
  translationKey: 'FINANCING.APPLICANT_INFO.CO_APPLICANT_TYPE.GUARANTOR'
}, {
  value: 'driver',
  translationKey: 'FINANCING.APPLICANT_INFO.CO_APPLICANT_TYPE.DRIVER'
}, {
  value: 'ignore',
  translationKey: 'FINANCING.APPLICANT_INFO.CO_APPLICANT_TYPE.IGNORE'
}];
const RELATION = exports.RELATION = [{
  value: 'BR',
  translationKey: 'FINANCING.APPLICANT_INFO.RELATIONS.BR'
}, {
  value: 'CL',
  translationKey: 'FINANCING.APPLICANT_INFO.RELATIONS.CL'
}, {
  value: 'GF',
  translationKey: 'FINANCING.APPLICANT_INFO.RELATIONS.GF'
}, {
  value: 'GM',
  translationKey: 'FINANCING.APPLICANT_INFO.RELATIONS.GM'
}, {
  value: 'IL',
  translationKey: 'FINANCING.APPLICANT_INFO.RELATIONS.IL'
}, {
  value: 'MP',
  translationKey: 'FINANCING.APPLICANT_INFO.RELATIONS.MP'
}, {
  value: 'OT',
  translationKey: 'FINANCING.APPLICANT_INFO.RELATIONS.OT'
}, {
  value: 'PR',
  translationKey: 'FINANCING.APPLICANT_INFO.RELATIONS.PR'
}, {
  value: 'SP',
  translationKey: 'FINANCING.APPLICANT_INFO.RELATIONS.SP'
}, {
  value: 'SR',
  translationKey: 'FINANCING.APPLICANT_INFO.RELATIONS.SR'
}];
var _default = exports.default = {
  CO_APPLICANT_TYPE,
  RELATION
};