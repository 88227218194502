"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _filters = require("../selectors/filters");
var _loadInventoryFilters = _interopRequireDefault(require("./loadInventoryFilters"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {String|Undefined} lastUpdatedName - The last field we changed
 */
function* _default(lastUpdatedName) {
  const isCatalog = yield (0, _effects.select)(_filters.getIsCategoryCatalog);
  // TODO STDESK-63 Temporary fix, do not call the filters api for used vehicles
  const isCategoryUsed = yield (0, _effects.select)(_filters.getIsCategoryUsed);
  if (!isCatalog && !isCategoryUsed) {
    yield (0, _effects.call)(_loadInventoryFilters.default, lastUpdatedName);
  }
}