"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _selectors = require("../../selectors");
var _paymentOptions = require("../../selectors/paymentOptions");
/**
 * @param {Object} state
 * @param {Object} payload - { kmPerYearPlan }
 * @returns {Object}
 */
var _default = (state, _ref) => {
  let {
    kmPerYearPlan
  } = _ref;
  const purchaseMethodParams = (0, _selectors.getPurchaseMethod)(state);
  const paymentOptions = (0, _paymentOptions.getPaymentOptions)(state);
  const financeOptions = (0, _paymentOptions.getFinanceOptions)(state);

  // Deep copy
  const transformedFinanceOptions = structuredClone(financeOptions);
  // Update
  transformedFinanceOptions.forEach(fo => {
    fo.kmPerYearPlan = kmPerYearPlan;
  });
  return {
    ...state,
    purchaseMethod: {
      ...purchaseMethodParams,
      kmPerYearPlan
    },
    paymentOptions: {
      ...paymentOptions,
      financeOptions: transformedFinanceOptions
    }
  };
};
exports.default = _default;