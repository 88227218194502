"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateValuesFromMetadata = void 0;
var _payment = require("../../../constants/payment");
/**
 * - Have to detect if the current selected purchase method is still available in the new metadata.
 * - Have to check if the values from a choices list are still correct with the new metadata, or set default values.
 *
 * @param {Object} metadata
 * @param {Number} selectedKmPerYearPlan
 * @param {Number} selectedPaymentFrequency
 * @param {String} selectedPurchaseMethod
 * @param {Number} selectedTerm
 * @returns {Object} { kmPerYearPlan, paymentFrequency, purchaseMethod, term }
 */
const validateValuesFromMetadata = (metadata, selectedKmPerYearPlan, selectedPaymentFrequency, selectedPurchaseMethod, selectedTerm) => {
  const isCashSelected = selectedPurchaseMethod === _payment.PURCHASE_METHOD_CASH;
  const isLeaseSelected = selectedPurchaseMethod === _payment.PURCHASE_METHOD_LEASE;
  let kmPerYearPlan = selectedKmPerYearPlan;
  let paymentFrequency = selectedPaymentFrequency;
  let purchaseMethod = selectedPurchaseMethod;
  let term = selectedTerm;
  if (!isCashSelected) {
    const found = metadata.find(plan => plan.isAvailable === true && plan.purchaseMethod === selectedPurchaseMethod);

    // If not available, switch to default cash
    if (found === undefined) {
      purchaseMethod = _payment.PURCHASE_METHOD_CASH;
    } else {
      // Check if value from choices are still correct, or set a default value (min/max of a list)
      const {
        kmPerYearPlans,
        paymentFrequencies,
        terms
      } = found;
      if (isLeaseSelected) {
        kmPerYearPlan = kmPerYearPlans.indexOf(selectedKmPerYearPlan) !== -1 ? selectedKmPerYearPlan : Math.min(...kmPerYearPlans);
      }
      paymentFrequency = paymentFrequencies.indexOf(selectedPaymentFrequency) !== -1 ? selectedPaymentFrequency : Math.max(...paymentFrequencies);
      term = terms.indexOf(selectedTerm) !== -1 ? selectedTerm : Math.max(...terms);
    }
  }
  return {
    kmPerYearPlan,
    paymentFrequency,
    purchaseMethod,
    term
  };
};
exports.validateValuesFromMetadata = validateValuesFromMetadata;