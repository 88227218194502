"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "CreateResource", {
  enumerable: true,
  get: function () {
    return _CreateResource.default;
  }
});
Object.defineProperty(exports, "CreateResourceButton", {
  enumerable: true,
  get: function () {
    return _CreateResourceButton.default;
  }
});
exports.default = void 0;
var _CreateResource = _interopRequireDefault(require("./CreateResource"));
var _CreateResourceButton = _interopRequireDefault(require("./components/CreateResourceButton"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var _default = exports.default = _CreateResource.default;