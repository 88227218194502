"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _selectors = require("../selectors");
var _validationHelpers = require("./validationHelpers");
/**
 * - We take the root negociationTransaction in validation, and apply it to the store
 * - The saga takes care of reloading the current step with these new params
 *
 * @param {Object} state
 * @returns {Object}
 */
var _default = state => {
  const {
    negociationTransaction
  } = (0, _selectors.getValidationContent)(state);

  // Safety check, empty data
  if (Object.keys(negociationTransaction).length === 0) {
    return state;
  }

  // Edge case not handled by API yet, but will trigger a fatal error if we store a configurationId at 0
  if (negociationTransaction.configurationId === 0) {
    return {
      ...state,
      validation: {
        isValid: true
      }
    };
  }
  return {
    ...state,
    ...(0, _validationHelpers.applyNegociationTransaction)(state, negociationTransaction)
  };
};
exports.default = _default;