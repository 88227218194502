"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _resetTabItems = _interopRequireDefault(require("./common/resetTabItems"));
var _resetTabProtection = _interopRequireDefault(require("./common/resetTabProtection"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} state
 * @param {Object} payload - response
 * @returns {Object} new state
 */
var _default = (state, payload) => {
  const {
    response
  } = payload;
  const {
    cashDown,
    cashPurchase,
    cashPurchaseOption,
    finance,
    financeOptions,
    financePlan,
    kmPerYearPlan,
    lien,
    odometer,
    paymentFrequency,
    purchaseMethod,
    saleType,
    taxPlan,
    term,
    tradeIn,
    transactionToken,
    vehicleCondition,
    negativeEquityOffsetType,
    positiveEquityOffsetType,
    showNegativeEquityOffset,
    showPositiveEquityOffset,
    disclaimer
  } = response;
  return {
    ...state,
    ...(0, _resetTabItems.default)(state),
    ...(0, _resetTabProtection.default)(state),
    quote: {
      ...state.quote,
      cashPurchase,
      finance
    },
    paymentOptions: {
      ...state.paymentOptions,
      cashDown,
      cashPurchaseOption,
      financeOptions,
      financePlan,
      kmPerYearPlan,
      lien,
      odometer,
      paymentFrequency,
      purchaseMethod,
      saleType,
      taxPlan,
      term,
      tradeIn,
      transactionToken,
      vehicleCondition,
      negativeEquityOffsetType,
      positiveEquityOffsetType,
      showNegativeEquityOffset,
      showPositiveEquityOffset,
      disclaimer
    }
  };
};
exports.default = _default;