"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _selectors = require("../selectors");
var _content = require("../selectors/content");
/**
 * The response should always be an array, an error is thrown in @sm360/commons-api/ShowroomService if not
 *
 * @param {Object} state
 * @param {Object} payload - response
 * @returns {Object} new state
 */
var _default = (state, _ref) => {
  let {
    response
  } = _ref;
  const content = (0, _content.getContent)(state);
  const previouslySelectedYear = (0, _content.getYear)(state);
  let modelYears = (0, _content.getModelYears)(state);
  let year;

  // Don't replace response if it's the same, avoiding to rerender components depending on it
  if (!(0, _commonsUtils.isEqual)(modelYears, response)) {
    modelYears = response;
  }
  if (modelYears.length === 1) {
    // If only one choice, always pre-select it
    const [model] = modelYears;
    ({
      year
    } = model);
  } else {
    // Check if the previous choice is still valid with the new modelYears
    const isStillValid = modelYears.some(item => item.year === previouslySelectedYear);
    year = isStillValid ? previouslySelectedYear : null;
  }

  // Have to decide if data are enough to be able to go next step directly
  const nav = (0, _selectors.getNav)(state);
  const isNextAvailable = year !== null;
  return {
    ...state,
    nav: {
      ...nav,
      isNextAvailable
    },
    content: {
      ...content,
      modelYears,
      year
    }
  };
};
exports.default = _default;