"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NOTE_ITEM_EMPTY = exports.ITEM_EMPTY = exports.ITEM = exports.EDIT_PARAMS = void 0;
const ITEM = exports.ITEM = {
  ACCESSORY: 'accessory',
  AMOUNT: 'amount',
  CATEGORY: 'category',
  COST: 'cost',
  DESCRIPTION: 'description',
  DISCOUNT: 'discount',
  DISPLAY_CODE: 'displayCode',
  REBATE: 'rebate',
  RETAIL: 'retail',
  RESIDUAL_RETAIL: 'residualRetail',
  RESIDUAL_RETAIL_AMOUNT: 'residualRetailAmount',
  TAXES_ARE_INCLUDED: 'taxesAreIncluded',
  CUSTOMER_NOTE: 'customerNote',
  INTERNAL_NOTE: 'internalNote',
  NOTE: 'note',
  EXTERIOR_COLOUR: 'exteriorColour',
  INTERIOR_TRIM: 'interiorTrim',
  OPTION: 'option',
  INSURANCE: 'insurance',
  WARRANTY: 'warranty',
  WARRANTY_TAXES_INCLUDED: 'warrantyTaxesIncluded',
  PROTECTION: 'protection',
  PROTECTION_TAXES_INCLUDED: 'protectionTaxesIncluded',
  COST_ADJUSTMENT: 'costAdjustment',
  PROFIT_ADJUSTMENT: 'profitAdjustment',
  PROFIT: 'profit',
  COMMISSION: 'commission',
  DEPOSIT: 'deposit',
  TAX: 'tax',
  TO_PAY: 'toPay',
  OTHER_FEE_TAXES_INCLUDED: 'otherFeeTaxesIncluded',
  BASE: 'base',
  FEE: 'fee',
  TOTAL: 'total',
  TAXES_AND_DUTIES: 'taxesAndDuties',
  OTHER_FEE: 'otherFee',
  CASH_DOWN: 'cashDown',
  TRADE_IN: 'tradeIn',
  TRADE_IN_TAXES_INCLUDED: 'tradeInTaxesIncluded',
  LIEN: 'lien',
  INSURANCE_TAXES_INCLUDED: 'insuranceTaxesIncluded',
  LIFE_INSURANCE: 'lifeInsurance',
  DISABILITY_INSURANCE: 'disabilityInsurance',
  IS_SELECTED: 'isSelected'
};
const EDIT_PARAMS = exports.EDIT_PARAMS = {
  IS_DUE_AT_DELIVERY: 'isDueAtDelivery',
  IS_OUTSIDE_TRANSACTION: 'isOutsideTransaction',
  AMOUNT_AT_DELIVERY: 'amountAtDelivery',
  AMOUNT_OUTSIDE_TRANSACTION: 'amountOutsideTransaction'
};
const ITEM_EMPTY = exports.ITEM_EMPTY = {
  [ITEM.COST]: 0,
  [ITEM.DESCRIPTION]: '',
  [ITEM.DISPLAY_CODE]: '',
  [ITEM.RETAIL]: 0
};
const NOTE_ITEM_EMPTY = exports.NOTE_ITEM_EMPTY = {
  [ITEM.PRICE]: 0,
  [ITEM.COST]: 0,
  [ITEM.SUBJECT]: '',
  [ITEM.DESCRIPTION]: ''
};