"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _resetValidation = _interopRequireDefault(require("./common/resetValidation"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var _default = state => ({
  ...state,
  ...(0, _resetValidation.default)()
});
exports.default = _default;