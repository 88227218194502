"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _commonsUtils = require("@sm360/commons-utils");
var _effects = require("redux-saga/effects");
var _actions = require("../../actions");
var _updatePayOptAndTransDetails = _interopRequireDefault(require("../api/updatePayOptAndTransDetails"));
var _selectors = require("../../selectors");
var _tabProtection = require("../../selectors/tabProtection");
var _tabProtection2 = require("../../../constants/tabProtection");
var _getItems = _interopRequireDefault(require("../items/getItems"));
var _getFNIItems = _interopRequireDefault(require("./getFNIItems"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function* _default(reloadFNIItems) {
  const {
    categories,
    name
  } = yield (0, _effects.select)(_tabProtection.getSelectedTab);
  const lgmItemsLoaded = yield (0, _effects.select)(_tabProtection.areLGMItemsLoaded);
  const bmwItemsLoaded = yield (0, _effects.select)(_tabProtection.areBMWItemsLoaded);
  const fniType = name;
  let items;
  let hasError = false;
  if (name !== _tabProtection2.TAB_PROTECTION.INSURANCE && name !== _tabProtection2.TAB_PROTECTION.PROTECTION && name !== _tabProtection2.TAB_PROTECTION.WARRANTY) {
    if (reloadFNIItems || name === _tabProtection2.TAB_PROTECTION.LGM && !lgmItemsLoaded || name === _tabProtection2.TAB_PROTECTION.BMW && !bmwItemsLoaded) {
      yield (0, _effects.put)(_actions.Creators.setIsFetchingFNIItems());
      items = yield (0, _effects.call)(_getFNIItems.default, fniType);
      const {
        transactionToken,
        error,
        errMsg,
        ...rest
      } = items;
      if (error && error !== 0) {
        hasError = true;
        yield (0, _effects.put)(_actions.Creators.setFNIItemsError(fniType, error, errMsg));
      } else {
        yield (0, _effects.put)(_actions.Creators.setFNIItemsError(fniType, 0, ''));
        if (!(0, _commonsUtils.isEmpty)(transactionToken)) {
          const paymentOptions = yield (0, _effects.select)(_selectors.getPaymentOptions);
          yield (0, _effects.call)(_updatePayOptAndTransDetails.default, {
            ...paymentOptions,
            transactionToken
          });
        }
        items = rest;
      }
      yield (0, _effects.put)(_actions.Creators.setFNIItemsAreLoaded(fniType));
      yield (0, _effects.put)(_actions.Creators.setIsFetchingFNIItemsDone());
    }
  } else {
    items = yield (0, _effects.call)(_getItems.default, categories);
  }
  if (items && !hasError) {
    yield (0, _effects.put)(_actions.Creators.reloadTabProtectionSuccess(name, items));
  }
}