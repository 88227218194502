"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _effects = require("redux-saga/effects");
var _deskingCommons = require("@sm360/desking-commons");
var _actions = require("./actions");
var _sagas = _interopRequireDefault(require("./sagas"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function* rootSaga() {
  yield (0, _effects.all)([(0, _effects.takeLatest)(_actions.Types.OPEN_IN_DESKING, _sagas.default.openInDesking), (0, _effects.takeLatest)(_actions.Types.INITIALIZE_STORE, _sagas.default.initializeStore), (0, _effects.takeLatest)(_actions.Types.SET_CURRENT_STEP, _sagas.default.setCurrentStep), (0, _effects.takeLatest)(_actions.Types.SEND_EXIT_CONFIRMATION, _sagas.default.sendExitConfirmation), (0, _effects.takeLatest)(_actions.Types.LOAD_MATCHES, _sagas.default.loadMatches), (0, _effects.takeLatest)(_actions.Types.GET_LEAD_INFO, _sagas.default.getLeadInfo),
  // Step 1
  (0, _effects.takeLatest)(_actions.Types.SELECT_MODEL_YEAR, _sagas.default.selectModelYear),
  // Step 2
  (0, _effects.takeLatest)(_actions.Types.SELECT_TRIM, _sagas.default.updateModelTrim), (0, _effects.takeLatest)(_actions.Types.SELECT_EXTERIOR_COLOUR, _sagas.default.updateModelTrim), (0, _effects.takeLatest)(_actions.Types.SELECT_INTERIOR_TRIM, _sagas.default.updateModelTrim),
  // Step 3, 4
  (0, _effects.takeLatest)(_actions.Types.UPDATE_MODEL_TRIM, _sagas.default.updateModelTrim),
  // Step 5
  (0, _effects.takeLatest)(_actions.Types.ADD_DELETE_STEP_OPTION_ITEMS, _sagas.default.addDeleteStepOptionItems),
  // Payment bar
  (0, _effects.takeLatest)(_actions.Types.SELECT_PURCHASE_METHOD, _sagas.default.updatePaymentOptions), (0, _effects.takeLatest)(_actions.Types.UPDATE_CASH_DOWN, _sagas.default.updatePaymentOptions), (0, _effects.takeLatest)(_actions.Types.UPDATE_LIEN, _sagas.default.updatePaymentOptions), (0, _effects.takeLatest)(_actions.Types.UPDATE_KM_PLAN, _sagas.default.updatePaymentOptions), (0, _effects.takeLatest)(_actions.Types.UPDATE_PAYMENT_FREQUENCY, _sagas.default.updatePaymentOptions), (0, _effects.takeLatest)(_actions.Types.UPDATE_PAYMENT_OPTIONS_SUCCESS, _sagas.default.updatePaymentOptionsSuccess), (0, _effects.takeLatest)(_actions.Types.UPDATE_TERM, _sagas.default.updatePaymentOptions), (0, _effects.takeLatest)(_actions.Types.UPDATE_TRADE_IN, _sagas.default.updatePaymentOptions),
  // Updating the priceIncreaseRollCount act with the same behavior than changing any payment option
  (0, _effects.takeLatest)(_deskingCommons.priceIncreaseTypes.SET_PRICE_INCREASE_ROLL_COUNT, _sagas.default.updatePaymentOptions),
  // Validation
  (0, _effects.takeLatest)(_actions.Types.APPLY_CONFLICT_RESOLUTION, _sagas.default.reloadAll), (0, _effects.takeLatest)(_actions.Types.CANCEL_CONFLICT, _sagas.default.reloadAll), (0, _effects.takeLatest)(_actions.Types.VALIDATE_SUCCESS, _sagas.default.reloadAll)]);
}
var _default = exports.default = rootSaga;