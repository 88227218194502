"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getQueryParams = void 0;
var _commonsApi = require("@sm360/commons-api");
var _filters = require("../../constants/filters");
var _selectors = require("../selectors");
var _filters2 = require("./filters");
const {
  KEYS: FILTER_KEYS
} = _commonsApi.FilterService;

/**
 * @param {Object} state
 * @returns {Object}
 */
const getQueryParams = state => {
  const {
    organizationUnitId
  } = (0, _selectors.getConfig)(state);
  const inventoryType = (0, _filters2.getCategory)(state);
  const isCategoryNew = (0, _filters2.getIsCategoryNew)(state);
  const priceRange = (0, _filters2.getPriceRange)(state);
  const odometerRange = (0, _filters2.getOdometerRange)(state);
  const params = {
    organizationUnitId,
    inventoryType,
    [FILTER_KEYS.TEXT]: (0, _filters2.getSearchText)(state),
    [FILTER_KEYS.MAX_PRICE]: priceRange.selectedMaxPrice,
    [FILTER_KEYS.ODOMETER]: odometerRange.selectedMaxOdometer,
    // Dropdowns
    [FILTER_KEYS.BODY]: (0, _filters2.getSelectedItem)(state, _filters.KEYS.BODY),
    [FILTER_KEYS.DRIVE]: (0, _filters2.getSelectedItem)(state, _filters.KEYS.DRIVE),
    [FILTER_KEYS.ENGINE]: (0, _filters2.getSelectedItem)(state, _filters.KEYS.ENGINE),
    [FILTER_KEYS.EXTERNAL_COLOR]: (0, _filters2.getSelectedItem)(state, _filters.KEYS.EXTERNAL_COLOR),
    [FILTER_KEYS.FUEL]: (0, _filters2.getSelectedItem)(state, _filters.KEYS.FUEL),
    [FILTER_KEYS.INTERNAL_COLOR]: (0, _filters2.getSelectedItem)(state, _filters.KEYS.INTERNAL_COLOR),
    [FILTER_KEYS.MAKE]: (0, _filters2.getSelectedItem)(state, _filters.KEYS.MAKE),
    [FILTER_KEYS.MODEL]: (0, _filters2.getSelectedItem)(state, _filters.KEYS.MODEL),
    [FILTER_KEYS.TRIM]: (0, _filters2.getSelectedItem)(state, _filters.KEYS.TRIM),
    [FILTER_KEYS.TRANSMISSION]: (0, _filters2.getSelectedItem)(state, _filters.KEYS.TRANSMISSION),
    [FILTER_KEYS.YEAR]: (0, _filters2.getSelectedItem)(state, _filters.KEYS.YEAR)
  };
  if (isCategoryNew) {
    params.builtVehiclesOnly = isCategoryNew;
  }
  return params;
};
exports.getQueryParams = getQueryParams;