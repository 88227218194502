"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transformUsersToOptions = exports.getAdditionalUsers = exports.SYSTEM_WEB_USER = void 0;
var _commonsUtils = require("@sm360/commons-utils");
const SYSTEM_WEB_USER = exports.SYSTEM_WEB_USER = {
  label: 'System Web',
  id: 75,
  value: '75'
};
const getAdditionalUsers = right => {
  switch (right) {
    case 'LeadsCanBeAssigned':
      return [SYSTEM_WEB_USER];
    default:
      return [];
  }
};
exports.getAdditionalUsers = getAdditionalUsers;
const transformUsersToOptions = (users, t) => {
  if (!Array.isArray(users)) return [];
  return users.map(u => ({
    id: u?.id,
    value: (0, _commonsUtils.nullOrString)(u?.id),
    label: (0, _commonsUtils.getUserLabel)(u, t),
    email: u?.email
  })).sort((a, b) => (0, _commonsUtils.compareUserLabel)(a, b, t));
};
exports.transformUsersToOptions = transformUsersToOptions;