"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _selectors = require("../selectors");
/**
 * Reset pagination to page 1
 *
 * @param {Object} state - Global store
 * @param {Object} payload
 * @return {Object} new composed global store
 */
var _default = (state, payload) => {
  const pagination = (0, _selectors.getPagination)(state);
  return {
    ...state,
    pagination: {
      ...pagination,
      pageNumber: 1
    },
    sort: {
      vehicleSortParameter: payload.sortType,
      direction: payload.direction
    }
  };
};
exports.default = _default;