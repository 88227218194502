"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _filters = require("../selectors/filters");
var _filters2 = require("../../constants/filters");
/**
 * @param {Object} state - Global store
 * @param {Object} payload
 * @return {Object} new composed global store
 */
var _default = (state, payload) => {
  const filters = (0, _filters.getFilters)(state);
  return {
    ...state,
    filters: {
      ...filters,
      odometerRange: {
        maxOdometer: _filters2.MAX_ODOMETER,
        selectedMaxOdometer: payload.selectedMaxOdometer
      }
    }
  };
};
exports.default = _default;