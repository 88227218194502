"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _actions = require("../../actions");
/**
 * @param {String} quoteId
 * @param {Object} queryParams
 * @param {Object} options
 * @param {Boolean} updateStore
 */
function* updateCommissionDetails(quoteId, queryParams, options, updateStore) {
  try {
    const response = yield (0, _effects.call)(_commonsApi.ShowroomService.desking.getCommissionDetails, quoteId, queryParams, options);
    if (updateStore === true) {
      yield (0, _effects.put)(_actions.Creators.loadCommissionSuccess(response));
    }
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'updateCommissions'));
  }
}
var _default = exports.default = updateCommissionDetails;