"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _deskingCommons = require("@sm360/desking-commons");
var _actions = require("../actions");
var _hasDeskingAccess = _interopRequireDefault(require("./api/hasDeskingAccess"));
var _hasPricesAccess = _interopRequireDefault(require("./api/hasPricesAccess"));
var _loadStepYear = _interopRequireDefault(require("./steps/loadStepYear"));
var _loadModel = _interopRequireDefault(require("./loadModel"));
var _loadPaymentOptions = _interopRequireDefault(require("./loadPaymentOptions"));
var _getDealerInfo = _interopRequireDefault(require("./api/getDealerInfo"));
var _getLeadInfo = _interopRequireDefault(require("./getLeadInfo"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Load the first step, once reducer done
 */
function _default(_ref) {
  let {
    queryParams
  } = _ref;
  return function* () {
    const hasAccess = yield (0, _effects.call)(_hasDeskingAccess.default);
    yield (0, _effects.put)(_actions.Creators.setPermission({
      hasDeskingAccess: hasAccess
    }));
    if (!hasAccess) {
      return;
    }
    yield (0, _effects.call)(_getLeadInfo.default);
    yield (0, _effects.call)(_getDealerInfo.default);
    yield (0, _effects.fork)(_hasPricesAccess.default);
    yield (0, _effects.put)(_deskingCommons.priceIncreaseActions.setPriceIncreaseRollCount(queryParams.upg));
    const availableChoices = yield (0, _effects.call)(_loadStepYear.default);

    // If only one choice, preload the full model and the payment options
    if (availableChoices === 1) {
      yield (0, _effects.call)(_loadModel.default);
      yield (0, _effects.call)(_loadPaymentOptions.default);
    }
    yield (0, _effects.put)(_actions.Creators.setLoadingSuccess());
  }();
}