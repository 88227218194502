"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("react");
var _dequal = require("dequal");
/**
 * Custom useEffect to ensure that dependencies are deeply compared
 * @param effectFunc The effect function to be called
 * @param deps The dependencies that the effect function depends on
 */
const useDeepEffect = (effectFunc, deps) => {
  const isFirst = (0, _react.useRef)(true);
  const prevDeps = (0, _react.useRef)(deps);
  (0, _react.useEffect)(() => {
    const isSame = prevDeps.current.every((obj, index) => (0, _dequal.dequal)(obj, deps[index]));
    if (isFirst.current || !isSame) {
      effectFunc();
    }
    isFirst.current = false;
    prevDeps.current = deps;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
var _default = exports.default = useDeepEffect;