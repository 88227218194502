"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.applyNegociationTransaction = void 0;
var _selectors = require("../selectors");
/**
 * @param {Object} state
 * @param {Object} negociationTransaction
 * @returns {Object}
 */
const applyNegociationTransaction = (state, negociationTransaction) => {
  const {
    cashDown,
    configurationId,
    kmPerYearPlan,
    lien,
    modelTrimCode,
    odometer,
    paymentFrequency,
    purchaseMethod,
    saleType,
    taxPlan,
    term,
    tradeIn,
    transactionToken,
    vehicleCondition
  } = negociationTransaction;
  const baseProps = (0, _selectors.composeNewBaseProps)(state, configurationId, modelTrimCode, transactionToken);
  return {
    baseProps,
    // Reset validation data
    validation: {
      isValid: true,
      mustResetSelection: false,
      negociationTransaction: {},
      validationItem: {},
      validationMessage: '',
      validResolutionCombinations: []
    },
    plan: {
      odometer,
      saleType,
      taxPlan,
      vehicleCondition
    },
    purchaseMethod: {
      cashDown,
      kmPerYearPlan,
      paymentFrequency,
      purchaseMethod,
      term
    },
    tradeIn: {
      lien,
      tradeIn
    }
  };
};
exports.applyNegociationTransaction = applyNegociationTransaction;