"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsUtils = require("@sm360/commons-utils");
var _actions = require("../../actions");
var _getModelYears = _interopRequireDefault(require("../api/getModelYears"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @returns {Number} Amount of available choices
 */
function* _default() {
  const response = yield (0, _effects.call)(_getModelYears.default);
  if (!Array.isArray(response)) {
    return 0;
  }

  // Keep only what we need
  const filteredResponse = response.map(item => (0, _commonsUtils.pick)(item, ['bestIncentives', 'bestIncentivesDisclaimer', 'cashPurchase', 'exteriorColourPhoto', 'finance', 'lease', 'make', 'model', 'year']));
  yield (0, _effects.put)(_actions.Creators.loadStepYearSuccess(filteredResponse));
  return filteredResponse.length;
}