"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _actions = require("../../actions");
var _selectors = require("../../selectors");
function* _default() {
  const {
    lang,
    location,
    organizationId,
    organizationUnitId
  } = yield (0, _effects.select)(_selectors.getConfig);
  const quoteId = yield (0, _effects.select)(_selectors.getQuoteId);
  const selectedTemplateId = yield (0, _effects.select)(_selectors.getAccSelectedTemplate);
  const queryParams = {
    orgId: organizationId,
    orgUnitId: organizationUnitId,
    location,
    lang
  };
  try {
    const templateData = yield (0, _effects.call)(_commonsApi.ShowroomService.desking.getAccTemplateData, quoteId, selectedTemplateId, queryParams);
    yield (0, _effects.put)(_actions.Creators.setAccTemplateData(templateData));
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'getAccounting'));
  }
}