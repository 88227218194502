"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _selectors = require("../selectors");
/**
 * @param {Object} state
 * @param {Object} payload - response
 * @returns {Object} new state
 */
var _default = (state, _ref) => {
  let {
    calculatedAdjustments
  } = _ref;
  return {
    ...state,
    paymentOptions: {
      ...(0, _selectors.getPaymentOptions)(state),
      calculatedAdjustments: {
        ...calculatedAdjustments
      }
    }
  };
};
exports.default = _default;