"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.vehicleShape = exports.paymentDetailsShape = exports.newIncentiveShape = void 0;
var _propTypes = _interopRequireDefault(require("prop-types"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const newIncentiveShape = exports.newIncentiveShape = _propTypes.default.shape({
  disclaimer: _propTypes.default.string,
  isMoreFavorable: _propTypes.default.bool
});
const vehicleShape = exports.vehicleShape = _propTypes.default.shape({
  bodyStyle: _propTypes.default.shape({
    name: _propTypes.default.string
  }),
  driveTrain: _propTypes.default.shape({
    name: _propTypes.default.string
  }),
  engine: _propTypes.default.shape({
    name: _propTypes.default.string
  }),
  exteriorColourPhotos: _propTypes.default.shape({
    front: _propTypes.default.string,
    rear: _propTypes.default.string,
    side: _propTypes.default.string
  }),
  features: _propTypes.default.arrayOf(_propTypes.default.string),
  gallery: _propTypes.default.arrayOf(_propTypes.default.string),
  hasExtendedWarranty: _propTypes.default.bool,
  isInspected: _propTypes.default.bool,
  isCertified: _propTypes.default.bool,
  inventoryMainPictureUrl: _propTypes.default.string,
  make: _propTypes.default.shape({
    name: _propTypes.default.string
  }),
  model: _propTypes.default.shape({
    name: _propTypes.default.string
  }),
  modelTrim: _propTypes.default.shape({
    name: _propTypes.default.string
  }),
  stockNumber: _propTypes.default.string,
  transmission: _propTypes.default.shape({
    name: _propTypes.default.string
  }),
  vin: _propTypes.default.string,
  year: _propTypes.default.number
});
const paymentDetailsShape = exports.paymentDetailsShape = _propTypes.default.shape({
  cashDown: _propTypes.default.number,
  exclusionsDisclaimer: _propTypes.default.string,
  inclusionsDisclaimer: _propTypes.default.string,
  kmPerYearPlan: _propTypes.default.number,
  paymentFrequency: _propTypes.default.number,
  purchaseMethod: _propTypes.default.oneOf(['cash', 'finance', 'lease', 'financeWithBalloon', 'singlePaymentLease']),
  rebate: _propTypes.default.number,
  sellingPrice: _propTypes.default.number,
  term: _propTypes.default.shape({
    apr: _propTypes.default.number,
    payment: _propTypes.default.number,
    paymentTaxes: _propTypes.default.number,
    term: _propTypes.default.number,
    totalPayment: _propTypes.default.number
  }),
  tradeIn: _propTypes.default.number
});