"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.KEYS = void 0;
const KEYS = exports.KEYS = {
  BODY: 'frameStyleIds',
  DRIVE: 'driveTrainIds',
  ENGINE: 'engineIds',
  EXTERNAL_COLOR: 'exteriorColorIds',
  FUEL: 'fuelIds',
  INTERNAL_COLOR: 'interiorColorIds',
  MAKE: 'makeIds',
  MODEL: 'modelIds',
  TEXT: 'textSearch',
  TRIM: 'trimIds',
  TRANSMISSION: 'transmissionIds',
  YEAR: 'years',
  MIN_SALE_PRICE: 'minSalePrice',
  MAX_SALE_PRICE: 'maxSalePrice',
  MIN_ODOMETER: 'minOdometer',
  MAX_ODOMETER: 'maxOdometer'
};