"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/**
 * @param {Object} state
 * @param {Object} payload - { orgRights }
 * @returns {Object}
 */
const setDealerInfo = (state, _ref) => {
  let {
    dealerInfo
  } = _ref;
  return {
    ...state,
    dealerInfo: {
      ...dealerInfo
    }
  };
};
var _default = exports.default = setDealerInfo;