"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _paymentOptions = require("../../redux/selectors/paymentOptions");
var _PaymentBar = _interopRequireDefault(require("./PaymentBar"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} state - Global state
 * @returns {Object} injected props
 */
const mapStateToProps = state => ({
  isCashSelected: (0, _paymentOptions.getIsCashSelected)(state),
  isLeaseSelected: (0, _paymentOptions.getIsLeaseSelected)(state)
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps))(_PaymentBar.default);