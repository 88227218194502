"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _reloadTabItems = _interopRequireDefault(require("./items/reloadTabItems"));
var _reloadTabProtection = _interopRequireDefault(require("./protection/reloadTabProtection"));
var _loadMatches = _interopRequireDefault(require("./loadMatches"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function* _default() {
  yield (0, _effects.spawn)(_reloadTabItems.default);
  yield (0, _effects.spawn)(_reloadTabProtection.default);
  yield (0, _effects.spawn)(_loadMatches.default);
}