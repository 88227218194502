"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkForErrors = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _TradeInVehicleHelpers = require("./TradeInVehicleHelpers");
const validateFormField = (name, value) => {
  switch (name) {
    case 'year':
      return value && !Number.isNaN(Number(value)) && value > 1900 && value <= 9999;
    case 'make':
    case 'model':
    case 'exteriorColor':
      return value?.length > 0;
    case 'kilometers':
      return value && !Number.isNaN(Number(value)) && value > 0 && value <= 9999999;
    case 'vin':
      return (0, _commonsUtils.optional)(_commonsUtils.validVin)(value);
    case 'payment':
    case 'balance':
      return value && !Number.isNaN(Number(value));
    case 'expirationDate':
      const match = value && /^(1[0-2]|0[1-9]|\d)\/(2\d{3}|19\d{2})$/.test(value);
      if (match) {
        const splittedDate = value.split('/');
        const month = splittedDate[0];
        const formattedMonth = Number(month);
        const year = splittedDate[1];
        const expiration = new Date(year, formattedMonth - 1, 1);
        const now = new Date();
        const currentMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        const isValid = currentMonth <= expiration;
        return isValid;
      }
      return false;
    default:
      return true;
  }
};
const checkForErrors = tradeIn => {
  const {
    paymentType,
    payment,
    balance,
    expirationDate,
    ...otherValues
  } = tradeIn;
  let validateValues = {
    ...otherValues
  };
  if (paymentType) {
    if (!(0, _TradeInVehicleHelpers.isPaid)(paymentType) && !(0, _TradeInVehicleHelpers.isUnknown)(paymentType)) {
      validateValues = {
        ...validateValues
      };
    }
    if ((0, _TradeInVehicleHelpers.isLoan)(paymentType)) {
      validateValues = {
        ...validateValues
      };
    }
  }
  return Object.entries(validateValues).reduce((acc, formField) => {
    const [fieldName, fieldValue] = formField;
    const isValid = validateFormField(fieldName, fieldValue);
    if (!isValid) {
      acc[fieldName] = true;
    }
    return acc;
  }, {});
};
exports.checkForErrors = checkForErrors;