"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _actions = require("../../redux/actions");
var _selectors = require("../../redux/selectors");
var _CashPurchaseCard = _interopRequireDefault(require("./CashPurchaseCard"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} state
 * @returns {Object}
 */
const mapStateToProps = state => ({
  cashPurchaseOption: (0, _selectors.getCashPurchaseOption)(state),
  deposit: (0, _selectors.getCashPurchaseDeposit)(state),
  isReadOnly: (0, _selectors.getIsReadOnly)(state),
  isSelected: (0, _selectors.getIsCashSelected)(state),
  toPay: (0, _selectors.getCashPurchaseToPay)(state)
});
const mapDispatchToProps = dispatch => ({
  selectCashPlan: () => dispatch(_actions.Creators.selectCashPlan())
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps))(_CashPurchaseCard.default);