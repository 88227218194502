"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _selectors = require("../../selectors");
var _updatePayOptAndTransDetails = _interopRequireDefault(require("./updatePayOptAndTransDetails"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} payload - Data from action
 */
function* updateDueAtDeliveryRoll(payload) {
  const quoteId = yield (0, _effects.select)(_selectors.getQuoteId);
  const queryParams = yield (0, _effects.select)(_selectors.getShowroomQueryParams);
  const paymentOptions = yield (0, _effects.select)(_selectors.getPaymentOptions);
  const {
    ...paymentOptionsData
  } = paymentOptions;
  const body = {
    calculationTargetType: payload.targetType,
    calculationTargetValue: parseFloat(payload.value),
    ...paymentOptionsData
  };
  try {
    const response = yield (0, _effects.call)(_commonsApi.ShowroomService.desking.dueAtDeliveryRoll, quoteId, queryParams, body);
    const {
      isValid
    } = response;
    if (isValid) {
      if (payload.handleIsValid && typeof payload.handleIsValid === 'function') {
        payload.handleIsValid(true);
      }
      yield (0, _effects.call)(_updatePayOptAndTransDetails.default, {
        ...paymentOptions,
        ...response.negociationTransaction
      });
    }
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'updateQuote'));
  }
}
var _default = exports.default = updateDueAtDeliveryRoll;