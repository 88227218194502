"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.REFERENCE_RELATIONSHIP = void 0;
const REFERENCE_RELATIONSHIP = exports.REFERENCE_RELATIONSHIP = [{
  value: 'Friend',
  translationKey: 'FINANCING.APPLICANT_INFO.REFERENCE_RELATIONSHIP.FRIEND'
}, {
  value: 'Landlord',
  translationKey: 'FINANCING.APPLICANT_INFO.REFERENCE_RELATIONSHIP.LANDLORD'
}, {
  value: 'Relative',
  translationKey: 'FINANCING.APPLICANT_INFO.REFERENCE_RELATIONSHIP.RELATIVE'
}, {
  value: 'Other',
  translationKey: 'FINANCING.APPLICANT_INFO.REFERENCE_RELATIONSHIP.OTHER'
}];
var _default = exports.default = {
  REFERENCE_RELATIONSHIP
};