"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _selectors = require("../selectors");
/**
 * @param {Object} state
 * @returns {Object}
 */
var _default = state => ({
  ...state,
  nav: {
    ...(0, _selectors.getNav)(state),
    hasSentExitConfirmation: true
  }
});
exports.default = _default;