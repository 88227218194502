"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _selectors = require("../selectors");
var _default = (state, _ref) => {
  let {
    commission
  } = _ref;
  const paymentOptions = (0, _selectors.getPaymentOptions)(state);
  const {
    transactionToken
  } = paymentOptions;
  const transTokenToStore = commission.transactionToken || transactionToken;
  return {
    ...state,
    commission,
    paymentOptions: {
      ...paymentOptions,
      transactionToken: transTokenToStore
    }
  };
};
exports.default = _default;