"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  NEVER: 'never',
  MINORACCIDENT: 'minorAccident',
  MAJOR: 'major'
};