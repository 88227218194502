"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsUtils = require("@sm360/commons-utils");
var _deskingCommons = require("@sm360/desking-commons");
var _vehicles = require("../selectors/vehicles");
var _requestCatalogVehicles = require("../selectors/requestCatalogVehicles");
var _selectors = require("../selectors");
var _addVehicleToGarage = _interopRequireDefault(require("./addVehicleToGarage"));
var _assignLead = _interopRequireDefault(require("./assignLead"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function* _default(payload) {
  const navigate = yield (0, _effects.getContext)('navigate');
  const {
    priceIncreaseRollCount
  } = yield (0, _effects.select)(_requestCatalogVehicles.getBody);
  const addedVehicles = yield (0, _effects.select)(_vehicles.getAddedVehicles);
  const {
    vehicle
  } = payload;
  const vehicleAdded = addedVehicles.find(addedVehicle => addedVehicle.vehicleId === vehicle.vehicleId);
  const salesPersonnel = yield (0, _effects.select)(_selectors.getSalesPersonnel);
  const deskingWidgetParams = yield (0, _effects.select)(_selectors.getDeskingWidgetParams);
  const {
    salesPerson
  } = salesPersonnel;
  const hasSalesPerson = salesPerson && Object.entries(salesPerson).length > 0;
  if (!hasSalesPerson) {
    yield (0, _effects.call)(_assignLead.default, _deskingCommons.SALES_PERSONNEL.SALES_PERSON);
  }
  if (vehicleAdded) {
    navigate((0, _deskingCommons.getDeskingPath)({
      ...deskingWidgetParams,
      quoteId: vehicleAdded.quoteId,
      upg: priceIncreaseRollCount
    }));
  } else {
    const quote = yield (0, _addVehicleToGarage.default)(payload);
    if (!(0, _commonsUtils.isEmpty)(quote)) {
      navigate((0, _deskingCommons.getDeskingPath)({
        ...deskingWidgetParams,
        quoteId: quote.id,
        upg: priceIncreaseRollCount
      }));
    }
  }
}