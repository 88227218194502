"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _filters = require("../selectors/filters");
var _loadCatalogVehicles = _interopRequireDefault(require("./loadCatalogVehicles"));
var _loadInventoryVehicles = _interopRequireDefault(require("./loadInventoryVehicles"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function* _default() {
  const isCatalog = yield (0, _effects.select)(_filters.getIsCategoryCatalog);
  if (isCatalog) {
    yield (0, _effects.call)(_loadCatalogVehicles.default);
  } else {
    yield (0, _effects.call)(_loadInventoryVehicles.default);
  }
}