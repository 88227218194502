"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _selectors = require("../../redux/selectors");
var _vehicles = require("../../redux/selectors/vehicles");
var _filters = require("../../redux/selectors/filters");
var _common = require("../../redux/selectors/common");
var _actions = require("../../redux/actions");
var _Layout = _interopRequireDefault(require("./Layout"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} state
 * @returns {Object}
 */
const mapStateToProps = state => {
  const {
    totalElements: total
  } = (0, _selectors.getPagination)(state);
  return {
    category: (0, _filters.getCategory)(state),
    isCatalog: (0, _filters.getIsCategoryCatalog)(state),
    isHidingPrices: (0, _common.getIsHidingPrices)(state),
    leadId: (0, _selectors.getLeadId)(state),
    taxIncluded: (0, _common.getHasTaxesIncluded)(state),
    total,
    vehicles: (0, _vehicles.getVehicles)(state),
    dealersList: (0, _selectors.getDealersList)(state),
    onlineVehiclesOnly: (0, _filters.getIsOnlineVehiclesOnly)(state),
    dealerVehiclesOnly: (0, _filters.getIsDealerVehiclesOnly)(state)
  };
};

/**
 * @param {Function} dispatch
 * @returns {Object}
 */
const mapDispatchToProps = dispatch => ({
  openVehicleInDesking: vehicle => dispatch(_actions.Creators.openVehicleInDesking(vehicle)),
  openVehicleInBuilder: vehicle => dispatch(_actions.Creators.openVehicleInBuilder(vehicle)),
  setOnlineVehiclesOnly: onlineVehiclesOnly => dispatch(_actions.Creators.setOnlineVehiclesOnly(onlineVehiclesOnly)),
  setDealerVehiclesOnly: dealerVehiclesOnly => dispatch(_actions.Creators.setDealerVehiclesOnly(dealerVehiclesOnly)),
  applyFilters: () => dispatch(_actions.Creators.applyFilters())
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps))(_Layout.default);