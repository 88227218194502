"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _deskingError = require("@sm360/desking-error");
var _actions = require("../../redux/actions");
var _selectors = require("../../redux/selectors");
var _ConfirmModifications = _interopRequireDefault(require("./ConfirmModifications"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const mapStateToProps = state => {
  const isQuoteChanged = (0, _selectors.getIsQuoteChanged)(state);
  const quoteChangeType = (0, _selectors.getQuoteChangeType)(state);
  return {
    isQuoteChanged,
    quoteChangeType
  };
};
const mapDispatchToProps = dispatch => ({
  updateQuoteInformation: options => dispatch(_actions.Creators.updateQuoteInformation(options)),
  refreshQuote: () => dispatch(_actions.Creators.refreshQuote()),
  setFinancingDatesChanged: isFinancingDatesChanged => dispatch(_actions.Creators.setFinancingDatesChanged(isFinancingDatesChanged)),
  changeSelectedQuote: selectedMatch => dispatch(_actions.Creators.changeSelectedQuote(selectedMatch))
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps), _deskingError.withErrorWrapper)(_ConfirmModifications.default);