"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tabProtection = require("../selectors/tabProtection");
/**
 * @param {Object} state
 * @param {Object} payload - { index }
 * @returns {Object}
 */
var _default = (state, _ref) => {
  let {
    index
  } = _ref;
  return {
    ...state,
    tabProtection: {
      ...(0, _tabProtection.getTabProtection)(state),
      selectedIndex: index
    }
  };
};
exports.default = _default;