"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _actions = require("../actions");
var _requestInventoryFilters = require("../selectors/requestInventoryFilters");
const {
  fetchAll
} = _commonsApi.FilterService;

/**
 * @param {String|Undefined} lastUpdatedName - The last field we changed
 */
function* _default(lastUpdatedName) {
  const queryParams = yield (0, _effects.select)(_requestInventoryFilters.getQueryParams);
  try {
    const response = yield (0, _effects.call)(fetchAll, queryParams);
    yield (0, _effects.put)(_actions.Creators.loadInventoryFiltersSuccess(response, lastUpdatedName));
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'getFilters'));
  }
}