"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = (state, _ref) => {
  let {
    perfectList,
    similarList
  } = _ref;
  return {
    ...state,
    matches: {
      selectedVehicle: state.matches.selectedVehicle,
      perfectList,
      similarList
    }
  };
};
exports.default = _default;