"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("react");
var _appsCore = require("@sm360/apps-core");
var _state = require("../config/state.manager");
var _users = require("../api/users.service");
const useRefreshUser = () => {
  const {
    oidcUser
  } = (0, _appsCore.useReactOidc)();
  const {
    dispatch
  } = (0, _react.useContext)(_state.CrudContext);
  const appUserId = Number(oidcUser.profile.sub);
  const refreshUser = (0, _react.useCallback)(() => {
    dispatch({
      type: 'userLoading'
    });
    (0, _users.getUserById)(appUserId).then(response => dispatch({
      type: 'setUser',
      user: response.data
    })).finally(() => dispatch({
      type: 'userLoaded'
    }));
  }, [dispatch, appUserId]);
  return {
    refreshUser
  };
};
var _default = exports.default = useRefreshUser;