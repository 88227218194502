"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _filters = require("../../../redux/selectors/filters");
var _actions = require("../../../redux/actions");
var _SelectCategory = _interopRequireDefault(require("./SelectCategory"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} state
 * @returns {Object}
 */
const mapStateToProps = state => ({
  value: (0, _filters.getCategory)(state)
});

/**
 * @param {Function} dispatch
 * @returns {Object}
 */
const mapDispatchToProps = dispatch => ({
  updateCategory: category => dispatch(_actions.Creators.updateCategory(category))
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps))(_SelectCategory.default);