"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESOURCES = void 0;
const RESOURCES = exports.RESOURCES = {
  LEAD: {
    id: 'LEAD',
    transKey: 'createResource.resources.LEAD',
    icon: 'fa-folder',
    rights: ['EditLead'],
    warningTransKey: 'commons.rightsWarning.EditLead'
  },
  CONTACT: {
    id: 'CONTACT',
    transKey: 'createResource.resources.CONTACT',
    icon: 'fa-user',
    rights: ['EditLead', 'ContactAccess'],
    warningTransKey: 'commons.rightsWarning.ContactAccess'
  },
  QUOTE: {
    id: 'QUOTE',
    transKey: 'createResource.resources.QUOTE',
    icon: 'fa-car',
    rights: ['DeskingAccess'],
    rightsToDisplay: ['DeskingAccess']
  }
};