"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getClientContacts = void 0;
var _appsCore = require("@sm360/apps-core");
const CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;
const getClientContacts = async _ref => {
  let {
    id,
    dispatch
  } = _ref;
  return await _appsCore.axios.get(`${CRM_ENDPOINT}/contacts/${id}/clientContacts`).then(response => dispatch({
    type: 'init',
    contactClients: response.data
  })).catch(() => dispatch({
    type: 'init',
    contactClients: []
  }));
};
exports.getClientContacts = getClientContacts;