"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isLast = exports.isFirst = exports.generatePages = void 0;
/**
 * If the page is the first
 *
 * @param {Number[]} pages
 * @param {Number} page
 * @returns {Boolean}
 */
const isFirst = (pages, page) => Array.isArray(pages) && page === pages[0];

/**
 * If the page is the last
 *
 * @param {Number[]} pages
 * @param {Number} page
 * @returns {Boolean}
 */
exports.isFirst = isFirst;
const isLast = (pages, page) => Array.isArray(pages) && page === pages[pages.length - 1];

/**
 * If the given page is "near" the selected current page.
 * The goal is to show the button aound the current page.
 * If we are on page 4, we want to show the buttons page 3 and page 5, but not pages "far" from current one.
 *
 * @param {Number} currentPage
 * @param {Number} page
 * @returns {Boolean}
 */
exports.isLast = isLast;
const isAround = (currentPage, page) => [currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2].some(num => num === page);

/**
 * Tag '-1' all the pages we want to hide
 * For a current page at 4, we should have [1, -1, 3, 4, 5, -1, -1, -1, -1, 10]
 *
 * @param {Number[]} pages
 * @param {Number} currentPage
 * @returns {Number[]}
 */
const detectUnusedPages = (pages, currentPage) => pages.map(page => isAround(currentPage, page) ? page : -1);
// pages.map((page) => (isFirst(pages, page) || isLast(pages, page) || isAround(currentPage, page) ? page : -1));

/**
 * At this point we still have an array the size of the total pages.
 * We want only one element to represent a "..." between too "far away" pages.
 *
 * We are checking series of multiple '-1', to transform them into a single '-1' and 'null' for the next others.
 * So from this
 *      [1, -1, 3, 4, 5, -1, -1, -1, -1, 10]
 * We want that
 *      [1, -1, 3, 4, 5, -1, null, null, null, 10]
 *
 * At the end we are doing  simple filter to remove the 'null' values, to have something like that
 *      [1, -1, 3, 4, 5, -1, 10]
 *
 * @param {Number[]} pages
 * @returns {Array}
 */
const removeUnusedPages = pages => {
  let previousValue;
  let hasToBeRemoved;
  const reducedPages = pages.reduce((acc, page) => {
    previousValue = acc.length > 0 && acc[acc.length - 1];

    // Page is "dummy" and the previous in the list is dummy are already null
    hasToBeRemoved = page === -1 && (previousValue === -1 || previousValue === null);
    return acc.concat(hasToBeRemoved ? null : page);
  }, []);
  return reducedPages.filter(val => val !== null);
};

/**
 * @param {Number} currentPage
 * @param {Number} size
 * @param {Number} total
 * @returns {Number[]}
 */
const generatePages = (currentPage, size, total) => {
  // Need min size and if no content
  if (size < 1 || total === 0) {
    return [];
  }

  // Generate total page list, will create an Array like that [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  const totalPages = Array.from(Array(Math.ceil(total / size)), (v, i) => i + 1);

  // Do nothing if 1 page
  if (totalPages < 2) {
    return [];
  }

  // For a currentPage at 4, we should have that [1, -1, 3, 4, 5, -1, -1, -1, -1, 10]
  const transformedPages = detectUnusedPages(totalPages, currentPage);

  // And the answer looks like this [1, -1, 3, 4, 5, -1, 10]
  return removeUnusedPages(transformedPages);
};
exports.generatePages = generatePages;