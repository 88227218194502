"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _deskingError = require("@sm360/desking-error");
var _selectors = require("../../../redux/selectors");
var _actions = require("../../../redux/actions");
var _TabTransactionDetails = _interopRequireDefault(require("./TabTransactionDetails.js"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const mapStateToProps = state => ({
  transaction: (0, _selectors.getQuoteTransaction)(state),
  hasCostAndProfitAccess: (0, _selectors.getHasCostAndProfitAccess)(state),
  displayCostAndProfit: (0, _selectors.getDisplayCostAndProfit)(state),
  displayPadValue: (0, _selectors.getDisplayPadValue)(state)
});

/**
 * @param {Function} dispatch
 * @returns {Object} injected props
 */
const mapDispatchToProps = dispatch => ({
  setDisplayCostAndProfit: isOpen => dispatch(_actions.Creators.setDisplayCostAndProfit(isOpen)),
  loadCommission: () => dispatch(_actions.Creators.loadCommission())
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps), _deskingError.withErrorWrapper)(_TabTransactionDetails.default);