"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsUtils = require("@sm360/commons-utils");
var _actions = require("../actions");
var _content = require("../selectors/content");
var _stepTrim = require("../selectors/stepTrim");
var _validateModel = _interopRequireDefault(require("./api/validateModel"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Can come from different actions, so different payloads.
 * Try to find colours in payload, if not, get from store
 *
 * @param {Object} payload
 */
function _default(_ref) {
  let {
    exteriorColour,
    interiorTrim
  } = _ref;
  return function* () {
    let selectedExteriorColour;
    let selectedInteriorTrim;
    if ((0, _commonsUtils.isPlainObject)(exteriorColour)) {
      selectedExteriorColour = exteriorColour;
    } else {
      selectedExteriorColour = yield (0, _effects.select)(_content.getSelectedExteriorColour);
    }
    if ((0, _commonsUtils.isPlainObject)(interiorTrim)) {
      selectedInteriorTrim = interiorTrim;
    } else {
      selectedInteriorTrim = yield (0, _effects.select)(_stepTrim.getSelectedInteriorTrim);
    }
    const response = yield (0, _effects.call)(_validateModel.default, selectedExteriorColour, selectedInteriorTrim);
    if (!response) {
      return;
    }
    const {
      isValid,
      negociationTransaction
    } = response;
    if (isValid) {
      yield (0, _effects.put)(_actions.Creators.validateSuccess(negociationTransaction));
    } else {
      yield (0, _effects.put)(_actions.Creators.validateFailure(response));
    }
  }();
}