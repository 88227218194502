"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getValidationBody = exports.getRollBody = void 0;
var _selectors = require("../selectors");
/**
 * @param {Object} state
 * @returns {Object}
 */
const getBody = state => {
  const {
    cashPurchaseOption,
    financeOptions,
    ...paymentOptionsData
  } = (0, _selectors.getPaymentOptions)(state);
  return paymentOptionsData;
};

/**
 * @param {Object} state
 * @param {String} category
 * @param {Number} value
 * @returns {Object}
 */
const getRollBody = (state, category, value) => ({
  ...getBody(state),
  calculationTargetType: category,
  calculationTargetValue: value
});

/**
 * @param {Object} state
 * @param {String} category
 * @param {Number} value
 * @returns {Object}
 */
exports.getRollBody = getRollBody;
const getValidationBody = (state, category, value) => ({
  ...getBody(state),
  residualAdjustment: {
    category,
    value
  }
});
exports.getValidationBody = getValidationBody;