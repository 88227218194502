"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetExtraInfoFromAccount = exports.isUnknown = exports.isPaid = exports.isLoan = exports.getOtherColor = exports.formatVehicleColors = exports.formatShowroomVehicleFromInternalAndAccount = void 0;
var _PaymentTypes = _interopRequireDefault(require("../constants/PaymentTypes"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const uniqueArray = function (objects, uniqueBy) {
  let keepFirst = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  return Array.from(objects.reduce((map, e) => {
    let key = uniqueBy.map(key => [e[key], typeof e[key]]).flat().join('-');
    if (keepFirst && map.has(key)) return map;
    return map.set(key, e);
  }, new Map()).values());
};
const getOtherColor = t => ({
  description: t('trade-in.VEHICLE_DETAILS.OTHER'),
  htmlColour: 'linear-gradient(to right, red,orange,yellow,green,blue,indigo,violet)',
  colorCode: 'linear-gradient(to right, red,orange,yellow,green,blue,indigo,violet)'
});
exports.getOtherColor = getOtherColor;
const formatVehicleColors = function () {
  let vehicleColors = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  let t = arguments.length > 1 ? arguments[1] : undefined;
  const otherColor = getOtherColor(t);
  vehicleColors.push(otherColor);
  return uniqueArray(vehicleColors, ['htmlColour']);
};
exports.formatVehicleColors = formatVehicleColors;
const resetExtraInfoFromAccount = (vehicleExtraInfo, accountVehicleExtraInfo, inputName, inputValue) => {
  let resetedVehicleExtraInfo;
  if (inputValue === _PaymentTypes.default.PAID || inputValue === _PaymentTypes.default.UNKNOWN || inputValue !== accountVehicleExtraInfo.paymentType) {
    const {
      payment,
      balance,
      expirationDate,
      lienHolderName,
      ...otherValues
    } = vehicleExtraInfo || {};
    resetedVehicleExtraInfo = {
      ...otherValues,
      [inputName]: inputValue
    };
  } else {
    const {
      payment,
      balance,
      expirationDate,
      lienHolderName
    } = accountVehicleExtraInfo || {};
    resetedVehicleExtraInfo = {
      ...vehicleExtraInfo,
      ...{
        payment,
        balance,
        expirationDate,
        lienHolderName
      },
      [inputName]: inputValue
    };
  }
  // Removed keys with the value of undefined
  Object.keys(resetedVehicleExtraInfo).forEach(key => resetedVehicleExtraInfo[key] === undefined && delete resetedVehicleExtraInfo[key]);
  return resetedVehicleExtraInfo;
};
exports.resetExtraInfoFromAccount = resetExtraInfoFromAccount;
const formatShowroomVehicleFromInternalAndAccount = (tradeVehicle, internalTradeIn, accountTradeInVehicle) => {
  const transformed = {
    ...tradeVehicle
  };
  const {
    make,
    model,
    modelTrim,
    year,
    bodyStyle
  } = tradeVehicle.vehicle;
  const {
    vehicleCondition,
    accidentStatus,
    paymentType,
    payment,
    balance,
    expirationDate,
    lienHolderName
  } = internalTradeIn?.vehicleExtraInfo || {};
  transformed.accountId = accountTradeInVehicle?.accountId;
  transformed.id = accountTradeInVehicle?.id || '';
  transformed.vehicleId = '';
  transformed.make = make;
  transformed.model = model;
  transformed.trim = modelTrim;
  // eslint-disable-next-line prefer-destructuring
  transformed.bodyStyle = bodyStyle[0];
  transformed.vin = internalTradeIn.vin;
  transformed.vehicle.vin = internalTradeIn.vin;
  transformed.year = year;
  transformed.vehicleOptions = [];
  transformed.vehicleCondition = vehicleCondition;
  transformed.accidentStatus = accidentStatus;
  transformed.paymentType = paymentType;
  // Add lease or finance payment extra info
  if (paymentType === _PaymentTypes.default.LEASE || paymentType === _PaymentTypes.default.LOAN) {
    transformed.payment = payment;
    transformed.balance = balance;
    transformed.lien.amount = balance;
    transformed.lien.owner = lienHolderName;
    if (expirationDate) {
      const [dueMonth, dueYear] = expirationDate.split('/');
      transformed.dueMonth = dueMonth;
      transformed.dueYear = dueYear;
    }
  }
  if (!transformed.vehicle?.bodyColour) transformed.exteriorColor = 'unknown';
  if (!transformed.vehicle?.interiorColour) transformed.interiorColor = 'unknown';
  if (typeof internalTradeIn?.evaluatorValues === 'object' && Object.keys(internalTradeIn.evaluatorValues).length > 0) {
    transformed.evaluatorValues = internalTradeIn.evaluatorValues;
  }
  return transformed;
};
exports.formatShowroomVehicleFromInternalAndAccount = formatShowroomVehicleFromInternalAndAccount;
const isUnknown = paymentType => paymentType === _PaymentTypes.default.UNKNOWN;
exports.isUnknown = isUnknown;
const isPaid = paymentType => paymentType === _PaymentTypes.default.PAID;
exports.isPaid = isPaid;
const isLoan = paymentType => paymentType === _PaymentTypes.default.LOAN;
exports.isLoan = isLoan;