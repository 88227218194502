"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _filters = require("../selectors/filters");
/**
 * @param {Object} state - Global store
 * @param {Object} payload - { value }
 * @return {Object} new composed global store
 */
var _default = (state, _ref) => {
  let {
    onlineVehiclesOnly
  } = _ref;
  const filters = (0, _filters.getFilters)(state);
  return {
    ...state,
    filters: {
      ...filters,
      onlineVehiclesOnly
    }
  };
};
exports.default = _default;