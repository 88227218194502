"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useVehicleSubCategories = void 0;
var _appsCore = require("@sm360/apps-core");
var _VehicleStaticDataService = require("../../api/VehicleStaticDataService");
var _utils = require("../../tools/utils");
const transformSubCategoriesToOptions = data => (0, _utils.transformDataToOptions)(data, 'frameCategory', 'id', 'id', true);

/**
 * @returns {Object} a Query object
 */

const useVehicleSubCategories = params => {
  const {
    category,
    select,
    ...rest
  } = params || {};
  return (0, _appsCore.useQuery)({
    queryKey: ['vehicleSubCategories', {
      category
    }],
    queryFn: () => (0, _VehicleStaticDataService.getVehicleSubCategories)(category),
    select: select || transformSubCategoriesToOptions,
    ...rest
  });
};
exports.useVehicleSubCategories = useVehicleSubCategories;