"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateLeadUser = exports.updateLeadSalesman = exports.updateLeadSalesManager = exports.getLeadSalesTeam = void 0;
var _appsCore = require("@sm360/apps-core");
const CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;

// Get all users assigned to a lead
const getLeadSalesTeam = async _ref => {
  let {
    leadId
  } = _ref;
  return await _appsCore.axios.get(`${CRM_ENDPOINT}/leads/${leadId}/salesTeam`);
};
exports.getLeadSalesTeam = getLeadSalesTeam;
const updateLeadUser = async _ref2 => {
  let {
    leadId,
    newUserId,
    userId,
    orgUnitId
  } = _ref2;
  return await _appsCore.axios.put(`${CRM_ENDPOINT}/leads/${leadId}/assignUser?newUserId=${newUserId}&userId=${userId}&currentOrganizationUnitId=${orgUnitId}`, null, {
    ignoreAppsCoreCatch: true
  });
};
exports.updateLeadUser = updateLeadUser;
const updateLeadSalesman = async _ref3 => {
  let {
    leadId,
    newSalesmanUserId,
    userId
  } = _ref3;
  return await _appsCore.axios.put(`${CRM_ENDPOINT}/leads/${leadId}/assignSalesman?newSalesmanUserId=${newSalesmanUserId}&userId=${userId}`, null, {
    ignoreAppsCoreCatch: true
  });
};
exports.updateLeadSalesman = updateLeadSalesman;
const updateLeadSalesManager = async (leadId, newSalesManagerId, userId) => {
  return await _appsCore.axios.put(`${CRM_ENDPOINT}/leads/${leadId}/assignSalesManager?newSalesManagerId=${newSalesManagerId}&userId=${userId}`, null, {
    ignoreAppsCoreCatch: true
  });
};
exports.updateLeadSalesManager = updateLeadSalesManager;