"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _selectors = require("../selectors");
/**
 * @param {Object} state
 * @param {Object} payload - { permission }
 * @returns {Object}
 */
var _default = (state, _ref) => {
  let {
    permission
  } = _ref;
  return {
    ...state,
    permissions: {
      ...(0, _selectors.getPermissions)(state),
      ...permission
    }
  };
};
exports.default = _default;