"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _reactRedux = require("react-redux");
var _payment = require("../../../../constants/payment");
var _actions = require("../../../../redux/actions");
var _selectors = require("../../../../redux/selectors");
var _SelectKmPlan = _interopRequireDefault(require("./SelectKmPlan"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} state - Global state
 * @returns {Object} injected props
 */
const mapStateToProps = state => {
  const financePlanMeta = (0, _selectors.getSelectedFinancePlan)(state);
  const {
    kmPerYearPlans
  } = financePlanMeta;
  const financeOptions = (0, _selectors.getSelectedFinanceOptions)(state);
  const {
    kmPerYearPlan
  } = financeOptions;
  const isReadOnly = (0, _selectors.getIsReadOnly)(state);
  return {
    choices: kmPerYearPlans,
    value: kmPerYearPlan,
    isReadOnly
  };
};

/**
 * @param {Function} dispatch
 * @returns {Object} injected props
 */
const mapDispatchToProps = dispatch => ({
  changeKmPlan: value => dispatch(_actions.Creators.changePaymentOptions({
    [_payment.KEY_KMPLAN]: parseInt(value, 10)
  }))
});
var _default = exports.default = (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_SelectKmPlan.default);