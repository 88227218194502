"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsUtils = require("@sm360/commons-utils");
var _uiLibrary = require("@sm360/ui-library");
var _actions = require("../../actions");
var _selectors = require("../../selectors");
var _updatePayOptAndTransDetails = _interopRequireDefault(require("../api/updatePayOptAndTransDetails"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const {
  toast
} = _uiLibrary.Toast;

/**
 * Not attached to any actions, but this a common behavior when the user want to change something.
 * Showroom APIs always send back the same response to validate the change asked by the user.
 *
 * @param {Object} response
 * @param {String} conflictPicture
 * @returns {Boolean} If validation passed or not
 */
function* _default(response, conflictPicture) {
  const t = yield (0, _effects.getContext)('t');

  // Safety Type check
  if (!(0, _commonsUtils.isPlainObject)(response)) {
    toast.error(t('deskingCommon.error.validation'));
    return false;
  }

  // If a conflict needs to be resolve/validate
  if (response.isValid === false) {
    yield (0, _effects.put)(_actions.Creators.handleConflictValidation(response, conflictPicture));
    return false;
  }
  const paymentOptions = yield (0, _effects.select)(_selectors.getPaymentOptions);

  // Reload all data with new paymentOptions from the response
  yield (0, _effects.call)(_updatePayOptAndTransDetails.default, {
    ...paymentOptions,
    ...response.negociationTransaction,
    callCommission: true
  });
  return true;
}