"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useLeadSources = void 0;
var _react = require("react");
var _appsCore = require("@sm360/apps-core");
var _LeadService = require("../../api/LeadService");
var _utils = require("../../tools/utils");
var _utils2 = require("./utils");
/**
 * Returns a list of lead sources (those configured for a dealer if specified)
 * @param {number} organizationUnitId
 * @param {boolean} [groupedBySuggestion]
 * @returns {Object} a Query object
 */
const useLeadSources = params => {
  const {
    t
  } = (0, _appsCore.useTranslation)();
  const {
    organizationUnitId,
    groupedBySuggestion,
    select,
    ...rest
  } = params || {};
  const queryKey = ['leads', 'sources', {
    organizationUnitId
  }];
  const queryFn = () => (0, _LeadService.getLeadSources)(organizationUnitId);
  const defaultSelect = (0, _react.useCallback)(data => groupedBySuggestion && organizationUnitId ? (0, _utils2.groupSourcesBySuggestions)(data, t) : (0, _utils.transformDataToOptions)(data, 'sourceType'), [groupedBySuggestion, organizationUnitId, t]);
  return (0, _appsCore.useQuery)({
    queryKey,
    queryFn,
    select: select || defaultSelect,
    ...rest
  });
};
exports.useLeadSources = useLeadSources;