"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTask = exports.editTask = exports.createTask = void 0;
var _appsCore = require("@sm360/apps-core");
const CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;

// Get Task
const getTask = async id => {
  return await _appsCore.axios.get(`${CRM_ENDPOINT}/tasks/${id}`);
};

// Edit Task
exports.getTask = getTask;
const editTask = async _ref => {
  let {
    body
  } = _ref;
  return await _appsCore.axios.put(`${CRM_ENDPOINT}/tasks/${body.id}`, body);
};

// Create new task from leads
exports.editTask = editTask;
const createTask = async _ref2 => {
  let {
    body,
    contactId,
    leadId,
    organizationId
  } = _ref2;
  const contactIdQuery = contactId ? `contactId=${contactId}` : '';
  const leadIdQuery = leadId ? `&leadId=${leadId}` : '';
  const requestBody = {
    ...body,
    organizationId,
    result: 'SUCCESS',
    type: 'TASK_CREATED',
    userId: body.assignedUserId
  };
  return await _appsCore.axios.post(`${CRM_ENDPOINT}/tasks?${contactIdQuery}${leadIdQuery}`, requestBody);
};
exports.createTask = createTask;