"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("react");
var _appsCore = require("@sm360/apps-core");
var _commonsApi = require("@sm360/commons-api");
var _state = require("../config/state.manager");
var _common = require("../api/common.service");
var _utils = require("../tools/utils");
const useSmsNotifications = props => {
  const {
    leadId,
    contactId
  } = props || {};
  const {
    state: {
      sms,
      new_sms: newNotification,
      last_sms_received: lastSmsReceived
    },
    dispatch
  } = (0, _react.useContext)(_state.CrudContext);
  const {
    state: {
      organization,
      user
    }
  } = (0, _react.useContext)(_appsCore.AppContext);
  const dealers = (0, _commonsApi.useDealers)({
    organizationId: organization.id
  });

  // Filter only SMS the user is allowed to see
  const filteredSms = (0, _react.useMemo)(() => {
    if (sms.length > 0 && dealers?.data) {
      return sms.filter(s => {
        if (!s) return false;
        // leadId always takes precedence over contactIdc
        const matchesSpecificLeadOrContact = leadId ? s.leadId === leadId : contactId ? s.contactId === contactId : true;
        return (s.ownerId === user.id || s.salespersonId === user.id || s.salesManagerId === user.id || s.businessManagerId === user.id || user.rights.includes('ReadAllSmsNotifications')) && dealers.data.map(o => o.id).includes(s.dealerId) && matchesSpecificLeadOrContact;
      });
    } else return [];
  }, [sms, user.id, user.rights, dealers?.data, leadId, contactId]);

  // Delete some SMS notification for all the dealer group
  const deleteNotification = (0, _react.useCallback)((activityIds, onSuccess) => {
    Promise.allSettled(activityIds.map(id => (0, _common.deleteSMS)({
      organization,
      id
    }))).then(results => {
      const successCount = results.filter(r => r.status === 'fulfilled').length;
      const failedCount = results.filter(r => r.status === 'rejected').length;
      if (typeof onSuccess === 'function') onSuccess({
        successCount,
        failedCount
      });
    });
  }, [organization]);

  // Dismiss some SMS notifications for the logged user only
  const dismissNotification = (0, _react.useCallback)((activityIds, onSuccess) => {
    Promise.allSettled(activityIds.map(id => (0, _common.dismissSMS)({
      organization,
      id,
      userId: user.id
    }))).then(results => {
      // locally remove the sms notifications
      dispatch({
        type: 'dismissNotifications',
        notifications: {
          type: 'sms',
          id: activityIds
        }
      });

      // ensure we locally remove the sms notification from all other NotificationsProvider of the browser
      (0, _utils.internallyDismissNotification)('sms', activityIds);
      const successCount = results.filter(r => r.status === 'fulfilled').length;
      const failedCount = results.filter(r => r.status === 'rejected').length;
      if (typeof onSuccess === 'function') onSuccess({
        successCount,
        failedCount
      });
    });
  }, [dispatch, organization, user.id]);
  return {
    allSms: filteredSms,
    newNotification,
    lastSmsReceived,
    dismissNotification,
    deleteNotification
  };
};
var _default = exports.default = useSmsNotifications;