"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isLateActivity = exports.getActivitySearchKeywords = void 0;
var _commonsUtils = require("@sm360/commons-utils");
const getGender = (sexId, t) => {
  const parsedSexId = parseInt(sexId);
  switch (parsedSexId) {
    case 1:
      return t(`crm-commons.gender.MALE`);
    case 2:
      return t(`crm-commons.gender.FEMALE`);
    case 3:
      return t(`crm-commons.gender.PREFER_NOT_TO_SAY`);
    case 0:
    default:
      return t(`crm-commons.gender.OTHER`);
  }
};
const getParsedValue = (id, value, t) => {
  if (value === 'true') return t('crm-commons.activities.diff.fields.enabled');else if (value === 'false') return t('crm-commons.activities.diff.fields.disabled');
  switch (id) {
    case 'sexId':
      return getGender(value, t);
    case 'preferredContactMoment':
      return t(`crm-commons.activities.diff.fields.preferredContactMomentTypes.${value}`);
    case 'preferredContactTime':
      return t(`crm-commons.activities.diff.fields.preferredContactTimeTypes.${value}`);
    default:
      return value;
  }
};
const isLateActivity = lead => {
  const statusIds = [1, 15, 16, 17, 11, 12, 27, 14, 31, 10, 40, 22, 39, 36, 34];
  if (lead.nextActivity) {
    const now = new Date();
    const activityDate = new Date(lead.nextActivity.activityDate);
    if (activityDate < now) {
      return !statusIds.includes(lead.leadStatusId);
    }
  }
  return false;
};
exports.isLateActivity = isLateActivity;
const getActivityDiff = (json, t) => {
  if (!(0, _commonsUtils.validJson)(json)) return '';
  const noneText = t('crm-commons.activities.diff.NONE');
  const oldValue = JSON.parse(json).originalValue;
  const newValue = JSON.parse(json).newValue;
  const keys = Object.keys(oldValue);
  return keys?.map(key => {
    const translatedKey = t([`crm-commons.activities.diff.fields.${key}`, key]);
    const changedFrom = t('crm-commons.activities.diff.CHANGED_FROM');
    const changedTo = t('crm-commons.activities.diff.CHANGED_TO');
    const oldParsedValue = oldValue[key] ? getParsedValue(key, oldValue[key], t) : noneText;
    const newParsedValue = newValue[key] ? getParsedValue(key, newValue[key], t) : noneText;
    return `${translatedKey}: ${changedFrom} "${oldParsedValue}" ${changedTo} "${newParsedValue}"`;
  }).join(' ');
};
const getActivitySearchKeywords = (row, t) => {
  switch (row.activity.type) {
    case 'ACCOUNT_CREATION':
      {
        return t('crm-commons.activities.cells.ACCOUNT_CREATED');
      }
    case 'ACTIVITY_ALTERED':
      {
        if (!row.activity.description) return '';
        const translatedDescription = row.activity.description.replace('DATE_SOLD', t('crm-commons.activities.cells.DATE_SOLD_CHANGED')).replace('DATE_DELIVERED', t('crm-commons.activities.cells.DATE_DELIVERED_CHANGED'));
        return translatedDescription;
      }
    case 'CONTACT_UPDATED':
    case 'VEHICLE_CHANGED':
      {
        return getActivityDiff(row.activity.description, t);
      }
    case 'LEAD_CREATED':
      {
        return t('crm-commons.activities.cells.LEAD_CREATED');
      }
    case 'LEAD_MODIFIED':
      {
        return getActivityDiff(row.activity.description, t) || t('crm-commons.activities.cells.LEAD_MODIFIED');
      }
    // TO DO: investigate if INVALID badge and lostTo are needed
    case 'LEAD_STATUS_CHANGED':
      {
        const isSoldDeliveredActivityWithChangedDate = (row.activity.leadStatusId === 10 || row.activity.leadStatusId === 11) && row.activity.lastAlteredActivityId;
        return [t('crm-commons.activities.cells.LEAD_STATUS_CHANGED_HINT'), row.leadStatus, isSoldDeliveredActivityWithChangedDate ? `${t('crm-commons.activities.cells.DATE_CHANGED')} ${row.activity.dateDone}` : ''].join(' ');
      }
    case 'LEAD_TYPE_CHANGED':
      {
        return [t('crm-commons.activities.cells.LEAD_TYPE_CHANGED'), row.leadType].join(' ');
      }
    case 'LEAD_EVENT_COMPLETED':
      {
        return [t('crm-commons.activities.cells.LEAD_EVENT'), row.leadStatus, t('crm-commons.activities.cells.LEAD_EVENT_COMPLETED'), row.activity.description].join(' ');
      }
    case 'EMAIL_SENT':
    case 'EMAIL_RECEIVED':
      {
        return [row.emailDateRead ? t('crm-commons.activities.cells.READ') : '', t('crm-commons.activities.cells.EMAIL_FROM'), row.emailFrom, t('crm-commons.activities.cells.EMAIL_TO'), row.emailTo?.split(',').join(', '), t('crm-commons.activities.cells.EMAIL_OBJECT'), row.emailSubject].join(' ');
      }
    // TO DO: validate which field should be used for the recipient
    case 'EMAIL_BOUNCE':
      {
        return t('crm-commons.activities.cells.EMAIL_BOUNCED', {
          email: row?.recipient
        });
      }
    case 'TASK_CREATED':
    case 'APPOINTMENT_CREATED':
      {
        return [row.activityStatus, row.activity.subject].join(' ');
      }
    case 'SMS':
    case 'SMS_RECEIVED':
      {
        return [t('crm-commons.activities.cells.SMS_FROM'), row.activity.phoneFrom, t('crm-commons.activities.cells.SMS_TO'), row.activity.phone || row.activity.phoneTo, row.activity.message].join(' ');
      }
    case 'PUBLISHED_TO_THIRDPARTY':
      {
        return [row.activity.wasSuccessful ? t('crm-commons.activities.cells.PUBLISHED_TO_THIRDPARTY') : t('crm-commons.activities.cells.PUBLISHED_TO_THIRDPARTY_FAIL'), row.activity.source].join(' ');
      }
    case 'PHONE_CALL':
      {
        const isOutbound = row.activity.direction === 1;
        const hasSubject = isOutbound && row.activity.subject;
        const hasPhoneContact = !!row.activity.phoneContact;
        const hasConfiguredNumber = !!row.configuredNumber;
        const hasDetails = isOutbound && row.activity.details;
        const hasDuration = row.activity.duration > 0;
        return [hasSubject ? `${t('crm-commons.phonecall.SUBJECT')} ${row.activity.subject}` : '', hasPhoneContact ? `${t('crm-commons.phonecall.INTERLOCUTOR')} ${row.activity.phoneContact}` : '', t('crm-commons.phonecall.CONTACT'), row.activity.phone, hasConfiguredNumber ? `${t('crm-commons.phonecall.CONFIGURED_NUMBER')} ${row.configuredNumber} ${row.configuredNumberDescription || ''}` : '', t('crm-commons.phonecall.DIRECTION'), isOutbound ? t('crm-commons.phonecall.DEALER_TO_CLIENT') : t('crm-commons.phonecall.CLIENT_TO_DEALER'), hasDetails ? `${t('crm-commons.phonecall.DETAILS')} ${row.activity.details}` : '', hasDuration ? `${t('crm-commons.phonecall.DURATION')} ${row.activity.duration}` : ''].join(' ');
      }
    case 'LEAD_MEDIA_CHANGED':
    case 'CHECKOUT_CONFIRMATION':
    case 'DEPOSIT':
    case 'FINANCING_REQUEST':
    case 'SALES_APPOINTMENT_REQUEST':
    case 'SMS_MARKED_AS_DONE':
    case 'SMS_NOT_DELIVERED':
    case 'TRADE_IN_EVAL':
    case 'ORGANIZATION_UNIT_ASSIGNED':
    case 'USER_ASSIGNED':
    case 'VEHICLE_IN_GARAGE':
    case 'CONTACT_MERGED':
    case 'CONTACT_UPDATED_DMS':
    case 'CONTACT_UNSUBSCRIBED':
    case 'SALESMAN_ASSIGNED':
    case 'BUSINESS_MANAGER_ASSIGNED':
    case 'SALES_MANAGER_ASSIGNED':
      {
        return [t(`crm-commons.activities.cells.${row.activity.type}`), row.activity.description].join(': ');
      }
    case 'SURVEY':
    case 'AUTOMATED_RESPONSE':
    default:
      {
        return row.activity.description;
      }
  }
};
exports.getActivitySearchKeywords = getActivitySearchKeywords;