"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _selectors = require("../selectors");
/**
 * @param {Object} state
 * @param {Object} payload - { vehicle }
 * @returns {Object}
 */
var _default = (state, _ref) => {
  let {
    vehicle
  } = _ref;
  return {
    ...state,
    quote: {
      ...(0, _selectors.getQuote)(state),
      vehicle
    }
  };
};
exports.default = _default;