"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _actions = require("../../redux/actions");
var _AddItemModal = _interopRequireDefault(require("./AddItemModal"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const mapDispatchToProps = dispatch => ({
  addTransactionItems: items => dispatch(_actions.Creators.addTransactionItems(items)),
  addCommissionAdjustment: items => dispatch(_actions.Creators.addCommissionAdjustment(items))
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(null, mapDispatchToProps))(_AddItemModal.default);