"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _reactRedux = require("react-redux");
var _paymentOptions = require("../../redux/selectors/paymentOptions");
var _selectors = require("../../redux/selectors");
var _TrimColourChoice = _interopRequireDefault(require("./TrimColourChoice"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} state
 * @returns {Object}
 */
const mapStateToProps = state => {
  const {
    paymentFrequency
  } = (0, _selectors.getPurchaseMethod)(state);
  return {
    hasTaxesIncluded: (0, _selectors.getHasTaxesIncluded)(state),
    isCashSelected: (0, _paymentOptions.getIsCashSelected)(state),
    isPaymentUpdating: (0, _selectors.getIsPaymentUpdating)(state),
    paymentFrequency
  };
};
var _default = exports.default = (0, _reactRedux.connect)(mapStateToProps)(_TrimColourChoice.default);