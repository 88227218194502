"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.configureStore = void 0;
var _redux = require("redux");
var _reduxSaga = _interopRequireDefault(require("redux-saga"));
var _deskingError = require("@sm360/desking-error");
var _extension = require("@redux-devtools/extension");
var _rootReducer = _interopRequireWildcard(require("./rootReducer"));
var _rootSaga = _interopRequireDefault(require("./rootSaga"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const bindMiddleware = middleware => {
  return (0, _extension.composeWithDevToolsDevelopmentOnly)((0, _redux.applyMiddleware)(...middleware));
};
const configureStore = function (user, t, navigate) {
  let state = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : _rootReducer.INITIAL_STATE;
  let store = null;
  const sagaMiddleware = (0, _reduxSaga.default)({
    // Context to allow sagas using react functions
    context: {
      user,
      // logged user
      t,
      // translation function
      navigate // function to navigate within app
    },
    // Unexpected errors triggered from a saga
    onError: error => {
      // Show the stack trace in console
      console.error(error);

      // Send an action for the User
      store.dispatch(_deskingError.errorActions.handleError(error, 'unexpected'));
    }
  });
  store = (0, _redux.createStore)(_rootReducer.default, state, bindMiddleware([sagaMiddleware]));
  store.sagaTask = sagaMiddleware.run(_rootSaga.default);
  return store;
};
exports.configureStore = configureStore;
var _default = exports.default = configureStore;