"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUsers = void 0;
var _request = require("../../tools/request");
const CRM_API_URL = process.env.REACT_APP_CRM_API_URL;

/**
 * @param {String} right
 * @param {String} organizationUnitIds
 * @param {String} activeOnly
 * @returns {Promise}
 */
const getUsers = (right, organizationUnitIds, activeOnly) => {
  return (0, _request.post)(`${CRM_API_URL}/users?activeOnly=${activeOnly}&withOrganizationUnitRight=${right}`, Array.isArray(organizationUnitIds) ? organizationUnitIds : [], null, null, 'getUsers');
};
exports.getUsers = getUsers;