"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _selectors = require("../../redux/selectors");
var _paymentOptions = require("../../redux/selectors/paymentOptions");
var _stepSummary = require("../../redux/selectors/stepSummary");
var _content = require("../../redux/selectors/content");
var _stepTrim = require("../../redux/selectors/stepTrim");
var _VehicleDetails = _interopRequireDefault(require("./VehicleDetails"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} state
 * @returns {Object}
 */
const mapStateToProps = state => {
  return {
    hasTaxesIncluded: (0, _selectors.getHasTaxesIncluded)(state),
    isCashSelected: (0, _paymentOptions.getIsCashSelected)(state),
    paymentDetails: (0, _stepSummary.getPaymentDetails)(state),
    vehicle: (0, _stepSummary.getVehicle)(state),
    selectedExteriorColourPhotos: (0, _content.getSelectedExteriorColour)(state),
    selectedInteriorTrim: (0, _stepTrim.getSelectedInteriorTrim)(state),
    isHidingPrices: (0, _selectors.getIsHidingPrices)(state)
  };
};
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps))(_VehicleDetails.default);