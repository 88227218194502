"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vehicles = require("../../constants/vehicles");
var _filters = require("../../constants/filters");
const toNumberOrZero = number => Number(number) || 0;
const isNil = value => value === null || value === undefined;
const omitBy = (obj, predicate) => {
  const entries = Object.entries(obj);
  const filteredEntries = entries.filter(_ref => {
    let [key, value] = _ref;
    return !predicate(value);
  });
  return Object.fromEntries(filteredEntries);
};
var _default = (state, payload) => {
  const {
    filters: defaultFilters
  } = state;
  const {
    queryParams
  } = payload;
  const {
    lang,
    location,
    category,
    leadId,
    organizationId,
    organizationUnitId,
    contactId: inputContactId,
    accountId: inputAccountId,
    cashDown,
    drive,
    financePlan,
    includeTaxes,
    kmPerYearPlan,
    lien,
    make,
    model,
    paymentFrequency,
    purchaseMethod,
    saleType,
    taxPlan,
    term,
    tradeIn,
    transmission,
    trim,
    year
  } = queryParams;
  const parsedAccountId = parseInt(inputAccountId, 10);
  const accountId = !Number.isNaN(parsedAccountId) ? parsedAccountId : 0;
  const parsedContactId = parseInt(inputContactId, 10);
  const contactId = !Number.isNaN(parsedContactId) && parsedContactId > 0 ? parsedContactId : null;
  const options = {
    drive,
    make,
    model,
    transmission,
    trim,
    year
  };
  defaultFilters.selected = Object.keys(_filters.KEYS).reduce((result, key) => {
    let optionsKey = options[_filters.KEYS[key]];
    result[_filters.KEYS[key]] = [];
    const parsedOptionsKey = !Number.isNaN(parseInt(optionsKey, 10)) ? parseInt(optionsKey, 10) : null;
    if (parsedOptionsKey) {
      if (_filters.KEYS[key] !== _filters.KEYS.YEAR) optionsKey = parsedOptionsKey;
      result[_filters.KEYS[key]].push(optionsKey);
    }
    return result;
  }, {});

  // Have to keep the default category if category not valid from queryParams
  if (_vehicles.CATEGORIES.indexOf(category) !== -1) {
    defaultFilters.category = category;
  }

  // Force true, have to be exactly "false" in query param to disable it
  let hasTaxesIncluded = true;
  if (includeTaxes && includeTaxes.toLowerCase() === 'false') {
    hasTaxesIncluded = false;
  }
  return {
    ...state,
    config: {
      lang,
      location,
      organizationId: toNumberOrZero(organizationId),
      organizationUnitId: toNumberOrZero(organizationUnitId),
      contactId: toNumberOrZero(contactId),
      accountId: toNumberOrZero(accountId),
      leadId: toNumberOrZero(leadId)
    },
    filters: defaultFilters,
    paymentOptions: {
      ...state.paymentOptions,
      kmPerYearPlan: toNumberOrZero(kmPerYearPlan) || state.paymentOptions.kmPerYearPlan,
      paymentFrequency: toNumberOrZero(paymentFrequency) || state.paymentOptions.paymentFrequency,
      term: toNumberOrZero(term) || state.paymentOptions.term,
      cashDown: toNumberOrZero(cashDown),
      tradeIn: toNumberOrZero(tradeIn),
      lien: toNumberOrZero(lien),
      ...omitBy({
        saleType,
        taxPlan,
        financePlan,
        purchaseMethod
      }, isNil)
    },
    hasTaxesIncluded
  };
};
exports.default = _default;