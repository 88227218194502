"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findNewCarTrimId = exports.default = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _selectors = require("../selectors");
var _content = require("../selectors/content");
/**
 * @param {Object} model
 * @returns {Number}
 */
const findNewCarTrimId = model => {
  const {
    configurations
  } = model;
  let newCarTrimId;
  if (Array.isArray(configurations)) {
    const found = configurations.find(item => item.isSelected === true);
    if (found) {
      ({
        newCarTrimId
      } = found);
    }
  }
  return newCarTrimId;
};

/**
 * @param {Object} state
 * @param {Object} payload - { response }
 */
exports.findNewCarTrimId = findNewCarTrimId;
var _default = (state, _ref) => {
  let {
    response
  } = _ref;
  const {
    configurationId,
    modelTrimCode,
    transactionToken,
    ...newModel
  } = response;

  // Compose new baseProps
  const baseProps = (0, _selectors.composeNewBaseProps)(state, configurationId, modelTrimCode, transactionToken);
  const content = (0, _content.getContent)(state);
  let model = (0, _content.getModel)(state);
  let newCarTrimId = (0, _content.getNewCarTrimId)(state);

  // Don't replace response if it's the same, avoiding to rerender components depending on it
  if (!(0, _commonsUtils.isEqual)(model, newModel)) {
    model = newModel;
    newCarTrimId = findNewCarTrimId(newModel);
  }
  return {
    ...state,
    isPaymentUpdating: false,
    baseProps,
    content: {
      ...content,
      model,
      newCarTrimId
    }
  };
};
exports.default = _default;