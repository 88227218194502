"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("react");
var _useEventListener = _interopRequireDefault(require("./useEventListener"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function getVisibilityPropertyNames() {
  // Opera 12.10 and Firefox 18 and later support
  if (typeof document.hidden !== 'undefined') {
    return ['hidden', 'visibilitychange'];
  }
  if (typeof document.msHidden !== 'undefined') {
    return ['msHidden', 'msvisibilitychange'];
  }
  if (typeof document.webkitHidden !== 'undefined') {
    return ['webkitHidden', 'webkitvisibilitychange'];
  }
  return ['hidden', 'visibilitychange'];
}
const [hidden, visibilityChange] = getVisibilityPropertyNames();
function isDocumentHidden() {
  return document[hidden];
}
if (typeof document.addEventListener === 'undefined' || hidden === undefined) {
  console.warn('This feature requires a browser that supports the Page Visibility API.');
}
function useDocumentVisibilityChange(callback) {
  const onChange = (0, _react.useCallback)(() => {
    callback(isDocumentHidden());
  }, [callback]);
  (0, _useEventListener.default)(visibilityChange, onChange, document);
}
const useDocumentVisibility = () => {
  const [isHidden, setHidden] = (0, _react.useState)(isDocumentHidden());
  const onChange = (0, _react.useCallback)(state => setHidden(state), [setHidden]);
  useDocumentVisibilityChange(onChange);
  return isHidden;
};
var _default = exports.default = useDocumentVisibility;