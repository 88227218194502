"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.STREET_TYPE = void 0;
const STREET_TYPE = exports.STREET_TYPE = [{
  key: 'AB',
  value: 'ABBEY'
}, {
  key: 'AE',
  value: 'ALLEE'
}, {
  key: 'AL',
  value: 'ALLEY'
}, {
  key: 'AS',
  value: 'ACRES'
}, {
  key: 'AU',
  value: 'AUTOROUTE'
}, {
  key: 'AV',
  value: 'AVENUE'
}, {
  key: 'BA',
  value: 'BAY'
}, {
  key: 'BE',
  value: 'BEACH'
}, {
  key: 'BN',
  value: 'BEND'
}, {
  key: 'BO',
  value: 'BOUL'
}, {
  key: 'BP',
  value: 'BYPASS'
}, {
  key: 'BV',
  value: 'BLVD'
}, {
  key: 'CA',
  value: 'CAMPUS'
}, {
  key: 'CB',
  value: 'CORNERS'
}, {
  key: 'CC',
  value: 'CERCLE'
}, {
  key: 'CD',
  value: 'CUL-DE-SAC'
}, {
  key: 'CE',
  value: 'CHASE'
}, {
  key: 'CF',
  value: 'CIRCUIT'
}, {
  key: 'CH',
  value: 'CHEMIN'
}, {
  key: 'CI',
  value: 'CIRCLE'
}, {
  key: 'CL',
  value: 'CLOSE'
}, {
  key: 'CM',
  value: 'COMMON'
}, {
  key: 'CN',
  value: 'CENTRE'
}, {
  key: 'CO',
  value: 'COTE'
}, {
  key: 'CP',
  value: 'CAPE'
}, {
  key: 'CQ',
  value: 'CONCESSION'
}, {
  key: 'CR',
  value: 'CARRE'
}, {
  key: 'CS',
  value: 'CRESCENT'
}, {
  key: 'CT',
  value: 'COURT'
}, {
  key: 'CU',
  value: 'COUR'
}, {
  key: 'CV',
  value: 'COVE'
}, {
  key: 'CW',
  value: 'CROISSANT'
}, {
  key: 'CX',
  value: 'CROSSING'
}, {
  key: 'CY',
  value: 'COURS'
}, {
  key: 'DA',
  value: 'DALE'
}, {
  key: 'DE',
  value: 'DELL'
}, {
  key: 'DI',
  value: 'DIVERSION'
}, {
  key: 'DO',
  value: 'DOWNS'
}, {
  key: 'DR',
  value: 'DRIVE'
}, {
  key: 'EN',
  value: 'END'
}, {
  key: 'EP',
  value: 'ESPLANADE'
}, {
  key: 'ES',
  value: 'ESTATES'
}, {
  key: 'ET',
  value: 'EXTENSION'
}, {
  key: 'EX',
  value: 'EXPRESSWAY'
}, {
  key: 'FI',
  value: 'FIELD'
}, {
  key: 'FR',
  value: 'FRONT'
}, {
  key: 'FY',
  value: 'FREEWAY'
}, {
  key: 'GA',
  value: 'GROUNDS'
}, {
  key: 'GD',
  value: 'GARDENS'
}, {
  key: 'GE',
  value: 'GLEN'
}, {
  key: 'GL',
  value: 'GLADE'
}, {
  key: 'GN',
  value: 'GREEN'
}, {
  key: 'GR',
  value: 'GROVE'
}, {
  key: 'GT',
  value: 'GATE'
}, {
  key: 'HA',
  value: 'HARBOUR'
}, {
  key: 'HE',
  value: 'HEATH'
}, {
  key: 'HG',
  value: 'HIGHLANDS'
}, {
  key: 'HL',
  value: 'HILL'
}, {
  key: 'HO',
  value: 'HOLLOW'
}, {
  key: 'HT',
  value: 'HEIGHTS'
}, {
  key: 'HW',
  value: 'HIGHWAY'
}, {
  key: 'IL',
  value: 'ILE'
}, {
  key: 'IM',
  value: 'IMPASSE'
}, {
  key: 'IN',
  value: 'INLET'
}, {
  key: 'IS',
  value: 'ISLAND'
}, {
  key: 'KE',
  value: 'key'
}, {
  key: 'KN',
  value: 'KNOLL'
}, {
  key: 'LA',
  value: 'LANDNG'
}, {
  key: 'LE',
  value: 'LINE'
}, {
  key: 'LI',
  value: 'LINK'
}, {
  key: 'LK',
  value: 'LOOKOUT'
}, {
  key: 'LM',
  value: 'LIMITS'
}, {
  key: 'LN',
  value: 'LANE'
}, {
  key: 'LP',
  value: 'LOOP'
}, {
  key: 'MA',
  value: 'MALL'
}, {
  key: 'MN',
  value: 'MONTEE'
}, {
  key: 'MO',
  value: 'MOUNT'
}, {
  key: 'MR',
  value: 'MANOR'
}, {
  key: 'MS',
  value: 'MEWS'
}, {
  key: 'MT',
  value: 'MOUNTAIN'
}, {
  key: 'MW',
  value: 'MEADOW'
}, {
  key: 'MZ',
  value: 'MAZE'
}, {
  key: 'PA',
  value: 'PATH'
}, {
  key: 'PC',
  value: 'PARC'
}, {
  key: 'PE',
  value: 'PROMENADE'
}, {
  key: 'PK',
  value: 'PARK'
}, {
  key: 'PL',
  value: 'PLACE'
}, {
  key: 'PP',
  value: 'PLATEAU'
}, {
  key: 'PQ',
  value: 'POINT'
}, {
  key: 'PR',
  value: 'PARADE'
}, {
  key: 'PS',
  value: 'PASSAGE'
}, {
  key: 'PT',
  value: 'POINTE'
}, {
  key: 'PV',
  value: 'PRIVATE'
}, {
  key: 'PW',
  value: 'PATHWAY'
}, {
  key: 'PY',
  value: 'PARKWAY'
}, {
  key: 'PZ',
  value: 'PLAZA'
}, {
  key: 'QU',
  value: 'QUAI'
}, {
  key: 'QY',
  value: 'QUAY'
}, {
  key: 'RA',
  value: 'RANG'
}, {
  key: 'RD',
  value: 'ROAD'
}, {
  key: 'RE',
  value: 'RIDGE'
}, {
  key: 'RG',
  value: 'RANGE'
}, {
  key: 'RI',
  value: 'RISE'
}, {
  key: 'RL',
  value: 'RUELLE'
}, {
  key: 'RM',
  value: 'RAMP'
}, {
  key: 'RN',
  value: 'RUN'
}, {
  key: 'RO',
  value: 'ROW'
}, {
  key: 'RT',
  value: 'ROUTE'
}, {
  key: 'RU',
  value: 'RUE'
}, {
  key: 'SN',
  value: 'SENTIER'
}, {
  key: 'SQ',
  value: 'SQUARE'
}, {
  key: 'ST',
  value: 'STREET'
}, {
  key: 'SU',
  value: 'SUBDIVISION'
}, {
  key: 'TC',
  value: 'TERRACE'
}, {
  key: 'TL',
  value: 'TOWNLINE'
}, {
  key: 'TR',
  value: 'TRAIL'
}, {
  key: 'TS',
  value: 'TERRASSE'
}, {
  key: 'TT',
  value: 'TURNABOUT'
}, {
  key: 'VA',
  value: 'VILLAS'
}, {
  key: 'VI',
  value: 'VILLAGE'
}, {
  key: 'VL',
  value: 'VALE'
}, {
  key: 'VO',
  value: 'VOIE'
}, {
  key: 'VS',
  value: 'VISTA'
}, {
  key: 'VW',
  value: 'VIEW'
}, {
  key: 'WH',
  value: 'WHARF'
}, {
  key: 'WK',
  value: 'WALK'
}, {
  key: 'WN',
  value: 'WYND'
}, {
  key: 'WO',
  value: 'WOOD'
}, {
  key: 'WY',
  value: 'WAY'
}];
var _default = exports.default = {
  STREET_TYPE
};