"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _selectors = require("../selectors");
/**
 * @param {Object} state
 * @param {Object} payload - { location }
 * @returns {Object}
 */
const setLocation = (state, _ref) => {
  let {
    location
  } = _ref;
  return {
    ...state,
    config: {
      ...(0, _selectors.getConfig)(state),
      location
    }
  };
};
var _default = exports.default = setLocation;