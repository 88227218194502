"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAppointment = exports.editAppointment = exports.createAppointment = void 0;
var _appsCore = require("@sm360/apps-core");
const CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;

// Get Appointment
const getAppointment = async id => {
  return await _appsCore.axios.get(`${CRM_ENDPOINT}/activities/${id}`);
};

// Create Appointment
exports.getAppointment = getAppointment;
const createAppointment = async _ref => {
  let {
    body,
    contactId,
    leadId,
    userId,
    organizationId
  } = _ref;
  const contactIdQuery = contactId ? `&contactId=${contactId}` : '';
  const leadIdQuery = leadId ? `&leadId=${leadId}` : '';
  const requestBody = {
    ...body,
    guests: body?.guests ? body.guests.join(',') : '',
    type: 'APPOINTMENT_CREATED',
    organizationId,
    userId
  };
  return await _appsCore.axios.post(`${CRM_ENDPOINT}/activities/appointments?${contactIdQuery}${leadIdQuery}`, requestBody);
};

// Edit Appointment
exports.createAppointment = createAppointment;
const editAppointment = async _ref2 => {
  let {
    body,
    userId
  } = _ref2;
  const requestBody = {
    ...body,
    guests: body?.guests ? body.guests.join(',') : ''
  };
  return await _appsCore.axios.put(`${CRM_ENDPOINT}/activities/appointments?loggedUserId=${userId}`, requestBody);
};
exports.editAppointment = editAppointment;