"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _request = require("../../../tools/request");
const SHOWROOM_API_URL = process.env.REACT_APP_SHOWROOM_API_URL;

/**
 * @param {Object} queryParams
 * @param {Object} body
 * @returns {Promise}
 */
const addUsedVehicleToGarage = (queryParams, body) => {
  const url = `${SHOWROOM_API_URL}/quote/vehicle/listingUsed`;
  return (0, _request.put)(url, body, {
    params: {
      ...queryParams,
      module: 1
    }
  }, true);
};

/**
 * @param {String} showroomVehicleId
 * @param {Object} queryParams
 * @param {Object} body
 * @returns {Promise}
 */
const addNewVehicleToGarage = (showroomVehicleId, queryParams, body) => {
  const url = `${SHOWROOM_API_URL}/quote/vehicle/listingNew/${showroomVehicleId}`;
  return (0, _request.put)(url, body, {
    params: {
      ...queryParams,
      module: 1
    }
  }, true);
};
var _default = exports.default = {
  addUsedVehicleToGarage,
  addNewVehicleToGarage
};