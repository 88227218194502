"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _selectors = require("../selectors");
function* _default() {
  const quoteId = yield (0, _effects.select)(_selectors.getQuoteId);
  const {
    organizationUnitId
  } = yield (0, _effects.select)(_selectors.getConfig);
  try {
    yield (0, _effects.call)(_commonsApi.DealService.deleteDeal, quoteId, organizationUnitId);
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'deleteDeal'));
  }
}