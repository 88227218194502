"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("react");
var _appsCore = require("@sm360/apps-core");
var _commonsUtils = require("@sm360/commons-utils");
const useEntryValidation = entry => {
  const {
    t
  } = (0, _appsCore.useTranslation)();
  const [invalidFieldsMessages, setInvalidFieldsMessages] = (0, _react.useState)([]);
  (0, _react.useEffect)(() => {
    const {
      homePhone,
      mobilePhone,
      workPhone,
      workPhoneExtension,
      email1,
      email2,
      postalCode
    } = entry?.contact || {};
    const fieldsToValidate = [{
      type: 'phone',
      number: homePhone
    }, {
      type: 'phone',
      number: mobilePhone
    }, {
      type: 'phone',
      number: workPhone
    }, {
      type: 'phoneExtension',
      number: workPhone,
      extension: workPhoneExtension
    }, {
      type: 'email',
      email: email1
    }, {
      type: 'email',
      email: email2
    }, {
      type: 'postalCode',
      postalCode: postalCode
    }];
    const invalidFields = fieldsToValidate.filter(field => {
      switch (field.type) {
        case 'phone':
          return field.number || field.number === 0 ? !(0, _commonsUtils.validPhoneNumber)(field.number) : false;
        case 'phoneExtension':
          return field.number || field.number === 0 ? !(0, _commonsUtils.validPhoneNumber)(field.number) : !!field.extension;
        case 'email':
          return field.email ? !(0, _commonsUtils.validEmail)(field.email) : false;
        case 'postalCode':
          return field.postalCode ? !(0, _commonsUtils.validPostalCode)(field.postalCode) : false;
        default:
          return false;
      }
    });

    // eslint-disable-next-line array-callback-return
    setInvalidFieldsMessages(invalidFields.map(field => {
      switch (field.type) {
        case 'phone':
          return t('leads.updateView.errors.INVALID_PHONE');
        case 'phoneExtension':
          return t('leads.updateView.errors.INVALID_PHONE_EXT');
        case 'email':
          return t('leads.updateView.errors.INVALID_EMAIL');
        case 'postalCode':
          return t('leads.updateView.errors.INVALID_POSTAL_CODE');
        default:
          return '';
      }
    }));
  }, [entry, t]);
  return invalidFieldsMessages;
};
var _default = exports.default = useEntryValidation;