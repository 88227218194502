"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _actions = require("../actions");
/**
 * Load first page
 */
function* _default() {
  yield (0, _effects.put)(_actions.Creators.setCurrentPage(1));
}