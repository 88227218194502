"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("react");
var _appsCore = require("@sm360/apps-core");
var _constants = require("../tools/constants");
var _state = require("../config/state.manager");
const useEmailProvider = providerId => {
  const {
    t
  } = (0, _appsCore.useTranslation)();
  const {
    state: {
      providers
    }
  } = (0, _react.useContext)(_state.CrudContext);
  const {
    type,
    iconUrl,
    loginTransKey
  } = _constants.PROVIDERS_METADATA.find(p => p.type === providerId) || {};
  const loginUrl = providers.find(p => p.type === providerId)?.providerUrl;
  return {
    type,
    iconUrl,
    loginText: loginTransKey ? t(loginTransKey) : t('profile-imap-config.login.NO_NAME', {
      name: providerId || '<>'
    }),
    loginUrl
  };
};
var _default = exports.default = useEmailProvider;