"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.groupTypesByCategory = void 0;
// Convert a flat list of leadTypes into a list of leadCategories which contain leadTypes
const groupTypesByCategory = types => {
  return types.reduce((acc, type) => {
    const groupIndex = acc.findIndex(group => group.groupId === String(type.leadCategory.id));
    if (groupIndex >= 0) {
      // group already exists, push new lead type in it
      const group = acc[groupIndex];
      const updatedGroup = {
        ...group,
        options: [...group.options, {
          id: String(type.id),
          label: type.type
        }]
      };
      const updatedAcc = [...acc];
      updatedAcc.splice(groupIndex, 1, updatedGroup);
      return updatedAcc;
    } else {
      // group doesn't exist yet, create it then push new lead type in it
      return [...acc, {
        groupId: String(type.leadCategory.id),
        groupLabel: type.leadCategory.category,
        options: [{
          id: String(type.id),
          label: type.type
        }]
      }];
    }
  }, []).sort((a, b) => a.groupId.localeCompare(b.groupId));
};
exports.groupTypesByCategory = groupTypesByCategory;