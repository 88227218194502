"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAllMatchVehicles = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _request = require("../../tools/request");
var _StatusService = require("../StatusService");
const VEHICLE_API_URL = process.env.REACT_APP_VEHICLE_API_URL;
const TYPE_ALL = 'all';

/**
 * @param {String} transactionToken
 * @param {Object} queryParams
 * @param {Object} paymentOptions
 * @param {String} matchType
 * @param {Boolean} onlyCount
 * @returns {Promise}
 */
const baseRequest = async function (transactionToken, queryParams, paymentOptions) {
  let matchType = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : TYPE_ALL;
  let onlyCount = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  const params = {
    ...queryParams,
    matchType
  };
  const body = {
    transactionToken,
    filters: {
      onlyCount,
      vehicleInventoryStatuses: [_StatusService.VEHICLE_STATUS.FOR_SALE, _StatusService.VEHICLE_STATUS.ON_HOLD]
    },
    paymentOptionRequest: paymentOptions
  };
  return (0, _request.post)(`${VEHICLE_API_URL}/inventory/vehicles/match`, body, {
    params
  });
};

/**
 * @param {Object} response
 * @param {String} keyName - Name of the prop to get
 * @return {Object[]}
 */
const transformResponseToList = (response, keyName) => {
  const matches = response[keyName];
  if (!(0, _commonsUtils.isPlainObject)(matches)) {
    return [];
  }
  const {
    inventoryVehicles
  } = matches;
  return Array.isArray(inventoryVehicles) ? inventoryVehicles : [];
};

/**
 * @param {String} transactionToken
 * @param {Object} queryParams
 * @param {Object} paymentOptions
 * @returns {Array} [perfectMatches, similarMatches]
 */
const getAllMatchVehicles = async function () {
  const response = await baseRequest(...arguments);
  if (!(0, _commonsUtils.isPlainObject)(response)) {
    return [[], []];
  }
  return [transformResponseToList(response, 'perfectMatches'), transformResponseToList(response, 'similarMatches')];
};
exports.getAllMatchVehicles = getAllMatchVehicles;