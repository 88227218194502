"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.USA = exports.SALUTATION_PREFIX = exports.RESIDENCE_TYPE_DEFAULT = exports.QUEBEC = exports.MIN_PERIOD_OF_RESIDENCE = exports.COUNTRIES = exports.CANADIAN_PROVINCES = exports.CANADA = exports.AMERICAN_PROVINCES = void 0;
const QUEBEC = exports.QUEBEC = 'QC';
const USA = exports.USA = 'USA';
const CANADA = exports.CANADA = 'CA';
const AMERICAN_PROVINCES = exports.AMERICAN_PROVINCES = [{
  value: 'AL',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.ALABAMA'
}, {
  value: 'AK',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.ALASKA'
}, {
  value: 'AS',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.AMERICAN SAMOA'
}, {
  value: 'AZ',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.ARIZONA'
}, {
  value: 'AR',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.ARKANSAS'
}, {
  value: 'CA',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.CALIFORNIA'
}, {
  value: 'CO',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.COLORADO'
}, {
  value: 'CT',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.CONNECTICUT'
}, {
  value: 'DE',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.DELAWARE'
}, {
  value: 'DC',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.DISTRICT OF COLUMBIA'
}, {
  value: 'FM',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.FEDERATED STATES OF MICRONESIA'
}, {
  value: 'FL',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.FLORIDA'
}, {
  value: 'GA',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.GEORGIA'
}, {
  value: 'GU',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.GUAM'
}, {
  value: 'HI',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.HAWAII'
}, {
  value: 'ID',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.IDAHO'
}, {
  value: 'IL',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.ILLINOIS'
}, {
  value: 'IN',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.INDIANA'
}, {
  value: 'IA',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.IOWA'
}, {
  value: 'KS',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.KANSAS'
}, {
  value: 'KY',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.KENTUCKY'
}, {
  value: 'LA',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.LOUISIANA'
}, {
  value: 'ME',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.MAINE'
}, {
  value: 'MH',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.MARSHALL ISLANDS'
}, {
  value: 'MD',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.MARYLAND'
}, {
  value: 'MA',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.MASSACHUSETTS'
}, {
  value: 'MI',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.MICHIGAN'
}, {
  value: 'MN',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.MINNESOTA'
}, {
  value: 'MS',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.MISSISSIPPI'
}, {
  value: 'MO',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.MISSOURI'
}, {
  value: 'MT',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.MONTANA'
}, {
  value: 'NE',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.NEBRASKA'
}, {
  value: 'NV',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.NEVADA'
}, {
  value: 'NH',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.NEW HAMPSHIRE'
}, {
  value: 'NJ',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.NEW JERSEY'
}, {
  value: 'NM',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.NEW MEXICO'
}, {
  value: 'NY',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.NEW YORK'
}, {
  value: 'NC',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.NORTH CAROLINA'
}, {
  value: 'ND',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.NORTH DAKOTA'
}, {
  value: 'MP',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.NORTHERN MARIANA ISLANDS'
}, {
  value: 'OH',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.OHIO'
}, {
  value: 'OK',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.OKLAHOMA'
}, {
  value: 'OR',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.OREGON'
}, {
  value: 'PW',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.PALAU'
}, {
  value: 'PA',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.PENNSYLVANIA'
}, {
  value: 'PR',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.PUERTO RICO'
}, {
  value: 'RI',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.RHODE ISLAND'
}, {
  value: 'SC',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.SOUTH CAROLINA'
}, {
  value: 'SD',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.SOUTH DAKOTA'
}, {
  value: 'TN',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.TENNESSEE'
}, {
  value: 'TX',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.TEXAS'
}, {
  value: 'UT',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.UTAH'
}, {
  value: 'VT',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.VERMONT'
}, {
  value: 'VI',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.VIRGIN ISLANDS'
}, {
  value: 'VA',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.VIRGINIA'
}, {
  value: 'WA',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.WASHINGTON'
}, {
  value: 'WV',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.WEST VIRGINIA'
}, {
  value: 'WI',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.WISCONSIN'
}, {
  value: 'WY',
  translationKey: 'FINANCING.RESIDENCE_INFOS.AMERICAN_PROVINCES.WYOMING'
}];
const CANADIAN_PROVINCES = exports.CANADIAN_PROVINCES = [{
  value: 'AB',
  translationKey: 'FINANCING.RESIDENCE_INFOS.CANADIAN_PROVINCES.ALBERTA'
}, {
  value: 'BC',
  translationKey: 'FINANCING.RESIDENCE_INFOS.CANADIAN_PROVINCES.BRITISH_COLUMBIA'
}, {
  value: 'MB',
  translationKey: 'FINANCING.RESIDENCE_INFOS.CANADIAN_PROVINCES.MANITOBA'
}, {
  value: 'NB',
  translationKey: 'FINANCING.RESIDENCE_INFOS.CANADIAN_PROVINCES.NEW_BRUNSWICK'
}, {
  value: 'NL',
  translationKey: 'FINANCING.RESIDENCE_INFOS.CANADIAN_PROVINCES.NEW_FOUNDLAND'
}, {
  value: 'NS',
  translationKey: 'FINANCING.RESIDENCE_INFOS.CANADIAN_PROVINCES.NOVA_SCOTIA'
}, {
  value: 'ON',
  translationKey: 'FINANCING.RESIDENCE_INFOS.CANADIAN_PROVINCES.ONTARIO'
}, {
  value: 'PE',
  translationKey: 'FINANCING.RESIDENCE_INFOS.CANADIAN_PROVINCES.PRINCE_EDWARD'
}, {
  value: QUEBEC,
  translationKey: 'FINANCING.RESIDENCE_INFOS.CANADIAN_PROVINCES.QUEBEC'
}, {
  value: 'SK',
  translationKey: 'FINANCING.RESIDENCE_INFOS.CANADIAN_PROVINCES.SASKATCHEWAN'
}];
const COUNTRIES = exports.COUNTRIES = [{
  value: CANADA,
  translationKey: 'FINANCING.RESIDENCE_INFOS.COUNTRIES.CANADA'
}, {
  value: USA,
  translationKey: 'FINANCING.RESIDENCE_INFOS.COUNTRIES.UNITED_STATES'
}];
const SALUTATION_PREFIX = exports.SALUTATION_PREFIX = [{
  value: 'Dr',
  translationKey: 'FINANCING.APPLICANT_INFO.SALUTATION_PREFIX.Dr'
}, {
  value: 'Miss',
  translationKey: 'FINANCING.APPLICANT_INFO.SALUTATION_PREFIX.Miss'
}, {
  value: 'Mrs',
  translationKey: 'FINANCING.APPLICANT_INFO.SALUTATION_PREFIX.Mrs'
}, {
  value: 'Mr',
  translationKey: 'FINANCING.APPLICANT_INFO.SALUTATION_PREFIX.Mr'
}];
const RESIDENCE_TYPE_DEFAULT = exports.RESIDENCE_TYPE_DEFAULT = 'Rent';

// Minimum of months to not ask for a previous address
const MIN_PERIOD_OF_RESIDENCE = exports.MIN_PERIOD_OF_RESIDENCE = 24;
var _default = exports.default = {
  AMERICAN_PROVINCES,
  CANADIAN_PROVINCES,
  COUNTRIES,
  QUEBEC,
  RESIDENCE_TYPE_DEFAULT,
  USA,
  CANADA,
  MIN_PERIOD_OF_RESIDENCE,
  SALUTATION_PREFIX
};