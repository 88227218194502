"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateRoadToSales = exports.getRoadToSales = void 0;
var _appsCore = require("@sm360/apps-core");
const NEW_CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;
const getRoadToSales = async leadId => {
  return await _appsCore.axios.get(`${NEW_CRM_ENDPOINT}/leads/${leadId}/roadToSales`);
};
exports.getRoadToSales = getRoadToSales;
const updateRoadToSales = async _ref => {
  let {
    leadId,
    leadStatusId,
    description
  } = _ref;
  const descriptionParam = description ? `?description=${encodeURIComponent(description)}` : '';
  return await _appsCore.axios.put(`${NEW_CRM_ENDPOINT}/leads/${leadId}/roadToSales/${leadStatusId}${descriptionParam}`);
};
exports.updateRoadToSales = updateRoadToSales;