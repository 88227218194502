"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _actions = require("../actions");
var _loadFilters = _interopRequireDefault(require("./loadFilters"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} payload - { name, values }
 */
function _default(_ref) {
  let {
    name
  } = _ref;
  return function* () {
    yield (0, _effects.call)(_loadFilters.default, name);
    yield (0, _effects.put)(_actions.Creators.applyFilters());
  }();
}