"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generateInitialAnswers = exports.generateConditionFormModel = void 0;
const getModelType = CBBType => {
  switch (CBBType) {
    case '$':
      return 'currency';
    default:
      return 'radio';
  }
};
const getShortCode = code => {
  switch (code) {
    case 'Y':
      return true;
    case 'N':
      return false;
    default:
      return code;
  }
};

/**
 * Recursive function generating an object containing each question
 * @param {*} questions      : list of questions
 * @param {*} parentQuestion : parent question of the list of questions
 */
const generateQuestion = (questions, parentQuestion) => {
  return {
    ...questions.reduce((acc, q) => {
      // Get the parent's answer having showChildren == true
      const showChildrenAnswer = parentQuestion?.possibleAnswers.find(a => a.showChildren);
      const parentLinkCode = showChildrenAnswer ? {
        onlyFor: getShortCode(showChildrenAnswer.answerCode)
      } : {};
      return {
        ...acc,
        ...{
          [q.questionId]: {
            type: getModelType(q.type),
            labelKey: q.questionText,
            linkToParent: parentLinkCode,
            choices: q.possibleAnswers.map(a => {
              const code = a.answerCode;
              return {
                value: getShortCode(code),
                labelKey: a.answer
              };
            }),
            // Sub-questions
            questions: q?.childQuestions ? generateQuestion(q.childQuestions, q) : {}
          }
        }
      };
    }, {})
  };
};
const generateInitialAnswers = questions => {
  return {
    ...questions.reduce((acc, q) => {
      let childAnswers = {};
      if (q?.childQuestions) {
        childAnswers = generateInitialAnswers(q.childQuestions);
      }
      return {
        ...childAnswers,
        ...acc,
        [q.questionId]: getShortCode(q.answerCode)
      };
    }, {})
  };
};
exports.generateInitialAnswers = generateInitialAnswers;
const generateConditionFormModel = CBBQuestions => {
  const formattedModel = {
    iconName: 'rapport',
    sections: {
      section1: {
        questions: generateQuestion(CBBQuestions)
      }
    }
  };
  return formattedModel;
};
exports.generateConditionFormModel = generateConditionFormModel;