"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _selectors = require("../../selectors");
var _requestAprRoll = require("../../selectors/requestAprRoll");
/**
 * @param {Number} calculationTargetValue
 */
function* _default(calculationTargetValue) {
  const quoteId = yield (0, _effects.select)(_selectors.getQuoteId);
  const queryParams = yield (0, _effects.select)(_selectors.getShowroomQueryParams);
  const body = yield (0, _effects.select)(_requestAprRoll.getBody, calculationTargetValue);
  try {
    return yield (0, _effects.call)(_commonsApi.ShowroomService.desking.aprRoll, quoteId, queryParams, body);
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'updateQuote'));
    return null;
  }
}