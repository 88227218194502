"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _content = require("../../../redux/selectors/content");
var _stepTrim = require("../../../redux/selectors/stepTrim");
var _StepComfort = _interopRequireDefault(require("./StepComfort"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} state
 * @returns {Object}
 */
const mapStateToProps = state => {
  const {
    name: trimName
  } = (0, _stepTrim.getModelTrim)(state);
  return {
    list: (0, _content.getConfigurations)(state),
    modelName: (0, _content.getModelName)(state),
    trimName
  };
};
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps))(_StepComfort.default);