"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _aprRoll = _interopRequireDefault(require("./api/aprRoll"));
var _handleChangeValidation = _interopRequireDefault(require("./common/handleChangeValidation"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} payload
 */
function _default(_ref) {
  let {
    calculationTargetValue
  } = _ref;
  return function* () {
    const response = yield (0, _effects.call)(_aprRoll.default, calculationTargetValue);
    yield (0, _effects.call)(_handleChangeValidation.default, response);
  }();
}