"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _filters = require("../../redux/selectors/filters");
var _actions = require("../../redux/actions");
var _SortDropdown = _interopRequireDefault(require("./SortDropdown"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const mapStateToProps = state => ({
  value: (0, _filters.getSelectedSortInfo)(state)
});
const mapDispatchToProps = dispatch => ({
  sortVehicles: (sortType, direction) => dispatch(_actions.Creators.sortVehicles(sortType, direction))
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps))(_SortDropdown.default);