"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _selectors = require("../../selectors");
var _requestFinancingDates = require("../../selectors/requestFinancingDates");
/**
 * @param {Object} payload
 */
function _default(_ref) {
  let {
    quoteRevision
  } = _ref;
  return function* () {
    const quoteId = yield (0, _effects.select)(_selectors.getQuoteId);
    const dates = yield (0, _effects.select)(_selectors.getFinancingDates);
    const queryParams = yield (0, _effects.select)(_selectors.getQuoteServiceQueryParams);
    const body = yield (0, _effects.select)(_requestFinancingDates.getFinancingDatesBody, dates, quoteRevision);
    try {
      yield (0, _effects.call)(_commonsApi.QuoteService.setDates, quoteId, queryParams, body);
    } catch (error) {
      yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'updateFinancingDates'));
    }
  }();
}