import React, { useContext, useMemo } from 'react';
import { AppWrapper, AppContext, ProgressChecks, Route } from '@sm360/apps-core';
import * as i18nRessources from '@sm360/apps-i18n';
import { ReactComponent as SvgSprite } from '@sm360/ui-crm/dist/assets/svg-sprite.svg';
import packageJson from '../package.json';
import * as NavList from './navigation';
import TopBar from './components/TopBar';
import AppLogo from './components/Logo';
import UserPanel from './components/UserPanel';
import CreateResource from '@sm360/crm-create-resource';
import DeskingDesking from '@sm360/crm-desking-desking';
import DeskingBuilder from '@sm360/crm-desking-builder';
import DeskingVehicles from '@sm360/crm-desking-vehicles';

const AUTO_UPDATE_ROUTES_EXCEPTIONS = ['leads/edit', 'contacts/edit', 'deals/view'];

const App = () => {
    const {
        state: { user },
    } = useContext(AppContext);

    const userOnlyHasAccessToCrmLite = user?.roles?.includes('CRMLite') && user?.roles?.length === 1;
    const userHasAccessToReports = user?.rights?.includes('ModuleReportEnabled');

    const homePageUrl = userOnlyHasAccessToCrmLite ? '/leads' : userHasAccessToReports ? '/dashboard' : '/calendar';

    const appNavigation = useMemo(() => {
        // for CRM lite: only render the leads route
        if (userOnlyHasAccessToCrmLite) {
            const crmSection = NavList.NavOrganization.find((section) => section.name === 'CRM 360');
            const crmSectionWithLeadsOnly = {
                ...crmSection,
                submenu: crmSection.submenu.filter((menu) => menu.name === 'Leads'),
            };
            return [crmSectionWithLeadsOnly];
        }
        return NavList.NavOrganization;
    }, [userOnlyHasAccessToCrmLite]);

    return (
        <AppWrapper
            ready
            autoUpdate
            autoUpdateRoutesExceptions={AUTO_UPDATE_ROUTES_EXCEPTIONS}
            defaultTitle="CRM 360"
            titleTemplate="CRM 360 - %s"
            packageJson={packageJson}
            appNavList={appNavigation}
            appHomePage={() => (userOnlyHasAccessToCrmLite ? null : <ProgressChecks state="authorizing" />)}
            appHomeUrl={homePageUrl}
            appLogo={AppLogo}
            topbar={TopBar}
            userPanel={UserPanel}
            svgSprite={<SvgSprite />}
            accessRight="CrmProAccess"
            i18nRessources={i18nRessources}
            customRoutes={[
                <Route path="/new" element={<CreateResource />} />,
                <Route path="/desking-builder" element={<DeskingBuilder />} />,
                <Route path="/desking-vehicles" element={<DeskingVehicles />} />,
                <Route path="/desking-desking" element={<DeskingDesking />} />,
            ]}
        />
    );
};

export default App;
