"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadContactSummary = void 0;
var _appsCore = require("@sm360/apps-core");
const CRM_API_URL = process.env.REACT_APP_CRM_API_URL;

/**
 * @param {String} contactId
 * @param {String} orgUnitId
 * @returns {Promise}
 */
const loadContactSummary = async (contactId, orgUnitId) => {
  try {
    const response = await _appsCore.axios.get(`${CRM_API_URL}/contacts/${contactId}/summary`, {
      params: {
        organizationUnitId: orgUnitId
      }
    });
    return response ? response.data : null;
  } catch (error) {
    return null;
  }
};
exports.loadContactSummary = loadContactSummary;