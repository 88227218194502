"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsUtils = require("@sm360/commons-utils");
var _commonsApi = require("@sm360/commons-api");
var _deskingCommons = require("@sm360/desking-commons");
var _deskingError = require("@sm360/desking-error");
var _selectors = require("../selectors");
var _actions = require("../actions");
/**
 * @param {Object} params
 */
function _default(_ref) {
  let {
    personnelType,
    userId
  } = _ref;
  return function* () {
    const {
      leadId
    } = yield (0, _effects.select)(_selectors.getQuote);
    const user = yield (0, _effects.getContext)('user');
    const {
      SALES_PERSON,
      SALES_MANAGER,
      BUSINESS_MANAGER
    } = _deskingCommons.SALES_PERSONNEL;
    const parsedUserId = (0, _commonsUtils.nullOrNumber)(userId);
    // assign to logged user if no userId specified
    const newUserId = Number.isInteger(parsedUserId) ? parsedUserId : Number(user.profile.sub);
    yield (0, _effects.put)(_actions.Creators.setIsQuoteUpdating());
    try {
      switch (personnelType) {
        case SALES_PERSON:
          yield (0, _effects.call)(_commonsApi.LeadInfoService.assignLeadToSalesPerson, leadId, newUserId);
          yield (0, _effects.put)(_actions.Creators.setLeadInfo({
            salesperson: {
              salespersonId: newUserId
            }
          }));
          break;
        case SALES_MANAGER:
          yield (0, _effects.call)(_commonsApi.LeadInfoService.assignLeadToSalesManager, leadId, newUserId);
          yield (0, _effects.put)(_actions.Creators.setLeadInfo({
            salesManager: {
              salesManagerId: newUserId
            }
          }));
          break;
        case BUSINESS_MANAGER:
          yield (0, _effects.call)(_commonsApi.LeadInfoService.assignLeadToBusinessManager, leadId, newUserId);
          yield (0, _effects.put)(_actions.Creators.setLeadInfo({
            businessManager: {
              businessManagerId: newUserId
            }
          }));
          break;
        default:
      }
    } catch (error) {
      yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'assignLead'));
      yield (0, _effects.put)(_actions.Creators.setIsQuoteUpdatingDone());
    }
    yield (0, _effects.put)(_actions.Creators.setIsQuoteUpdatingDone());
  }();
}