"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "TabAccounting", {
  enumerable: true,
  get: function () {
    return _TabAccounting.default;
  }
});
Object.defineProperty(exports, "TabDealStory", {
  enumerable: true,
  get: function () {
    return _TabDealStory.default;
  }
});
Object.defineProperty(exports, "TabForms", {
  enumerable: true,
  get: function () {
    return _TabForms.default;
  }
});
Object.defineProperty(exports, "TabItems", {
  enumerable: true,
  get: function () {
    return _TabItems.default;
  }
});
Object.defineProperty(exports, "TabMenuActions", {
  enumerable: true,
  get: function () {
    return _TabMenuActions.default;
  }
});
Object.defineProperty(exports, "TabPaymentOptions", {
  enumerable: true,
  get: function () {
    return _TabPaymentOptions.default;
  }
});
Object.defineProperty(exports, "TabProtection", {
  enumerable: true,
  get: function () {
    return _TabProtection.default;
  }
});
Object.defineProperty(exports, "TabTransactionDetails", {
  enumerable: true,
  get: function () {
    return _TabTransactionDetails.default;
  }
});
var _TabItems = _interopRequireDefault(require("./TabItems/TabItems"));
var _TabProtection = _interopRequireDefault(require("./TabProtection/TabProtection"));
var _TabMenuActions = _interopRequireDefault(require("./TabMenuActions"));
var _TabPaymentOptions = _interopRequireDefault(require("./TabPaymentOptions"));
var _TabTransactionDetails = _interopRequireDefault(require("./TabTransactionDetails"));
var _TabAccounting = _interopRequireDefault(require("./TabAccounting"));
var _TabDealStory = _interopRequireDefault(require("./TabDealStory"));
var _TabForms = _interopRequireDefault(require("./TabForms/TabForms"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }