"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TradeInVehicleHelpers = require("../helpers/TradeInVehicleHelpers");
const getSelectedExteriorColor = tradeInVehicle => {
  const {
    exteriorColor,
    availableExteriorColors
  } = tradeInVehicle || {};
  const selectedExteriorColor = availableExteriorColors && availableExteriorColors.find(color => color.description === exteriorColor);
  if (selectedExteriorColor) {
    selectedExteriorColor.colorCode = selectedExteriorColor.htmlColour;
  }
  return selectedExteriorColor;
};
const getSelectedInteriorColor = tradeInVehicle => {
  const {
    interiorColor,
    availableInteriorColors
  } = tradeInVehicle || {};
  const selectedInteriorColor = availableInteriorColors && availableInteriorColors.find(color => color.description === interiorColor);
  if (selectedInteriorColor) {
    selectedInteriorColor.colorCode = selectedInteriorColor.htmlColour;
  }
  return selectedInteriorColor;
};
const mapVehicleExtraInfo = tradeInVehicle => {
  let vehicleExtraInfo = {};
  vehicleExtraInfo = {
    ...vehicleExtraInfo,
    accidentStatus: tradeInVehicle?.accidentStatus || '',
    paymentType: tradeInVehicle?.paymentType || 'unknown',
    vehicleCondition: tradeInVehicle?.vehicleCondition || '',
    ...(tradeInVehicle?.balance && {
      balance: tradeInVehicle?.balance
    }),
    ...(tradeInVehicle?.payment && {
      payment: tradeInVehicle?.payment
    }),
    ...(tradeInVehicle?.lien && {
      lienHolderName: tradeInVehicle?.lien.owner
    }),
    ...(tradeInVehicle?.dueMonth && tradeInVehicle?.dueYear && {
      expirationDate: `${tradeInVehicle?.dueMonth}/${tradeInVehicle?.dueYear}`
    })
  };
  return vehicleExtraInfo;
};
/**
 * @param {Object} TradeInVehicle
 */
const tradeInVehicleToInternalTradeIn = (tradeInVehicle, t) => {
  if (typeof tradeInVehicle === 'object' && Object.keys(tradeInVehicle).length > 0) {
    return {
      exteriorColor: getSelectedExteriorColor(tradeInVehicle) || (0, _TradeInVehicleHelpers.getOtherColor)(t),
      exteriorColors: tradeInVehicle?.availableExteriorColors,
      interiorColor: getSelectedInteriorColor(tradeInVehicle) || (0, _TradeInVehicleHelpers.getOtherColor)(t),
      interiorColors: tradeInVehicle?.availableInteriorColors,
      make: tradeInVehicle?.make,
      model: tradeInVehicle?.model,
      options: tradeInVehicle?.availableOptions,
      style: tradeInVehicle?.bodyStyle,
      tradeVehicleToken: tradeInVehicle?.tradeVehicleToken,
      trim: tradeInVehicle?.trim,
      id: tradeInVehicle?.id || '',
      name: tradeInVehicle?.vehicleId || '',
      value: tradeInVehicle?.vehicleId || '',
      year: tradeInVehicle?.year,
      vin: tradeInVehicle?.vin,
      kilometers: tradeInVehicle?.kilometers || 0,
      vehicleOptions: tradeInVehicle?.vehicleOptions,
      vehicleExtraInfo: mapVehicleExtraInfo(tradeInVehicle)
    };
  } else return {};
};
var _default = exports.default = {
  tradeInVehicleToInternalTradeIn
};