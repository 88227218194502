"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tabItems = require("../../selectors/tabItems");
/**
 * @param {Object} state
 * @returns {Object}
 */
var _default = state => ({
  tabItemsAccessory: {
    ...(0, _tabItems.getTabAccessory)(state),
    isUpdated: false
  },
  tabItemsColour: {
    ...(0, _tabItems.getTabColour)(state),
    isUpdated: false
  },
  tabItemsOption: {
    ...(0, _tabItems.getTabOption)(state),
    isUpdated: false
  },
  tabItemsRebate: {
    ...(0, _tabItems.getTabRebate)(state),
    isUpdated: false
  },
  tabItemsNote: {
    ...(0, _tabItems.getTabNote)(state),
    isUpdated: false
  }
});
exports.default = _default;