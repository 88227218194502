"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPathParams = exports.getBody = void 0;
var _config = require("../../constants/config");
var _selectors = require("../selectors");
var _stepTrim = require("./stepTrim");
var _requestModelYear = require("./requestModelYear");
var _content = require("./content");
/**
 * @param {Object} state
 * @returns {Object}
 */
const getBody = state => {
  const {
    configurationId,
    modelTrimCode,
    transactionToken
  } = (0, _selectors.getBaseProps)(state);
  const modelYearBody = (0, _requestModelYear.getBody)(state);
  return {
    category: _config.STEP_ITEM_CATEGORIES,
    configurationId,
    modelTrimCode,
    transactionToken,
    ...modelYearBody
  };
};

/**
 * @param {Object} state
 * @returns {Array}
 */
exports.getBody = getBody;
const getPathParams = state => {
  const {
    bodyStyle,
    make,
    model
  } = (0, _selectors.getConfig)(state);
  const year = (0, _content.getYear)(state);
  const {
    slug
  } = (0, _stepTrim.getModelTrim)(state);
  return [year, make, model, bodyStyle, slug];
};
exports.getPathParams = getPathParams;