"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PURCHASE_METHODS = exports.PROVINCES = void 0;
const PROVINCES = exports.PROVINCES = {
  AB: 'AB',
  BC: 'BC',
  MN: 'MN',
  NB: 'NB',
  NL: 'NL',
  NS: 'NS',
  NT: 'NT',
  NU: 'NU',
  ON: 'ON',
  PE: 'PE',
  QC: 'QC',
  SK: 'SK',
  YK: 'YT'
};
const PURCHASE_METHODS = exports.PURCHASE_METHODS = {
  LEASE: 'lease',
  FINANCE: 'finance'
};