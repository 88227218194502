"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _selectors = require("../selectors");
var _filters = require("../selectors/filters");
var _resetFilters = require("./resetFilters");
/**
 * Reset filters and vehicles
 *
 * @param {Object} state
 * @param {Object} payload - { category }
 * @returns {Object}
 */
var _default = (state, _ref) => {
  let {
    category
  } = _ref;
  const filters = (0, _filters.getFilters)(state);
  const pagination = (0, _selectors.getPagination)(state);
  return {
    ...state,
    filters: {
      ...filters,
      category,
      searchText: '',
      options: (0, _resetFilters.resetSelected)(),
      selected: (0, _resetFilters.resetSelected)()
    },
    pagination: {
      ...pagination,
      pageNumber: 1,
      totalElements: 0
    },
    vehicles: []
  };
};
exports.default = _default;