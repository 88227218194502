"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "BuyerCobuyerModal", {
  enumerable: true,
  get: function () {
    return _BuyerCobuyerModal.default;
  }
});
Object.defineProperty(exports, "DMSCustomerCreationSection", {
  enumerable: true,
  get: function () {
    return _DMSCustomerCreationSection.default;
  }
});
Object.defineProperty(exports, "DMSCustomerMatchesSection", {
  enumerable: true,
  get: function () {
    return _DMSCustomerMatchesSection.default;
  }
});
Object.defineProperty(exports, "EditEmails", {
  enumerable: true,
  get: function () {
    return _EditEmails.default;
  }
});
Object.defineProperty(exports, "EditPhones", {
  enumerable: true,
  get: function () {
    return _EditPhones.default;
  }
});
Object.defineProperty(exports, "LocalisationForm", {
  enumerable: true,
  get: function () {
    return _LocalisationForm.default;
  }
});
Object.defineProperty(exports, "TabSummaryBuyerCobuyer", {
  enumerable: true,
  get: function () {
    return _TabSummaryBuyerCobuyer.default;
  }
});
var _DMSCustomerMatchesSection = _interopRequireDefault(require("./DMSCustomerMatchesSection"));
var _DMSCustomerCreationSection = _interopRequireDefault(require("./DMSCustomerCreationSection"));
var _EditEmails = _interopRequireDefault(require("./EditEmails"));
var _EditPhones = _interopRequireDefault(require("./EditPhones"));
var _LocalisationForm = _interopRequireDefault(require("./LocalisationForm"));
var _TabSummaryBuyerCobuyer = _interopRequireDefault(require("./TabSummaryBuyerCobuyer"));
var _BuyerCobuyerModal = _interopRequireDefault(require("./BuyerCobuyerModal"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }