"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteContact = void 0;
var _appsCore = require("@sm360/apps-core");
const CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;
const deleteContact = async id => {
  return await _appsCore.axios.delete(`${CRM_ENDPOINT}/contacts/${id}`);
};
exports.deleteContact = deleteContact;