"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _quoteChanges = require("../../constants/quoteChanges");
var _actions = require("../actions");
var _selectors = require("../selectors");
function* _default(payload) {
  const paymentOptions = yield (0, _effects.select)(_selectors.getPaymentOptions);
  const {
    vehicle
  } = payload;
  const {
    vehicleId
  } = vehicle;
  const {
    organizationId,
    organizationUnitId,
    location
  } = yield (0, _effects.select)(_selectors.getConfig);
  const queryParams = {
    organizationId,
    organizationUnitId,
    location,
    showroomModule: 'desking'
  };
  const body = {
    transactionToken: paymentOptions.transactionToken,
    purchaseMethod: paymentOptions.purchaseMethod,
    kmPerYearPlan: paymentOptions.kmPerYearPlan,
    paymentFrequency: paymentOptions.paymentFrequency,
    term: paymentOptions.term,
    cashDown: paymentOptions.cashDown,
    tradeIn: paymentOptions.tradeIn,
    lien: paymentOptions.lien,
    saleType: paymentOptions.saleType,
    taxPlan: paymentOptions.taxPlan,
    priceIncreaseRollCount: paymentOptions.priceIncreaseRollCount,
    financePlan: paymentOptions.financePlan,
    financeOptions: paymentOptions.financeOptions,
    cashPurchaseOption: paymentOptions.cashPurchaseOption
  };
  yield (0, _effects.put)(_actions.Creators.setIsQuoteUpdating());
  try {
    const vehicleResults = yield (0, _effects.call)(_commonsApi.VehicleService.getNewVehicle, vehicleId, queryParams, body);
    const {
      vehicleStatus
    } = vehicleResults.vehicle;
    if (vehicleStatus !== _commonsApi.StatusService.VEHICLE_STATUS.FOR_SALE) {
      yield (0, _effects.put)(_actions.Creators.setIsQuoteUpdatingDone());
      yield (0, _effects.put)(_deskingError.errorActions.handleVehicleNotFound(true, vehicleStatus));
      return;
    }
    yield (0, _effects.put)(_actions.Creators.setIsQuoteUpdatingDone());
    yield (0, _effects.put)(_actions.Creators.setMatchVehicle(vehicleResults.vehicle));
    yield (0, _effects.put)(_actions.Creators.setQuoteChanged(true, _quoteChanges.QUOTE_CHANGES_TYPE.CREATE_NEW_QUOTE));
  } catch (error) {
    yield (0, _effects.put)(_actions.Creators.setIsQuoteUpdatingDone());
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'selectVehicleMatch'));
  }
}