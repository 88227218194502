"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = (state, payload) => {
  const {
    amount
  } = payload;
  return {
    ...state,
    paymentOptions: {
      ...state.paymentOptions,
      cashDown: amount
    }
  };
};
exports.default = _default;