"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getValidationBody = void 0;
var _selectors = require("../selectors");
/**
 * @param {Object} state
 * @returns {Object}
 */
const getBody = state => {
  const {
    cashPurchaseOption,
    financeOptions,
    ...paymentOptionsData
  } = (0, _selectors.getPaymentOptions)(state);
  return paymentOptionsData;
};

/**
 * @param {Object} state
 * @param {String} category
 * @param {Number} amount
 * @returns {Object}
 */
const getValidationBody = (state, category, amount) => ({
  ...getBody(state),
  taxableAmount: {
    category,
    amount
  }
});
exports.getValidationBody = getValidationBody;