"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _deskingError = require("@sm360/desking-error");
var _actions = require("../actions");
var _reload = _interopRequireDefault(require("./reload"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function* _default(payload) {
  try {
    yield (0, _effects.put)(_actions.Creators.setOrganizationUnitId(payload.dealerId.toString()));
    yield (0, _effects.call)(_reload.default);
    yield (0, _effects.put)(_actions.Creators.resetFilters());
    return true;
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'selectDealer'));
    return false;
  }
}