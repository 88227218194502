"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.EMAIL_DESCRIPTION = void 0;
const EMAIL_DESCRIPTION = exports.EMAIL_DESCRIPTION = [{
  value: 'Home',
  translationKey: 'FINANCING.APPLICANT_INFO.EMAIL_DESCRIPTION.HOME'
}, {
  value: 'Work',
  translationKey: 'FINANCING.APPLICANT_INFO.EMAIL_DESCRIPTION.WORK'
}];
var _default = exports.default = {
  EMAIL_DESCRIPTION
};