"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateMergeContact = exports.mergeContact = exports.getContact = void 0;
var _appsCore = require("@sm360/apps-core");
var _payload = require("../tools/payload.transform");
const CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;
const getContact = async id => {
  const contact = await _appsCore.axios.get(`${CRM_ENDPOINT}/contacts/${id}`);
  return Promise.resolve((0, _payload.transformContactFromBackend)(contact.data));
};
exports.getContact = getContact;
const validateMergeContact = async _ref => {
  let {
    baseContactId,
    duplicateContactId
  } = _ref;
  return await _appsCore.axios.post(`${CRM_ENDPOINT}/contacts/merge/validate?baseContactId=${baseContactId}&duplicatedContactId=${duplicateContactId}`);
};
exports.validateMergeContact = validateMergeContact;
const mergeContact = async _ref2 => {
  let {
    baseContactId,
    duplicateContactId,
    userId,
    body
  } = _ref2;
  return await _appsCore.axios.post(`${CRM_ENDPOINT}/contacts/merge/override?baseContactId=${baseContactId}&duplicatedContactId=${duplicateContactId}&userId=${userId}`, body);
};
exports.mergeContact = mergeContact;