"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _actions = require("../../../../redux/actions");
var _selectors = require("../../../../redux/selectors");
var _finance = require("../../../../redux/selectors/finance");
var _InputCashDown = _interopRequireDefault(require("./InputCashDown"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} state - Global state
 * @returns {Object} injected props
 */
const mapStateToProps = state => {
  const finance = (0, _finance.getQuoteFinance)(state);
  const isCashDownIncludesTaxes = (0, _finance.getIsCashDownIncludesTaxes)(state);
  const isRebatesIncludesTaxes = (0, _finance.getIsRebatesIncludesTaxes)(state);
  const securityDepositIsWaived = (0, _finance.getSecurityDepositIsWaived)(state);
  const salesDeposit = (0, _finance.getSalesDeposit)(state);
  const {
    cashDownTaxesDetails
  } = finance;
  const aprDetails = (0, _finance.getAprDetails)(state);
  const isReadOnly = (0, _selectors.getIsReadOnly)(state);
  return {
    isReadOnly,
    cashDownTaxesDetails,
    isCashDownIncludesTaxes,
    isRebatesIncludesTaxes,
    securityDepositIsWaived,
    salesDeposit,
    cashOnlyRebatesAdjustment: aprDetails.cashOnlyRebatesAdjustment,
    adminFeesNotChargedToCashAdjustment: aprDetails.adminFeesNotChargedToCashAdjustment
  };
};

/**
 * @param {Function} dispatch
 * @returns {Object} injected props
 */
const mapDispatchToProps = dispatch => ({
  setQuoteFlags: params => dispatch(_actions.Creators.setQuoteFlags(params))
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps))(_InputCashDown.default);