"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getBody = void 0;
var _selectors = require("../selectors");
/**
 * @param {Object} state
 * @param {Number} calculationTargetValue
 * @returns {Object}
 */
const getBody = (state, calculationTargetValue) => {
  const {
    cashPurchaseOption,
    financeOptions,
    ...paymentOptionsData
  } = (0, _selectors.getPaymentOptions)(state);
  return {
    calculationTargetValue,
    ...paymentOptionsData
  };
};
exports.getBody = getBody;