"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _requestAddToGarage = require("../../selectors/requestAddToGarage");
/**
 * @param {Object} vehicle
 * @returns {Object} quote
 */
function* _default(vehicle) {
  const queryParams = yield (0, _effects.select)(_requestAddToGarage.getQueryParams);
  const body = yield (0, _effects.select)(_requestAddToGarage.getBodyVehicleMatch, vehicle);
  try {
    return yield (0, _effects.call)(_commonsApi.QuoteService.addNewVehicle, queryParams, body, vehicle.showroomVehicleId);
  } catch (error) {
    return yield (0, _effects.call)(_deskingError.errorSagas.handleAddVehicleError, error, 'createQuote');
  }
}