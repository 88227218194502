"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/**
 * @param {Object} state
 * @returns {Object}
 */
var _default = state => {
  return {
    ...state,
    showInvalidResponseModal: false,
    invalidResponseTitle: '',
    invalidResponseMessages: []
  };
};
exports.default = _default;