"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.categories = exports.SIMILAR_MATCHES_SECTION = exports.PHONE_TYPE = exports.PERFECT_MATCHES_SECTION = exports.EMAIL_TYPE = exports.ACCOUNT_CREATION_SECTION = void 0;
const PERFECT_MATCHES_SECTION = exports.PERFECT_MATCHES_SECTION = 'perfectMatchesSection';
const SIMILAR_MATCHES_SECTION = exports.SIMILAR_MATCHES_SECTION = 'similarMatchesSection';
const ACCOUNT_CREATION_SECTION = exports.ACCOUNT_CREATION_SECTION = 'accountCreationSection';
const categories = exports.categories = {
  exactName: 'EXACT_NAME',
  nameTry: 'NAME_TRY',
  phone: 'PHONE'
};
const PHONE_TYPE = exports.PHONE_TYPE = {
  MOBILE: 'mobile',
  WORK: 'work',
  HOME: 'home'
};
const EMAIL_TYPE = exports.EMAIL_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary'
};