"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _reactRedux = require("react-redux");
var _payment = require("../../../../constants/payment");
var _actions = require("../../../../redux/actions");
var _selectors = require("../../../../redux/selectors");
var _InputLien = _interopRequireDefault(require("./InputLien"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} state - Global state
 * @returns {Object} injected props
 */
const mapStateToProps = state => {
  const paymentOptions = (0, _selectors.getPaymentOptions)(state);
  const {
    lien
  } = paymentOptions;
  const isReadOnly = (0, _selectors.getIsReadOnly)(state);
  return {
    value: lien,
    isReadOnly
  };
};

/**
 * @param {Function} dispatch
 * @returns {Object} injected props
 */
const mapDispatchToProps = dispatch => ({
  changeLien: value => dispatch(_actions.Creators.changePaymentOptions({
    [_payment.KEY_LIEN]: value
  }))
});
var _default = exports.default = (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_InputLien.default);