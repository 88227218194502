"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _actions = require("../../actions");
var _selectors = require("../../selectors");
var _vehicle = require("../../selectors/vehicle");
var _updatePayOptAndTransDetails = _interopRequireDefault(require("./updatePayOptAndTransDetails"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {String} params
 */
function* _default(payload) {
  const paymentOptions = yield (0, _effects.select)(_selectors.getPaymentOptions);
  const {
    lang,
    location,
    organizationId,
    organizationUnitId
  } = yield (0, _effects.select)(_selectors.getConfig);
  const {
    saleIsOutsideLocation,
    registrationLocation,
    registrationLocationTaxPlan
  } = payload;
  const quoteId = yield (0, _effects.select)(_selectors.getQuoteId);
  const queryParams = {
    orgId: organizationId,
    orgUnitId: organizationUnitId,
    location,
    lang,
    saleIsOutsideLocation,
    registrationLocation,
    registrationLocationTaxPlan
  };
  try {
    const newQuote = yield (0, _effects.call)(_commonsApi.ShowroomService.desking.getLocationOutsideProvince, quoteId, queryParams);
    const vehicle = yield (0, _effects.select)(_vehicle.getVehicle);
    yield (0, _effects.put)(_actions.Creators.setQuote({
      ...newQuote,
      vehicle
    }));
    const {
      cashDown,
      financeOptions,
      financePlan,
      kmPerYearPlan,
      lien,
      odometer,
      paymentFrequency,
      purchaseMethod,
      saleType,
      taxPlan,
      term,
      tradeIn,
      transactionToken,
      vehicleCondition
    } = newQuote;
    const paymentOptionsParams = {
      cashDown,
      financeOptions,
      financePlan,
      kmPerYearPlan,
      lien,
      odometer,
      paymentFrequency,
      purchaseMethod,
      saleType,
      taxPlan,
      term,
      tradeIn,
      transactionToken,
      vehicleCondition
    };
    yield (0, _effects.call)(_updatePayOptAndTransDetails.default, {
      ...paymentOptions,
      ...paymentOptionsParams
    });
    return newQuote;
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'getLocationOutsideProvince'));
    return null;
  }
}