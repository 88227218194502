"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useExteriorColors = void 0;
var _appsCore = require("@sm360/apps-core");
var _VehicleStaticDataService = require("../../api/VehicleStaticDataService");
var _utils = require("../../tools/utils");
const transformExteriorColorsToOptions = data => (0, _utils.transformDataToOptions)(data, 'color');

/**
 * @returns {Object} a Query object
 */

const useExteriorColors = params => {
  const {
    select,
    ...rest
  } = params || {};
  return (0, _appsCore.useQuery)({
    queryKey: ['exteriorColors'],
    queryFn: _VehicleStaticDataService.getVehicleExteriorColors,
    select: select || transformExteriorColorsToOptions,
    ...rest
  });
};
exports.useExteriorColors = useExteriorColors;