"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.FORM_INDEX = exports.CANADIAN_CURRENCY = exports.AMERICAN_CURRENCY = void 0;
const FORM_INDEX = exports.FORM_INDEX = {
  PERSONAL_INFO: 0,
  RESIDENCE_INFO: 1,
  EMPLOYMENT_INFO: 2,
  JOINT_INFO: 3
};
const CANADIAN_CURRENCY = exports.CANADIAN_CURRENCY = 'CAD';
const AMERICAN_CURRENCY = exports.AMERICAN_CURRENCY = 'USD';
var _default = exports.default = {
  FORM_INDEX,
  CANADIAN_CURRENCY,
  AMERICAN_CURRENCY
};