"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = (state, payload) => {
  const {
    hasTaxesIncluded
  } = payload;
  return {
    ...state,
    hasTaxesIncluded
  };
};
exports.default = _default;