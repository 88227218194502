"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _selectors = require("../../selectors");
var _actions = require("../../actions");
var _customer = require("../../../constants/customer");
/**
 */
function* getCustomer() {
  const {
    organizationId,
    organizationUnitId,
    location,
    lang
  } = yield (0, _effects.select)(_selectors.getConfig);
  const leadId = yield (0, _effects.select)(_selectors.getLeadId);
  const queryParams = {
    orgId: organizationId,
    orgUnitId: organizationUnitId,
    location,
    lang,
    mainApplicant: true
  };
  try {
    let response = yield (0, _effects.call)(_commonsApi.ShowroomService.desking.getCustomer, leadId, queryParams);
    yield (0, _effects.put)(_actions.Creators.setCustomer(response));
    const hasCreditApplication = yield (0, _effects.select)(_selectors.getHasCreditApplication);
    if (hasCreditApplication === false) {
      yield (0, _effects.put)(_actions.Creators.updateCustomer({
        applicant: {
          type: _customer.APPLICATION_TYPE.INDIVIDUAL
        },
        coApplicant: {}
      }));
    }
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'getCustomer'));
  }
}
var _default = exports.default = getCustomer;