"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsUtils = require("@sm360/commons-utils");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _selectors = require("../../selectors");
var _updatePayOptAndTransDetails = _interopRequireDefault(require("../api/updatePayOptAndTransDetails"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @TODO better handling when calls not valid
 *
 * @param {Object} payload - { items }
 */
function* _default(payload) {
  const {
    items,
    commissionAdjustmentType
  } = payload;
  const quoteId = yield (0, _effects.select)(_selectors.getQuoteId);
  const queryParams = yield (0, _effects.select)(_selectors.getShowroomQueryParams);
  const formattedQueryParams = {
    ...queryParams,
    commissionAdjustmentType
  };
  try {
    let paymentOptions;
    let response;
    for (let i = 0; i < items.length; i += 1) {
      paymentOptions = yield (0, _effects.select)(_selectors.getPaymentOptions);
      const quote = yield (0, _effects.select)(_selectors.getQuote);
      const body = {
        transactionToken: paymentOptions.transactionToken,
        saleType: quote.saleType,
        taxPlan: quote.taxPlan,
        vehicleCondition: quote.vehicleCondition,
        odometer: paymentOptions.odometer,
        purchaseMethod: paymentOptions.purchaseMethod,
        financePlan: paymentOptions.financePlan,
        kmPerYearPlan: paymentOptions.kmPerYearPlan,
        paymentFrequency: paymentOptions.paymentFrequency,
        term: paymentOptions.term,
        cashDown: paymentOptions.cashDown,
        tradeIn: paymentOptions.tradeIn,
        lien: paymentOptions.lien,
        displayCode: items[i].displayCode,
        description: items[i].description,
        commission: items[i].cost
      };
      response = yield (0, _effects.call)(_commonsApi.ShowroomService.desking.addCommissionAdjustment, quoteId, formattedQueryParams, body);
      if ((0, _commonsUtils.isPlainObject)(response) && response.isValid === true) {
        yield (0, _effects.call)(_updatePayOptAndTransDetails.default, {
          ...paymentOptions,
          ...response.negociationTransaction,
          callCommission: true
        });
      }
    }
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'addCommissionAdjustment'));
  }
}