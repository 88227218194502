"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transformFinanceOptionsRequest = void 0;
/**
 * Prepare financeOptions array for the request
 *  - Remove useless params
 *  - Prepare cashDowns param
 *
 * @param {Array} financeOptions
 * @returns {Array}
 */
const transformFinanceOptionsRequest = financeOptions => {
  return financeOptions.map(financeOptionsItem => {
    const {
      buyBackAmount,
      cashDownOptions,
      disclaimer,
      incentives,
      selectedApr,
      selectedPayment,
      sellingPrice,
      ...options
    } = financeOptionsItem;
    return {
      ...options,
      cashDowns: cashDownOptions.map(cashDownOptionsItem => cashDownOptionsItem.cashDown ? cashDownOptionsItem.cashDown : 0)
    };
  });
};
exports.transformFinanceOptionsRequest = transformFinanceOptionsRequest;