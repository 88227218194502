"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _actions = require("../actions");
var _selectors = require("../selectors");
var _payment = require("../../constants/payment");
/**
 * @param {Object} payload - Data from action
 */
function* changeDesiredPayment(payload) {
  const quoteId = yield (0, _effects.select)(_selectors.getQuoteId);
  const queryParams = yield (0, _effects.select)(_selectors.getShowroomQueryParams);
  const quote = yield (0, _effects.select)(_selectors.getQuote);
  const hasTaxesIncluded = yield (0, _effects.select)(_selectors.getHasTaxesIncluded);
  const paymentOptions = yield (0, _effects.select)(_selectors.getPaymentOptions);
  const body = {
    calculationTargetType: payload.targetType,
    calculationTargetValue: parseFloat(payload.value),
    includeTaxes: hasTaxesIncluded,
    transactionToken: paymentOptions.transactionToken,
    saleType: quote.saleType,
    taxPlan: quote.taxPlan,
    vehicleCondition: quote.vehicleCondition,
    purchaseMethod: paymentOptions.purchaseMethod,
    financePlan: paymentOptions.financePlan,
    kmPerYearPlan: paymentOptions.kmPerYearPlan,
    paymentFrequency: paymentOptions.paymentFrequency,
    term: paymentOptions.term,
    cashDown: paymentOptions.cashDown,
    tradeIn: paymentOptions.tradeIn,
    lien: paymentOptions.lien,
    odometer: paymentOptions.odometer
  };
  try {
    const response = yield (0, _effects.call)(_commonsApi.ShowroomService.desking.paymentRoll, quoteId, queryParams, body);
    const {
      isValid
    } = response;
    if (isValid) {
      if (payload.handleIsValid && typeof payload.handleIsValid === 'function') {
        payload.handleIsValid(true);
      }
      yield (0, _effects.put)(_actions.Creators.changePaymentOptions({
        ...response.negociationTransaction
      }));
      yield (0, _effects.put)(_actions.Creators.setCalculatedAdjustments({
        [_payment.PAYMENT_ADJUSTMENT_TYPE.CASH_DOWN]: response.calculatedCashDown,
        [_payment.PAYMENT_ADJUSTMENT_TYPE.SELLING_PRICE]: response.calculatedDealDiscount,
        [_payment.PAYMENT_ADJUSTMENT_TYPE.TRADE_IN]: response.calculatedTradeIn
      }));
    }
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'updateQuote'));
  }
}
var _default = exports.default = changeDesiredPayment;