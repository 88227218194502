"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _selectors = require("../../redux/selectors");
var _actions = require("../../redux/actions");
var _DealersDropdown = _interopRequireDefault(require("./DealersDropdown"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const mapStateToProps = state => {
  const config = (0, _selectors.getConfig)(state);
  const {
    organizationUnitId
  } = config;
  return {
    dealersList: (0, _selectors.getDealersList)(state),
    organizationUnitId
  };
};
const mapDispatchToProps = dispatch => ({
  selectDealer: dealerId => dispatch(_actions.Creators.selectDealer(dealerId))
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps))(_DealersDropdown.default);