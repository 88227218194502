"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsUtils = require("@sm360/commons-utils");
var _selectors = require("../selectors");
var _updatePayOptAndTransDetails = _interopRequireDefault(require("./api/updatePayOptAndTransDetails"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * If no options in payload, we just reload the paymentOptions with what's inside the store
 *
 * @param {Object} payload
 */
function _default(_ref) {
  let {
    options
  } = _ref;
  return function* () {
    const currentPaymentOptions = yield (0, _effects.select)(_selectors.getPaymentOptions);
    const paymentOptions = {
      ...currentPaymentOptions
    };
    if ((0, _commonsUtils.isPlainObject)(options)) {
      Object.assign(paymentOptions, options);
    }
    yield (0, _effects.call)(_updatePayOptAndTransDetails.default, paymentOptions);
  }();
}