"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Details", {
  enumerable: true,
  get: function () {
    return _Details.default;
  }
});
Object.defineProperty(exports, "MainInfo", {
  enumerable: true,
  get: function () {
    return _MainInfo.default;
  }
});
Object.defineProperty(exports, "MainPicture", {
  enumerable: true,
  get: function () {
    return _MainPicture.default;
  }
});
Object.defineProperty(exports, "Pricing", {
  enumerable: true,
  get: function () {
    return _Pricing.default;
  }
});
Object.defineProperty(exports, "PromoTags", {
  enumerable: true,
  get: function () {
    return _PromoTags.default;
  }
});
var _Details = _interopRequireDefault(require("./Details"));
var _MainInfo = _interopRequireDefault(require("./MainInfo"));
var _MainPicture = _interopRequireDefault(require("./MainPicture"));
var _Pricing = _interopRequireDefault(require("./Pricing"));
var _PromoTags = _interopRequireDefault(require("./PromoTags"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }