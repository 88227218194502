"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "VehiclesList", {
  enumerable: true,
  get: function () {
    return _VehiclesList.default;
  }
});
var _VehiclesList = _interopRequireDefault(require("./components/VehiclesList"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }