"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _reactRedux = require("react-redux");
var _actions = require("../../../../redux/actions");
var _paymentOptions = require("../../../../redux/selectors/paymentOptions");
var _SelectTerm = _interopRequireDefault(require("./SelectTerm"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} state - Global state
 * @returns {Object} injected props
 */
const mapStateToProps = state => {
  const {
    terms,
    term
  } = (0, _paymentOptions.getPaymentBarData)(state);
  return {
    choices: terms,
    value: term
  };
};

/**
 * @param {Function} dispatch
 * @returns {Object} injected props
 */
const mapDispatchToProps = dispatch => ({
  updateTerm: term => dispatch(_actions.Creators.updateTerm(parseInt(term, 10)))
});
var _default = exports.default = (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_SelectTerm.default);