"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getQueryParams = exports.getPathParams = exports.getBody = void 0;
var _deskingCommons = require("@sm360/desking-commons");
var _selectors = require("../selectors");
var _stepTrim = require("./stepTrim");
var _paymentOptions = require("./paymentOptions");
var _content = require("./content");
/**
 * @param {Object} state
 * @returns {Object}
 */
const getBody = state => {
  const {
    configurationId,
    modelTrimCode,
    transactionToken
  } = (0, _selectors.getBaseProps)(state);
  const baseBody = (0, _selectors.getBaseBodyParams)(state);
  const financeOptions = (0, _paymentOptions.getFinanceOptions)(state);
  const selectedFinancePlan = (0, _paymentOptions.getSelectedFinancePlan)(state);
  return {
    configurationId,
    modelTrimCode,
    transactionToken,
    financePlan: selectedFinancePlan,
    ...baseBody,
    financeOptions
  };
};

/**
 * @param {Object} state
 * @returns {Array}
 */
exports.getBody = getBody;
const getPathParams = state => {
  const year = (0, _content.getYear)(state);
  const baseParams = (0, _selectors.getBasePathParams)(state);
  const modelTrim = (0, _stepTrim.getModelTrim)(state);
  return [year, ...baseParams, modelTrim.slug];
};

/**
 * @param {Object} state
 * @return {Object}
 */
exports.getPathParams = getPathParams;
const getQueryParams = state => ({
  ...(0, _selectors.getQueryParams)(state),
  priceIncreaseRollCount: _deskingCommons.priceIncreaseSelectors.getPriceIncreaseRollCount(state),
  includeMeta: true
});
exports.getQueryParams = getQueryParams;