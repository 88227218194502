"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsUtils = require("@sm360/commons-utils");
var _actions = require("../../actions");
var _selectors = require("../../selectors");
var _getTradeIn = _interopRequireDefault(require("./getTradeIn"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function* _default() {
  const leadId = yield (0, _effects.select)(_selectors.getLeadId);
  // Safety check
  if (!leadId) {
    return;
  }
  const tradeIn = yield (0, _effects.select)(_selectors.getTradeIn);
  const newTradeIn = yield (0, _effects.call)(_getTradeIn.default);
  const hasTradeIn = tradeIn && !(0, _commonsUtils.isEmpty)(tradeIn);
  const hasNewTradeIn = newTradeIn && !(0, _commonsUtils.isEmpty)(newTradeIn);
  const hasTradeInBeenRemoved = hasTradeIn && !hasNewTradeIn;
  if (newTradeIn || hasTradeInBeenRemoved) {
    yield (0, _effects.put)(_actions.Creators.reloadTradeInSuccess(newTradeIn));
    yield (0, _effects.put)(_actions.Creators.refreshQuote());
  }
}