"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PHONE_INTERLOCUTOR_CHAR_LIMIT = exports.APPOINTMENT_GUESTS_CHAR_LIMIT = exports.ACTION_TYPES = void 0;
const PHONE_INTERLOCUTOR_CHAR_LIMIT = exports.PHONE_INTERLOCUTOR_CHAR_LIMIT = 45;
const APPOINTMENT_GUESTS_CHAR_LIMIT = exports.APPOINTMENT_GUESTS_CHAR_LIMIT = 5000;
const ACTION_TYPES = exports.ACTION_TYPES = {
  APPOINTMENT: 'appointment',
  TASK: 'task',
  SEND_SMS: 'sendSmsThread',
  SEND_EMAIL: 'sendEmail',
  READ_EMAIL: 'readEmail',
  CREATE_CALL: 'createCall',
  CREATE_NOTE: 'createNote'
};