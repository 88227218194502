"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _selectors = require("../selectors");
function* _default() {
  const quote = yield (0, _effects.select)(_selectors.getQuote);
  try {
    yield (0, _effects.call)(_commonsApi.DealService.createDealFromQuote, quote);
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'createDeal'));
  }
}