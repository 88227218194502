"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updatePhoneNumber = exports.getPhonesNumbers = exports.getPhoneNumber = exports.deletePhoneNumber = exports.createPhoneNumber = void 0;
var _appsCore = require("@sm360/apps-core");
const NEW_CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;

// Get phone numbers list
const getPhonesNumbers = async userId => {
  return await _appsCore.axios.get(`${NEW_CRM_ENDPOINT}/users/phoneNumbers?userId=${userId}`);
};
exports.getPhonesNumbers = getPhonesNumbers;
const getPhoneNumber = async id => {
  return await _appsCore.axios.get(`${NEW_CRM_ENDPOINT}/users/phoneNumbers/${id}`);
};
exports.getPhoneNumber = getPhoneNumber;
const createPhoneNumber = async body => {
  return await _appsCore.axios.post(`${NEW_CRM_ENDPOINT}/users/phoneNumbers`, body);
};
exports.createPhoneNumber = createPhoneNumber;
const updatePhoneNumber = async body => {
  return await _appsCore.axios.put(`${NEW_CRM_ENDPOINT}/users/phoneNumbers/${body.id}`, body);
};
exports.updatePhoneNumber = updatePhoneNumber;
const deletePhoneNumber = async id => {
  return await _appsCore.axios.delete(`${NEW_CRM_ENDPOINT}/users/phoneNumbers/${id}`);
};
exports.deletePhoneNumber = deletePhoneNumber;