"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _paymentOptions = require("../../../../redux/selectors/paymentOptions");
var _actions = require("../../../../redux/actions");
var _SelectKmPlan = _interopRequireDefault(require("./SelectKmPlan"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} state - Global state
 * @returns {Object} injected props
 */
const mapStateToProps = state => {
  const {
    kmPerYearPlans,
    kmPerYearPlan
  } = (0, _paymentOptions.getPaymentBarData)(state);
  return {
    choices: kmPerYearPlans,
    value: kmPerYearPlan
  };
};

/**
 * @param {Function} dispatch
 * @returns {Object} injected props
 */
const mapDispatchToProps = dispatch => ({
  updateKmPlan: value => dispatch(_actions.Creators.updateKmPlan(value))
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps))(_SelectKmPlan.default);