"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useDriveTrains = void 0;
var _appsCore = require("@sm360/apps-core");
var _VehicleStaticDataService = require("../../api/VehicleStaticDataService");
/**
 * @returns {Object} a Query object
 */

const useDriveTrains = params => {
  return (0, _appsCore.useQuery)({
    queryKey: ['driveTrains'],
    queryFn: _VehicleStaticDataService.getVehicleDriveTrains,
    ...params
  });
};
exports.useDriveTrains = useDriveTrains;