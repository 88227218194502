"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _selectors = require("../selectors");
var _actions = require("../actions");
var _quoteChanges = require("../../constants/quoteChanges");
var _api = require("./api");
function* _default(payload) {
  const accountId = yield (0, _effects.select)(_selectors.getAccountId);
  const dates = yield (0, _effects.select)(_selectors.getFinancingDates);
  const quote = yield (0, _effects.call)(_api.addVehicleToGarage, payload.selectedVehicle);
  if (quote) {
    const newQuote = yield (0, _effects.call)(_api.loadQuote, quote.id);
    const {
      id,
      quoteRevision
    } = newQuote;
    const body = {
      quoteId: id,
      accountId,
      quoteRevision
    };
    yield (0, _effects.fork)(_api.setPreferredQuote, body);

    /** Get dates from previous quote and apply same dates to new selected quote */
    yield (0, _effects.put)(_actions.Creators.setFinancingDates({
      ...dates
    }));
    yield (0, _effects.put)(_actions.Creators.setFinancingDatesChanged(true));
    yield (0, _effects.put)(_actions.Creators.setQuoteChanged(false, _quoteChanges.QUOTE_CHANGES_TYPE.MODIFY_QUOTE));
    yield (0, _effects.put)(_actions.Creators.updateQuoteInformation());
  }
}