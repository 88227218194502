"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const contact = {
  address: '',
  address2: '',
  city: '',
  country: '',
  dateOfBirth: null,
  // Not available
  driverLicense: '',
  driverLicenseProvince: '',
  // Not available
  dependents: 0,
  // Not available
  educationLevel: '',
  // Not available
  email1: '',
  email2: '',
  firstname: '',
  homePhone: null,
  lastname: '',
  mobilePhone: null,
  postalCode: '',
  preferredContactMoment: {
    key: '',
    label: ''
  },
  preferredContactTime: {
    key: '',
    label: ''
  },
  province: '',
  workPhone: null,
  workPhoneExtension: null
};
var _default = exports.default = contact;