"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generateFinancePlansChoices = exports.findPlanFromOptions = void 0;
/**
 * @param {Array} plans
 * @param {Object} options
 * @returns {Object|undefined}
 * @throws TypeError
 */
const findPlanFromOptions = (plans, options) => {
  if (!Array.isArray(plans) || plans.length === 0) {
    return {};
  }
  const {
    financePlan,
    purchaseMethod
  } = options;
  if (typeof financePlan !== 'string' || typeof purchaseMethod !== 'string') {
    throw new TypeError(`A financeOptions object is not valid:
            financePlan=${typeof financePlan} purchaseMedthod=${typeof purchaseMethod}`);
  }
  return plans.find(item => item.purchaseMethod === options.purchaseMethod && item.financePlan === options.financePlan);
};

/**
 * @param {Array} plans
 * @retruns {Array}
 * @throws TypeError
 */
exports.findPlanFromOptions = findPlanFromOptions;
const generateFinancePlansChoices = plans => {
  if (!Array.isArray(plans) || plans.length === 0) {
    return [];
  }
  const options = plans.map(plan => ({
    isDisabled: !plan.isAvailable,
    value: `${plan.financePlan}|${plan.purchaseMethod}`,
    label: plan.financePlanDescription
  }));

  // If all plans are disabled, don't return any options
  const areAllDisabled = options.every(opt => opt.isDisabled);
  return areAllDisabled ? [] : options;
};
exports.generateFinancePlansChoices = generateFinancePlansChoices;