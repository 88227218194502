"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.timeStampToStringDate = exports.timeStampToDate = exports.isFormatDate = exports.getTodayStartTimeStamp = exports.dateToTimeStamp = exports.dateToReadableStringFormat = void 0;
const isFormatDate = value => {
  return value instanceof Date && Object.prototype.toString.call(value) === '[object Date]';
};
exports.isFormatDate = isFormatDate;
const isFormatTimeStamp = value => {
  return typeof value === 'number';
};
const isDateValid = date => {
  return date instanceof Date && !Number.isNaN(date);
};
const getDate = timeStamp => {
  let t = timeStamp;
  if (!isFormatTimeStamp(t)) {
    t = parseInt(t, 10);
  }
  // js timeStamp is in ms and unix timeStamp in s
  return new Date(t * 1000);
};
const getTimeStamp = date => {
  let d = date;
  if (!isFormatDate(date)) {
    d = new Date(date);
  }
  if (!isDateValid(d)) {
    return null;
  }
  // js timeStamp is in ms and unix timeStamp in s
  return d.getTime() / 1000;
};
const timeStampToStringDate = timeStamp => {
  if (timeStamp) {
    const date = getDate(timeStamp);
    return isDateValid(date) ? date.toString() : null;
  }
  return null;
};
exports.timeStampToStringDate = timeStampToStringDate;
const timeStampToDate = timeStamp => {
  if (timeStamp) {
    const date = getDate(timeStamp);
    return isDateValid(date) ? date : null;
  }
  return null;
};
exports.timeStampToDate = timeStampToDate;
const dateToTimeStamp = date => {
  if (date) {
    const timeStamp = getTimeStamp(date);
    return timeStamp;
  }
  return null;
};

// TODO define this function globally (or even the whole file)
exports.dateToTimeStamp = dateToTimeStamp;
const dateToReadableStringFormat = (date, lang) => {
  if (date && isFormatDate(date)) {
    const d = date.getDate();
    const m = date.getMonth() + 1;
    const day = d < 10 ? '0'.concat(d).toString() : d.toString();
    const month = m < 10 ? '0'.concat(m).toString() : m.toString();
    const year = date.getFullYear().toString();
    if (lang.search('en') !== -1 || lang.search('en'.toUpperCase()) !== -1) {
      return month.concat('/', day, '/', year);
    }
    if (lang.search('uni') !== -1 || lang.search('uni'.toUpperCase()) !== -1) {
      return year.concat('/', month, '/', day);
    }
    return day.concat('/', month, '/', year);
  }
  return null;
};
/**
 *
 * @returns unix timestamp (seconds)
 */
exports.dateToReadableStringFormat = dateToReadableStringFormat;
const getTodayStartTimeStamp = () => {
  const now = new Date();
  const day = now.getDate();
  const year = now.getFullYear();
  const month = now.getMonth();
  const todayStart = new Date(year, month, day, 0, 0, 0);
  return todayStart.getTime() / 1000;
};
exports.getTodayStartTimeStamp = getTodayStartTimeStamp;