"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ucFirst = exports.getTabRebateContent = exports.getTabRebate = exports.getTabOptionContent = exports.getTabOption = exports.getTabNoteContent = exports.getTabNote = exports.getTabItems = exports.getTabContent = exports.getTabColourContent = exports.getTabColour = exports.getTabAccessoryContent = exports.getTabAccessory = exports.getSelectedTab = exports.getSelectedInteriorTrim = exports.getSelectedIndex = exports.getSelectedExteriorColour = exports.getRequestValidateBody = exports.getRequestFetchBody = exports.getIsTabColourDisabled = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _deskingCommons = require("@sm360/desking-commons");
var _selectors = require("../selectors");
/**
 * @param {Object} state
 * @returns {Object}
 */
const getTabItems = state => {
  const {
    tabItems
  } = state;
  return (0, _commonsUtils.isPlainObject)(tabItems) ? tabItems : {};
};

/**
 * @param {Object} state
 * @returns {Number}
 */
exports.getTabItems = getTabItems;
const getSelectedIndex = state => {
  const {
    selectedIndex
  } = getTabItems(state);
  return Number.isInteger(selectedIndex) ? selectedIndex : 0;
};

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getSelectedIndex = getSelectedIndex;
const getIsTabColourDisabled = state => {
  const {
    isTabColourDisabled
  } = getTabItems(state);
  return isTabColourDisabled === true;
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getIsTabColourDisabled = getIsTabColourDisabled;
const getTabAccessory = state => {
  const {
    tabItemsAccessory
  } = state;
  return (0, _commonsUtils.isPlainObject)(tabItemsAccessory) ? tabItemsAccessory : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getTabAccessory = getTabAccessory;
const getTabColour = state => {
  const {
    tabItemsColour
  } = state;
  return (0, _commonsUtils.isPlainObject)(tabItemsColour) ? tabItemsColour : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getTabColour = getTabColour;
const getTabOption = state => {
  const {
    tabItemsOption
  } = state;
  return (0, _commonsUtils.isPlainObject)(tabItemsOption) ? tabItemsOption : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getTabOption = getTabOption;
const getTabRebate = state => {
  const {
    tabItemsRebate
  } = state;
  return (0, _commonsUtils.isPlainObject)(tabItemsRebate) ? tabItemsRebate : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getTabRebate = getTabRebate;
const getTabNote = state => {
  const {
    tabItemsNote
  } = state;
  return (0, _commonsUtils.isPlainObject)(tabItemsNote) ? tabItemsNote : {};
};

/**
 * Capitalize first letter only
 *
 * @param {String} val
 * @returns {String}
 */
exports.getTabNote = getTabNote;
const ucFirst = val => `${val.charAt(0).toUpperCase()}${val.slice(1)}`;

/**
 * Prepare data for UI
 *
 * @param {Object} item
 * @param {Function} prefixMethod
 * @param {Boolean} isCashSelected
 * @param {Boolean} hasTaxesIncluded
 * @returns {Object}
 */
exports.ucFirst = ucFirst;
const formatItemData = (item, prefixMethod, isCashSelected, hasTaxesIncluded) => {
  const {
    code,
    displayCode,
    dataTypeId,
    description,
    features,
    isSelected,
    isDisplayCodeAndDescriptionEditable,
    payment: paymentWithoutTaxes,
    photo,
    retail,
    residualRetail,
    supplier,
    totalPayment,
    typeId
  } = item;
  let imageUrl = null;
  let payment = retail;
  if (typeof photo === 'string' && photo.length > 0) {
    const isFullURL = string => {
      let url;
      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }
      return url.protocol === 'http:' || url.protocol === 'https:' || url.protocol === 'data:';
    };
    imageUrl = !isFullURL(photo) ? prefixMethod.call(null, photo) : photo;
  }
  if (!isCashSelected) {
    payment = hasTaxesIncluded ? totalPayment : paymentWithoutTaxes;
  }
  return {
    caption: code,
    displayCode,
    description: ucFirst(description),
    imageUrl,
    isCashSelected,
    isDisplayCodeAndDescriptionEditable,
    isSelected,
    listItems: Array.isArray(features) ? features : [],
    // API Payload when the user want to add/delete an item
    payload: {
      code,
      dataTypeId,
      retail,
      supplier,
      typeId
    },
    payment,
    retail,
    residualRetail
  };
};

/**
 * @param {Object} state
 * @param {Object[]} items
 * @param {Function} prefixMethod
 * @returns {Object[]}
 */
const formatItems = (state, items, prefixMethod) => {
  const isCashSelected = (0, _selectors.getIsCashSelected)(state);
  const hasTaxesIncluded = (0, _selectors.getHasTaxesIncluded)(state);
  return (0, _commonsUtils.sortBy)(items, 'description').map(item => formatItemData(item, prefixMethod, isCashSelected, hasTaxesIncluded));
};

/**
 * @param {Object} state
 * @param {Function} selector
 * @returns {Object[]}
 */
const getTabContent = (state, selector) => {
  const {
    content
  } = selector.call(null, state);
  if (!Array.isArray(content)) {
    return [];
  }
  return formatItems(state, content, _deskingCommons.prefixOptions);
};
exports.getTabContent = getTabContent;
const getTabAccessoryContent = state => getTabContent(state, getTabAccessory);
exports.getTabAccessoryContent = getTabAccessoryContent;
const getTabOptionContent = state => getTabContent(state, getTabOption);
exports.getTabOptionContent = getTabOptionContent;
const getTabRebateContent = state => getTabContent(state, getTabRebate);
exports.getTabRebateContent = getTabRebateContent;
const getTabNoteContent = state => getTabContent(state, getTabNote);

/**
 * @param {Object} state
 * @returns {Object[]}
 */
exports.getTabNoteContent = getTabNoteContent;
const getExteriorColourChoices = state => {
  const {
    content
  } = getTabColour(state);
  if (!Array.isArray(content)) {
    return [];
  }
  const items = content.filter(_ref => {
    let {
      category
    } = _ref;
    return category === _deskingCommons.ITEM_CATEGORY.EXTERIOR_COLOUR;
  });
  return formatItems(state, items, _deskingCommons.prefixNewColor);
};

/**
 * @param {Object} state
 * @returns {Object}
 */
const getSelectedExteriorColour = state => {
  const choices = getExteriorColourChoices(state);
  const found = choices.find(colour => colour.isSelected === true);
  return (0, _commonsUtils.isPlainObject)(found) ? found : {};
};

/**
 * @param {Object} state
 * @returns {Object[]}
 */
exports.getSelectedExteriorColour = getSelectedExteriorColour;
const getInterioTrimChoices = state => {
  const {
    content
  } = getTabColour(state);
  if (!Array.isArray(content)) {
    return [];
  }
  const items = content.filter(_ref2 => {
    let {
      category
    } = _ref2;
    return category === _deskingCommons.ITEM_CATEGORY.INTERIOR_TRIM;
  });
  return formatItems(state, items, _deskingCommons.prefixNewCloth);
};

/**
 * @param {Object} state
 * @returns {Object}
 */
const getSelectedInteriorTrim = state => {
  const choices = getInterioTrimChoices(state);
  const found = choices.find(trim => trim.isSelected === true);
  return (0, _commonsUtils.isPlainObject)(found) ? found : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getSelectedInteriorTrim = getSelectedInteriorTrim;
const getTabColourContent = state => ({
  exteriorColours: getExteriorColourChoices(state),
  interiorTrims: getInterioTrimChoices(state)
});

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getTabColourContent = getTabColourContent;
const getSelectedTab = state => {
  const selectedIndex = getSelectedIndex(state);
  const list = [getTabAccessory(state), getTabColour(state), getTabOption(state), getTabRebate(state), getTabNote(state)];
  const found = list.find(_ref3 => {
    let {
      uiIndex
    } = _ref3;
    return uiIndex === selectedIndex;
  });
  return (0, _commonsUtils.isPlainObject)(found) ? found : {};
};

/**
 * @param {Object} state
 * @param {String[]} category
 * @returns {Object}
 */
exports.getSelectedTab = getSelectedTab;
const getRequestFetchBody = (state, category) => {
  const {
    cashPurchaseOption,
    financeOptions,
    ...paymentOptionsData
  } = (0, _selectors.getPaymentOptions)(state);
  return {
    ...paymentOptionsData,
    category
  };
};

/**
 * @param {Object} state
 * @param {Object} item
 * @returns {Object}
 */
exports.getRequestFetchBody = getRequestFetchBody;
const getRequestValidateBody = (state, item) => {
  const {
    cashPurchaseOption,
    financeOptions,
    ...paymentOptionsData
  } = (0, _selectors.getPaymentOptions)(state);
  return {
    ...paymentOptionsData,
    item
  };
};
exports.getRequestValidateBody = getRequestValidateBody;