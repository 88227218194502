"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("react");
var _appsCore = require("@sm360/apps-core");
var _hooks = require("@sm360/hooks");
var _uiLibrary = require("@sm360/ui-library");
var _commonsUtils = require("@sm360/commons-utils");
var _state = require("../config/state.manager");
var _common = require("../api/common.service");
var _utils = require("../tools/utils");
const useCallNotifications = () => {
  const {
    t
  } = (0, _appsCore.useTranslation)();
  const {
    state: {
      calls,
      new_calls: newNotification,
      last_calls_received: lastCallReceived,
      callTaken
    },
    dispatch
  } = (0, _react.useContext)(_state.CrudContext);
  const {
    state: {
      organization,
      user
    }
  } = (0, _react.useContext)(_appsCore.AppContext);
  const filteredCalls = (0, _react.useMemo)(() => calls.filter(c => !(0, _utils.isCallExpired)(c?.dateCreated)), [calls]);
  const isCallTaken = callTaken && Object.keys(callTaken).length > 0;

  // Update taken call when new notifications come in
  (0, _hooks.useDeepEffect)(() => {
    const updatedCallTaken = calls.find(c => c.activityId === callTaken?.activityId);
    if (updatedCallTaken) {
      dispatch({
        type: 'setCallTaken',
        callTaken: updatedCallTaken
      });
    }
  }, [callTaken?.activityId, calls, dispatch]);

  // Try to extend the taken call with configured numbers data (orgUnitId)
  (0, _hooks.useDeepEffect)(() => {
    if (isCallTaken) {
      try {
        (0, _common.extendCallTakenWithConfiguredNumber)({
          callTaken,
          organization,
          dispatch
        });
      } catch {}
    }
  }, [callTaken?.activityId, organization, dispatch]);
  const setCallTaken = (0, _react.useCallback)(newCallTaken => dispatch({
    type: 'setCallTaken',
    callTaken: newCallTaken
  }), [dispatch]);
  const setCompletedTakenCallFlow = (0, _react.useCallback)(() => dispatch({
    type: 'setCompletedTakenCallFlow'
  }), [dispatch]);

  // Delete a call notification for everyone
  const invalidateNotification = (0, _react.useCallback)((id, organizationUnitId) => {
    setCompletedTakenCallFlow();
    (0, _common.invalidateCall)({
      id,
      organizationId: organization.id,
      organizationUnitId,
      userId: user.id
    }).then(() => {
      _uiLibrary.Toast.toast.success(t('notifications.calls.INVALIDATE_CALL_SUCCESS'), _commonsUtils.SHORT_TOAST_OPTIONS);
    });
  }, [organization.id, setCompletedTakenCallFlow, t, user.id]);

  // Locally dismiss a call notification
  const dismissNotification = (0, _react.useCallback)(id => {
    dispatch({
      type: 'dismissNotifications',
      notifications: {
        type: 'calls',
        id
      }
    });

    // ensure we locally remove the sms notification from all other NotificationsProvider of the browser
    (0, _utils.internallyDismissNotification)('call', id);
  }, [dispatch]);
  return {
    allCalls: filteredCalls,
    newNotification,
    lastCallReceived,
    invalidateNotification,
    dismissNotification,
    callTaken,
    setCallTaken,
    isCallTaken,
    setCompletedTakenCallFlow // ideally should be updated internally
  };
};
var _default = exports.default = useCallNotifications;