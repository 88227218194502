"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getStaticData = void 0;
var _appsCore = require("@sm360/apps-core");
var _utils = require("../tools/utils");
const CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;
const getStaticData = async _ref => {
  let {
    dispatch,
    organization,
    t
  } = _ref;
  const typeIds = await _appsCore.axios.get(`${CRM_ENDPOINT}/leads/static/types`);
  const groupedTypes = (0, _utils.groupTypesByCategory)(typeIds?.data || []);
  const mediaIds = await _appsCore.axios.get(`${CRM_ENDPOINT}/leads/static/medias?organizationId=${organization.id}`);
  dispatch({
    type: 'initStaticData',
    staticData: {
      types: typeIds.data.map(t => ({
        id: t.id,
        value: t.id,
        label: t.type
      })),
      groupedTypes,
      medias: mediaIds.data.map(t => ({
        id: t.id,
        label: t.media,
        value: t.id
      })).sort((a, b) => a.label.localeCompare(b.label))
    }
  });
};
exports.getStaticData = getStaticData;