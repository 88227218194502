"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  fetchAll: true
};
exports.fetchAll = void 0;
var _request = require("../../tools/request");
var _constants = require("./constants");
Object.keys(_constants).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _constants[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _constants[key];
    }
  });
});
const VEHICLES_FILTER_API_URL = process.env.REACT_APP_FILTER_API_URL;

/**
 * Specific format for the vehicleSoldAfterDate parameter, DD-MM-YYYY
 *
 * @returns {String}
 */
const today = () => {
  const now = new Date();
  return `${`0${now.getDate()}`.slice(-2)}-${`0${now.getMonth() + 1}`.slice(-2)}-${now.getFullYear()}`;
};

/**
 * @param {Object} queryParams
 * @returns {Object[]}
 */
const fetchAll = async function () {
  let queryParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const url = `${VEHICLES_FILTER_API_URL}/filters/count`;
  const params = new URLSearchParams();
  let queryParam;
  params.append('vehicleSoldAfterDate', today());
  Object.keys(queryParams).forEach(key => {
    queryParam = queryParams[key];
    if (!Array.isArray(queryParam)) {
      params.append(key, queryParam);
    } else {
      queryParam.forEach(value => params.append(key, value));
    }
  });
  const {
    response
  } = await (0, _request.get)(url, {
    params
  });

  // When 204, empty object
  if (response === undefined) {
    return [];
  }
  if (!Array.isArray(response)) {
    throw new TypeError('The response of the /filters/count endpoint is not an array');
  }
  return response;
};
exports.fetchAll = fetchAll;