"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.MARITAL_STATUS = void 0;
const MARITAL_STATUS = exports.MARITAL_STATUS = [{
  value: 'CL',
  translationKey: 'FINANCING.APPLICANT_INFO.MARITAL_STATUS_OPTIONS.CL'
}, {
  value: 'DV',
  translationKey: 'FINANCING.APPLICANT_INFO.MARITAL_STATUS_OPTIONS.DV'
}, {
  value: 'MR',
  translationKey: 'FINANCING.APPLICANT_INFO.MARITAL_STATUS_OPTIONS.MR'
}, {
  value: 'SG',
  translationKey: 'FINANCING.APPLICANT_INFO.MARITAL_STATUS_OPTIONS.SG'
}, {
  value: 'SP',
  translationKey: 'FINANCING.APPLICANT_INFO.MARITAL_STATUS_OPTIONS.SP'
}, {
  value: 'WD',
  translationKey: 'FINANCING.APPLICANT_INFO.MARITAL_STATUS_OPTIONS.WD'
}];
var _default = exports.default = {
  MARITAL_STATUS
};