"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _tabProtection = require("../selectors/tabProtection");
/**
 * @param {Object} state
 * @param {String} fniType
 * @param {String} contentType
 * @param {String} persistentId
 * @param {String} planPersistentId
 * @param {Object} payload
 * @returns {Object}
 */
var _default = (state, _ref) => {
  let {
    fniType,
    contentType,
    persistentId,
    planPersistentId,
    payload
  } = _ref;
  const fniTab = (0, _tabProtection.getFNITab)(state, fniType);
  const items = (0, _tabProtection.getFNIItems)(state, fniType, contentType);
  if ((0, _commonsUtils.isEmpty)(fniTab) || (0, _commonsUtils.isEmpty)(items)) {
    return state;
  }
  items.every((item, itemsIndex) => {
    if (item.persistentId === persistentId) {
      item.plans.every((plan, planIndex) => {
        if (plan.persistentId === planPersistentId) {
          if (contentType === 'warranties' || contentType === 'protections') {
            items[itemsIndex].plans[planIndex].protectionCoverageParameters = {
              ...items[itemsIndex].plans[planIndex].protectionCoverageParameters,
              ...payload
            };
          } else if (contentType === 'insurances') {
            items[itemsIndex].plans[planIndex].insuranceCoverageParameters = {
              ...items[itemsIndex].plans[planIndex].insuranceCoverageParameters,
              ...payload
            };
          }
          return false;
        }
        return true;
      });
      return false;
    }
    return true;
  });
  fniTab.content[contentType] = items;
  return {
    ...state,
    ...fniTab
  };
};
exports.default = _default;