"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PROFILE_FORM = void 0;
const translationPrefix = 'leads.updateView.contactForm';
const PROFILE_FORM = exports.PROFILE_FORM = [{
  legend: `${translationPrefix}.identity.TITLE`,
  fieldset: '',
  fields: [{
    input: 'profile',
    className: 'small-12 medium-4 large-3',
    skipLayout: true
  }]
}, {
  legend: `${translationPrefix}.contactInformation.TITLE`,
  fields: [{
    input: 'language',
    className: 'small-12 medium-4 large-3 align-self-top'
  }, {
    input: 'optInPreferences',
    className: 'small-12 medium-4 large-3'
  }, {}, {
    input: 'select',
    blockLabel: `${translationPrefix}.contactInformation.PREFERRED_CONTACT_MOMENT`,
    className: 'small-6 medium-3',
    name: 'contact.preferredContactMoment',
    optionLabelValue: 'label'
  }, {
    input: 'select',
    className: 'small-6 medium-3',
    name: 'contact.preferredContactTime',
    optionLabelValue: 'label'
  }, {}, {
    input: 'phones',
    className: 'small-12'
  }, {
    input: 'emails',
    className: 'small-12'
  }, {
    input: 'textarea',
    className: 'small-12 medium-6',
    name: 'contact.notes',
    label: 'Notes'
  }]
}, {
  legend: `${translationPrefix}.localization.TITLE`,
  fields: [{
    input: 'input',
    className: 'small-12 medium-8',
    label: `${translationPrefix}.localization.ADDRESS`,
    name: 'contact.address'
  }, {
    input: 'input',
    className: 'small-12 medium-8',
    label: '',
    name: 'contact.address2'
  }, {}, {
    input: 'postalCode',
    className: 'small-12 medium-3'
  }, {
    input: 'input',
    className: 'small-12 medium-5',
    label: `${translationPrefix}.localization.CITY`,
    name: 'contact.city'
  }, {}, {
    input: 'territories',
    className: 'small-12 medium-4',
    label: `${translationPrefix}.localization.PROVINCE`,
    name: 'contact.province',
    optionLabelValue: 'label'
  }, {
    input: 'select',
    className: 'small-12 medium-4',
    label: `${translationPrefix}.localization.COUNTRY`,
    name: 'contact.country',
    optionLabelValue: 'label',
    searchable: true
  }]
}, {
  legend: `${translationPrefix}.contactTags.TITLE`,
  fieldset: '',
  fields: [{
    input: 'contactContactTags',
    label: `${translationPrefix}.contactTags.TAGS`,
    className: 'small-12 medium-5',
    name: 'contact.contactContactTags',
    optionLabelValue: 'label'
  }]
}
// {
//     legend: 'Contact Information',
//     fieldset: '',
//     fields: [
//         {
//             input: 'input',
//             className: 'small-12 medium-6',
//             label: `${translationPrefix}.FIRST_NAME`,
//             name: 'contact.firstname',
//         },
//         {
//             input: 'input',
//             className: 'small-12 medium-6',
//             label: `${translationPrefix}.LAST_NAME`,
//             name: 'contact.lastname',
//         },
//         {
//             input: 'input',
//             className: 'small-12 medium-6',
//             label: `${translationPrefix}.MIDDLE_NAME`,
//             name: 'contact.middlename',
//         },
//         {
//             input: 'input',
//             className: 'small-12 medium-6',
//             label: `${translationPrefix}.BUSINESS_NAME`,
//             name: 'contact.businessName',
//         },
//         {
//             input: 'phone',
//             className: 'small-12 medium-6',
//             label: `${translationPrefix}.HOME_PHONE`,
//             name: 'contact.homePhone',
//         },
//         {
//             input: 'phone',
//             className: 'small-12 medium-6',
//             label: `${translationPrefix}.MOBILE_PHONE`,
//             name: 'contact.mobilePhone',
//         },
//         {
//             input: 'phone',
//             className: 'small-12 medium-6',
//             label: `${translationPrefix}.WORK_PHONE`,
//             name: 'contact.workPhone',
//             addon: true,
//         },
//         {
//             input: 'inputEmail',
//             className: 'small-12 medium-6',
//             label: `${translationPrefix}.EMAIL1`,
//             name: 'contact.email1',
//         },
//         {
//             input: 'inputEmail',
//             className: 'small-12 medium-6',
//             label: `${translationPrefix}.EMAIL2`,
//             name: 'contact.email2',
//         },
//         {
//             input: 'datePicker',
//             className: 'small-12 medium-6',
//             label: `${translationPrefix}.BIRTH_DATE`,
//             name: 'contact.dateOfBirth',
//         },
//         {
//             input: 'select',
//             className: 'small-12 medium-6',
//             label: `${translationPrefix}.GENDER`,
//             name: 'contact.sexId',
//             optionLabelValue: 'label',
//         },
//         {
//             input: 'radioGroup',
//             className: 'small-12 medium-6',
//             label: 'languageId',
//             name: 'contact.contactLanguage',
//             inline: true,
//             checkboxOption: [
//                 {
//                     label: 'English',
//                 },
//                 {
//                     label: 'French',
//                 },
//             ],
//         },
//         {
//             input: 'checkboxGroup',
//             className: 'small-12 medium-6',
//             label: 'preferredContactType',
//             name: 'contact.preferredContactType',
//             inline: true,
//             checkboxOption: [
//                 {
//                     label: 'optInPhone',
//                     name: 'optInPhone',
//                 },
//                 {
//                     label: 'optInSms',
//                     name: 'optInSms',
//                 },
//                 {
//                     label: 'optInEmail',
//                     name: 'optInEmail',
//                 },
//             ],
//         },
//         {
//             input: 'select',
//             className: 'small-6 medium-3',
//             label: `${translationPrefix}.PREFERRED_CONTACT_MOMENT`,
//             name: 'contact.preferredContactMoment',
//             optionLabelValue: 'label',
//         },
//         {
//             input: 'select',
//             className: 'small-6 medium-3',
//             // label: 'preferredContactMoment',
//             name: 'contact.preferredContactTime',
//             optionLabelValue: 'label',
//         },
//         {
//             input: 'textarea',
//             className: 'small-12 medium-6',
//             label: `${translationPrefix}.NOTES`,
//             name: 'contact.notes',
//         },
//         {
//             input: 'input',
//             className: 'small-12 medium-6',
//             label: `${translationPrefix}.ADDRESS`,
//             name: 'contact.address',
//         },
//         {
//             input: 'input',
//             className: 'small-12 medium-6',
//             label: `${translationPrefix}.ADDRESS2`,
//             name: 'contact.address2',
//         },
//         {
//             input: 'input',
//             className: 'small-12 medium-6',
//             label: `${translationPrefix}.CITY`,
//             name: 'contact.city',
//         },
//         {
//             input: 'territories',
//             className: 'small-12 medium-6',
//             label: `${translationPrefix}.PROVINCE`,
//             name: 'contact.province',
//             optionLabelValue: 'label',
//         },
//         {
//             input: 'select',
//             className: 'small-12 medium-6',
//             label: `${translationPrefix}.COUNTRY`,
//             name: 'contact.country',
//             optionLabelValue: 'label',
//         },
//         {
//             input: 'input',
//             className: 'small-12 medium-6',
//             label: `${translationPrefix}.POSTALCODE`,
//             name: 'contact.postalCode',
//         },
//         {
//             input: 'input',
//             className: 'small-12 medium-6',
//             label: `${translationPrefix}.CONTACT_CAMPAIGN_TAGS`,
//             name: 'contactContactTags',
//         },
//     ],
// },
];