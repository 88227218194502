"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _reactRedux = require("react-redux");
var _actions = require("../../redux/actions");
var _stepTrim = require("../../redux/selectors/stepTrim");
var _TrimChoice = _interopRequireDefault(require("./TrimChoice"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} state
 * @returns {Object}
 */
const mapStateToProps = state => ({
  bodyClassifications: (0, _stepTrim.getBodyClassifications)(state),
  bodyDimensions: (0, _stepTrim.getBodyDimensions)(state),
  modelTrims: (0, _stepTrim.getModelTrims)(state)
});

/**
 * @param {Function} dispatch
 * @returns {Object}
 */
const mapDispatchToProps = dispatch => ({
  selectTrim: (configurationId, modelTrimCode) => dispatch(_actions.Creators.selectTrim(configurationId, modelTrimCode))
});
var _default = exports.default = (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_TrimChoice.default);