"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getVehicles = exports.getAddedVehicles = void 0;
/**
 * @param {Object} state
 * @returns {Array}
 */
const getAddedVehicles = state => {
  const {
    addedVehicles
  } = state;
  return Array.isArray(addedVehicles) ? addedVehicles : [];
};

/**
 * @param {Object} state
 * @returns {Array}
 */
exports.getAddedVehicles = getAddedVehicles;
const getVehicles = state => {
  const {
    vehicles
  } = state;
  return Array.isArray(vehicles) ? vehicles : [];
};
exports.getVehicles = getVehicles;