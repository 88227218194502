"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("react");
const useEventListener = function (eventName, callback) {
  let element = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : window;
  const callbackRef = (0, _react.useRef)(callback);
  (0, _react.useEffect)(() => {
    callbackRef.current = callback;
  }, [callback]);
  (0, _react.useEffect)(() => {
    if (!element) return;
    const handler = e => callbackRef.current(e);
    element.addEventListener(eventName, handler);
    return () => element.removeEventListener(eventName, handler);
  }, [eventName, element]);
};
var _default = exports.default = useEventListener;