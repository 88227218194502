"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.willUserConnectionExpireSoon = exports.isUserConnected = exports.getUserOAuthProvider = exports.getUserConnectionStatus = exports.getUserConnectionRemainingDays = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _constants = require("./constants");
const getUserConnectionRemainingDays = user => {
  if (!(0, _commonsUtils.validDate)(user?.maxTokenExpirationDate)) return NaN;
  const now = new Date();
  const maxExpirationDate = new Date(user.maxTokenExpirationDate);

  // add 1 day offset to include last day in the remaining count
  maxExpirationDate.setTime(maxExpirationDate.getTime() + 24 * 60 * 60 * 1000);
  const diffInDays = Math.trunc((maxExpirationDate - now) / 86400000);
  return diffInDays;
};
exports.getUserConnectionRemainingDays = getUserConnectionRemainingDays;
const isUserConnected = user => {
  return user?.imapConnectionStatus === _constants.CONNECTION_STATUS.CONNECTED;
};
exports.isUserConnected = isUserConnected;
const getUserOAuthProvider = user => {
  return (0, _commonsUtils.nullOrString)(user?.oauthProvider?.type);
};
exports.getUserOAuthProvider = getUserOAuthProvider;
const getUserConnectionStatus = user => {
  return user?.imapConnectionStatus;
};
exports.getUserConnectionStatus = getUserConnectionStatus;
const willUserConnectionExpireSoon = user => {
  const remainingDaysBeforeExpiration = getUserConnectionRemainingDays(user);
  const userConnected = isUserConnected(user);
  const userConnectedToOAuthProvider = getUserOAuthProvider(user);
  return userConnected && userConnectedToOAuthProvider && !Number.isNaN(remainingDaysBeforeExpiration) && remainingDaysBeforeExpiration <= 10;
};
exports.willUserConnectionExpireSoon = willUserConnectionExpireSoon;