"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _selectors = require("../selectors");
var _default = (state, payload) => {
  const {
    queryParams
  } = payload;
  const {
    lang,
    location,
    organizationId,
    organizationUnitId,
    contactId: inputContactId,
    accountId: inputAccountId
  } = queryParams;
  const parsedAccountId = parseInt(inputAccountId, 10);
  const accountId = !Number.isNaN(parsedAccountId) ? parsedAccountId : 0;
  const parsedContactId = parseInt(inputContactId, 10);
  const contactId = !Number.isNaN(parsedContactId) && parsedContactId > 0 ? parsedContactId : null;
  const dealerLocation = (0, _selectors.getDealerLocation)(state);
  return {
    ...state,
    config: {
      lang,
      location: location || dealerLocation,
      organizationId,
      organizationUnitId,
      contactId,
      accountId
    }
  };
};
exports.default = _default;