"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateEmailTemplate = exports.getPaginatedEmailTemplates = exports.getFilledEmailTemplate = exports.getEmailTemplates = exports.getEmailTemplateTags = exports.getEmailTemplate = exports.deleteEmailTemplate = exports.createEmailTemplate = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _request = require("../../tools/request");
const CRM_API_URL = process.env.REACT_APP_CRM_API_URL;

/**
 * Returns a paginated sample of the mail templates (will also return the mail templates french/english content)
 * @param {Object} filters
 * @param {Number} pageSize
 * @param {Number} pageIndex
 * @param {Array} request
 * @param {Array} sortBy
 * @returns {Promise}
 */
const getPaginatedEmailTemplates = _ref => {
  let {
    filters,
    pageSize,
    pageIndex,
    request,
    sortBy
  } = _ref;
  const newQuery = {
    filters: {
      ...filters,
      searchTerms: request ? [(0, _commonsUtils.sanitizeSearch)(request)] : []
    },
    meta: {
      from: pageIndex,
      size: pageSize,
      sort: sortBy
    }
  };
  return (0, _request.post)(`${CRM_API_URL}/emailTemplates/search`, newQuery, null, null, 'getPaginatedEmailTemplates');
};

/**
 *  Returns all the mail templates for a given set of dealers (will only return the id, title and subscriptionContextSlug for each of them)
 * @param {Array} organizationUnitIds
 * @returns {Promise}
 */
exports.getPaginatedEmailTemplates = getPaginatedEmailTemplates;
const getEmailTemplates = function () {
  let organizationUnitIds = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return (0, _request.get)(`${CRM_API_URL}/emailTemplates/titles?${organizationUnitIds.map(id => `organizationUnitId=${id}`).join('&')}`, null, null, null, 'getEmailTemplates');
};

/**
 * @param {Number} id
 * @returns {Promise}
 */
exports.getEmailTemplates = getEmailTemplates;
const getEmailTemplate = id => {
  return (0, _request.get)(`${CRM_API_URL}/emailTemplates/${id}`, null, null, 'getEmailTemplate');
};

/**
 * @param {Object} body
 * @returns {Promise}
 */
exports.getEmailTemplate = getEmailTemplate;
const createEmailTemplate = body => {
  return (0, _request.post)(`${CRM_API_URL}/emailTemplates`, body, null, null, 'createEmailTemplate');
};

/**
 * @param {Number} id
 * @param {Object} body
 * @returns {Promise}
 */
exports.createEmailTemplate = createEmailTemplate;
const updateEmailTemplate = body => {
  return (0, _request.put)(`${CRM_API_URL}/emailTemplates/${body.id}`, body, null, null, 'updateEmailTemplate');
};

/**
 * @param {Number} id
 * @returns {Promise}
 */
exports.updateEmailTemplate = updateEmailTemplate;
const deleteEmailTemplate = id => {
  return (0, _request.deleteHttp)(`${CRM_API_URL}/emailTemplates/${id}`, null, null, null, 'deleteEmailTemplate');
};

/**
 * @returns {Promise}
 */
exports.deleteEmailTemplate = deleteEmailTemplate;
const getEmailTemplateTags = () => {
  return (0, _request.get)(`${CRM_API_URL}/emailTemplates/tags`, null, null, 'getEmailTemplateTags');
};

/**
 * @param {Number} id
 * @param {String} lang
 * @param {Number} contactId
 * @param {Number} leadId
 * @param {Number} organizationUnitId
 * @param {String} to
 * @param {Number} userId
 * @returns {Promise}
 */
exports.getEmailTemplateTags = getEmailTemplateTags;
const getFilledEmailTemplate = _ref2 => {
  let {
    id,
    lang,
    contactId,
    leadId,
    organizationUnitId,
    to,
    userId
  } = _ref2;
  const leadIdQuery = leadId ? `&leadId=${leadId}` : '';
  const organizationUnitIdQuery = `&organizationUnitId=${organizationUnitId || -1}`;
  return (0, _request.get)(`${CRM_API_URL}/emailTemplates/${id}/filled?contactId=${contactId}&to=${to}&userId=${userId}&language=${lang}${leadIdQuery}${organizationUnitIdQuery}`, null, null, 'getFilledEmailTemplate');
};
exports.getFilledEmailTemplate = getFilledEmailTemplate;