"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _common = require("../../../../redux/selectors/common");
var _actions = require("../../../../redux/actions");
var _CheckboxTaxes = _interopRequireDefault(require("./CheckboxTaxes"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} state - Global state
 * @returns {Object} injected props
 */
const mapStateToProps = state => {
  const hasTaxesIncluded = (0, _common.getHasTaxesIncluded)(state);
  return {
    checked: hasTaxesIncluded
  };
};

/**
 * @param {Function} dispatch
 */
const mapDispatchToProps = dispatch => ({
  displayTaxes: isTaxesIncludedChecked => dispatch(_actions.Creators.updateTaxesIncluded(isTaxesIncludedChecked))
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps))(_CheckboxTaxes.default);