"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _deskingCommons = require("@sm360/desking-commons");
var _actions = require("../actions");
var _selectors = require("../selectors");
var _hasDeskingAccess = _interopRequireDefault(require("./hasDeskingAccess"));
var _hasCatalogPricesAccess = _interopRequireDefault(require("./hasCatalogPricesAccess"));
var _reload = _interopRequireDefault(require("./reload"));
var _getDealerInfo = _interopRequireDefault(require("./getDealerInfo"));
var _getDealersList = _interopRequireDefault(require("./getDealersList"));
var _getLeadInfo = _interopRequireDefault(require("./getLeadInfo"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _default(_ref) {
  let {
    queryParams
  } = _ref;
  return function* () {
    const hasAccess = yield (0, _effects.call)(_hasDeskingAccess.default);
    const leadId = yield (0, _effects.select)(_selectors.getLeadId);
    yield (0, _effects.put)(_actions.Creators.setPermission({
      hasDeskingAccess: hasAccess
    }));
    if (hasAccess) {
      if (leadId) {
        yield (0, _effects.call)(_getLeadInfo.default);
      }
      yield (0, _effects.call)(_getDealerInfo.default);
      yield (0, _effects.call)(_getDealersList.default);
      yield (0, _effects.fork)(_hasCatalogPricesAccess.default);
      yield (0, _effects.call)(_reload.default);
      yield (0, _effects.put)(_deskingCommons.priceIncreaseActions.setPriceIncreaseRollCount(queryParams.upg));
      yield (0, _effects.put)(_actions.Creators.setLoadingSuccess());
    }
  }();
}