"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _deskingError = require("@sm360/desking-error");
var _selectors = require("../../../redux/selectors");
var _TabAccounting = _interopRequireDefault(require("./TabAccounting.js"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const mapStateToProps = state => ({
  templates: (0, _selectors.getAccountingTemplates)(state),
  selectedTemplate: (0, _selectors.getAccSelectedTemplate)(state),
  selectedTemplateData: (0, _selectors.getAccSelectedTemplateData)(state)
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps), _deskingError.withErrorWrapper)(_TabAccounting.default);