"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateSignature = exports.getSignatures = exports.getSignature = exports.deleteSignature = exports.createSignature = void 0;
var _appsCore = require("@sm360/apps-core");
const NEW_CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;

// Get signatures list
const getSignatures = async userId => {
  return await _appsCore.axios.get(`${NEW_CRM_ENDPOINT}/users/signatures?userId=${userId}`);
};
exports.getSignatures = getSignatures;
const getSignature = async id => {
  return await _appsCore.axios.get(`${NEW_CRM_ENDPOINT}/users/signatures/${id}`);
};
exports.getSignature = getSignature;
const createSignature = async body => {
  return await _appsCore.axios.post(`${NEW_CRM_ENDPOINT}/users/signatures`, body);
};
exports.createSignature = createSignature;
const updateSignature = async body => {
  const signatureId = body?.id;
  return await _appsCore.axios.put(`${NEW_CRM_ENDPOINT}/users/signatures/${signatureId}`, body);
};
exports.updateSignature = updateSignature;
const deleteSignature = async id => {
  return await _appsCore.axios.delete(`${NEW_CRM_ENDPOINT}/users/signatures/${id}`);
};
exports.deleteSignature = deleteSignature;