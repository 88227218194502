"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = (state, payload) => {
  const {
    vehicleId,
    quoteId
  } = payload;
  return {
    ...state,
    addedVehicles: [...state.addedVehicles, {
      vehicleId,
      quoteId
    }]
  };
};
exports.default = _default;