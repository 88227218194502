"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _content = require("../../../redux/selectors/content");
var _StepTrim = _interopRequireDefault(require("./StepTrim"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} state
 * @returns {Object}
 */
const mapStateToProps = state => ({
  modelName: (0, _content.getModelName)(state)
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps))(_StepTrim.default);