"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _selectors = require("../selectors");
var _updatePayOptAndTransDetails = _interopRequireDefault(require("./api/updatePayOptAndTransDetails"));
var _reloadVehicle = _interopRequireDefault(require("./reloadVehicle"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @TODO Should find a way to reload the vehicle colour,
 * a change/conflict of items can lead to a change of colour.
 */
function* _default() {
  const paymentOptions = yield (0, _effects.select)(_selectors.getPaymentOptions);
  yield (0, _effects.call)(_updatePayOptAndTransDetails.default, paymentOptions);
  yield (0, _effects.fork)(_reloadVehicle.default);
}