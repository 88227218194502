"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NOTIFICATION_TYPE = exports.NOTIFICATION_LEVEL = void 0;
const NOTIFICATION_LEVEL = exports.NOTIFICATION_LEVEL = {
  LOW: 'low',
  MEDIUM: 'medium',
  HIGH: 'high'
};
const NOTIFICATION_TYPE = exports.NOTIFICATION_TYPE = {
  PRICING: 'pricing',
  RESIDUAL: 'residual',
  INCENTIVE: 'incentive',
  FINANCE: 'finance'
};