"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFinancingDatesBody = void 0;
var _selectors = require("../selectors");
// TODO investigate the backend error the frontend receives when vehicleId is added (as expected) to Financing Dates body
// import { getVehicleId } from './vehicle';
/**
 * @param {Object} state
 * @param {Object} values
 * @returns {Object}
 */
const getFinancingDatesBody = (state, dates, quoteRevision) => {
  const {
    id,
    leadId,
    accountId
  } = (0, _selectors.getQuote)(state);

  // const vehicleId = getVehicleId(state);

  return {
    id,
    leadId,
    accountId,
    // vehicleId,
    quoteRevision,
    ...dates
  };
};
exports.getFinancingDatesBody = getFinancingDatesBody;