"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PROVIDERS_METADATA = exports.PROVIDERS = exports.ISSUE_TYPE = exports.ISSUE_SEVERITY_COLOR = exports.ISSUE_SEVERITY = exports.CONNECTION_STATUS = void 0;
const CONNECTION_STATUS = exports.CONNECTION_STATUS = {
  CONNECTED: 'CONNECTED',
  BAD_CREDENTIALS: 'BAD_CREDENTIALS',
  SERVER_UNAVAILABLE: 'SERVER_UNAVAILABLE',
  UNKNOWN: 'UNKNOWN',
  DISCONNECTED: null
};
const PROVIDERS = exports.PROVIDERS = {
  MICROSOFT: 'MICROSOFT',
  GOOGLE: 'GOOGLE',
  THIRDPARTY: null
};
const PROVIDERS_METADATA = exports.PROVIDERS_METADATA = [{
  type: PROVIDERS.MICROSOFT,
  iconUrl: 'https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg',
  loginTransKey: 'profile-imap-config.login.MICROSOFT'
}, {
  type: PROVIDERS.GOOGLE,
  iconUrl: 'https://gitlab.com/assets/auth_buttons/google_64-9ab7462cd2115e11f80171018d8c39bd493fc375e83202fbb6d37a487ad01908.png',
  loginTransKey: 'profile-imap-config.login.GOOGLE'
}, {
  type: PROVIDERS.THIRDPARTY,
  loginTransKey: 'profile-imap-config.login.MANUAL'
}];
const ISSUE_TYPE = exports.ISSUE_TYPE = {
  CONNECTION_FAILED: 'CONNECTION_FAILED',
  OAUTH_TOKEN_EXPIRED: 'OAUTH_TOKEN_EXPIRED',
  OAUTH_TOKEN_WILL_EXPIRE: 'OAUTH_TOKEN_WILL_EXPIRE'
};
const ISSUE_SEVERITY = exports.ISSUE_SEVERITY = {
  WARNING: 'warn',
  ERROR: 'error'
};
const ISSUE_SEVERITY_COLOR = exports.ISSUE_SEVERITY_COLOR = {
  [ISSUE_SEVERITY.WARNING]: 'warning',
  [ISSUE_SEVERITY.ERROR]: 'alert'
};