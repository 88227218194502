"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _paymentOptions = require("../../../../redux/selectors/paymentOptions");
var _actions = require("../../../../redux/actions");
var _InputLien = _interopRequireDefault(require("./InputLien"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} state - Global state
 * @returns {Object} injected props
 */
const mapStateToProps = state => {
  const paymentOptions = (0, _paymentOptions.getPaymentOptions)(state);
  const {
    lien
  } = paymentOptions;
  return {
    lienAmount: lien
  };
};

/**
 * @param {Function} dispatch
 * @returns {Object} injected props
 */
const mapDispatchToProps = dispatch => ({
  updateLien: amount => dispatch(_actions.Creators.updateLien(amount))
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps))(_InputLien.default);