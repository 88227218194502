"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _deskingError = require("@sm360/desking-error");
var _actions = require("../../../redux/actions");
var _selectors = require("../../../redux/selectors");
var _finance = require("../../../redux/selectors/finance");
var _TabPaymentOptions = _interopRequireDefault(require("./TabPaymentOptions"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} state
 * @returns {Object}
 */
const mapStateToProps = state => {
  const index = (0, _selectors.getSelectedFinanceOptionsIndex)(state);
  return {
    financeOptions: (0, _selectors.getFinanceOptions)(state),
    financePlans: (0, _selectors.getMetaFinancePlans)(state),
    selectedIndex: index,
    kmPerYearPlanDetails: (0, _finance.getKmPerYearPlanDetails)(state)
  };
};
const mapDispatchToProps = dispatch => ({
  selectPlan: index => dispatch(_actions.Creators.selectPlan(index))
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps), _deskingError.withErrorWrapper)(_TabPaymentOptions.default);