"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/**
 * @param {Object} state
 * @param {Object} payload - response
 * @returns {Object} new state
 */
var _default = (state, payload) => {
  const {
    transactionToken
  } = payload;
  return {
    ...state,
    paymentOptions: {
      ...state.paymentOptions,
      transactionToken: transactionToken.transactionToken
    }
  };
};
exports.default = _default;