"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _selectors = require("../../redux/selectors");
var _actions = require("../../redux/actions");
var _Pagination = _interopRequireDefault(require("./Pagination"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} state
 * @returns {Object}
 */
const mapStateToProps = state => {
  const {
    pageNumber: currentPage,
    pageSize: size,
    totalElements: total
  } = (0, _selectors.getPagination)(state);
  return {
    currentPage,
    size,
    total
  };
};

/**
 * @param {Function} dispatch
 * @returns {Object}
 */
const mapDispatchToProps = dispatch => ({
  setCurrentPage: page => dispatch(_actions.Creators.setCurrentPage(page))
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps))(_Pagination.default);