"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _actions = require("../../actions");
var _selectors = require("../../selectors");
function _default(_ref) {
  let {
    requestBody,
    checkIfPassed
  } = _ref;
  return function* () {
    const {
      contactId,
      organizationUnitId
    } = yield (0, _effects.select)(_selectors.getConfig);
    const queryParams = {
      organizationUnitId
    };
    try {
      const response = yield (0, _effects.call)(_commonsApi.ContactService.updateContactInformation, contactId, queryParams, requestBody);
      checkIfPassed();
      yield (0, _effects.put)(_actions.Creators.reloadContactSummary());
      return response;
    } catch (error) {
      yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'updateContact'));
      return null;
    }
  }();
}