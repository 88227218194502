"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _selectors = require("../selectors");
/**
 * Only update the total elements in pagination
 */
var _default = (state, _ref) => {
  let {
    result
  } = _ref;
  const pagination = (0, _selectors.getPagination)(state);
  const {
    totalElements,
    vehicles
  } = result;
  const total = parseInt(totalElements, 10);
  return {
    ...state,
    pagination: {
      ...pagination,
      totalElements: Number.isInteger(total) ? total : 0
    },
    vehicles
  };
};
exports.default = _default;