"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateLeadStatus = exports.getDuplicates = exports.deleteLead = void 0;
var _appsCore = require("@sm360/apps-core");
const CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;
const deleteLead = async _ref => {
  let {
    leadId
  } = _ref;
  return await _appsCore.axios.delete(`${CRM_ENDPOINT}/leads/${leadId}`);
};
exports.deleteLead = deleteLead;
const updateLeadStatus = async (leadId, newStatusId, userId, desc) => {
  return await _appsCore.axios.put(`${CRM_ENDPOINT}/leads/${leadId}/leadStatus?leadStatusId=${newStatusId}&loggedUserId=${userId}&description=${desc}`);
};
exports.updateLeadStatus = updateLeadStatus;
const getDuplicates = async leadId => {
  return await _appsCore.axios.get(`${CRM_ENDPOINT}/leads/${leadId}/duplicates`);
};
exports.getDuplicates = getDuplicates;