"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _stepSummary = require("../../../redux/selectors/stepSummary");
var _selectors = require("../../../redux/selectors");
var _StepSummary = _interopRequireDefault(require("./StepSummary"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} state
 * @returns {Object}
 */
const mapStateToProps = state => ({
  perfectMatches: (0, _stepSummary.getPerfectMacthes)(state),
  similarMatches: (0, _stepSummary.getSimilarMatches)(state),
  isHidingPrices: (0, _selectors.getIsHidingPrices)(state)
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps))(_StepSummary.default);