"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setPriceIncreaseRollCount = exports.reducers = exports.Types = exports.Creators = void 0;
var _reduxsauce = require("reduxsauce");
const {
  Types,
  Creators
} = (0, _reduxsauce.createActions)({
  setPriceIncreaseRollCount: ['priceIncreaseRollCount']
});
exports.Creators = Creators;
exports.Types = Types;
const setPriceIncreaseRollCount = (state, _ref) => {
  let {
    priceIncreaseRollCount
  } = _ref;
  const parsedCount = parseInt(priceIncreaseRollCount, 10);
  return {
    ...state,
    priceIncreaseRollCount: Number.isNaN(parsedCount) ? 0 : parsedCount
  };
};
exports.setPriceIncreaseRollCount = setPriceIncreaseRollCount;
const reducers = exports.reducers = {
  [Types.SET_PRICE_INCREASE_ROLL_COUNT]: setPriceIncreaseRollCount
};