"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _validationHelpers = require("./validationHelpers");
/**
 * - We receive the new negociationTransaction params from the user choice, and put it in the store
 * - The saga takes car of reloading the current step with these new params
 *
 * @param {Object} state
 * @param {Object} payload - { negociationTransaction }
 * @returns {Object}
 */
var _default = (state, _ref) => {
  let {
    negociationTransaction
  } = _ref;
  return {
    ...state,
    ...(0, _validationHelpers.applyNegociationTransaction)(state, negociationTransaction)
  };
};
exports.default = _default;