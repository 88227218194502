"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Contacts", {
  enumerable: true,
  get: function () {
    return _Contacts.default;
  }
});
Object.defineProperty(exports, "CreateContact", {
  enumerable: true,
  get: function () {
    return _CreateContact.default;
  }
});
exports.default = void 0;
var _Contacts = _interopRequireDefault(require("./Contacts"));
var _CreateContact = _interopRequireDefault(require("./CreateContact"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var _default = exports.default = _Contacts.default;