"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.timeStampToDate = exports.dateToReadableStringFormat = void 0;
const EN_LANG = 'en';
const UNI_LANG = 'uni';
const isFormatDate = value => {
  return value instanceof Date && Object.prototype.toString.call(value) === '[object Date]';
};
const isFormatTimeStamp = value => {
  return typeof value === 'number';
};
const isDateValid = date => {
  return date instanceof Date && !Number.isNaN(date);
};
const getDate = timeStamp => {
  let t = timeStamp;
  if (!isFormatTimeStamp(t)) {
    t = parseInt(t, 10);
  }
  // js timeStamp is in ms and unix timeStamp in s
  return new Date(t * 1000);
};
const timeStampToDate = timeStamp => {
  if (timeStamp) {
    const date = getDate(timeStamp);
    return isDateValid(date) ? date : null;
  }
  return null;
};
// TODO define this function globally (or even the whole file)
exports.timeStampToDate = timeStampToDate;
const dateToReadableStringFormat = (date, lang) => {
  if (date && isFormatDate(date)) {
    const d = date.getDate();
    const m = date.getMonth() + 1;
    const day = d < 10 ? '0'.concat(d).toString() : d.toString();
    const month = m < 10 ? '0'.concat(m).toString() : m.toString();
    const year = date.getFullYear().toString();
    if (lang.search(EN_LANG) !== -1 || lang.search(EN_LANG.toUpperCase()) !== -1) {
      return month.concat('/', day, '/', year);
    }
    if (lang.search(UNI_LANG) !== -1 || lang.search(UNI_LANG.toUpperCase()) !== -1) {
      return year.concat('/', month, '/', day);
    }
    return day.concat('/', month, '/', year);
  }
  return null;
};
exports.dateToReadableStringFormat = dateToReadableStringFormat;