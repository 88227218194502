"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PURCHASE_METHOD_LEASE = exports.PURCHASE_METHOD_FINANCE = exports.PURCHASE_METHOD_DEFAULT = exports.PURCHASE_METHOD_CASH = exports.PURCHASE_METHODS = exports.KEY_TRADEIN = exports.KEY_TERM = exports.KEY_LIEN = exports.KEY_KMPLAN = exports.KEY_FREQUENCY = exports.KEY_CASHDOWN = void 0;
const KEY_CASHDOWN = exports.KEY_CASHDOWN = 'cashDown';
const KEY_FREQUENCY = exports.KEY_FREQUENCY = 'paymentFrequency';
const KEY_LIEN = exports.KEY_LIEN = 'lien';
const KEY_KMPLAN = exports.KEY_KMPLAN = 'kmPerYearPlan';
const KEY_TERM = exports.KEY_TERM = 'term';
const KEY_TRADEIN = exports.KEY_TRADEIN = 'tradeIn';
const PURCHASE_METHOD_CASH = exports.PURCHASE_METHOD_CASH = 'cash';
const PURCHASE_METHOD_FINANCE = exports.PURCHASE_METHOD_FINANCE = 'finance';
const PURCHASE_METHOD_LEASE = exports.PURCHASE_METHOD_LEASE = 'lease';
const PURCHASE_METHOD_DEFAULT = exports.PURCHASE_METHOD_DEFAULT = PURCHASE_METHOD_CASH;
const PURCHASE_METHODS = exports.PURCHASE_METHODS = [PURCHASE_METHOD_CASH, PURCHASE_METHOD_FINANCE, PURCHASE_METHOD_LEASE];