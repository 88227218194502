"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Label", {
  enumerable: true,
  get: function () {
    return _Label.default;
  }
});
exports.default = void 0;
var _FormFields = _interopRequireDefault(require("./FormFields"));
var _Label = _interopRequireDefault(require("./components/Label"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var _default = exports.default = _FormFields.default;