"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUsers = exports.getUserSignatures = exports.getUserPhoneNumbers = exports.getTaskType = exports.getSmsNumbers = exports.getAppointmentTypes = void 0;
var _appsCore = require("@sm360/apps-core");
var _commonsUtils = require("@sm360/commons-utils");
const CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;

// Organization related
const getSmsNumbers = async _ref => {
  let {
    dispatch,
    organizationId,
    allowedOrganizationUnitIds = [],
    organizationUnitId
  } = _ref;
  dispatch({
    type: 'fetching'
  });
  const smsNumbers = await _appsCore.axios.get(`${CRM_ENDPOINT}/smsNumbers?organizationId=${organizationId}`);

  // Only keep the numbers with a dealer that the user has access to
  let restrictedSmsNumbers = [...smsNumbers.data.filter(n => allowedOrganizationUnitIds.includes(n.organizationUnitId))];
  if (organizationUnitId > 0) restrictedSmsNumbers = restrictedSmsNumbers.filter(n => n.organizationUnitId === organizationUnitId);
  dispatch({
    type: 'setStaticData',
    staticData: ['smsNumbers', restrictedSmsNumbers]
  });
};

// User related
exports.getSmsNumbers = getSmsNumbers;
const getUserSignatures = async _ref2 => {
  let {
    dispatch,
    user
  } = _ref2;
  let userSignatures = await _appsCore.axios.get(`${CRM_ENDPOINT}/users/signatures?userId=${user.id}`);
  userSignatures = userSignatures.data.map(t => ({
    id: t.id,
    label: t.title,
    value: t.signature,
    default: t.defaultMailSignature
  })).sort((a, b) => a.label.localeCompare(b.label));
  dispatch({
    type: 'setStaticData',
    staticData: ['userSignatures', userSignatures]
  });
};

// Appointment related
exports.getUserSignatures = getUserSignatures;
const getAppointmentTypes = async _ref3 => {
  let {
    dispatch
  } = _ref3;
  const appointmentTypes = await _appsCore.axios.get(`${CRM_ENDPOINT}/activities/appointments/types`);
  dispatch({
    type: 'setStaticData',
    staticData: ['appointmentType', appointmentTypes.data.map(t => ({
      id: t.id,
      label: t.type,
      value: t.id
    })).sort((a, b) => a.label.localeCompare(b.label))]
  });
};

// Task related
exports.getAppointmentTypes = getAppointmentTypes;
const getTaskType = async _ref4 => {
  let {
    dispatch
  } = _ref4;
  const taskTypes = await _appsCore.axios.get(`${CRM_ENDPOINT}/tasks/types`);
  dispatch({
    type: 'setStaticData',
    staticData: ['taskType', taskTypes.data.map(t => ({
      id: t.id,
      label: t.type,
      value: t.id
    })).sort((a, b) => a.label.localeCompare(b.label))]
  });
};
exports.getTaskType = getTaskType;
const getUsers = async _ref5 => {
  let {
    key,
    dispatch,
    right,
    body = [],
    translate
  } = _ref5;
  const results = await _appsCore.axios.post(`${CRM_ENDPOINT}/users?activeOnly=true&withOrganizationUnitRight=${right}`, body);
  dispatch({
    type: 'setStaticData',
    staticData: [key, results.data.map(t => ({
      id: t.id,
      label: (0, _commonsUtils.getUserLabel)(t, translate),
      value: t.id,
      email: t.email
    })).sort((a, b) => (0, _commonsUtils.compareUserLabel)(a, b, translate))]
  });
};
exports.getUsers = getUsers;
const getUserPhoneNumbers = async userId => {
  return await _appsCore.axios.get(`${CRM_ENDPOINT}/users/phoneNumbers?userId=${userId}`);
};
exports.getUserPhoneNumbers = getUserPhoneNumbers;