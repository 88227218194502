"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSmsSentActivities = exports.getActivities = void 0;
var _appsCore = require("@sm360/apps-core");
const CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;
const getSmsSentActivities = async _ref => {
  let {
    dispatch,
    contactId
  } = _ref;
  const results = await _appsCore.axios.get(`${CRM_ENDPOINT}/activities/sms?contactId=${contactId}`);
  dispatch({
    type: 'setStaticData',
    staticData: ['smsActivities', results.data]
  });
};
exports.getSmsSentActivities = getSmsSentActivities;
const getActivities = async (contactId, leadId, displayForLeadOnly) => {
  if (displayForLeadOnly ? !leadId : !contactId) {
    return Promise.reject(new Error('Missing parameters for getting activities'));
  }
  if (displayForLeadOnly) {
    return await _appsCore.axios.get(`${CRM_ENDPOINT}/activities/filtered?leadId=${leadId}`);
  }
  return await _appsCore.axios.get(`${CRM_ENDPOINT}/activities/filtered?contactId=${contactId}`);
};
exports.getActivities = getActivities;