"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.DISBURSEMENT_TYPE = exports.DISBURSEMENT_PERIOD = void 0;
const DISBURSEMENT_PERIOD = exports.DISBURSEMENT_PERIOD = [{
  translationKey: 'FINANCING.INCOME_AND_PAYMENT_INFOS.DISBURSEMENT_PERIOD.WEEKLY',
  value: 'Weekly'
}, {
  translationKey: 'FINANCING.INCOME_AND_PAYMENT_INFOS.DISBURSEMENT_PERIOD.BI_WEEKLY',
  value: 'Bi-Weekly'
}, {
  translationKey: 'FINANCING.INCOME_AND_PAYMENT_INFOS.DISBURSEMENT_PERIOD.MONTHLY',
  value: 'Monthly'
}, {
  translationKey: 'FINANCING.INCOME_AND_PAYMENT_INFOS.DISBURSEMENT_PERIOD.YEARLY',
  value: 'Annually'
}, {
  translationKey: 'FINANCING.INCOME_AND_PAYMENT_INFOS.DISBURSEMENT_PERIOD.SEMI_MONTHLY',
  value: 'Semi-Monthly'
}, {
  translationKey: 'FINANCING.INCOME_AND_PAYMENT_INFOS.DISBURSEMENT_PERIOD.SEMI_ANNUALY',
  value: 'Semi-Annually'
}];
const DISBURSEMENT_TYPE = exports.DISBURSEMENT_TYPE = [{
  translationKey: 'FINANCING.INCOME_AND_PAYMENT_INFOS.DISBURSEMENT_TYPE.RENT',
  value: 'Rent'
}, {
  translationKey: 'FINANCING.INCOME_AND_PAYMENT_INFOS.DISBURSEMENT_TYPE.MORTGAGE',
  value: 'Mortgage'
}, {
  translationKey: 'FINANCING.INCOME_AND_PAYMENT_INFOS.DISBURSEMENT_TYPE.AUTO_LOAN',
  value: 'Auto Loan'
}, {
  translationKey: 'FINANCING.INCOME_AND_PAYMENT_INFOS.DISBURSEMENT_TYPE.LEASE',
  value: 'Lease'
}, {
  translationKey: 'FINANCING.INCOME_AND_PAYMENT_INFOS.DISBURSEMENT_TYPE.PERSONNAL_LOAN',
  value: 'Personnal Loan'
}, {
  translationKey: 'FINANCING.INCOME_AND_PAYMENT_INFOS.DISBURSEMENT_TYPE.OTHER',
  value: 'Other'
}];
var _default = exports.default = {
  DISBURSEMENT_PERIOD,
  DISBURSEMENT_TYPE
};