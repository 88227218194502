"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Types = exports.Creators = void 0;
var _reduxsauce = require("reduxsauce");
const {
  Types,
  Creators
} = (0, _reduxsauce.createActions)({
  setLoadingSuccess: [],
  selectVehicleSuccess: ['vehicleId', 'quoteId'],
  loadVehiclesSuccess: ['result'],
  setPermission: ['permission'],
  reloadVehiclesList: [],
  setLocation: ['location'],
  setPriceIncreaseRollCount: ['UPGValue'],
  setLeadInfo: ['leadInfo'],
  getLeadInfo: [],
  setDealersList: ['dealersList'],
  selectDealer: ['dealerId'],
  setOrganizationUnitId: ['organizationUnitId'],
  // Sagas
  openVehicleInDesking: ['vehicle'],
  openVehicleInBuilder: ['vehicle'],
  selectVehicle: ['vehicle'],
  initializeStore: ['queryParams'],
  setCurrentPage: ['page'],
  setPreferredQuote: ['quote'],
  // Payment options,
  updateCashDown: ['amount'],
  updateLien: ['amount'],
  updateTradeIn: ['amount'],
  updatePaymentFrequency: ['value'],
  updateTerm: ['value'],
  updateKmPlan: ['value'],
  selectPurchaseMethod: ['purchaseMethod'],
  updateTaxesIncluded: ['hasTaxesIncluded'],
  setPaymentOptionsMetadata: ['metadata'],
  // Filters
  applyFilters: [],
  applySearchText: ['value'],
  loadInventoryFiltersSuccess: ['response', 'lastUpdatedName'],
  resetFilters: [],
  sortVehicles: ['sortType', 'direction'],
  updateCategory: ['category'],
  updateFilterOptions: ['name', 'values'],
  updateSearchText: ['value'],
  updatePriceRange: ['selectedMinPrice', 'selectedMaxPrice'],
  updateOdometerRange: ['selectedMinOdometer', 'selectedMaxOdometer'],
  setOnlineVehiclesOnly: ['onlineVehiclesOnly'],
  setDealerVehiclesOnly: ['dealerVehiclesOnly']
});
exports.Creators = Creators;
exports.Types = Types;