"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transformDealersToOptions = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _utils = require("../../tools/utils");
const dealerHasDesking = dealer => {
  return !!dealer?.organizationUnitConfiguration?.desking;
};
const dealerHasShowroom360 = dealer => {
  const relatedConfigurations = ['showroomv2', 'showroomv2Catalog', 'showroomv2New', 'showroomv2Used', 'showroomv25', 'showroomv25Catalog', 'showroomv25New', 'showroomv25Used'];
  const dealerConfiguration = dealer?.organizationUnitConfiguration || {};
  return relatedConfigurations.some(config => dealerConfiguration?.[config]);
};
const transformDealersToOptions = function () {
  let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return data.map(d => ({
    id: d.id,
    value: (0, _commonsUtils.nullOrString)(d.id),
    label: d.name,
    address: `${d.address ? `${d.address} \n` : ''}` + `${d.city ? `${d.city} ` : ''}` + `${d.postalCode ? `${d.postalCode} ` : ''}` + `${d.region ? `, ${d.region}` : ''}` + `${d.countryCode ? `, ${d.countryCode}` : ''}`,
    provinceCode: d?.province1?.provinceCode,
    hasDesking: dealerHasDesking(d),
    hasShowroom360: dealerHasShowroom360(d)
  })).sort(_utils.compareByLabel);
};
exports.transformDealersToOptions = transformDealersToOptions;