"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.CURRENCY_0 = void 0;
const CURRENCY_0 = exports.CURRENCY_0 = {
  style: 'currency',
  currency: 'CAD',
  currencyDisplay: 'symbol',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
};
var _default = exports.default = {
  CURRENCY_0
};