"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getProvinces = exports.getDealersList = exports.getDealers = exports.getDealerInfo = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _request = require("../../tools/request");
const ORGANIZATION_CORE_URL = process.env.REACT_APP_ORGANIZATIONS_CORE_URL;
const XMS_API_URL = process.env.REACT_APP_XMS_API_URL;

// to reduce the object size before storing it in the cache
// because BE returns too much information we don't need
const stripDealer = dealer => (0, _commonsUtils.pick)(dealer, ['id', 'name', 'address', 'city', 'postalCode', 'region', 'countryCode', 'phone', 'province1', 'logo', 'url', 'organizationUnitConfiguration']);

/**
 * @param {String} orgUnitId
 * @returns {Object}
 */
const getDealerInfoRequest = orgUnitId => {
  return (0, _request.get)(`${ORGANIZATION_CORE_URL}/organizationUnits/${orgUnitId}`);
};

/**
 * @param {String} organizationId
 * @param {String} userId
 * @returns {Object}
 */
const getDealersList = (organizationId, userId) => (0, _request.get)(`${ORGANIZATION_CORE_URL}/organizations/${organizationId}/organizationUnits`, {
  params: {
    userId
  }
});
exports.getDealersList = getDealersList;
const getDealerInfo = async orgUnitId => getDealerInfoRequest(orgUnitId);

/**
 * @param {String} organizationId
 * @returns {Object}
 */
exports.getDealerInfo = getDealerInfo;
const getDealers = organizationId => (0, _request.get)(`${XMS_API_URL}/organizations/${organizationId}/organizationUnits`).then(response => Array.isArray(response) ? response.map(stripDealer) : []);

/**
 * @returns {Object}
 */
exports.getDealers = getDealers;
const getProvinces = () => (0, _request.get)(`${XMS_API_URL}/staticData/provinces`);
exports.getProvinces = getProvinces;